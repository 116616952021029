
const Hero = () => {
    return `
   <!-- Responsive left-aligned hero with image from bootstrap -->
<div class="container px-4 py-5 heroblock">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-3">
      <div class="col-10 col-sm-8 col-lg-6">
        <img id="IMGCreate" src="https://cdn.wum-solution.de//WUM/2023/01/23/md/Exkurs-UX-Design.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
      </div>
      <div class="col-lg-6">
        <h2 class="display-8 fw-bold lh-1 mb-4">Deine Subheadline.</h2>
        <p>Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte wie Hamburgefonts, Rafgenduks oder Handgloves, um Schriften zu testen. Manchmal Sätze, die alle Buchstaben des Alphabets enthalten – man nennt diese Sätze »Pangrams«.</p>
        <a href="#" class="btn btn-primary mb-3 mt-3">Dein Buttontext</a>
      </div>
    </div>
</div><div style="background-color: rgb(255, 255, 255);">Hier kannst du deinen Text Eintragen!</div>`
}

export default Hero;
