import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import AppendHTML from "./AppendHTML";

const ButtonEditModal = () => {

    return (
        <div
            className="modal show"
            id={'ButtonEdit'}
            style={{display: 'none'}}
        >
            <Modal.Dialog>
                <Modal.Header closeButton onHide={()=>CloseButtonEditModal()}>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Text:</Form.Label>
                            <Form.Control type="text" id='ButtonEditText' defaultValue={''}/>
                            <Form.Control type="hidden" id='ButtonEditID' defaultValue={''}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Link:</Form.Label>
                            <Form.Control type="text" id='ButtonEditLink' defaultValue={''}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Height:</Form.Label>
                            <Form.Control type="text" id='ButtonEditHeight' defaultValue={''} placeholder={'optional - Angabe in px, em oder % möglich'}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Width:</Form.Label>
                            <Form.Control type="text" id='ButtonEditWidth' defaultValue={''} placeholder={'optional - Angabe in px, em oder % möglich'}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Type:</Form.Label>
                        <Form.Select aria-label="Default select example" id={'ButonEditType'} defaultValue={''}>
                            <option value="submit">Submit</option>
                            <option value="button">Button</option>
                            <option value="Link">Link</option>
                        </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => CloseButtonEditModal()}>Schließen</Button>
                    <Button variant="primary" onClick={() => SaveButtonEditModal()}>Speichern</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}

export default ButtonEditModal;

export const ButtonEdit = (e: any) => {
    let ButtonField = e.target;
        ButtonField.id = 'EditButton'
        let Modal = document.getElementById('ButtonEdit') as HTMLDivElement;
        if (Modal !== null) {
            let text = document.getElementById('ButtonEditText') as HTMLInputElement
            text.value = ButtonField.innerText
            let id = document.getElementById('ButtonEditID') as HTMLInputElement
            id.value = 'EditButton'
            let link = document.getElementById('ButtonEditLink') as HTMLInputElement
            link.value = ButtonField.tagName === 'A' ? ButtonField.href : '';
            let height = document.getElementById('ButtonEditHeight') as HTMLInputElement
            height.value = '' + ButtonField.style.height
            let width = document.getElementById('ButtonEditWidth') as HTMLInputElement
            width.value = ButtonField.style.width
            let Type = document.getElementById('ButonEditType') as HTMLInputElement
            if(ButtonField.tagName === 'A') {
                Type.value =  'Link'
            } else {
                Type.value =  ButtonField.type
            }


            Modal.style.display = 'block'
        }

}

export const CloseButtonEditModal = () => {
    const ButtonField = document.getElementById('EditButton');
    const Modal = document.getElementById('ButtonEdit');

    if (ButtonField && Modal) {  // Verkette mehrere Bedingungen für Effizienz
        // Unabhängig vom Button-Typ die gemeinsame Logik anwenden
        ButtonField.id = '';

        const inputs = Modal.querySelectorAll('input');  // IDs für effizientere Suche nutzen
        inputs.forEach(input => input.value = '');

        Modal.style.display = 'none';
    }

}

export const SaveButtonEditModal = () => {
    let Modal = document.getElementById('ButtonEdit') as HTMLDivElement;
    if (Modal !== null) {
        let ButtonFields = document.getElementById('EditButton')
        let Type = document.getElementById('ButonEditType') as HTMLInputElement
        if(Type.value === 'Link' && ButtonFields?.tagName !== 'A') {

            if(ButtonFields){
                const newElement = document.createElement('a');

                newElement.id = ButtonFields.id;
                newElement.className = ButtonFields.className;
                newElement.textContent = ButtonFields.textContent;
                newElement.addEventListener('click', ButtonEdit);


                ButtonFields.parentNode?.replaceChild(newElement, ButtonFields);
            }

        } else if(Type.value !== 'Link' && ButtonFields?.tagName === 'A') {

            if(ButtonFields){
                const newElement = document.createElement('button');

                newElement.id = ButtonFields.id;
                newElement.className = ButtonFields.className;
                newElement.textContent = ButtonFields.textContent;
                newElement.addEventListener('click', ButtonEdit);


                ButtonFields.parentNode?.replaceChild(newElement, ButtonFields);
            }
        }
        if(ButtonFields?.tagName === 'A') {
            let id = document.getElementById('ButtonEditID') as HTMLInputElement
            let ButtonField = document.getElementById('EditButton') as HTMLAnchorElement;
            ButtonField.id = ''
            let text = document.getElementById('ButtonEditText') as HTMLInputElement
            ButtonField.innerHTML = text.value
            text.value = ''
            let link = document.getElementById('ButtonEditLink') as HTMLInputElement
            ButtonField.href = link.value
            link.value=''
            let height = document.getElementById('ButtonEditHeight') as HTMLInputElement
            ButtonField.style.height = height.value
            height.value=''
            let width = document.getElementById('ButtonEditWidth') as HTMLInputElement
            ButtonField.style.width = width.value
            width.alt = ''
            id.value = ''
        } else {
            let id = document.getElementById('ButtonEditID') as HTMLInputElement
            let ButtonField = document.getElementById('EditButton') as HTMLButtonElement;
            ButtonField.id = ''
            let text = document.getElementById('ButtonEditText') as HTMLInputElement
            ButtonField.innerHTML = text.value
            text.value = ''
            let link = document.getElementById('ButtonEditLink') as HTMLInputElement
            /*ButtonField.href = link.value*/
            link.value=''
            let height = document.getElementById('ButtonEditHeight') as HTMLInputElement
            ButtonField.style.height = height.value
            height.value=''
            let width = document.getElementById('ButtonEditWidth') as HTMLInputElement
            ButtonField.style.width = width.value
            width.alt = ''
            id.value = ''
        }
        AppendHTML(Modal)
        Modal.style.display = 'none'
    }
}
