import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../../core/Sidebar";
import {MIcon, WK24Button} from "../../core";
import SecureCheck from "../components/SecureCheck";
import {Route, Routes, useNavigate} from "react-router-dom";
import Site404 from "../404";
import React, {useEffect, useState} from "react";
import {RouteProps} from "../Interface/RouteProps.interface";
import RoutingConfig from "../components/RoutingConfig";
import {SitemapPropsInterface} from "../Interface/SitemapProps.interface";
import SidebarConfig from "../components/SidebarConfig";

import './css/default.css'
import './css/font.css'
import UseAuthCheck from '../AuthGate/useAuthCheck'; // Import the custom hook
import Badge from 'react-bootstrap/Badge';
import Button from "react-bootstrap/Button";
import {notifyGhostLogout} from "../../core/Notification/service/notification.service";
import {useSelector} from "react-redux";
interface DefaultTemplateProps{
    /** Passed value to App.tsx */
    value:string
}

/**
 * Default template includes a sidebar and the content area
 * @param props
 * @constructor
 */
const DefaultTemplate = (props:DefaultTemplateProps) => {
    const Store = useSelector((state: any) => state);
    let route: RouteProps[] = RoutingConfig({value: props.value})
    let Sidebar: SitemapPropsInterface[] = SidebarConfig({value: props.value})
    const navigate = useNavigate();
    const UserNavigate = () => {
        navigate('/login')
        localStorage.clear()
    }

    useEffect(() => {
        if( (localStorage.getItem("userRole") === null || localStorage.getItem("userRole") === undefined) && window.location.href.split('/')[window.location.href.split('/').length - 1] === '') {
            navigate('/step0')
        }

    }, []); // Include elements in the dependency array
    const backgroundImage = 'url(/portalbg.jpg)';

    // @ts-ignore
    return (
        <>
            <div id={'notification'} style={{backgroundImage: backgroundImage}}>
                <div className={'Header'}>
                    <img src={'/Logo_Webkom24.png'} alt={''} className={'headerLogo'}/>
                    <div className={'headerRight'}>
                        <WK24Button
                            children={<MIcon children="logout" variant="-sharp"/>}
                            class={'userbtn'} variant={'link'}
                            onClick={(e) => UserNavigate()} />
                    </div>
                </div>
                <Row className={'sidebarRow'}>
                    <Col className={'sidebar'}>
                        <div id={'sitemap'} className={'pl-3'}>
                            <SideBar>
                                {Sidebar.map((sidebar, index) =>
                                    SecureCheck({role: sidebar.roles, mode: true, right: sidebar.right}) ?

                                        sidebar.menuitem === undefined ?
                                            <SideBar.Item route={sidebar.route as string} title={sidebar.header}
                                                          icon={sidebar.icon}/>
                                            :
                                            (<>
                                                <SideBar.Dropdown title={sidebar.header} icon={sidebar.icon}>
                                                    {sidebar.menuitem.map((sidebarItem, index) =>
                                                        <><SideBar.Item route={sidebarItem.route as string}
                                                                        title={sidebarItem.name}
                                                                        icon={sidebarItem.icon}/></>
                                                    )}
                                                </SideBar.Dropdown>
                                            </>) : (
                                            <></>
                                        )
                                )}
                            </SideBar>
                            {(localStorage.getItem('GhostUser') !== null ?
                                <p className={'shadow text-center p-3 mb-5 bg-body rounded'}>
                                    Eingeloggt
                                    als <br/>
                                    <Badge
                                        bg="primary">{JSON.parse(localStorage.getItem('User') as string).firstname} {JSON.parse(localStorage.getItem('User') as string).lastname}</Badge>
                                    <br/><Badge
                                    bg="secondary">{JSON.parse(localStorage.getItem('Customer') as string).company}</Badge>
                                    <br/><Button variant="danger" className={'m-0 mt-3 p-2'}
                                                 onClick={() => GhostLogout(Store)}>Ausloggen</Button>

                                </p> : <></>)}
                        </div>
                        <div className="chamiSidebar">
                            <img className="chamiIcon" src={'/chami2.png'} alt={''}/>
                        </div>
                    </Col>
                    <Col>

                        <div id={'wrapper'} className={'content pr-5 pl-5'}>
                            <Routes>
                                {route.map((route, index) =>
                                    (route.roles === null ?
                                            <Route
                                                key={'5'}
                                                path={route.path}
                                                element={route.elements}
                                                errorElement={<Site404/>}
                                            />
                                            :
                                    SecureCheck({role: route.roles,mode:true,right:route.right}) ? (
                                        (route.isNested === false ?  <Route
                                            key={'5'}
                                            path={route.path}
                                            element={route.elements}
                                            errorElement={<Site404/>}
                                        /> : <Route path={route.path} element={<UseAuthCheck search={JSON.parse(localStorage.getItem('User') as string)} param={'isCustomer'} value={'1'} /> }>
                                            <Route
                                                key={'5'}
                                                path={route.path}
                                                element={route.elements}
                                                errorElement={<Site404/>}
                                            />
                                        </Route> )


                                    ) : (<></>

                                    )
                                ))
                                }
                            </Routes>
                        </div>
                    </Col>
                </Row>
            </div>


        </>
    )
}

export default DefaultTemplate;


// userRole = superadmin

const GhostLogout = (Store:any) => {
    localStorage.setItem("User", localStorage.getItem('GhostUser') as string)
    localStorage.setItem("Customer", localStorage.getItem('GhostCustomer') as string)
    localStorage.setItem("Project", localStorage.getItem('GhostProject') as string)
    localStorage.setItem("Emails", localStorage.getItem('GhostEmails') as string)
    localStorage.setItem("imprint", localStorage.getItem('Ghostimprint') as string)
    localStorage.setItem("ProjectSettings", localStorage.getItem('GhostProjectSettings') as string)
    localStorage.removeItem('GhostUser')
    localStorage.removeItem('GhostCustomer')
    localStorage.removeItem('GhostProject')
    localStorage.removeItem('GhostEmails')
    localStorage.removeItem('Ghostimprint')
    localStorage.removeItem('GhostProjectSettings')
    notifyGhostLogout(Store)
    setTimeout(() => {
        window.location.href = '/';
    }, 2000);
}