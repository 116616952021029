/**
 * Manages the state of the fonts page.
 *
 * @params {fileList} files - the list of the selected (custom) fontfiles.
 * @params {string} fontGeneral - the general font-family for the website
 * @params {string} fontHeading - the font-family for the website headings
 *
 */

import React, {useEffect, useRef, useState} from "react";
import '../css/fonts.css'
import {BackButton, IntlMessage, MIcon, Store, WK24Button, Wk24Cards, Wk24Form} from "../../../@WK24/core";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import WK24Label from "../../../@WK24/core/Form/Elements/Label";
import {ResponseFonts} from "../../design/Api/response/sites";
import {createFonts} from "../../design/Pages/sitesDetail";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface FontsProps {
    value: any
}

const Fonts = (props: FontsProps) => {
    const UserID = JSON.parse(localStorage.getItem('User') as string).userID;
    const ProjectID = JSON.parse(localStorage.getItem('Project') as string).projectID;

    const [files, setFiles] = useState<FileList | null>();
    const filesRef = useRef(null);
    const [fontData, setFontData] = useState<any>([]);
    const [fontGeneral, setFontGeneral] = useState('');
    const [fontHeading, setFontHeading] = useState('');
    const [fontGeneralnumber, setFontHGeneralnumber] = useState(0)
    const [fontHeadingnumber, setFontHeadingnumber] = useState(0)
    const [validated, setValidated] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'uploading', 'success', 'error'
    const Fonts = ResponseFonts(JSON.parse(localStorage.getItem('User') as string).userID)
    for(let x=0;x<Fonts.length;x++) {
        createFonts(Fonts[x].name,Fonts[x].projectID)
    }
    useEffect(() => {
        handleGet();

    }, [])

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    /** delete font */
    const handleDelete = (deleteItem: any) => {
        console.log(deleteItem);

        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "fonts/remove/" + deleteItem.fontID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                console.log(result);
                handleGet();
                notifySave(Store);
            })
            .catch((error) => {
                console.error(error);
                notifyError(Store);
            });
    }

    /** get all fonts */
    const handleGet = () => {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "fonts/" + UserID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                setFontData(result);
                console.log(result);
            })
            .catch((error) => console.error(error));
    }

    /** upload selected fonts */
    const handleUpload = async () => {
        if (!files) {
            return alert(IntlMessage({locale: 'de', messageId: 'Fonts.fileError.text', Store: Store}));
        }

        setUploadStatus('uploading'); // Indicate upload progress

        try {
            const formData = new FormData();
            for (let x = 0; x < files.length; x++) {
                formData.append(`file${x}`, files[x]);
            }
            formData.append("username", ProjectID);

            const response = await axios.post(ApiRoot() + `fonts/store/${UserID}//${ProjectID}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Basic " + BasicAuth()
                },
                onUploadProgress: (progressEvent: any) => {
                    const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadStatus(`Uploading: ${uploadPercentage}%`);
                },
            });

            console.log(response.data); // Handle successful upload response
            setUploadStatus('success');
        } catch (error) {
            console.error(error);
            setUploadStatus('error');
        } finally {
            setFiles(null); // Clear selected file after upload
            handleGet();
        }
    }

    /** set fileList */
    const handleFileList = async (e: any) => {
        const fileList = e.target.files;
        setFiles(fileList);
    };

    /** clear fileList */
    const ClearFiles = () => {
        if (filesRef.current !== null) {
            (filesRef.current as HTMLInputElement).value = '';
            setFiles(null);
        }
    }

    console.log(fontGeneral)

    return (
        <div className="Fonts bpage">
            <h1>{IntlMessage({
                locale: 'de',
                messageId: 'Fonts.title',
                Store: Store
            })}<br/><small>{IntlMessage({locale: 'de', messageId: 'Fonts.subtitle', Store: Store})}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Fonts.header.text', Store: Store})
            }}/>
            <p className="mt-3 lead" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Fonts.header.title', Store: Store})
            }}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <Wk24Cards style={{width: '27rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="segment" variant="fill"/>{IntlMessage({
                            locale: 'de',
                            messageId: 'Fonts.generalHead.text',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.InputGroup>
                                <Wk24Form.InputGroupText
                                    text={IntlMessage({locale: 'de', messageId: 'Fonts.font.text', Store: Store})}
                                />
                                <Wk24Form.Select
                                    items={[...fontData]
                                        .map((item: any, index: any) => ({
                                            value: index,
                                            text: (item.backendPath.split('/')[8]).split('.')[0]
                                        }))
                                    }
                                    defaultValue={0}
                                    name="generalFont"
                                    onChange={(e: { target: { value: string, } }) => {
                                        setFontGeneral(fontData[e.target.value].backendPath);
                                        //setFontHGeneralnumber(e.target.value)
                                        /** set custom styles */
                                        document.body.style.setProperty("--customFontGeneralName", '"' + (fontData[e.target.value].backendPath.split('/')[8].split('.')[0]) + '"');
                                        document.body.style.setProperty("--customFontGeneralPath", 'url("../' + (fontData[e.target.value].backendPath).split('/')[6] + '")');
                                        document.body.style.setProperty("--customFontGeneralFormat", '"' + (fontData[e.target.value].type.split('/')[1]) + '"');
                                    }}
                                    class="checkboxAfterInputGroupText mb-3"
                                />
                            </Wk24Form.InputGroup>
                            <WK24Label class="text-center fontPreview"
                                       style={{fontFamily: (fontData[fontGeneralnumber] !== undefined ? fontData[fontGeneralnumber].name : '')}}
                                       text={IntlMessage({
                                           locale: 'de',
                                           messageId: 'Fonts.generalExample.text',
                                           Store: Store
                                       })}/>
                        </Wk24Cards.Body>
                    </Wk24Cards>
                    <Wk24Cards style={{width: '27rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="text_fields" variant="fill"/>{IntlMessage({
                            locale: 'de',
                            messageId: 'Fonts.headingHead.text',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.InputGroup>
                                <Wk24Form.InputGroupText
                                    text={IntlMessage({locale: 'de', messageId: 'Fonts.font.text', Store: Store})}
                                />
                                <Wk24Form.Select
                                    items={[...fontData]
                                        .map((item: any, index: any) => ({
                                            value: index,
                                            text: (item.backendPath.split('/')[8]).split('.')[0]
                                        }))
                                    }
                                    defaultValue={0}
                                    name="headingFont"
                                    onChange={(e: { target: { value: string, } }) => {
                                        setFontHeading(fontData[e.target.value].backendPath);
                                        //setFontHeadingnumber(e.target.value)
                                        /** set custom styles */
                                        document.body.style.setProperty("--customFontHeadingName", '"' + (fontData[e.target.value].backendPath.split('/')[8].split('.')[0]) + '"');
                                        document.body.style.setProperty("--customFontHeadingPath", 'url("..' + (fontData[e.target.value].backendPath).split('/')[6] + '")');
                                        document.body.style.setProperty("--customFontHeadingFormat", '"' + (fontData[e.target.value].type.split('/')[1]) + '"');
                                    }}
                                    class="checkboxAfterInputGroupText mb-3"
                                />
                            </Wk24Form.InputGroup>
                            <WK24Label class="text-center fontHeadingPreview"
                                       style={{fontFamily: (fontData[fontHeadingnumber] !== undefined ? fontData[fontHeadingnumber].name : '')}}
                                       text={IntlMessage({
                                           locale: 'de',
                                           messageId: 'Fonts.headingExample.text',
                                           Store: Store
                                       })}/>
                        </Wk24Cards.Body>
                    </Wk24Cards>
                    <Wk24Cards style={{width: '27rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="text_increase" variant="fill"/>{IntlMessage({
                            locale: 'de',
                            messageId: 'Fonts.customHead.text',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.InputGroup class="form-group mb-3">
                                <Form.Control type={'file'}
                                              name={'vat'}
                                              ref={filesRef}
                                              placeholder={''}
                                    //accept="image/*"
                                              multiple={true}
                                              onChange={handleFileList}/>
                                <WK24Button type={'button'}
                                            variant={'danger'}
                                            onClick={ClearFiles}>{'X'} </WK24Button>
                            </Wk24Form.InputGroup>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'Fonts.customBtn.text', Store: Store})}
                                class={''} variant={'primary'} type={'button'} onClick={() => handleUpload()}/>
                            {uploadStatus === 'success' && <p>{IntlMessage({
                                locale: 'de',
                                messageId: 'Fonts.uploadSuccess.text',
                                Store: Store
                            })}</p>}
                            {uploadStatus === 'error' &&
                                <p>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Fonts.uploadNotSuccess.text',
                                    Store: Store
                                })}</p>}
                        </Wk24Cards.Body>
                    </Wk24Cards>
                </div>
            </Wk24Form>

            <p className="mt-4 lead" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Fonts.customFonts.title', Store: Store})
            }}/>
            <ul className="fontList d-flex flex-row flex-wrap">
                {[...fontData].map((item: any, index: any) => {
                    return (
                        <li className="d-flex flex-row flex-wrap">
                            <span className="schriftItem">{((item.backendPath).split('/')[8]).split('.')[0]}</span>
                            <WK24Button
                                children={<MIcon children="delete" variant="fill"/>}
                                class={'float-start deleteFont'}
                                variant={'outline-primary'}
                                onClick={() => {
                                    handleDelete(item);
                                }}
                            />
                        </li>
                    )
                })}
            </ul>

            <BackButton className={'mr-3'}/>
            <WK24Button
                children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                class={''} variant={'primary'} type={'button'} onClick={() => {
                localStorage.setItem('HeadingFont', fontHeading);
                localStorage.setItem('GeneralFont', fontGeneral);
                notifySave(Store);
            }}/>
        </div>
    )
}

export default Fonts;
