import React from 'react'
import AppLocale from './localization/'
import PreperatorReplace from './localization/preperator/replace'

interface IntlProps {
    /** Language JSON Key */
    messageId: string
    /** Language Key */
    locale?: string
    /** preperator name */
    preperator?: string
    /** preperator params */
    param?: string
    /** preperator replace params */
    replace?: string | Element | number
    /** Set HTML Replace Tag true or false */
    htmlReplace?: boolean
    /** Pass storage */
    Store: any
}

/**
 * Accesses the Language.json and gets the relevant key.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <IntlMessage Store={Store} messageId={'Key from Language.json'} />
 * ```
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * IntlMessageValue({locale:'de', messageId:'name.label', Store:Store})
 * ```
 * @visibleName IntlService
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @param props
 * @constructor
 */
const IntlMessage  = (props:IntlProps) => {
    const Store = props.Store

    /**
     * locale wird aus der localStorage geholt
     */
    let locales =
        Store !== undefined &&
        Store !== null &&
        Store.locale !== undefined &&
        Store.locale !== null &&
        Store.locale.locale !== '' &&
        Store.locale.locale !== undefined &&
        Store.locale.locale !== null
            ? Store.locale.locale
            : localStorage.getItem('locale')

    let locale =
        locales !== undefined && locales !== null ? locales.toLowerCase() : 'de'

    let languageFile
    if (AppLocale[locale] !== undefined) {
        languageFile = AppLocale[locale]?.messages

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[props.messageId]

        if (messageValue === undefined) {
            messageValue = props.messageId
        }

        /**
         * Preperatoren werden durchlaufen falls einer angegeben wurde.
         *
         */
        switch (props.preperator) {
            case 'replace':
                let searchAndReplace = new PreperatorReplace(
                    messageValue,
                    props.param,
                    props.replace
                )
                messageValue = searchAndReplace.Init()
                break
        }

        if (props.htmlReplace !== undefined && props.htmlReplace) {
            messageValue = replaceHTMLTag(messageValue)
        }

        return messageValue
    } else {
        return <></>
    }
}

export default IntlMessage

export const IntlMessageValue = (
    locale: any,
    messageId: any,
    Store: any,
    preperator: string = '',
    param: string = '',
    replace: string | number | Element = '',
    htmlReplace?: boolean
) => {
    /**
     * locale wird aus der localStorage geholt
     */
    let locales =
        Store !== undefined &&
        Store !== null &&
        Store.locale !== undefined &&
        Store.locale !== null &&
        Store.locale.locale !== '' &&
        Store.locale.locale !== undefined &&
        Store.locale.locale !== null
            ? Store.locale.locale
            : localStorage.getItem('locale')

    let localeValue =
        locales !== undefined && locales !== null ? locales.toLowerCase() : 'de'

    let languageFile

    if (AppLocale[localeValue] !== undefined) {
        languageFile = AppLocale[localeValue]?.messages

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[messageId]

        if (messageValue === undefined) {
            messageValue = messageId
        }

        /**
         * Preperatoren werden durchlaufen falls einer angegeben wurde.
         *
         */
        switch (preperator) {
            case 'replace':
                let searchAndReplace = new PreperatorReplace(
                    messageValue,
                    param,
                    replace
                )
                messageValue = searchAndReplace.Init()
                break
        }

        if (htmlReplace !== undefined && htmlReplace) {
            messageValue = replaceHTMLTag(messageValue)
        }

        return messageValue
    } else {
        return <></>
    }
}

const replaceHTMLTag = (data: any) => {
    var htmlReplace = data
    for (let x = 0; x < mapSearchAndReplace().length; x++) {
        htmlReplace = htmlReplace.replaceAll(
            mapSearchAndReplace()[x].search,
            mapSearchAndReplace()[x].replace
        )
    }

    return htmlReplace
        .replaceAll(/&lt;/g, '<')
        .replaceAll(/&gt;/g, '>')
        .replaceAll(/&amp;/g, '&')
}

const mapSearchAndReplace = () => {
    return [
        {search: '[bold]', replace: '<strong>'},
        {search: '[/bold]', replace: '</strong>'}
    ]
}
