/**
 * The preview of the template 1.
 *
 * @params {string[]} value - array of the colorset.
 * @params {string[]} pages - array of the navigation items.
 * @params {File | undefined | string} logo - the logo file or project name.
 *
 */

import React from "react";
import '../css/templates.css';
import {IntlMessage, Store} from "../../../@WK24/core";
import {NavLink} from "react-router-dom";

interface Template1Props {
    value: string[],
    pages: string[],
    logo: File | undefined | string
}

const Template1 = (props: Template1Props) => {
    const backgroundImage = 'url(/tmp1header.jpg)';

    let sizeWeight = '100%';
    let sizeHeight = '100px';

    const colorStyles = {
        primaryColor: props.value[1],
        secondaryColor: props.value[2],
        tertiarColor: props.value[3],
        fontColor: props.value[0],
        backgroundColor: props.value[4]
    }
    /** set custom styles */
    document.body.style.setProperty("--customBtnColor", colorStyles.primaryColor);
    document.body.style.setProperty("--customTitleColor", colorStyles.secondaryColor);
    document.body.style.setProperty("--customFontColor", colorStyles.fontColor);
    document.body.style.setProperty("--customTertiaerColor", colorStyles.tertiarColor);
    document.body.style.setProperty("--customBGColor", colorStyles.backgroundColor);

    /** Preview of the logo or project name */
    const LogoPreview = () => {
        if (typeof (props.logo) === 'string') {
            return (props.logo)
        } else {
            if (props.logo !== undefined) {
                return (<img src={URL.createObjectURL(props.logo)} alt=""
                             style={{maxWidth: sizeWeight, height: sizeHeight}}/>)
            } else {
                return ('Logo')
            }
        }
    }

    const copyRight = () => {
        if (typeof (props.logo) === 'string') {
            return (props.logo)
        } else {
            return ('Dein Firmenname')
        }
    }


    return (
        <div className="template1 ">
                <div className="tmpheader" style={{backgroundImage: backgroundImage}}>
                    <div className="topheader">
                        <a id="logo" href={"/"}>{LogoPreview()}</a>
                        <nav className="wk-horizontal">
                            <ul className="navi">
                                {props.pages.map((page, index) => (
                                    <li>
                                        <NavLink
                                            key={index}
                                            to={page}
                                            className={({isActive, isPending}) =>
                                                isPending ? "pending" : isActive ? "active" : ""}
                                        >{page}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="headercontent ">
                        <p className="title headlineHeader" style={{color: colorStyles.secondaryColor}}>
                            {IntlMessage({locale: 'de', messageId: 'Template.header.label', Store: Store})}
                        </p>
                        <p className={"sublineHeader"}>{IntlMessage({
                            locale: 'de',
                            messageId: 'Template.header.text',
                            Store: Store
                        })}</p>
                        <p>
                            <a className="wk-button ButtonHeader" style={{backgroundColor: colorStyles.primaryColor}} href={'/'}>
                                {IntlMessage({locale: 'de', messageId: 'Template.header.button', Store: Store})}
                            </a>
                        </p>
                    </div>
                </div>

            <div className="Webbuilder">
                <div className="tmpmain " style={{backgroundColor: colorStyles.backgroundColor}}>
                    <p className="title" style={{color: colorStyles.primaryColor}}>
                        {IntlMessage({locale: 'de', messageId: 'Template.main.label', Store: Store})}
                    </p>
                    <p style={{color: colorStyles.fontColor}}>{IntlMessage({
                        locale: 'de',
                        messageId: 'Template.main.text',
                        Store: Store
                    })}</p>
                </div>
            </div>
            <div className="tmpfooter" style={{backgroundColor: colorStyles.tertiarColor}}>
                <div className="footercontent">
                    <p className="footer-company">{IntlMessage({
                        locale: 'de',
                        messageId: 'Template.footer.text',
                        Store: Store
                    })}</p>
                    <table>
                        <tbody>
                        <tr>
                            <td>Kontakt:</td>
                            <td><a className={'emailFooter'} style={{color: colorStyles.primaryColor}}
                                   href={"mailto:info@weko24.de"}>info@weko24.de</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Telefon:</td>
                            <td><a className={'phoneFooter'} style={{color: colorStyles.primaryColor}} href={"tel:+493712435610"}>0371 2435610</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="socialmedia">
                        <img src={'/socialmedia/facebook.svg'} alt={''}/>
                        <img src={'/socialmedia/instagram.svg'} alt={''}/>
                        <img src={'/socialmedia/linkedin.svg'} alt={''}/>
                    </div>
                </div>
                <div className="down">
                    <nav className="footernav wk-horizontal">
                        <ul>
                            <li><NavLink key={0} to={'/'}
                                         className={({
                                                         isActive,
                                                         isPending
                                                     }) => isPending ? "pending" : isActive ? "active" : ""}
                            >{'Impressum'}</NavLink>
                            </li>
                            <li><NavLink key={1} to={'/'}
                                         className={({
                                                         isActive,
                                                         isPending
                                                     }) => isPending ? "pending" : isActive ? "active" : ""}
                            >{'Datenschutz'}</NavLink>
                            </li>
                            <li><NavLink key={2} to={'/'}
                                         className={({
                                                         isActive,
                                                         isPending
                                                     }) => isPending ? "pending" : isActive ? "active" : ""}
                            >{'Sitemap'}</NavLink>
                            </li>
                        </ul>
                    </nav>
                    <div id="copyright">
                        <span id="company">&copy; {new Date().getFullYear()} {copyRight()}</span> |
                        <span id="weko">
                            <a href={"https://www.weko24.de"}
                               style={{color: colorStyles.fontColor}}
                               target="_blank"
                               rel={"nofollow noreferrer"}> webkommunikation24</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Template1;
