import {useNavigate} from "react-router-dom";

interface SecureCheckProps {
    role:string[] | null
    mode?:boolean
    right?:any
}

/**
 * Checks whether the user has access rights. Returns a true or false
 * @param props
 * @constructor
 */
const SecureCheck = (props:SecureCheckProps) => {
    const navigate = useNavigate()
    if (props.role === undefined || props.role === null) {
        if(props.mode === true) {

        } else {
            navigate('/login')
        }
        return
    }

    if (props.right !== undefined && props.right !== null) {
        if (checkRight(props.right) === false) {
            return false
        } else {
            return true
        }
    }

    for (let x = 0; x < props.role.length; x++) {
        if (localStorage.getItem('userRole') !== null) {
            if (
                props.role[x] ===
                (localStorage.getItem('userRole') as string)?.toLowerCase()
            ) {
                return true
            }
        }
    }
    if(props.mode === true) {

    } else {
        navigate('/login')
    }

    return false
}

export default SecureCheck

const checkRight = (right:any) => {
    let user = JSON.parse(localStorage.getItem('User') as string)
    let GhostUser = JSON.parse(localStorage.getItem('GhostUser') as string)
    if(right !== null && user !== null && GhostUser !== null) {
        if(user[right] !== undefined && user[right] !== null && (user[right] === 1 || user[right] === '1')
        || GhostUser[right] !== undefined && GhostUser[right] !== null && (GhostUser[right] === 1 || GhostUser[right] === '1') ) {
            return true;
        } else {
            return false;
        }
    } else if(right !== null && user !== null) {
        if(user[right] !== undefined && user[right] !== null && (user[right] === 1 || user[right] === '1')) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }


}
