import { Navigate, Outlet } from 'react-router-dom';

/**
 * A React hook that performs authentication checks based on search parameters.
 *
 * @param props - The properties for the hook.
 * @param props.search - The search object .
 * @param props.param - The name of the search parameter to check for authentication.
 * @param props.value - The expected value of the search parameter for successful authentication.
 * @param props.forwardTo - The URL to redirect to if authentication fails (optional, defaults to '/step1').
 */
const useAuthCheck = (props:{search: any, param: string, value: string, forwardTo?: string }) => {
    /**
     * Determines whether the user is authenticated based on the search parameters.
     */
    const  isAuthenticated:boolean = props.search !== null && props.search[props.param] !== undefined && props.search[props.param] == props.value ? true : false;

    /**
     * Conditional rendering based on authentication status.
     */
    return (isAuthenticated === false ? <Navigate to={props.forwardTo !== undefined ? props.forwardTo : '/step1'} replace /> : <Outlet /> )

};

export default useAuthCheck;