/**
 * Set meta data to frontend template.
 *
 */

const pageMeta = (pageTitle: string, pageDescription: string, pageProperty: any) => {
    // Set page title
    document.title = pageTitle;

    // Set page description
    let descElement = document.querySelector('meta[name="description"]');
    if (descElement) {
        descElement.setAttribute("content", pageDescription)
    } else {
        let descNewElement = '<meta name="description" content={pageDescription} />';
        document.head.insertAdjacentHTML("beforeend", descNewElement);
    }

    // Set page meta properties
    pageProperty.map((propertyItem: any) => {
        let propertyElement = document.querySelector('meta[property="' + propertyItem.property + '"]');
        if (propertyElement) {
            propertyElement.setAttribute("content", propertyItem.content)
        } else {
            let propNewElement = '<meta property="' + propertyItem.property + '" content="' + propertyItem.content + '" />';
            document.head.insertAdjacentHTML("beforeend", propNewElement);
        }
        return null
    })
}
export default pageMeta