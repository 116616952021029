import {IntlMessage, Store, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import {IAccountData, IAddress} from "../interface";

interface IAddressProps {
    value: IAddress,
    onChange?: any;
    onChangeData?: IAccountData
}

const Address = (props: IAddressProps) => {
    return <Wk24Form.FormGroup>
        <Wk24Form.FloatingLabel
            text={IntlMessage({messageId: 'Address.street.placeholder', Store: Store})}>
            <Wk24Form.FormControl
                type="text"
                required
                placeholder={IntlMessage({messageId: 'Address.street.placeholder', Store: Store})}
                name={'addresses'}
                defaultValue={props.value.street}
                onChange={(e: React.ChangeEvent<Element>) => props.onChange({
                    ...props.value,
                    street: (e.target as HTMLInputElement).value
                })}
                />
        </Wk24Form.FloatingLabel>
        <WK24Row>
            <WK24Col>
                <Wk24Form.FloatingLabel
                    text={IntlMessage({messageId: 'Address.zip.placeholder', Store: Store})}>
                    <Wk24Form.FormControl
                        type="text"
                        required
                        placeholder="Postleitzahl"
                        name={IntlMessage({messageId: 'Address.zip.placeholder', Store: Store})}
                        defaultValue={props.value.zip}
                        onChange={(e: React.ChangeEvent<Element>) => props.onChange({
                            ...props.value,
                            zip: (e.target as HTMLInputElement).value
                        })}/>
                </Wk24Form.FloatingLabel>
            </WK24Col>
            <WK24Col>
                <Wk24Form.FloatingLabel
                    text={IntlMessage({messageId: 'Address.city.placeholder', Store: Store})}>
                    <Wk24Form.FormControl
                        type="text"
                        required
                        placeholder={IntlMessage({messageId: 'Address.city.placeholder', Store: Store})}
                        name={'addresses'}
                        defaultValue={props.value.city}
                        onChange={(e: React.ChangeEvent<Element>) => props.onChange({
                            ...props.value,
                            city: (e.target as HTMLInputElement).value
                        })}/>
                </Wk24Form.FloatingLabel>
            </WK24Col>
        </WK24Row>
    </Wk24Form.FormGroup>
}
export default Address;
