import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

interface SitesActivateFetchProps {
    id: number
}

async function sitesActivateFetch(props: SitesActivateFetchProps) {
    const raw = JSON.stringify({
        "id": props
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + BasicAuth()
        },
        body: raw
    };

    fetch(ApiRoot() + "page/activate", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
}

export default sitesActivateFetch;