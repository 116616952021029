import Alert from "react-bootstrap/Alert";
import ReactDOM from "react-dom";
import React from "react";

interface MessageProps{
    /** Alert Title */
    title: string,
    /** Alert Content */
    message: string,
    /** Alert Type */
    type: string,
    /** Alert Position */
    insert: string,
    /** Alert Container Position */
    container: string,
    /** Alert Animation In */
    animationIn: any,
    /** Alert Animation Out */
    animationOut: any,
    dismiss: {
        /** Alert Duration */
        duration: number
    }
}


/**
 * Plays a bootstrap alert message with the desired content and color. Will be automatically hidden after runtime. Controls yourself and ensures that only 1 message appears at a time. Multi messages are automatically deleted<br/><br/>
 *  <strong>Example embeded Function:</strong><br/>
 * ```js
 * notifySave(Store);
 * ```
 *
 *  <strong>Example Function in notification.service.tsx:</strong><br/>
 * ```js
 * export const notifySave = (Stores: any) =>
 *     NotificationMessage({
 *         title: IntlMessageValue(
 *             'de',
 *             'notification.service.provision.title',
 *             Stores
 *         ),
 *         message: IntlMessageValue(
 *             'de',
 *             'notification.service.provision.message',
 *             Stores
 *         ),
 *         type: 'success',
 *         insert: 'top',
 *         container: 'top-center',
 *         animationIn: ['animate__animated', 'animate__fadeIn'],
 *         animationOut: ['animate__animated', 'animate__fadeOut'],
 *         dismiss: {
 *             duration: 3000,
 *
 *         }
 *     })
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
export const NotificationMessage = (props:MessageProps) => {

    setTimeout(function(){
        const elementOld = document.getElementById("Notificationprops.message");
        if(elementOld !== null) {
            elementOld.remove();
        }
        const elementOldHolder = document.getElementById("NotificationHolder");
        if(elementOldHolder !== null) {

        }},props.dismiss.duration);

    let Notification = (
        <>
            <Alert variant={props.type} className={'animateTop notificationPosition'+props.insert} id={'Notificationprops.message'}  style={{visibility:"visible",transition: 'visibility  1s linear',}} dismissible={true}>
                <Alert.Heading>{props.title}</Alert.Heading>
                <p>
                    {props.message}
                </p>
            </Alert>
        </>
    )

    const elementOld = document.getElementById("Notificationprops.message");
    if(elementOld !== null) {
        elementOld.remove();
    }
    const elementOldHolder = document.getElementById("NotificationHolder");
    if(elementOldHolder !== null) {
        elementOldHolder.remove();
    }

    const element = document.getElementById("Notificationprops.message");
    if(element !== null) {
        element.remove()
    }
    let newElement = Notification
    let temp = document.createElement('div')
    temp.id = 'NotificationHolder'
    ReactDOM.render(newElement, temp)
    let ele = document.getElementById('notification') as HTMLDivElement
    if(ele !== null) {
        ele.appendChild(temp)
    }

    return(
        <></>
    )

}
