import React, {useEffect, useRef, useState} from 'react';
import '../Webbuilder/css/style.css';
import {ContentArea} from "./components/compontents/ContentArea";
import Toolbar from "./components/Toolbar";
import './css/style.css';
import {basic} from './components/config/Toolbar/basic';
import {WYSIWYGState} from "./interface/WYSIWYGState";
import AddEventlistener from "./components/compontents/addEventlistener";
import Modals from "./components/compontents/Modals";
import gridRow from "./components/elements/gridRow";
import {
    handleClick, handlePaste,ReplaceElement
} from "./components/compontents/EditorFunctions";

/**
 * WYSIWYG editor component that allows users to create rich text content using toolbar buttons.
 *
 * @param {WYSIWYGState} props - The component's properties.
 * @returns {JSX.Element} - The rendered WYSIWYG editor component.
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 */
export const WYSIWYG = (props: WYSIWYGState): JSX.Element => {
    const inputEditor = useRef<HTMLDivElement>(null);
    const EditorContent = useRef<HTMLDivElement>(null);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showIMGModal, setShowIMGModal] = useState(false);
    const [Row, setRow] = useState(0)
    const [Cell, setCell] = useState(0)

    useEffect(() => {
        if(Row !== 0 && Cell !== 0) {
            ReplaceElement('[row]',gridRow(Row,Cell),inputEditor.current as HTMLDivElement)
        }

    }, [Row,Cell,inputEditor]); // Include elements in the dependency array

    useEffect(() => {
        if (EditorContent.current) {
            EditorContent.current.addEventListener('paste', handlePaste);
            return () => EditorContent.current?.removeEventListener('paste', handlePaste); // Cleanup
        }
    }, [EditorContent]);
    /**
     * Sets up event listeners for toolbar buttons when the editor element is available.
     * Cleans up event listeners when the component unmounts.
     */
    useEffect(() => {
        if (inputEditor.current !== null) {
            const Holder = inputEditor.current as HTMLDivElement;
            const EditorHolder = EditorContent.current as HTMLDivElement;
            const elements = Holder.querySelectorAll('.main-content .btn');

            AddEventlistener(EditorHolder)

            elements.forEach(element => {
                element.addEventListener('click', (event)=>handleClick(event,setShowLinkModal,setShowIMGModal,ReplaceElement,EditorHolder,Holder));
            });

            return () => {
                elements.forEach(element => {
                    element.removeEventListener('click', (event)=>handleClick(event,setShowLinkModal,setShowIMGModal,ReplaceElement,EditorHolder,Holder));
                });
            };
        }

    }, []); // Include elements in the dependency array

    /**
     * Renders the WYSIWYG editor with a toolbar and content area.
     *
     * @returns {JSX.Element} - The rendered WYSIWYG editor component.
     */
    return (
        <div className="main-content" ref={inputEditor} id={props.id}>
            <Toolbar config={props.config !== undefined ? props.config : basic} setRow={setRow} setCell={setCell}/>
            <div ref={EditorContent}>
                <ContentArea defaultValue={props.defaultValue}/>
            </div>
            <Modals setShowIMGModal={setShowIMGModal} setShowLinkModal={setShowLinkModal}
                    showLinkModal={showLinkModal} showIMGModal={showIMGModal}
                    EditorContent={EditorContent.current} />
        </div>
    );
};




