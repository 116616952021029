import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import React, {JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useState} from "react";

interface SideBarDropdownProps{
    /** Set Title for Navigation. */
    title:string,
    /** Set Icon Code for Material Icons. */
    icon:string,
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
}

/**
 * Section with Items for Sidebar.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <SideBar>
 *     <SideBar.Dropdown title={sidebar.header} icon={sidebar.icon}>
 *          ...
 *     </SideBar.Dropdown>
 * </SideBar>
 * ```
 *
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */

const SideBarDropdown = (props:SideBarDropdownProps) => {
    const [open, setOpen] = useState(false);
    return (
        <Container fluid className={'p-0 m-0'}>
            <Nav.Item onClick={() => setOpen(!open)} className={'p-0 m-0'}>
                <span className="material-icons pr-3" style={{verticalAlign: 'bottom'}}>
                                            {props.icon}
                                        </span> {props.title}
            </Nav.Item>
            <Collapse in={open}>
                <div> {props.children} </div>
            </Collapse>
        </Container>
    )
}
export default SideBarDropdown
