import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Modal from 'react-bootstrap/Modal';
import React from 'react'
interface UniModalHeaderProps{
    /** Allows it to have children and have them reflected. */
    elements: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}

export const ModalHeader = (props:UniModalHeaderProps) => {

    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.elements}
                </Modal.Title>
            </Modal.Header>
        </>
    )

}
