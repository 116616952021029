/**
 * The frontend view of the template 1.
 *
 */

import React, {useState} from "react";
import '../css/template01.css';
import {NavLink} from "react-router-dom";
import {ManageOverview} from "../../../dummy/manageOverview";
import pageMeta from "../components/components/pageMeta";

interface FrontendTemplate1Props {
    value: any
}

const FrontendTemplate1 = (props: FrontendTemplate1Props) => {
    const [data, setData] = useState(ManageOverview);

    return (
        <>
            {
                [...data].map((item: any) => {
                    return (
                        (item.pages).map((page: any) => {
                                    pageMeta(page.siteTitle, page.metaDescription, page.metaProperty);

                                    const headerImage = 'url(' + page.pageContent.header.images + ')';
                                    const styles = {
                                        primaryColor: item.colors.primary,
                                        secondaryColor: item.colors.secondary,
                                        tertiarColor: item.colors.tertiary,
                                        fontColor: item.colors.font,
                                        backgroundColor: item.colors.background
                                    }

                            /** set custom styles */
                            document.body.style.setProperty("--customBtnColor", styles.primaryColor);
                            document.body.style.setProperty("--customTitleColor", styles.secondaryColor);
                            document.body.style.setProperty("--customFontColor", styles.fontColor);
                            document.body.style.setProperty("--customTertiaerColor", styles.tertiarColor);
                            document.body.style.setProperty("--customBGColor", styles.backgroundColor);

                                    return (
                                        <div className="site tmp1">
                                            <header id="site-header" style={{backgroundImage: headerImage}}>
                                                <div className="wk-width">
                                                    <div className="topheader">
                                                        <a id="logo" href={"/"}><img src={item.logo}
                                                                                     alt={item.companyName}/></a>
                                                        <nav className="wk-horizontal">
                                                            <ul className="navi" style={{color: "#ffffff"}}>
                                                                {(item.navigation.mainNavigation).map((navItem: any, index: number) => {
                                                                    return (
                                                                        <li>
                                                                            <NavLink
                                                                                key={index}
                                                                                to={navItem.path}
                                                                                className={({isActive, isPending}) =>
                                                                                    isPending ? "pending" : isActive ? "active" : ""}
                                                                            >{navItem.title}</NavLink>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                    <div className="headercontent" style={{color: "#ffffff"}}>
                                                        <p className="title">{page.pageContent.header.headline}</p>
                                                        <p>{page.pageContent.header.text}</p>
                                                        <p>
                                                            <a className="wk-button"
                                                               style={{backgroundColor: styles.primaryColor}}
                                                               href={page.pageContent.header.button.link}>{page.pageContent.header.button.text}</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </header>
                                            <main id="site-main">
                                                {(page.pageContent.main).map((mainItem: any) => {
                                                    return (
                                                        <div className="wk-width"
                                                             dangerouslySetInnerHTML={{__html: mainItem.content}}
                                                        />
                                                    )
                                                })
                                                }
                                            </main>
                                            <footer id="site-footer">
                                                <div className="wk-width">
                                                    <div className="footercontent">
                                                        <p className="footer-company">{page.pageContent.footer.company}</p>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <td>Kontakt:</td>
                                                                <td><a
                                                                    href={"mailto:" + page.pageContent.footer.email}>{page.pageContent.footer.email}</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Telefon:</td>
                                                                <td><a
                                                                    href={"tel:" + page.pageContent.footer.phone}>{page.pageContent.footer.phone}</a>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="socialmedia">
                                                            {(page.pageContent.footer.socialLinks).map((socialItem: any) => {
                                                                return (
                                                                    <a href={socialItem.link} target="_blank"
                                                                       rel="nofollow noreferrer">
                                                                        <img
                                                                            src={'/socialmedia/' + socialItem.type + '.svg'}
                                                                            alt={''}/>
                                                                    </a>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="down">
                                                        <nav className="footernav wk-horizontal">
                                                            <ul>
                                                                {(item.navigation.serviceNavigation).map((footNavItem: any, index: number) => {
                                                                    return (
                                                                        <li>
                                                                            <NavLink key={index} to={footNavItem.path}
                                                                                     className={({
                                                                                                     isActive,
                                                                                                     isPending
                                                                                                 }) => isPending ? "pending" : isActive ? "active" : ""}>
                                                                                {footNavItem.title}
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </nav>
                                                        <div id="copyright">
                                                            <span
                                                                id="company">&copy; {new Date().getFullYear()} {page.pageContent.footer.company}</span> |
                                                            <span id="weko">
                                                                <a href={"https://www.webkommunikation24.de"}
                                                                   target="_blank"
                                                                   rel="nofollow noreferrer">webkommunikation24</a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </div>
                                    )
                                })
                            )
                })
            }
        </>
    )
}
export default FrontendTemplate1;