/**
 * Manages the info for login reasons
 *
 */

import React, {useState} from "react";
import '../css/step.css'
import {IntlMessage, Store, WK24Button, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useNavigate} from "react-router-dom";
import Template1 from "../../templates/Pages";
import Template2 from "../../templates/Pages/Template2";
import Template3 from "../../templates/Pages/Template3";

interface Step3aProps {
    value: any
}

const Step3a = (props: Step3aProps) => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const BackNavigate = () => {
        navigate('/step3/')
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        navigate('/signin/')
    };

    /** Preview of the selected template */
    const PreviewTemplate = () => {
        const template = localStorage.getItem('Template');
        const colorSet = localStorage.getItem('ColorSet');
        const nav = localStorage.getItem('Navigation');

        if (template === "Tmp01" && colorSet && nav) {
            return (<Template1 value={JSON.parse(colorSet)} pages={JSON.parse(nav)} logo={'Logo'}/>)
        }
        if (template === "Tmp02" && colorSet && nav) {
            return (<Template2 value={JSON.parse(colorSet)} pages={JSON.parse(nav)} logo={'Logo'}/>)
        }
        if (template === "Tmp03" && colorSet && nav) {
            return (<Template3 value={JSON.parse(colorSet)} pages={JSON.parse(nav)} logo={'Logo'}/>)
        }
    }

    return (
        <div className="Step noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <WK24Row>
                    <WK24Col class={'stepTopAbstand'}>
                        <Wk24Form.Label class="header text-center"
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step3a.header.label',
                                            Store: Store
                                        })}/>
                        <p className="text-center mt-3 stepAbstand" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Step3a.title', Store: Store})
                        }}/>
                        <div className="chamiStep3a">
                            <img className="chamiIcon" src={'/chami5.png'} alt={''}/>
                        </div>
                        <div className="giraffeStep3a">
                            <img className="giraIcon" src={'/gira6.png'} alt={''}/>
                        </div>

                    </WK24Col>
                    <WK24Col class={'text-center pt-6 previewImage'} style={{backgroundColor: 'transparent'}}>
                        {PreviewTemplate()}
                    </WK24Col>
                </WK24Row>
                <WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-3'}>
                            <ProgressBar now={45} style={{height: '5px'}} className={'mt-3'}/>
                        </WK24Col>
                    </WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-4'}>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnBackward.label', Store: Store})}
                                class={'float-start'} variant={'outline-primary'}
                                onClick={BackNavigate}/>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                                type={'submit'} class={'float-end'} variant={'primary'}/>
                        </WK24Col>
                    </WK24Row>
                </WK24Row>
            </Wk24Form>
        </div>
    )
}
export default Step3a;