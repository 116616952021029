import Order from "./Pages";

export const OrderConfig = (value: any) => {
    return [
        {
            path: '/order/',
            elements: <Order value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        }
    ]
}

export const OrderMenuConfig = (value: any) => {
    return [
        {
            header: 'Order',
            icon: 'public',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/order'
        }
    ]
}