import Media from "./Pages";

export const MediaConfig = (value: any) => {
    return [
        {
            path: '/media/',
            elements: <Media value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        }
    ]
}

export const MediaMenuConfig = (value: any) => {
    return [
        {
            header: 'Medien',
            icon: 'public',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/media'
        }
    ]
}