/**
 * Creates a progress bar element with a progress value of 25%.
 *
 * @returns {string} The HTML string representing the progress bar.
 */
const ProgressBar = (): string => {
    return `
    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar" style="width: 25%"></div>
    </div><div style="background-color: rgb(255, 255, 255);">Hier kannst du deinen Text Eintragen!</div>
  `;
};

export default ProgressBar;
