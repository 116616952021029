
import {ToolbarConfig} from "../../interface/ToolbarConfig";

import React, {useEffect, useRef, useState} from "react";
import TablePicker from "../compontents/TablePicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import {controllCommand, FontCommand, removeStylesAndTags} from "../compontents/EditorFunctions";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {ResponseFonts} from "../../../../../modules/design/Api/response/sites";
/**
 * Toolbar component that renders a list of toolbar buttons based on configuration.
 *
 * @param {ToolbarConfig[]} props.config - An array of toolbar button configurations.
 * @returns {JSX.Element} The rendered Toolbar component.
 */
const Toolbar = (props: { config: ToolbarConfig[],setRow:any,setCell:any}): JSX.Element => {
    const expandRef = useRef<HTMLDivElement>(null);
    const Fonts = ResponseFonts(JSON.parse(localStorage.getItem('User') as string).userID)
    const expand = () => {
        if(expandRef.current !== null) {
            if(expandRef.current.style.display === 'none'){
                expandRef.current.style.display = 'block'
            } else if(expandRef.current.style.display === 'block'){
                expandRef.current.style.display = 'none'
            }
        }
    }

    let fontsHolder:any = [];

    for(let x=0;x<Fonts.length;x++){
        fontsHolder.push(
            <>
                <option value={Fonts[x].name}>{Fonts[x].name}</option>
            </>
        )
    }


    /**
     * Returns the JSX representation of the Toolbar component.
     * @returns {JSX.Element} The rendered Toolbar element.
     */
    return (
        <>
            <div className="text-editor-header" id="text-editor-header">

                {props.config.map((route: ToolbarConfig, index) => (

                    (route.command === 'fonts'
                            ?
                            <>
                            <Form.Select aria-label="Default select example" style={{width:'125px',display:'inline'}} onChange={(e)=>FontCommand(e)}>
                                <option></option>
                                {fontsHolder}
                            </Form.Select>
                            </>
                    : route.command === 'headline'
                    ?
                        <>
                            <DropdownButton variant="info"
                                            className="headlines"
                                            key={index} // Ensure unique keys for React
                                            data-element={route.command}
                                            title={'H'}>
                                <Dropdown.Item onClick={()=>SetHeadline('0')}>Normal</Dropdown.Item>
                                <Dropdown.Item onClick={()=>SetHeadline('1')}>H1</Dropdown.Item>
                                <Dropdown.Item onClick={()=>SetHeadline('2')}>H2</Dropdown.Item>
                                <Dropdown.Item onClick={()=>SetHeadline('3')}>H3</Dropdown.Item>
                                <Dropdown.Item onClick={()=>SetHeadline('4')}>H4</Dropdown.Item>
                                <Dropdown.Item onClick={()=>SetHeadline('5')}>H5</Dropdown.Item>
                                <Dropdown.Item onClick={()=>SetHeadline('6')}>H6</Dropdown.Item>
                            </DropdownButton>

                        </>
                        : route.extra === false || route.extra === undefined ?
                            <button
                                type="button"
                                className="btn"
                                key={index} // Ensure unique keys for React
                                data-element={route.command}
                                title={route.title}
                            >
                                <span className={route.icon_type}>{route.icon}</span>
                            </button> : <></>)
                ))}
                <button type="button"
                        className="btn" title={'Editor erweitern '}
                        onClick={() => expand()}>
                    <span className="material-symbols-outlined">
                    view_list
                    </span>
                </button>
                <div ref={expandRef} style={{position: 'relative', display:"none"}}>

                    <TablePicker setRow={props.setRow} setCell={props.setCell}/>
                    {props.config.map((route: ToolbarConfig, index) => (
                        (route.extra === true ?
                        <button
                            type="button"
                            className="btn"
                            key={index} // Ensure unique keys for React
                            data-element={route.command}
                            title={route.title}
                        >
                            <span className={route.icon_type}>{route.icon}</span>
                        </button>
                                :<></>)
                    ))}

                </div>

            </div>
        </>
    );
};

export default Toolbar;


/**
 * Sets the headline level for the currently selected text.
 *
 * @param {string} value The desired headline level (1-6 for h1-h6 tags, or "0" to remove the H tag).
 */
const SetHeadline = (value: string) => {

    /**
     * Handles removing an H tag if the selected text is wrapped in one.
     */
    if (value === '0') {
        const userSelection = window.getSelection();
        if (!userSelection?.rangeCount) return; // No selection

        const selectedRange = userSelection.getRangeAt(0);
        const selectedText = selectedRange.toString();

        // Clear the selected text content
        selectedRange.deleteContents();

        // Check if selected text is wrapped in an H tag (any level)
        if (selectedRange.startContainer.parentElement?.tagName.toLowerCase().startsWith('h')) {
            // Remove the parent H tag element
            selectedRange.startContainer.parentElement.remove();
            const textNode = document.createTextNode(selectedText.trim()); // Trim whitespace
            selectedRange.insertNode(textNode);
        } else {
            // Insert the text as plain text (no H tag)
            const textNode = document.createTextNode(selectedText.trim()); // Trim whitespace
            selectedRange.insertNode(textNode);
        }
        return;
    }

    /**
     * Retrieves the current selection and selected text.
     */
    const userSelection = window.getSelection();
    if (!userSelection?.rangeCount) return; // No selection

    const selectedRange = userSelection.getRangeAt(0);
    const selectedText = selectedRange.toString();

    // Clear the selected text content
    selectedRange.deleteContents();

    // Check for existing H tags from h1 to h6
    for (let level = 1; level <= 6; level++) {
        let currentElement = selectedRange.startContainer.parentElement;
        let existingHtag;
        // Traverse upwards until an H tag is found or body is reached
        while (currentElement && currentElement !== document.body) {
            if (currentElement.tagName.toLowerCase() === `h${level}`) {
                existingHtag = currentElement;
                break; // Stop traversing if an H tag is found
            }
            currentElement = currentElement.parentElement;
        }

        if (existingHtag) {
            // Replace existing H tag with H tag of desired level
            const newHtag = document.createElement(`h${value}`);
            newHtag.textContent = selectedText;
            existingHtag.parentNode?.replaceChild(newHtag, existingHtag);
            return; // No need to create a new H tag
        }
    }

    // If no existing H tag is found, create a new one
    const newHtag = document.createElement(`h${value}`);
    newHtag.textContent = selectedText;
    // Insert the new H tag at the selection start (adjust as needed)
    selectedRange.startContainer.parentNode?.insertBefore(newHtag, selectedRange.startContainer);

};
