/**
 * Manages the state of the selection of the three templates
 *
 * @params {boolean} checkTemplate01 - The checked state of the first template.
 * @params {boolean} checkTemplate02 - The checked state of the second template.
 * @params {boolean} checkTemplate03 - The checked state of the third template.
 *
 */

import React, {useState} from "react";
import '../css/user.css'
import {WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import {useNavigate} from "react-router-dom";

interface UserProps {
    value: any
}

const User = (props: UserProps) => {
    const [validated, setValidated] = useState(false);

    const navigate = useNavigate()
    const BackNavigate = () => {
        //navigate('/signin/')
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        //navigate('/step2/')
    };

    return (
        <div className="User">
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <WK24Row>
                    <WK24Col>
                        <Wk24Form.Label class="header text-center"
                                        text={'Hier stehen die Userdaten und können geändert werden (Passwort, Name)'}/>

                    </WK24Col>
                </WK24Row>
            </Wk24Form>
        </div>
    )
}

export default User;
