import Form from 'react-bootstrap/Form';
import InfoButton from "../../InfoButton";
import React, {CSSProperties} from "react";

interface WK24LabelProps {
    /** Set Label text on Element */
    text: string,
    /** Set custom Classes on Element */
    class?: string,
    /** Set for on Element in DOM */
    for?: string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    InfoButton?: {
        display: boolean
        text: string
    }
}


/**
 * Create Label form text with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-control" target="_blank">Form.Label</a><br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <Wk24Form validated={validated} Submit={handleSubmit}>
 *    <Wk24Form.FormGroup>
 *         <Wk24Form.Label text={'text'} />
 *         <Wk24Form.FormControl type={'type'} name={'name'} placeholder={'placeholder'}  />
 *    </Wk24Form.FormGroup>
 * </Wk24Form>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const WK24Label = (props: WK24LabelProps) => {

    return (
        <>
            <Form.Label className={props.class} style={props.style}
                        htmlFor={props.for}>
                {props.InfoButton !== undefined && props.InfoButton.display === true
                    ? <><InfoButton.Wrapper>
                        <span>{props.text}</span><InfoButton
                        text={props.InfoButton.text}
                        icon="info"
                    />
                    </InfoButton.Wrapper></>
                    : props.text
                }</Form.Label>
        </>
    )
}

export default WK24Label;
