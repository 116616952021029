import {requests} from '../../../../@WK24/core/RestFullApi/api'
import {signinApiType} from "./signin.interface";

export const signin = {
    getPosts: (limit: any, searchValue: any): Promise<signinApiType[]> =>
        requests.get(`users`),
    createPost: (post: signinApiType, id: number): Promise<signinApiType> =>
        requests.put(`users/${id}/`, post),
    updatePost: (post: any, id: number): Promise<signinApiType> =>
        requests.post(`users/${id}/`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`users/${id}/`),
}