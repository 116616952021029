export const HeaderImagePos = (e: any) => {
    let HeaderImage = document.getElementsByClassName('HeaderImage')

    for (let sh = 0; sh < HeaderImage.length; sh++) {
        let tmp = HeaderImage[sh] as HTMLAnchorElement
        if (tmp) {
            tmp.classList.remove('top');
            tmp.classList.remove('bottom');
            tmp.classList.remove('center');
            tmp.className += ' ' + e.target.value;
        }
    }
}