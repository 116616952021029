import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import {Wk24Form} from "../../index";
import React, {CSSProperties} from "react";

interface ToggleSelectProps{
    /** Set DefaultValue on Element */
    defaultValue?:string,
    /** Set Name Attribute on Element */
    name:string,
    /** set id on Element in DOM */
    id?:string
    /** Make Element Required with val true */
    required?:boolean
    /** Set custom Classes on Element */
    className?:string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    ToggleButton:ToggleButtons[]
    short:string
}

interface ToggleButtons {
    value:string,
    label:string
}


const ToggleSelect = (props:ToggleSelectProps) => {

    const TransferValue = (id:any) => {
        let CheckRadio = document.getElementById('GederMaker') as HTMLInputElement;
        if(CheckRadio !== null) {
            CheckRadio.value = id;
        }
    }

    return(
        <>
            <ToggleButtonGroup type="radio" name={props.short} defaultValue={props.defaultValue} className={props.className} style={props.style}>
                {props.ToggleButton.map((route, index) =>
                    <ToggleButton id={"tbg-"+props.short+"-"+(index)} value={route.value}  onClick={()=>TransferValue(route.value)}>
                        {route.label}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
            <Wk24Form.FormControl name={props.name} id={props.id} defaultValue={props.defaultValue} required={props.required}
                                 type={'hidden'}/>
        </>
    )
}

export default ToggleSelect;
