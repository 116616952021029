/**
 * Manages the state of personal project name and images.
 *
 * @params {string} projectName: The name of the project.
 * @params {File | undefined} projectLogo: The selected logo file for the project (or null if none is selected).
 * @params {boolean} modalShow: The status indicates whether the modal is displayed or not.
 * @params {React.MutableRefObject<null>} logoRef: The reference to the logo input.
 *
 */

import React, {useReducer, useRef, useState} from "react";
import '../css/step.css'
import {IntlMessage, Store, WK24Button, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from "react-bootstrap/Form";
import {useNavigate} from "react-router-dom";
import Template1 from "../../templates/Pages";
import Template2 from "../../templates/Pages/Template2";
import Template3 from "../../templates/Pages/Template3";
import StepReducer from "../store/reducers/step/StepReducer";
import {StepActionType} from "../store/actions/ActionType"

interface Step4Props {
    value: any
}

const Step4 = (props: Step4Props) => {
    const [projectName, setProjectName] = useState<string>('');
    const [projectLogo, setProjectLogo] = useState<File>();
    const [validated, setValidated] = useState(false);
    const [checkLogo, setCheckLogo] = useState(true);

    const logoRef = useRef(null);

    const navigate = useNavigate();
    const [step4, step4Dispatch] = useReducer(StepReducer,
        {
            template: {template: null},
            colors: {colors: null},
            logo: {logo: null},
            name: {name: null},
            pages: {pages: null}
        })

    const BackNavigate = () => {
        navigate('/step3/')
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const template = localStorage.getItem('Template');
        const colorSet = localStorage.getItem('ColorSet');
        const nav = localStorage.getItem('Navigation');
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        if (template === "Tmp01" && colorSet && nav) {
            navigate('/template1/editor')
        }
        if (template === "Tmp02" && colorSet && nav) {
            navigate('/template2/editor')
        }
        if (template === "Tmp03" && colorSet && nav) {
            navigate('/template3/editor')
        }

        localStorage.setItem('Project', JSON.stringify(projectName));
        step4Dispatch({type: StepActionType.NAME_SUCCESS, payload: {projectName}});

        const localLogo = localStorage.getItem('Logo');
        if (localLogo) {
            step4Dispatch({type: StepActionType.LOGO_SUCCESS, payload: {localLogo}});
        }
    };

    /** Convert an image to base64 string */
    const toDataURL = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    /** Set the logo image in state and to localstorage (base64) */
    const handleLogoChange = async (e: any) => {
        const [logoFile] = e.target.files;
        if (logoFile !== null) {
            setProjectLogo(e.target.files[0]);
            const base64logo = await toDataURL(logoFile);
            localStorage.setItem('Logo', JSON.stringify(base64logo));
        }
    };

    /** Clear the selected logo file input */
    const ClearLogo = () => {
        if (logoRef.current !== null) {
            (logoRef.current as HTMLInputElement).value = '';
            setProjectLogo(undefined);
            localStorage.setItem('logo', JSON.stringify(''));
        }
    }

    /** Preview of the selected template */
    const PreviewTemplate = () => {
        const template = localStorage.getItem('Template');
        const colorSet = localStorage.getItem('ColorSet');
        const nav = localStorage.getItem('Navigation');
        let logo: File | undefined | string

        if (projectLogo === undefined) {
            if (projectName === '') {
                logo = 'Logo oder Name deines Business';
            } else {
                logo = projectName;
            }
        } else {
            logo = projectLogo;
        }

        if (template === "Tmp01" && colorSet && nav) {
            return (<Template1 value={JSON.parse(colorSet)} pages={JSON.parse(nav)} logo={logo}/>)
        }
        if (template === "Tmp02" && colorSet && nav) {
            return (<Template2 value={JSON.parse(colorSet)} pages={JSON.parse(nav)} logo={logo}/>)
        }
        if (template === "Tmp03" && colorSet && nav) {
            return (<Template3 value={JSON.parse(colorSet)} pages={JSON.parse(nav)} logo={logo}/>)
        }
    }

    return (
        <div className="Step noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <WK24Row>
                    <WK24Col>
                        <Wk24Form.Label class="header text-center"
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step4.header.label',
                                            Store: Store
                                        })}/>
                        <p className="text-center mt-3" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Step4.title', Store: Store})
                        }}/>
                        <Wk24Form.InputGroup class="form-group mt-5 mb 2 imagegroup">
                            <Wk24Form.Label
                                text={IntlMessage({locale: 'de', messageId: 'Step4.project.title', Store: Store})}
                                class={'mt-3 mr-3 imagelabel'}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessage({locale: 'de', messageId: 'Step4.project.text', Store: Store})
                                }}/>
                            <Wk24Form.FormControl type={'text'}
                                                  name={'vat'}
                                                  placeholder={IntlMessage({
                                                      locale: 'de',
                                                      messageId: 'Step4.project.placeholder',
                                                      Store: Store
                                                  })}
                                                  onChange={(e: { target: { value: string } }) => {
                                                      setProjectName(e.target.value)
                                                  }}/>
                        </Wk24Form.InputGroup>
                        <Wk24Form.Label
                            text={IntlMessage({locale: 'de', messageId: 'Step4.askLogo.title', Store: Store})}
                            class={'mt-3 mr-3 imagelabel imagegroup'}
                        />
                        <Wk24Form.InputGroup class="form-group mb 2 imagegroup">
                            <Wk24Form.Checks type={'checkbox'}
                                             label={IntlMessage({
                                                 locale: 'de',
                                                 messageId: 'Step4.LogoTrue.label',
                                                 Store: Store
                                             })}
                                             value={IntlMessage({
                                                 locale: 'de',
                                                 messageId: 'Step4.LogoTrue.label',
                                                 Store: Store
                                             })}
                                             checked={checkLogo}
                                             onChange={(e: { target: { value: string, } }) => {
                                                 setCheckLogo(true);
                                             }}/>
                            <Wk24Form.Checks type={'checkbox'}
                                             label={IntlMessage({
                                                 locale: 'de',
                                                 messageId: 'Step4.LogoFalse.label',
                                                 Store: Store
                                             })}
                                             value={IntlMessage({
                                                 locale: 'de',
                                                 messageId: 'Step4.LogoFalse.label',
                                                 Store: Store
                                             })}
                                             checked={!checkLogo}
                                             onChange={(e: { target: { value: string, } }) => {
                                                 setCheckLogo(false);
                                             }}/>
                        </Wk24Form.InputGroup>

                        {checkLogo &&
                            <Wk24Form.InputGroup class="form-group mt-3 mb 2 imagegroup">
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Step4.logo.title', Store: Store})}
                                    class={'mt-3 mr-3 imagelabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({locale: 'de', messageId: 'Step4.logo.text', Store: Store})
                                    }}/>
                                <Form.Control type={'file'}
                                              name={'logo'}
                                              ref={logoRef}
                                              placeholder={''}
                                              accept="image/png, image/jpeg, image/webp"
                                              multiple={false}
                                              onChange={handleLogoChange}/>
                                <WK24Button type={'button'}
                                            variant={'danger'}
                                            onClick={ClearLogo}>{'X'} </WK24Button>
                            </Wk24Form.InputGroup>
                        }
                        {!checkLogo &&
                            <p className="text-center mt-3 topAbstand stepAbstand" dangerouslySetInnerHTML={{
                                __html: IntlMessage({locale: 'de', messageId: 'Step4.LogoFalse.text', Store: Store})
                            }}/>
                        }

                        <p className="text-center mt-3 topAbstand stepAbstand" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Step4.bottom.text', Store: Store})
                        }}/>
                    </WK24Col>
                    <WK24Col class={'text-center pt-6 previewImage'} style={{backgroundColor: 'transparent'}}>
                        {PreviewTemplate()}
                    </WK24Col>
                </WK24Row>
                <WK24Row>
                    <div className="giraffeStep4">
                        <img className="giraIcon" src={'/gira4.png'} alt={''}/>
                    </div>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-3'}>
                            <ProgressBar now={65} style={{height: '5px'}} className={'mt-3'}/>
                        </WK24Col>
                    </WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-4'}>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnBackward.label', Store: Store})}
                                class={'float-start'} variant={'outline-primary'}
                                onClick={BackNavigate}/>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                                class={'float-end'} type={'submit'} variant={'primary'}
                                disabled={projectName === ''}/>
                        </WK24Col>
                    </WK24Row>
                </WK24Row>
            </Wk24Form>
        </div>
    )
}
export default Step4;
