import React from 'react';
import TemplateSwitcher from "./@WK24/template";
import './App.css';

function App() {
  return (
    <div className="App">
      <TemplateSwitcher layout={'default'} />
    </div>
  );
}

export default App;
