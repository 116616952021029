import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import AppendHTML from "./AppendHTML";
import {CloseButtonEditModal} from "./ButtonEdit";

const ElementModal = () => {

    return (
        <div
            className="modal show"
            id={'EditElement'}
            style={{display: 'none'}}
        >
            <Modal.Dialog>
                <Modal.Header closeButton onHide={()=>CloseElementEditModal()}>
                    <Modal.Title>Element-Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Style:</Form.Label>
                            <Form.Control as="textarea" rows={3} id='ElementEditStyle' data-highlight="css" defaultValue={''}  />
                            <Form.Control type="hidden" id='ImageEditID' defaultValue={''}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Class:</Form.Label>
                            <Form.Control type="text" id='ElementEditClass' defaultValue={''}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => CloseElementEditModal()}>Schließen</Button>
                    <Button variant="primary" onClick={() => SaveElementEditModal()}>Speichern</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}

export default ElementModal;

export const ElementEdit = (e: any) => {
    let ElementField = e.target as HTMLElement;
    ElementField.id = 'ElementEdit'
    ElementField.style.border = '1px solid blue'
    let Modal = document.getElementById('EditElement') as HTMLDivElement;
    if (Modal !== null) {
        let ModalContent = Modal.querySelector('.modal-content') as HTMLDivElement
        ModalContent.style.maxHeight = '310px'
        let style = document.getElementById('ElementEditStyle') as HTMLInputElement
        style.value = ElementField.style.cssText.replace('border: 1px solid blue;','');
        let classList = document.getElementById('ElementEditClass') as HTMLInputElement
        classList.value = ElementField.className;

        Modal.style.display = 'block'
    }
}

export const CloseElementEditModal = () => {
    let Modal = document.getElementById('EditElement') as HTMLDivElement;
    if (Modal !== null) {
        let ElementField = document.getElementById('ElementEdit') as HTMLImageElement;
        ElementField.id = ''
        ElementField.style.border = ''
        let id = document.getElementById('ImageEditID') as HTMLInputElement
        id.value = ''
        let style = document.getElementById('ElementEditStyle') as HTMLInputElement
        style.value = '';
        let classList = document.getElementById('ElementEditClass') as HTMLInputElement
        classList.value = '';
        Modal.style.display = 'none'
    }
}

export const SaveElementEditModal = () => {
    let Modal = document.getElementById('EditElement') as HTMLDivElement;
    if (Modal !== null) {
        let id = document.getElementById('ImageEditID') as HTMLInputElement
        let ElementField = document.getElementById('ElementEdit') as HTMLImageElement;
        ElementField.id = ''
        let style = document.getElementById('ElementEditStyle') as HTMLInputElement
        ElementField.style.cssText = style.value;
        let classList = document.getElementById('ElementEditClass') as HTMLInputElement
        ElementField.className = classList.value;
        id.value = ''
        AppendHTML(Modal)
        Modal.style.display = 'none'
    }
}
