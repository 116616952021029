import {DashbaordConfig} from "../modules/Dashbaord";
import {RouteProps} from "../@WK24/template/Interface/RouteProps.interface";
import {AccountConfig} from "../modules/account";
import {LoginConfig} from "../modules/login";
import {StepConfig} from "../modules/Step";
import {SigninConfig} from "../modules/signin";
import {TemplateConfig} from "../modules/templates";
import {FrontendTemplateConfig} from "../frontend/modules/templates"
import {UserConfig} from "../modules/user";
import {DesignConfig} from "../modules/design";
import {OrderConfig} from "../modules/order";
import {EditorTemplateConfig} from "../modules/EditorTemplates";
import {PaymentConfig} from "../modules/payment";
import {SocialLandingConfig} from "../modules/socialLanding"
import {MediaConfig} from "../modules/media";
import {CustomerConfig} from "../modules/customer";
import {FontsConfig} from "../modules/fonts";

export const RegisterRoute = (value: any) => {

    let TMPRegister: RouteProps[] = []

    TMPRegister = [
        ...DashbaordConfig(value),
        ...AccountConfig(value),
        ...LoginConfig(value),
        ...StepConfig(value),
        ...SigninConfig(value),
        ...TemplateConfig(value),
        ...FrontendTemplateConfig(value),
        ...UserConfig(value),
        ...DesignConfig(value),
        ...OrderConfig(value),
        ...PaymentConfig(value),
        ...EditorTemplateConfig(value),
        ...SocialLandingConfig(value),
        ...MediaConfig(value),
        ...CustomerConfig(value),
        ...FontsConfig(value)

    ]

    return [...TMPRegister]

}


/*
Ja Ostern war toll mal mit den Kids und nicht arbeiten. Erstmal freut es mich sehr das du nun wieder gesund bist und die Tage gut überstanden hast. Das mit deinen Vater betreffend des Gehirn Tumor tut mir sehr leid. Auch das sie ihn ein Auge raus nehmen müssen. Falls du irgendwas brauchst auch wen es nur Reden ist bin ich für dich da Paddy. Aber es kommen auch wieder bessere Zeiten. Unser Vater ist letztes Jahr Heiligabend verstorben und das war sehr hart für uns. Nehme deine Kraft aus deinen Kinder und sei für deinen Papa. Wie gesagt wen du reden willst oder was auch immer zögere nicht was zu sagen.
 */