import {DashbaordMenuConfig} from "../modules/Dashbaord";
import {SitemapPropsInterface} from "../@WK24/template/Interface/SitemapProps.interface";
import {AccountMenuConfig} from "../modules/account";
import {DesignMenuConfig} from "../modules/design"
import {SocialLandingMenuConfig} from "../modules/socialLanding";
import {MediaMenuConfig} from "../modules/media";
import {CustomerMenuConfig} from "../modules/customer";
import {FontsMenuConfig} from "../modules/fonts";

export const RegisterSitemap = (value: any) => {
    let TMPRegister: SitemapPropsInterface[] = []

    TMPRegister = [
        ...DashbaordMenuConfig(value),
        ...DesignMenuConfig(value),
        ...SocialLandingMenuConfig(value),
        ...FontsMenuConfig(value),
        ...MediaMenuConfig(value),
        ...AccountMenuConfig(value),
        ...CustomerMenuConfig(value)
        //...PaymentMenuConfig(value)
    ]

    return [...TMPRegister]

}

