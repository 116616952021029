import {mediaApiType} from "../request/media.interface";
import {media} from "../request/media";
import {useEffect, useState} from 'react'

export const ResponseMedia = (limit: any) => {
    const [posts, setPosts] = useState<mediaApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await media
                .getPosts(limit)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [limit])

    return isError ? posts : posts
}
