export const emailFooter = (e:any) => {
    let emailFooter = document.getElementsByClassName('emailFooter')
    for(let sh =0;sh< emailFooter.length;sh++) {
        let tmp = emailFooter[sh] as HTMLAnchorElement
        if(tmp) {
            tmp.innerText = e.target.value
            tmp.href = 'mailto:'+e.target.value
        }
    }
}
