/**
 * Creates a progress bar element with a progress value of 25%.
 *
 * @returns {string} The HTML string representing the progress bar.
 */
const blockquote = (): string => {
    return `
    <blockquote class="blockquote wk-full-width">
      <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
      <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
    </blockquote><div style="background-color: rgb(255, 255, 255);">Hier kannst du deinen Text Eintragen!</div>
  `;
};

export default blockquote;
