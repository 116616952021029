/**
 * Manages the state of the selected colors and images.
 *
 * @params {string} fontColor - The default color for text elements.
 * @params {string} primaryColor - The primary accent color for key elements.
 * @params {string} secondaryColor - The secondary accent color for complementary elements.
 * @params {string} tertiarColor - A tertiary accent color for additional elements.
 * @params {string} bgColor - The background color for the theme.
 * @params {file} projectLogo - The state of the logo file
 *
 */

import React, {useEffect, useRef, useState} from "react";
import '../css/design.css'
import {
    BackButton,
    IntlMessage,
    MIcon,
    Store,
    WK24Button,
    Wk24Cards,
    WK24Col,
    Wk24Form,
    WK24Row
} from "../../../@WK24/core";
import Form from "react-bootstrap/Form";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface DesignProps {
    value: any
}

const Design = (props: DesignProps) => {
    const [data, setData] = useState<any>();
    const [fontColor, setFontColor] = useState<string>('');
    const [primaryColor, setPrimaryColor] = useState<string>('');
    const [secondaryColor, setSecondaryColor] = useState<string>('');
    const [tertiarColor, setTertiarColor] = useState<string>('');
    const [bgColor, setBgColor] = useState<string>('');

    const colorSet = [fontColor, primaryColor, secondaryColor, tertiarColor, bgColor];
    const [projectLogo, setProjectLogo] = useState<File>();
    const logoRef = useRef(null);
    const [logoDel, setLogoDel] = useState<boolean>(false);

    let sizeWeight = '80px';
    let sizeHeight = '50px';

    const localSettings = JSON.parse(localStorage.getItem('ProjectSettings') as string);
    const localProject = JSON.parse(localStorage.getItem('Project') as string);
    const settingsID = localProject.projectSettingsID;
    const projectID = localProject.projectID;

    useEffect(() => {

        setFontColor(localSettings.colors.font);
        setPrimaryColor(localSettings.colors.primary);
        setSecondaryColor(localSettings.colors.secondary);
        setTertiarColor(localSettings.colors.tertiary);
        setBgColor(localSettings.colors.background)

        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "projetcSettings/" + projectID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                setData(result);
            })
            .catch((error) => console.error(error));

    }, [])

    /** Save Color*/
    const saveDataColor = () => {
        localStorage.setItem('ColorSet', JSON.stringify(colorSet));
        let settingsBack = {
            "id": data.id,
            "favicon": data.favicon,
            "backend": data.backend,
            "frontend": data.frontend,
            "version": data.version,
            "lastUpdate": data.lastUpdate,
            "lastAccess": data.lastAccess,
            "isActive": data.isActive,
            "login": data.login,
            "companyName": data.companyName,
            "logo": data.logo,
            "colors": {
                "font": colorSet[0],
                "primary": colorSet[1],
                "secondary": colorSet[2],
                "tertiary": colorSet[3],
                "background": colorSet[4]
            },
            "template": data.template,
            "AuthCode": data.AuthCode
        }

        const requestOptionsSettings = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(settingsBack)
        };
        fetch(ApiRoot() + "projetcSettings", requestOptionsSettings)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result)
                notifySave(Store);

                /** get new projectSettings data (color) */
                const requestOptions = {
                    method: "Get",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + BasicAuth()
                    }
                };
                fetch(ApiRoot() + "projetcSettings/" + projectID, requestOptions)
                    .then((response) => response.text())
                    .then((result: any) => {
                        result = JSON.parse(result);
                        setData(result);
                        localStorage.setItem('ProjectSettings', JSON.stringify(result))
                    })
                    .catch((error) => console.error(error));

            })
            .catch((error) => {
                console.error(error);
                notifyError(Store);
            });
    }

    /** Save Logo*/
    const saveDataLogo = () => {
        let localLogo = JSON.parse(localStorage.getItem('Logo') as string);
        let settingsBack = {
            "id": data.id,
            "favicon": data.favicon,
            "backend": data.backend,
            "frontend": data.frontend,
            "version": data.version,
            "lastUpdate": data.lastUpdate,
            "lastAccess": data.lastAccess,
            "isActive": data.isActive,
            "login": data.login,
            "companyName": data.companyName,
            "logo": localLogo,
            "colors": {
                "font": data.colors.font,
                "primary": data.colors.primary,
                "secondary": data.colors.secondary,
                "tertiary": data.colors.tertiary,
                "background": data.colors.background
            },
            "template": data.template,
            "AuthCode": data.AuthCode
        }

        const requestOptionsSettings = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(settingsBack)
        };
        fetch(ApiRoot() + "projetcSettings", requestOptionsSettings)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result)
                console.log(result)
                notifySave(Store);
                ClearLogo();
                setLogoDel(false);

                /** get new projectSettings data (logo) */
                const requestOptions = {
                    method: "Get",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + BasicAuth()
                    }
                };
                fetch(ApiRoot() + "projetcSettings/" + projectID, requestOptions)
                    .then((response) => response.text())
                    .then((result: any) => {
                        result = JSON.parse(result);
                        setData(result);
                        localStorage.setItem('ProjectSettings', JSON.stringify(result))
                    })
                    .catch((error) => console.error(error));

            })
            .catch((error) => console.error(error));
    }

    /** Convert an image to base64 string */
    const toDataURL = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    }
    const handleLogoChange = async (e: any) => {
        const [logoFile] = e.target.files;
        if (logoFile !== null) {
            setProjectLogo(e.target.files[0]);
            const base64logo = await toDataURL(logoFile);
            localStorage.setItem('Logo', JSON.stringify(base64logo));
        }
    };

    /** Clear the selected logo file input */
    const ClearLogo = () => {
        if (logoRef.current !== null) {
            (logoRef.current as HTMLInputElement).value = '';
            setProjectLogo(undefined);
            localStorage.setItem('logo', JSON.stringify(''));
        }
    }

    /** Logo Preview */
    const logoPreview = () => {
        let thumbSizeWeight = '300px';
        let thumbSizeHeight = '100px';
        let logoCheck = JSON.parse(localStorage.getItem('ProjectSettings') as string).logo !== '';

        return (
            <div className="thumbLogoArea d-flex flex-row flex-wrap justify-content-between">
                <div className="thumbLogoAreaItem itemHalf">
                    <p className="lead" dangerouslySetInnerHTML={{
                        __html: IntlMessage({locale: 'de', messageId: 'Design.logoPreview.text', Store: Store})
                    }}/>
                    {logoCheck &&
                        <div className="prewLogoItem">
                            {!logoDel && <>
                                <img src={JSON.parse(localStorage.getItem('ProjectSettings') as string).logo} alt=""
                                     style={{maxWidth: thumbSizeWeight, maxHeight: thumbSizeHeight}}/>
                                <div className="logoReset" title={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Design.logoReset.text',
                                    Store: Store
                                })} onClick={() => {
                                    localStorage.setItem('Logo', JSON.stringify(""));
                                    setLogoDel(true);
                                }}>
                                    <MIcon children="cancel" variant="-outlined"/>
                                </div>
                            </>}
                            {logoDel &&
                                <p dangerouslySetInnerHTML={{
                                    __html: IntlMessage({
                                        locale: 'de',
                                        messageId: 'Design.noLogoPreview.text',
                                        Store: Store
                                    })
                                }}/>
                            }
                        </div>
                    }
                    {!logoCheck &&
                        <p dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Design.noLogoPreview.text', Store: Store})
                        }}/>

                    }
                </div>
                {projectLogo &&
                    <div className="thumbLogoAreaItem itemHalf">
                        <p className="lead" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Design.newLogoPreview.text', Store: Store})
                        }}/>
                        <img src={URL.createObjectURL(projectLogo)} alt=""
                             style={{maxWidth: thumbSizeWeight, maxHeight: thumbSizeHeight}}/>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className="Design bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'Design.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Design.subTitle',
                Store: Store
            })}</small></h1>
            <p className="backendlabel" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Design.header.label', Store: Store})
            }}/>
            <div className="d-flex flex-row flex-wrap justify-content-between">
                <Wk24Cards style={{width: '41.5rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="palette" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'Design.cardColor.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <WK24Col class="wk-left">
                            <WK24Row>
                                <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.colorFont.title',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 colorlabel'}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Design.colorFont.text',
                                                Store: Store
                                            })
                                        }}/>
                                    <Wk24Form.FormControl
                                        type={'color'}
                                        style={{width: sizeWeight, maxWidth: sizeWeight, height: sizeHeight}}
                                        name={'vat'}
                                        value={colorSet[0]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setFontColor(e.target.value)
                                        }}
                                    />
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 hexlabel'}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        class={'hexinput'}
                                        name={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        placeholder={colorSet[0]}
                                        value={colorSet[0]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setFontColor(e.target.value);
                                        }}
                                    />
                                </Wk24Form.InputGroup>
                            </WK24Row>
                            <WK24Row>
                                <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.colorPrimary.title',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 colorlabel'}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Design.colorPrimary.text',
                                                Store: Store
                                            })
                                        }}/>
                                    <Wk24Form.FormControl
                                        type={'color'}
                                        style={{width: sizeWeight, maxWidth: sizeWeight, height: sizeHeight}}
                                        value={colorSet[1]}
                                        name={'vat'}
                                        onChange={(e: { target: { value: string } }) => {
                                            setPrimaryColor(e.target.value)
                                        }}
                                    />
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 hexlabel'}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        class={'hexinput'}
                                        name={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        placeholder={colorSet[1]}
                                        value={colorSet[1]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setPrimaryColor(e.target.value);
                                        }}
                                    />
                                </Wk24Form.InputGroup>
                            </WK24Row>
                            <WK24Row>
                                <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.colorSecondary.title',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 colorlabel'}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Design.colorSecondary.text',
                                                Store: Store
                                            })
                                        }}/>
                                    <Wk24Form.FormControl
                                        type={'color'}
                                        style={{width: sizeWeight, maxWidth: sizeWeight, height: sizeHeight}}
                                        value={colorSet[2]}
                                        name={'vat'}
                                        onChange={(e: { target: { value: string } }) => {
                                            setSecondaryColor(e.target.value)
                                        }}
                                    />
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 hexlabel'}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        class={'hexinput'}
                                        name={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        placeholder={colorSet[2]}
                                        value={colorSet[2]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setSecondaryColor(e.target.value);
                                        }}
                                    />
                                </Wk24Form.InputGroup>
                            </WK24Row>
                            <WK24Row>
                                <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.colorTertiar.title',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 colorlabel'}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Design.colorTertiar.text',
                                                Store: Store
                                            })
                                        }}/>
                                    <Wk24Form.FormControl
                                        type={'color'}
                                        style={{width: sizeWeight, maxWidth: sizeWeight, height: sizeHeight}}
                                        name={'vat'}
                                        value={colorSet[3]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setTertiarColor(e.target.value)
                                        }}
                                    />
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 hexlabel'}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        class={'hexinput'}
                                        name={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        placeholder={colorSet[3]}
                                        value={colorSet[3]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setTertiarColor(e.target.value);
                                        }}
                                    />
                                </Wk24Form.InputGroup>
                            </WK24Row>
                            <WK24Row>
                                <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup last mb 2">
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.colorBackground.title',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 colorlabel'}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Design.colorBackground.text',
                                                Store: Store
                                            })
                                        }}/>
                                    <Wk24Form.FormControl
                                        type={'color'}
                                        style={{width: sizeWeight, maxWidth: sizeWeight, height: sizeHeight}}
                                        name={'vat'}
                                        value={colorSet[4]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setBgColor(e.target.value)
                                        }}
                                    />
                                    <Wk24Form.Label
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        class={'mt-3 mr-3 hexlabel'}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        class={'hexinput'}
                                        name={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Design.hexcode.name',
                                            Store: Store
                                        })}
                                        placeholder={colorSet[4]}
                                        value={colorSet[4]}
                                        onChange={(e: { target: { value: string } }) => {
                                            setBgColor(e.target.value);
                                        }}
                                    />
                                </Wk24Form.InputGroup>
                            </WK24Row>
                        </WK24Col>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer>
                        <WK24Button children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                    class={''} variant={'link'}
                                    onClick={() => {
                                        saveDataColor();
                                    }}/>
                    </Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '41.5rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="image" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'Design.cardLogo.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <WK24Col>
                            <Wk24Form.InputGroup class="form-group mt-3 mb 2 imagegroup">
                                <Wk24Form.Label
                                    text={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Design.projectNamePreview.text',
                                        Store: Store
                                    })}
                                    class={'lead'}/>
                                <Wk24Form.Label
                                    text={JSON.parse(localStorage.getItem('Project') as string).name}
                                    class={'lead ml-1'}/>
                            </Wk24Form.InputGroup>
                            <p className="text-left pr-4" dangerouslySetInnerHTML={{
                                __html: IntlMessage({
                                    locale: 'de',
                                    messageId: 'Design.logoDescription.text',
                                    Store: Store
                                })
                            }}/>
                            <Wk24Form.InputGroup class="form-group mt-3 mb 2 imagegroup">
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Design.logo.title', Store: Store})}
                                    class={'mt-3 mr-3 imagelabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({locale: 'de', messageId: 'Design.logo.text', Store: Store})
                                    }}/>
                                <Form.Control type={'file'}
                                              name={'logo'}
                                              ref={logoRef}
                                              placeholder={''}
                                              accept="image/png, image/jpeg, image/webp"
                                              multiple={false}
                                              onChange={handleLogoChange}/>
                                <WK24Button type={'button'}
                                            variant={'danger'}
                                            onClick={ClearLogo}>{'X'} </WK24Button>
                            </Wk24Form.InputGroup>
                            {logoPreview()}
                        </WK24Col>

                    </Wk24Cards.Body>
                    <Wk24Cards.Footer>
                        <WK24Button children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                    class={''} variant={'link'}
                                    onClick={() => {
                                        saveDataLogo();
                                    }}/>
                    </Wk24Cards.Footer>
                </Wk24Cards>
                <div className="giraffeDesign">
                    <img className="giraIcon" src={'/gira3-2.png'} alt={''}/>
                </div>
            </div>
            <BackButton className={'mr-3'}/>
        </div>
    )
}

export default Design;
