/**
 * Manages the state of the navigations
 *
 * @params {any} data - the array of the navigation data.
 *
 */

import React, {useEffect, useState} from "react";
import '../css/design.css'
import {BackButton, IntlMessage, MIcon, Store, WK24Button, Wk24Cards} from "../../../@WK24/core";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";

interface NavigationProps {
    value: any
}

const Navigation = (props: NavigationProps) => {
    const [data, setData] = useState<any>([]);

    const localProject = JSON.parse(localStorage.getItem('Project') as string);
    const settingsID = localProject.projectID;

    useEffect(() => {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };
        /* hardcoded 5 */
        fetch(ApiRoot() + "projetcSettings/" + settingsID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                setData((current: any) => [result]);
                //localStorage.setItem('ProjectSettings', JSON.stringify(result))
            })
            .catch((error) => console.error(error));

    }, [])

    /** Save MainNavigation*/
    const saveDataMain = () => {
        console.log(data[0].navigation.mainNavigation)
        /*
        let mainNavPos = {
              "navigation": {
                    "mainNavigation": [
                        {
                            "id": 1,
                            "position": "2"
                        }
                    ] ,
                   "serviceNavigation": [
                        {
                            "id": 2,
                            "position": "2"
                        },
                        {
                            "id": 3,
                            "position": "1"
                        },
                        {
                            "id": 4,
                            "position": "3"
                        }
                    ]
                }
            }
            const requestOptionsSettings = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization:"Basic "+ BasicAuth()
            },
            body: JSON.stringify(mainNavPos)
        };*/
        /*fetch(ApiRoot() + "menu/position", requestOptionsSettings)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result)
                console.log(result)
            })
            .catch((error) => console.error(error));*/
    }

    const saveDataService = () => {
        console.log(data[0].navigation.serviceNavigation)
        /*
        let serviceNavPos = {
              "navigation": {
                    "mainNavigation": [
                        {
                            "id": 1,
                            "position": "2"
                        }
                    ] ,
                   "serviceNavigation": [
                        {
                            "id": 2,
                            "position": "2"
                        },
                        {
                            "id": 3,
                            "position": "1"
                        },
                        {
                            "id": 4,
                            "position": "3"
                        }
                    ]
                }
            }
            const requestOptionsSettings = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization:"Basic "+ BasicAuth()
            },
            body: JSON.stringify(serviceNavPos)
        };*/
        /*fetch(ApiRoot() + "menu/position", requestOptionsSettings)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result)
                console.log(result)
            })
            .catch((error) => console.error(error));*/
    }

    /** handle mainNavigation moveUp and moveDown */
    const handleSortMain = (position: number, index: number, direction: string) => {
        const currentDataIndex = (data).findIndex((todo: any) => todo.navigation.mainNavigation[index].position === position);
        const updatedData = Object.assign({}, data[currentDataIndex]);
        if (direction === 'moveUp') {
            if (position > 1) {
                updatedData.navigation.mainNavigation[index].position = position - 1;
                updatedData.navigation.mainNavigation[index - 1].position = position;
            } else {
                updatedData.navigation.mainNavigation[index].position = position;
            }
        }

        if (direction === 'moveDown') {
            if (position < updatedData.navigation.mainNavigation.length) {
                updatedData.navigation.mainNavigation[index].position = position + 1;
                updatedData.navigation.mainNavigation[index + 1].position = position;
            } else {
                updatedData.navigation.mainNavigation[index].position = position;
            }
        }

        const newData = data.slice();
        newData[currentDataIndex] = updatedData;
        setData(newData);
    };

    /** handle serviceNavigation moveUp and moveDown */
    const handleSortService = (position: number, index: number, direction: string) => {
        const currentDataIndex = (data).findIndex((todo: any) => todo.navigation.serviceNavigation[index].position === position);
        const updatedData = Object.assign({}, data[currentDataIndex]);
        if (direction === 'moveUp') {
            if (position > 1) {
                updatedData.navigation.serviceNavigation[index].position = position - 1;
                updatedData.navigation.serviceNavigation[index - 1].position = position;
            } else {
                updatedData.navigation.serviceNavigation[index].position = position;
            }
        }

        if (direction === 'moveDown') {
            if (position < updatedData.navigation.serviceNavigation.length) {
                updatedData.navigation.serviceNavigation[index].position = position + 1;
                updatedData.navigation.serviceNavigation[index + 1].position = position;
            } else {
                updatedData.navigation.serviceNavigation[index].position = position;
            }
        }

        const newData = data.slice();
        newData[currentDataIndex] = updatedData;
        setData(newData);
    };

    return (
        <div className="Navigation bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'Navigation.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Navigation.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Navigation.header.label', Store: Store})
            }}/>
            {[...data].map((item: any) => {
                return (
                    <div className="d-flex flex-row flex-wrap justify-content-between">
                        <Wk24Cards style={{width: '41.5rem'}}>
                            <Wk24Cards.Header as="h5"><MIcon children="move_up" variant="fill"/> {IntlMessage({
                                locale: 'de',
                                messageId: 'Navigation.cardMain.title',
                                Store: Store
                            })}</Wk24Cards.Header>
                            <Wk24Cards.Body>
                                <Wk24Cards.Text>
                                    <ul className="sortableNav">
                                        {(item.navigation.mainNavigation).sort((a: any, b: any) => a.position - (b.position))
                                            .map((navItem: any, navIndex: number) => {
                                                return (
                                                    <li className="d-flex flex-row flex-wrap justify-content-between">
                                                        <span className="navTitle">{navItem.title}</span>
                                                        <WK24Button
                                                            children={<MIcon children="expand_less" variant="fill"/>}
                                                            class={'float-start'}
                                                            variant={'outline-primary'}
                                                            onClick={() => {
                                                                handleSortMain(navItem.position, navIndex, 'moveUp');
                                                            }}
                                                        />
                                                        <WK24Button
                                                            children={<MIcon children="expand_more" variant="fill"/>}
                                                            class={'float-start'}
                                                            variant={'outline-primary'}
                                                            onClick={() => {
                                                                handleSortMain(navItem.position, navIndex, 'moveDown');
                                                            }}
                                                        />
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </Wk24Cards.Text>
                            </Wk24Cards.Body>
                            <Wk24Cards.Footer>
                                <WK24Button
                                    children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                    class={''} variant={'link'}
                                    onClick={() => {
                                        saveDataMain();
                                    }}/>
                            </Wk24Cards.Footer>
                        </Wk24Cards>
                        <Wk24Cards style={{width: '41.5rem'}}>
                            <Wk24Cards.Header as="h5"><MIcon children="move_up" variant="fill"/> {IntlMessage({
                                locale: 'de',
                                messageId: 'Navigation.cardService.title',
                                Store: Store
                            })}</Wk24Cards.Header>
                            <Wk24Cards.Body>
                                <Wk24Cards.Text>
                                    <ul className="sortableNav">
                                        {(item.navigation.serviceNavigation).sort((a: any, b: any) => a.position - (b.position))
                                            .map((navItem: any, navIndex: number) => {
                                                return (
                                                    <li className="d-flex flex-row flex-wrap justify-content-between">
                                                        <span className="navTitle">{navItem.title}</span>
                                                        <WK24Button children={<MIcon children="expand_less"
                                                                                     variant="fill"/>}
                                                                    class={'float-start'}
                                                                    variant={'outline-primary'}
                                                                    onClick={() => {
                                                                        handleSortService(navItem.position, navIndex, 'moveUp');
                                                                    }}
                                                        />
                                                        <WK24Button children={<MIcon children="expand_more"
                                                                                     variant="fill"/>}
                                                                    class={'float-start'}
                                                                    variant={'outline-primary'}
                                                                    onClick={() => {
                                                                        handleSortService(navItem.position, navIndex, 'moveDown');
                                                                    }}
                                                        />
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </Wk24Cards.Text>
                            </Wk24Cards.Body>
                            <Wk24Cards.Footer>
                                <WK24Button
                                    children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                    class={''} variant={'link'}
                                    onClick={() => {
                                        saveDataService();
                                    }}/>
                            </Wk24Cards.Footer>
                        </Wk24Cards>
                    </div>
                )
                }
            )}
            <BackButton className={'mr-3'}  />
        </div>
    )
}
export default Navigation;