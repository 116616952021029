import React, {useEffect, useRef, useState} from "react";
import {WK24Button} from "../index";
import Settings from "./components/Settings";
import Responsibility from "./components/Responsibility";
import Navigator from "./components/Navigator";
import {HandleClick} from "./const/handleClick";
import {UseEffectWebbuilderMain} from "./const/UseEffectWebbuilderMain";
import DraggableDiv from "../DragAbleDiv";
import {ToolbarConfig} from "../WYSIWYG/interface/ToolbarConfig";
import {basic} from "../WYSIWYG/components/config/Toolbar/basic";
import "./css/style.css"
/**
 * React component that renders WYSIWYG editors for elements with the class "Webbuilder".
 *
 * @props {Object} childs - Children components to be rendered.
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 */
const Webbuilder = ({ childs,SettingsEditor,config,header,footer,data,tmp }: { childs: React.ReactNode, SettingsEditor({expand, open, SeoRef}: {expand: any, open: any, SeoRef: any}): JSX.Element,config?: ToolbarConfig[],header?:any,footer?:any,data?:any,tmp?:any }) => {

    const elRef = useRef<HTMLDivElement>(null);
    const HeaderRef = useRef<HTMLDivElement>(null);
    const FooterRef = useRef<HTMLDivElement>(null);
    const [range,SetRange] = useState(0)

    let EditorConfig:ToolbarConfig[];
    if(config !== undefined) {
        EditorConfig = [
            ...basic,
            ...config
        ]
    } else {
        EditorConfig = basic
    }

    useEffect(() => {
        UseEffectWebbuilderMain(SetRange,EditorConfig);

    }, []);


    useEffect(() => {
        const elements = document.querySelectorAll(".main-content")
        for(let xr=0;xr<elements.length;xr++){
            let TMP = elements[xr] as HTMLDivElement;
            TMP.id = 'Webbuilder'+xr
        }
    }, [range]); // Re-run useEffect when range changes




    return (
        <>

            <DraggableDiv style={{top:'80px',right:'380px',position:'fixed',zIndex:'999999'}}
                          content={<><Navigator ranges={range} elRef={elRef} SetRange={SetRange} config={EditorConfig}  /></>} className={''}/>
                <Responsibility range={range} HeaderRef={HeaderRef} FooterRef={FooterRef} tmp={tmp}/>
                <div className={'float-start'}>

                    <Settings SettingsEditor={SettingsEditor} data={data} />
                </div>

                <div className={tmp !== undefined ? tmp : "template1 "}>
                    {header !== undefined ?
                        <div ref={HeaderRef} id={'HeaderHolder'} style={{overflow: 'hidden'}}>
                        {header}
                        </div>
                        :
                        <div ref={HeaderRef} id={'HeaderHolder'} style={{overflow: 'hidden'}}/>
                    }

                    <div ref={elRef} id={'ElementHolder'}>
                        {childs}
                    </div>
                    {footer !== undefined ?
                        <div ref={FooterRef} id={'FooterHolder'} style={{overflow: 'hidden'}}>
                            {footer}
                        </div>
                        :
                        <div ref={FooterRef} id={'FooterHolder'} style={{overflow: 'hidden'}}/>
                    }

                </div>
            <WK24Button children={<><span className="material-icons-outlined">
                    add
                </span>{"Block hinzufügen"}</>} class={'p-3 editorBtn'} variant={'primary'}
                            onClick={() => HandleClick(elRef, range, SetRange,EditorConfig)}
                            type={'button'}/>

        </>
    );
};

export default Webbuilder;

