export const HeaderClass = (e: any) => {
    let HeaderContent = document.getElementsByClassName('headercontent')

    for (let sh = 0; sh < HeaderContent.length; sh++) {
        let tmp = HeaderContent[sh] as HTMLAnchorElement
        if (tmp) {
            //tmp.setAttribute('style', 'text-align:' + e.target.value + '!important;')
            tmp.classList.remove('text-center');
            tmp.classList.remove('text-left');
            tmp.classList.remove('text-right');
            tmp.className += ' ' + e.target.value;
        }
    }
}