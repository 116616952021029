/**
 * Manages the state of the payment page.
 *
 */

import React from "react";
import '../css/payment.css'
import {PaymentForm} from "../../account/Elements/PaymentForm";
import {IntlMessage, Store} from "../../../@WK24/core";

interface PaymentProps {
    value: any
}

const Payment = (props: PaymentProps) => {

    return (
        <div className={props.value !== 'visibleNon' ? "Order pb-5 bpage" : "Order backendStep noneSidebar"}>
            {props.value === 'visibleNon' ?
                <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/> : ''}
            {props.value === 'visibleNon' ?
                <h1>{IntlMessage({locale: 'de', messageId: 'Payment.title', Store: Store})}</h1> :
                <h1>{IntlMessage({locale: 'de', messageId: 'Payment.visibleNon.title', Store: Store})}</h1>}
            <PaymentForm/>
            <div className="giraffeStep8">
                <img className="giraIcon" src={'/gira1-2.png'} alt={''}/>
            </div>
        </div>
    )
}

export default Payment;
