import {LoginActionType} from '../../actions/ActionType'

const user = localStorage.getItem('LoggedInUser');

const initialState = user
    ? {isLoggedIn: true, user}
    : {isLoggedIn: false, user: null};

type Action = {
    type: string,
    payload?: any
}

const LoginReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case LoginActionType.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload
            }
        case LoginActionType.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        case LoginActionType.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        default:
            return state;
    }
}
export default LoginReducer