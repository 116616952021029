import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Form from "react-bootstrap/Form";
import {ResponseMedia} from "./API/response/media";


interface ModalMediaProps {
    ProjectID: number;
    name?: string | undefined;
    id?:string | undefined;
    className?:string | undefined;
    /** Combine Element with Onchange Function and Event */
    onChange?: any;
    /** Set DefaultValue on Element */
    defaultValue?:string,
    dontPreview?:boolean
    inputType?:string
}

/**
 * A React component that displays a modal for selecting media and setting a hidden form input value.
 * @param {ModalMediaProps} props - The component's properties.
 * @prop {number} ProjectID - Number of ProjectID.
 * @prop {string} name - The name of the hidden form input field.
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 */
const ModalMedia = (props: ModalMediaProps) => {
    const [show, setShow] = useState(false);

    const [Img, setImg] = useState('');
    const ImageRef = useRef<HTMLInputElement>(null);
    const Media = ResponseMedia(props.ProjectID)
    /**
     * Updates the hidden form input value and closes the modal.
     *
     * @param {} media - The URL of the selected media.
     */
    const AddURL = (media: any) => {
         // Log the received media object

        // Assuming ImageRef is a useRef hook holding an HTMLInputElement reference
        const addedTo = ImageRef.current as HTMLInputElement;
        if (addedTo) { // Check if the reference is valid
            addedTo.value = media.url; // Update the input field value with the URL
        } else {
            console.error('ImageRef reference is not available'); // Handle cases where the reference is not set
        }

        setImg(media.url); // Update the internal image URL state

        setShow(false); // Presumably hides a modal or UI element (adjust based on your setup)

        props.onChange(media.url); // Call the parent component's onChange prop handler with the updated URL


    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /**
     * An array to hold JSX elements for each media item.
     */
    let MediaHolder = [];

    for (let m = 0; m < Media.length; m++) {
        MediaHolder.push(
            <>
                <Card style={{ width: '250px' }}>
                    <Card.Img variant="bottom" src={Media[m].url} />
                    <Card.Body>
                        <Card.Title>{Media[m].file}</Card.Title>
                        <Button variant="primary" onClick={() => AddURL(Media[m])}>
                            Hinzufügen
                        </Button>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">{Media[m].type}</small>
                    </Card.Footer>
                </Card>
            </>
        );
    }

    return (
        <>
            <Form.Control defaultValue={props.defaultValue} ref={ImageRef} type={props.inputType !== undefined ? props.inputType :'text'} id={props.id} name={props.name} className={props.className} />
            <div>
                <Button variant="primary" onClick={() => setShow(true)}>
                    Bild hinzufügen
                </Button>
            </div>
            {(Img !== '' && props.dontPreview !==undefined && props.dontPreview !== null && props.dontPreview !== false ? (
                <>
                    <br />
                    <Card style={{ width: '250px' }}>
                        <Card.Img variant="bottom" src={Img} />
                    </Card>
                </>
            ) : (
                <></>
            ))}
            <Modal show={show} size={"lg"} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Medien Galerie</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'medienmodal'}>
                    <br />
                    <br />
                    {MediaHolder}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalMedia;

/** Example for including the component
 <ModalMedia ProjectID={JSON.parse(localStorage.getItem('Project') as string).projectID} name={'test'} />
*/
