import de from './Language/de_DE.json'
import AccountDE from '../modules/account/localization/de_DE.json'
import DashbaordDE from '../modules/Dashbaord/localization/de_DE.json'
import LoginDE from '../modules/login/localization/de_DE.json'
import StepsDE from '../modules/Step/localization/de_DE.json'
import SignInDE from '../modules/signin/localization/de_DE.json'
import TemplatesDE from '../modules/templates/localization/de_DE.json'
import UserDE from '../modules/user/localization/de_DE.json'
import DesignDE from '../modules/design/localization/de_DE.json'
import OrderDE from '../modules/order/localization/de_DE.json'
import SocialLandingDE from '../modules/socialLanding/localization/de_DE.json'
import MediaLandingDE from '../modules/media/localization/de_DE.json'
import CustomerDE from '../modules/customer/localization/de_DE.json'
import FontsDE from '../modules/fonts/localization/de_DE.json'
import InvoiceDE from '../modules/invoicePDF/localization/de_DE.json'

import en from './Language/en_EN.json'
import AccountEN from '../modules/account/localization/en_EN.json'
import DashbaordEN from '../modules/Dashbaord/localization/en_EN.json'
import LoginEN from '../modules/login/localization/en_EN.json'
import StepsEN from '../modules/Step/localization/en_EN.json'
import SignInEN from '../modules/signin/localization/en_EN.json'
import TemplatesEN from '../modules/templates/localization/en_EN.json'
import UserEN from '../modules/user/localization/en_EN.json'
import DesignEN from '../modules/design/localization/en_EN.json'
import OrderEN from '../modules/order/localization/en_EN.json'
import SocialLandingEN from '../modules/socialLanding/localization/en_EN.json'
import MediaLandingEN from '../modules/media/localization/en_EN.json'
import CustomerEN from '../modules/customer/localization/en_EN.json'
import FontsEN from '../modules/fonts/localization/en_EN.json'
import InvoiceEN from '../modules/invoicePDF/localization/en_EN.json'

/**
 * RegisterLanguageDE
 * Registers the German language files
 */
export const RegisterLanguageDE = {
    ...de,
    ...AccountDE,
    ...DashbaordDE,
    ...LoginDE,
    ...StepsDE,
    ...SignInDE,
    ...TemplatesDE,
    ...UserDE,
    ...DesignDE,
    ...OrderDE,
    ...SocialLandingDE,
    ...MediaLandingDE,
    ...CustomerDE,
    ...FontsDE,
    ...InvoiceDE
}
/**
 * RegisterLanguageEN
 * Registers the English language files
 */
export const RegisterLanguageEN = {
    ...en,
    ...AccountEN,
    ...DashbaordEN,
    ...LoginEN,
    ...StepsEN,
    ...SignInEN,
    ...TemplatesEN,
    ...UserEN,
    ...DesignEN,
    ...OrderEN,
    ...SocialLandingEN,
    ...MediaLandingEN,
    ...CustomerEN,
    ...FontsEN,
    ...InvoiceEN
}

const deLang = {
    messages: {
        ...RegisterLanguageDE
    },
    locale: 'de'
}
const EnLang = {
    messages: {
        ...RegisterLanguageEN
    },
    locale: 'us'
}

export const AppLocale: any = {
    de: deLang,
    us: EnLang
}
