import Step1 from "./Pages";
import Step2 from "./Pages/Step2";
import Step3 from "./Pages/Step3";
import Step3a from "./Pages/Step3a";
import Step4 from "./Pages/Step4";
import Step0 from "./Pages/Step0";

export const StepConfig  = (value: any) => {
    return [
        {
            path: '/step0',
            elements: <Step0 value={''}/>,
            roles: null
        },
        {
            path: '/step1',
            elements: <Step1 value={''} />,
            roles: null
        },
        {
            path: '/step2',
            elements: <Step2 value={''} />,
            roles: null
        },
        {
            path: '/step3',
            elements: <Step3 value={''} />,
            roles: null
        },
        {
            path: '/step3a',
            elements: <Step3a value={''}/>,
            roles: null
        },
        {
            path: '/step4',
            elements: <Step4 value={''} />,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false

        }
    ]
}

export const StepMenuConfig = (value: any) => {
    return [
        {
            header: 'Steps',
            icon: 'public',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/steps'
        }
    ]
}
