import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

interface domainAlternativeFetchProps {
    domain: string
}

async function domainAlternativeFetch(props: domainAlternativeFetchProps) {
    let res = '';

    const raw = JSON.stringify({
        "domain": props.domain
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + BasicAuth()
        },
        body: raw
    };

    await fetch(ApiRoot() + 'domain/alternative', requestOptions)
        .then((response) => response.text())
        .then((result) => res = result)
        .catch((error) => console.error(error));

    return (res)
}

export default domainAlternativeFetch