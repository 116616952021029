import {ButtonEdit} from "./ButtonEdit";
import {ImageHero} from "./ImageEdit";
import {ElementEdit} from "./ElementEdit";

const AddEventlistener = (EditorHolder:any) => {

    const allElements = EditorHolder.querySelectorAll("*");
    for (const element of allElements) {
        element.addEventListener('contextmenu', (event:any) => {
            event.preventDefault(); // Prevent default context menu
            ElementEdit(event)
        });
    }

    const images = EditorHolder.querySelectorAll('img');
    for (const image of images) {
        image.addEventListener('click', ImageHero);
    }

    const buttons = EditorHolder.querySelectorAll('button');
    for (const button of buttons) {
        button.addEventListener('click', ButtonEdit);
    }
    const Anchors = EditorHolder.querySelectorAll('a');
    for (const Anchor of Anchors) {
        Anchor.addEventListener('click', ButtonEdit);
    }
}

export default AddEventlistener;



