import {InfoButton, IntlMessage, MIcon, Store, Wk24Form} from "../../../@WK24/core";
import React from "react";
import {HeaderTitle} from "./const/HeaderTitle";
import {SublineTitle} from "./const/SublineTitle";
import {ButtonTitle} from "./const/ButtonTitle";
import {ButtonLink} from "./const/ButtonLink";
import {emailFooter} from "./const/emailFooter";
import {phoneFooter} from "./const/phoneFooter";
import {HeaderImage} from "./const/HeaderImage";
import ModalMedia from "../../../@WK24/core/Media/ModalMedia";
import {HeaderClass} from "./const/HeaderClass";
import {HeaderImagePos} from "./const/HeaderImagePos"

const SettingsEditorDetail = ({ expand,open,SeoRef,data }: { expand:any,open:any,SeoRef:any,data:any}):JSX.Element => {


    let metaProperty = data.metaProperty

    return(
        <>
            <h3>Einstellungen
                {open === false
                    ?
                    <MIcon newMaterial={true} variant={'-outlined'} onClick={() => expand('more')}>
                        expand_more
                    </MIcon>
                    :
                    <MIcon newMaterial={true} variant={'-outlined'} onClick={() => expand('less')}>
                        expand_less
                    </MIcon>
                }
            </h3>
            <hr/>
            <div ref={SeoRef} style={{display: 'none'}}>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Site Name'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'company.infoLabel', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'siteName'}
                        name={'siteName'}
                        defaultValue={data.title}
                        placeholder={''}
                    />
                </Wk24Form.FormGroup>
                <h4>Header</h4>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Header Title'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.title', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'headerTitle'}
                        name={'HeaderTitle'}
                        defaultValue={data.pageContent.header.headline}
                        placeholder={''}
                        onChange={(e)=>HeaderTitle(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Header Description'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.subtitle', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'sublineHeader'}
                        name={'sublineHeader'}
                        placeholder={''}
                        as={'textarea'}
                        defaultValue={data.pageContent.header.text}
                        rows={6}
                        onChange={(e)=>SublineTitle(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Button Text'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.buttonText', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'buttonText'}
                        name={'buttonText'}
                        placeholder={''}
                        defaultValue={data.pageContent.header.button.text}
                        onChange={(e)=>ButtonTitle(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Button link'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.buttonLink', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'buttonLink'}
                        name={'buttonLink'}
                        placeholder={''}
                        defaultValue={data.pageContent.header.button.link}
                        onChange={(e)=>ButtonLink(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Header Image'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.headerimage', Store: Store})
                        }}
                    />
                    <ModalMedia ProjectID={JSON.parse(localStorage.getItem('Project') as string).projectID}
                                defaultValue={data.pageContent.header.images[0]}  id={'HeaderImage'}
                                className={'HeaderImage'} name={'HeaderImage'} onChange={HeaderImage} />
                    {/*<Wk24Form.FormControl
                        type={'text'}
                        id={'HeaderImage'}
                        name={'HeaderImage'}
                        placeholder={''}
                        defaultValue={data.pageContent.header.images[0]}
                        onChange={(e) => HeaderImage(e)}
                    />*/}
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Bildausrichtung'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.imageposition', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'ImagePosition'}
                        name={'ImagePosition'}
                        placeholder={''}
                        defaultValue={data.pageContent.header.imagePosition}
                        onChange={(e) => HeaderImagePos(e)}
                    />
                </Wk24Form.FormGroup>

                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Textausrichtung'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.headerclass', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'HeaderClass'}
                        name={'HeaderClass'}
                        placeholder={''}
                        defaultValue={data.pageContent.header.styleClass}
                        onChange={(e) => HeaderClass(e)}
                    />
                </Wk24Form.FormGroup>
                <hr/>
                <h4>Footer</h4>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Email'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.email', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'emailFooter'}
                        name={'emailFooter'}
                        defaultValue={data.pageContent.footer.email}
                        placeholder={''}
                        onChange={(e)=>emailFooter(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Telefon'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.phone', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'phoneFooter'}
                        name={'phoneFooter'}
                        placeholder={''}
                        defaultValue={data.pageContent.footer.phone}
                        onChange={(e)=>phoneFooter(e)}
                    />
                </Wk24Form.FormGroup>
                <hr/>
                <h4>Seo <InfoButton
                    text={'Suchmaschinenoptimierung'}
                    icon={'help'}
                /></h4>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Title'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.seoTitle', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteTitle'}
                        placeholder={''}
                        defaultValue={data.siteTitle}

                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Description'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.seoDescription', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'metaDescription'}
                        placeholder={''}
                        as={'textarea'}
                        defaultValue={data.metaDescription}
                    />
                </Wk24Form.FormGroup>
                <hr/>
                <h4>Open Graph Angaben <InfoButton
                    text={'Hier kannst du angeben, wie die Seitenvorschau aussehen soll, wenn die Seite auf Social Media geteilt wird. Wenn du nichts einträgst, werden Standardeinträge übermittelt. '}
                    icon={'help'}
                /></h4>
                {metaProperty.map((page: {content:string,pageID:number,property:string,propertyID:number}, index: any) => (

                    (
                        page.property === 'og:description' || page.property === 'og:title'
                            ?
                            <>
                                <Wk24Form.FormGroup class="form-gropup mt-2">
                                    <Wk24Form.Label
                                        text={page.property}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({messageId: 'settings.webuilder.header.'+page.property, Store: Store})
                                        }}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        name={page.property}
                                        placeholder={''}
                                        as={'textarea'}
                                        defaultValue={page.content}
                                    />
                                    <Wk24Form.FormControl
                                        type={'hidden'}
                                        name={page.property+'_propertyID'}
                                        placeholder={''}
                                        defaultValue={''+page.propertyID}

                                    />
                                </Wk24Form.FormGroup>
                            </>

                            :
                            <>
                                <Wk24Form.InputGroup class="form-gropup mt-2">
                                    <Wk24Form.InputGroupText
                                        text={page.property}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessage({messageId: 'settings.webuilder.header.'+page.property, Store: Store})
                                        }}
                                    />
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        name={page.property}
                                        placeholder={''}
                                        defaultValue={page.content}

                                    />
                                    <Wk24Form.FormControl
                                        type={'hidden'}
                                        name={page.property+'_propertyID'}
                                        placeholder={''}
                                        defaultValue={''+page.propertyID}

                                    />
                                </Wk24Form.InputGroup>
                            </>
                    )
                ))}


            </div>
        </>
    )
}

export default SettingsEditorDetail;
