/**
 * Manages the state of the dashboard
 *
 * @params {boolean} websiteCheck - the state of the website check button.
 *
 */

import {useSelector} from "react-redux";
import '../css/dashbaord.css'
import {IntlMessage, MIcon, WK24Button, Wk24Cards} from "../../../@WK24/core";
import React, {useEffect, useState} from "react";
import {
    notifyAccountDataWarning,
    notifyError,
    notifySave
} from "../../../@WK24/core/Notification/service/notification.service";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";

interface DashboardProps {
    value: string
}

const Dashboard = (props:DashboardProps) => {
    const [data, setData] = useState<any>();
    const Store = useSelector((state: any) => state)
    notifyAccountDataWarning(Store)

    const userItem = localStorage.getItem('User');
    const user = userItem ? JSON.parse(userItem) : null;
    let localProject;
    let settingsID: any;

    const [websiteCheck, setWebsiteCheck] = useState(false);

    useEffect(() => {
        localProject = JSON.parse(localStorage.getItem('Project') as string);
        settingsID = localProject.projectID;



        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "projetcSettings/" + settingsID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                setData(result);
                localStorage.setItem('ProjectSettings', JSON.stringify(result))
                let imprintBack = {
                    "root":  JSON.parse(localStorage.getItem('ProjectSettings')as string).frontend
                }
                const requestOptionss = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Basic " + BasicAuth()
                    },
                    body: JSON.stringify(imprintBack)
                }

                fetch(ApiRoot() + "impressum", requestOptionss)
                    .then((response) => response.json())
                    .then((result: any) => {
                        let newResult = {
                            director: (result.customer !== null ? result.customer.gf : ''),
                            imageRights: (result.mediaRights !== null ? result.mediaRights.right : ''),
                            authorized: (result.customer !== null ? result.customer.representative : ''),
                            settlement: (result.customer !== null ? result.customer.arbitration_settlement : 0),
                            contentResp: (result.customer !== null ? result.customer.responsible_for_content : '')
                        }
                        console.log(newResult);
                        localStorage.setItem('imprint', JSON.stringify(newResult));
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));

    }, [])

    /** save data: version */
    const saveCheck = () => {
        setWebsiteCheck(!websiteCheck);
        let settingsBack = {
            "id": JSON.parse(localStorage.getItem('ProjectSettings') as string).id,
            "favicon": JSON.parse(localStorage.getItem('ProjectSettings') as string).favicon,
            "backend": JSON.parse(localStorage.getItem('ProjectSettings') as string).backend,
            "frontend": JSON.parse(localStorage.getItem('ProjectSettings') as string).frontend,
            "version": "0.1",
            "lastUpdate": JSON.parse(localStorage.getItem('ProjectSettings') as string).lastUpdate,
            "lastAccess": JSON.parse(localStorage.getItem('ProjectSettings') as string).lastAccess,
            "isActive": JSON.parse(localStorage.getItem('ProjectSettings') as string).isActive,
            "login": JSON.parse(localStorage.getItem('ProjectSettings') as string).login,
            "companyName": JSON.parse(localStorage.getItem('ProjectSettings') as string).companyName,
            "logo": JSON.parse(localStorage.getItem('ProjectSettings') as string).logo,
            "colors": {
                "font": JSON.parse(localStorage.getItem('ProjectSettings') as string).colors.font,
                "primary": JSON.parse(localStorage.getItem('ProjectSettings') as string).colors.primary,
                "secondary": JSON.parse(localStorage.getItem('ProjectSettings') as string).colors.secondary,
                "tertiary": JSON.parse(localStorage.getItem('ProjectSettings') as string).colors.tertiary,
                "background": JSON.parse(localStorage.getItem('ProjectSettings') as string).colors.background
            },
            "template": JSON.parse(localStorage.getItem('ProjectSettings') as string).template,
            "AuthCode": JSON.parse(localStorage.getItem('ProjectSettings') as string).AuthCode
        }

        const requestOptionsSettings = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(settingsBack)
        };
        fetch(ApiRoot() + "projetcSettings", requestOptionsSettings)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result)
                console.log(result)
                notifySave(Store);
            })
            .catch((error) => {
                console.error(error);
                notifyError(Store);
            });
    }

    return(
        <div className="Dashboard bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'dashBoard.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'dashBoard.subTitle',
                Store: Store
            })} {user.firstname}!</small></h1>
            <p className="mb-4 backendlabel" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'dashBoard.header.label', Store: Store})
            }}/>
            <WK24Button
                class={(JSON.parse(localStorage.getItem('ProjectSettings') as string)?.version !== '0.0' || websiteCheck) ? 'webCheckBtnDisable' : 'webCheckBtn'}
                children={IntlMessage({locale: 'de', messageId: 'dashBoard.websiteCheck.text', Store: Store})}
                disabled={JSON.parse(localStorage.getItem('ProjectSettings') as string)?.version !== '0.0'}
                variant={'primary'}
                onClick={() => {
                    saveCheck();
                    window.location.href = 'mailto:mt@weko24.de?body='
                        + IntlMessage({locale: 'de', messageId: 'dashBoard.websiteCheckMail.text', Store: Store})
                        + JSON.parse(localStorage.getItem('ProjectSettings') as string)?.frontend
                        + IntlMessage({locale: 'de', messageId: 'dashBoard.websiteCheckMailEnd.text', Store: Store})
                        + JSON.parse(localStorage.getItem('User') as string)?.firstname + JSON.parse(localStorage.getItem('User') as string)?.lastname
                        + '&subject=Projekt:%20' + JSON.parse(localStorage.getItem('Project') as string)?.name
                        + '%20|%20' + JSON.parse(localStorage.getItem('ProjectSettings') as string)?.frontend;
                }
                }/>
            <div className="d-flex flex-row flex-wrap justify-content-between">
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="public" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardWebsite.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardWebsite.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer>
                        <a href={'https://www.' + JSON.parse(localStorage.getItem('ProjectSettings') as string)?.frontend}
                           target={"_blank"}
                           rel={"nofollow noreferrer"}
                           className={JSON.parse(localStorage.getItem('ProjectSettings') as string)?.version !== '0.2' ? 'notWebClickable' : 'webClickable'}>
                            {JSON.parse(localStorage.getItem('ProjectSettings') as string)?.frontend !== '' ? JSON.parse(localStorage.getItem('ProjectSettings') as string)?.frontend : 'www.deine-domain.de'}
                        </a>
                    </Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="contact_page" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardData.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardData.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/account"} rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="design_services" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardDesign.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardDesign.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/design"} rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="post_add" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardSites.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardSites.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/sites"} rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="move_up" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardNavigation.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardNavigation.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/sites"} rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="interests" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardSocial.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardSocial.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/socialmedia"}
                                         rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="share" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardSocialLanding.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardSocialLanding.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/sociallanding"}
                                         rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="domain" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardDomain.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardDomain.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/domain"}
                                         rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
                <Wk24Cards style={{width: '28rem'}}>
                    <Wk24Cards.Header as="h5"><MIcon children="mark_email_read" variant="fill"/> {IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardEmail.title',
                        Store: Store
                    })}</Wk24Cards.Header>
                    <Wk24Cards.Body>
                        <Wk24Cards.Text>
                            {IntlMessage({locale: 'de', messageId: 'dashBoard.cardEmail.text', Store: Store})}
                        </Wk24Cards.Text>
                    </Wk24Cards.Body>
                    <Wk24Cards.Footer><a href={"/emails"}
                                         rel={"nofollow noreferrer"}>{IntlMessage({
                        locale: 'de',
                        messageId: 'dashBoard.cardBtn.text',
                        Store: Store
                    })}</a></Wk24Cards.Footer>
                </Wk24Cards>
            </div>
        </div>
    )
}
export default Dashboard;
