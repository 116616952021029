import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Form from 'react-bootstrap/Form';
import WK24Label from "./Elements/Label";
import WK24FormGroup from "./Elements/FormGroup";
import WK24Select from "./Elements/Select";
import Wk24FloatingLabel from "./Elements/FloatingLabel";
import WK24InputGroupText from "./Elements/InputGroupText";
import WK24FormText from "./Elements/FormText";
import WK24FormControl from "./Elements/FormControl";
import WK24ControlFeedback from "./Elements/ControlFeedback";
import WK24Checks from "./Elements/Check";
import WK24InputGroup from "./Elements/InputGroup";
import ToggleSelect from "./Elements/ToggleSelect";

export interface Wk24FormProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Passes a Boolean whether validation should be carried out. */
    validated: boolean,
    /** Passes the function for the submit event. */
    Submit: (event: any) => void
}

/**
 * Wk24Form <br/>
 * ```tsx
 * <Wk24Form validated={validated} Submit={handleSubmit}>
 *          .....
 * </Wk24Form>
 * ```
 * Initializes the form. <br/>
 * The following modules can be used within the form
 * <table><tr><th>Module</th><th>Namespace</th></tr>
 * <tr><td>WK24Input</td><td>Wk24Form.FormControl</td></tr>
 * <tr><td> WK24Label</td><td>Wk24Form.Label</td></tr>
 * <tr><td> WK24FormGroup</td><td> Wk24Form.FormGroup </td></tr>
 * <tr><td> WK24FormControl</td><td> Wk24Form.InputGroup</td></tr>
 * <tr><td> WK24InputGroupText</td><td> Wk24Form.InputGroupText</td></tr>
 * </table><br/>
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const Wk24Form = (props: Wk24FormProps) => {

    return <Form noValidate validated={props.validated} onSubmit={props.Submit}>{props.children}</Form>

}

Wk24Form.FormControl = WK24FormControl
Wk24Form.Label = WK24Label
Wk24Form.FormGroup = WK24FormGroup
Wk24Form.InputGroup = WK24InputGroup
Wk24Form.InputGroupText = WK24InputGroupText
Wk24Form.Select = WK24Select
Wk24Form.FloatingLabel = Wk24FloatingLabel
Wk24Form.Text = WK24FormText
Wk24Form.ControlFeedback = WK24ControlFeedback
Wk24Form.Checks = WK24Checks
Wk24Form.ToggleSelect = ToggleSelect;

export default Wk24Form;

