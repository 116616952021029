import {defaultAddress, IAddress} from "./Address.interface";
import {IPayment} from "./Payments.interface"

export interface IAccountData{
    lastname: string,
    firstname: string,
    birthday: string,
    company:string,
    email: string,
    phone: string,
    commercial_register:{
        court:string,
        department:string,
        number:string
    },
    addresses:IAddress[],
    smallBusiness:number,
    freelancer:number,
    coworker:number,
    requiredVat:number,
    vat:string,
    payment?: IPayment,
    director: string,
    authorized: string,
    contentResp: string,
    imageRights: string,
    settlement: string
}
export const initAccountData:IAccountData = {
    lastname:'',
    firstname:'',
    birthday: '',
    company:'',
    email: '',
    phone: '',
    commercial_register:{
        court:'',
        department:'',
        number:''
    },
    addresses:[defaultAddress],
    smallBusiness:0,
    freelancer:0,
    coworker:0,
    requiredVat:0,
    vat: '',
    director: '',
    authorized: '',
    contentResp: '',
    imageRights: '',
    settlement: ''
}
