import React, {useState} from 'react';
import {Modal, Button, Form,Row,Col} from 'react-bootstrap';
import ModalMedia from "../../../Media/ModalMedia";
import ImageDimension from "../../../Media/ImageDimension";

/**
 * @typedef {Object} CreateIMGModalProps
 * @property {boolean} show - Whether to show the modal
 * @property {() => void} onClose - Callback function to call when the modal is closed
 * @property {HTMLDivElement | null} onInsertImage - Optional element to insert the image into
 */
interface CreateIMGModalProps {
    show: boolean;
    onClose: () => void;
    onInsertImage: HTMLDivElement | null;
}
/**
 * Image Modal component for creating and inserting images.
 *
 * @param {CreateIMGModalProps} props - The component props
 * @returns {JSX.Element} The rendered Image Modal component
 */
const ModalCreateIMG = (props:CreateIMGModalProps) => {
    // State variables for image details
    const [src, setSRC] = useState('');
    const [width, setWidth] = useState('0');
    const [height, setHeight] = useState('0');
    const [alt, setAlt] = useState('');
    const [Title, setTitle] = useState('');
    const [position,setPosition] = useState('');
    // State variables for the single image modal
    const [showSingle, setShowSingle] = useState(false);
    const [srcSingle, setSRCSingle] = useState('');
    const [widthSingle, setWidthSingle] = useState('');
    const [heightSingle, setHeightSingle] = useState('_self');
    const [altSingle, setAltSingle] = useState('');
    const [TitleSingle, setTitleSingle] = useState('');
    const [positionSingle,setPositionSingle] = useState('');
    const handleCloseSingle = () => setShowSingle(false);
    const handleShowSingle = () => setShowSingle(true);
    const [imageDimension, setImageDimensions] = useState({});

    /**
     * Opens the single image modal and sets state variables based on clicked image.
     *
     * @param {Event} event - The click event
     */
    const TestIMG = (event:Event) => {
        const element = event.currentTarget as HTMLImageElement;
        setSRCSingle(''+element.src);
        setWidthSingle(''+element.width);
        setHeightSingle(''+element.height);
        setAltSingle(''+element.alt)
        setTitleSingle(''+element.title)
        setPositionSingle(''+element.style.float)
        element.id = 'IMGCreate';
        handleShowSingle();
    };

    /**
     * Inserts the image from the single image modal and resets its state.
     */
    const handleInsertImageSingle = () => {
        const buildImageElement = (element: HTMLDivElement) => {
            const parseElement = element.querySelectorAll('#IMGCreate');
            if (parseElement[0]) {
                let Image = parseElement[0] as HTMLImageElement
                if(Image !== null) {
                    console.log(TitleSingle)
                    Image.src = srcSingle;
                    Image.width = parseInt(widthSingle);
                    Image.height = parseInt(heightSingle);
                    Image.alt = altSingle;
                    Image.title = TitleSingle;
                    Image.style.float = positionSingle
                    Image.removeAttribute('id');
                    Image.addEventListener('click', TestIMG);
                }
            }
        };

        if (props.onInsertImage) {
            buildImageElement(props.onInsertImage);
            handleCloseSingle();
        }
    };

    /**
     * Inserts the image and resets state variables.
     */
    const handleInsertImage = () => {
        const buildImageElement = (element: HTMLDivElement) => {
            const parseElement = element.querySelectorAll('#IMGCreate');
            if (parseElement[0]) {
                let Image = parseElement[0] as HTMLImageElement

                if(Image !== null) {
                    Image.src = src;
                    Image.width = parseInt(width);
                    Image.alt = alt;
                    Image.height = parseInt(height);
                    Image.title = Title;
                    Image.style.float =position
                    Image.removeAttribute('id');
                    Image.addEventListener('click', TestIMG);
                }
            }
        };

        if (props.onInsertImage) {
            buildImageElement(props.onInsertImage);
            setSRC('');
            setWidth('');
            setHeight('');
            setAlt('')
            setTitle('')
            setPosition('')
            setSRCSingle('');
            setWidthSingle('');
            setHeightSingle('');
            setAltSingle('');
            setTitleSingle('')
            setPositionSingle('')
        }

        props.onClose();
    };

    /**
     * Closes the modal and optionally removes the image if onInsertImage is provided.
     */
    const handleCloseCustom = () => {
        const removeImageElement = (element: HTMLDivElement) => {
            const parseElement = element.querySelectorAll('#IMGCreate');
            if (parseElement[0]) {
                let Image = parseElement[0] as HTMLImageElement
                if(Image.parentNode !== null) {
                    Image.parentNode.removeChild(Image);
                }
            }
        };

        if (props.onInsertImage) {
            removeImageElement(props.onInsertImage);
            setSRC('');
            setWidth('');
            setHeight('');
            setAlt('')
            setTitle('')
            setPosition('')
            setSRCSingle('');
            setWidthSingle('');
            setHeightSingle('');
            setAltSingle('')
            setTitleSingle('')
            setPositionSingle('')
        }

        props.onClose();
    };

    const handleChangeImage = (src:any) => {

        setSRC(src)
        ImageDimension(setImageDimensions, src)
        /* @ts-ignore */
        setWidth(imageDimension.width)
        /* @ts-ignore */
        setHeight(imageDimension.height)
    }



    return (
        <>
        <Modal show={props.show} onHide={handleCloseCustom}>
            <Modal.Header closeButton>
                <Modal.Title>Image-Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {JSON.parse(localStorage.getItem('User') as string).isCustomer === '1' ||
                     JSON.parse(localStorage.getItem('User') as string).isCustomer === 1  ?
                        <>
                        <Form.Group>
                            <Form.Label>src:</Form.Label>
                            <Form.Control type="text" value={src} onChange={(e) => setSRC(e.target.value)}/>
                        </Form.Group>
                        <ModalMedia ProjectID={JSON.parse(localStorage.getItem('Project') as string).projectID}
                                    defaultValue={src} inputType={'hidden'}
                                    onChange={handleChangeImage} /></>
                        :<>
                        <Form.Group>
                            <Form.Label>src:</Form.Label>
                            <Form.Control type="text" defaultValue={src} onChange={(e) => setSRC(e.target.value)}/>
                        </Form.Group>
                        </>
                    }
                    <Row>
                        <Form.Group>
                            <Form.Label><strong>Wichtig:</strong> Für das Bild muss eine Breite (Width) und Höhe (Height) angegeben werden.</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Width in pixel:</Form.Label>
                                <Form.Control type="text" defaultValue={width} onChange={(e) => setWidth(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Height in pixel:</Form.Label>
                                <Form.Control type="text" defaultValue={height} onChange={(e) => setHeight(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Alternativ:</Form.Label>
                                <Form.Control type="text" defaultValue={alt} onChange={(e) => setAlt(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Titel:</Form.Label>
                                <Form.Control type="text" defaultValue={Title} onChange={(e) => setTitle(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group>
                        <Form.Label>Positionierung:</Form.Label>
                        <Form.Select defaultValue={position}  onChange={(e)=>setPosition(e.target.value)}>
                            <option value="unset">als eigener Absatz</option>
                            <option value="left">Bild links neben Text</option>
                            <option value="right">Bild rechts neben Text</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
                {/* <ImageDimension url={src}  />*/}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCustom}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={handleInsertImage}>
                    Einfügen
                </Button>
            </Modal.Footer>
        </Modal>
            <Modal show={showSingle} onHide={handleCloseSingle}>
                <Modal.Header closeButton>
                    <Modal.Title>Image-Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {JSON.parse(localStorage.getItem('User') as string).isCustomer === '1' ||
                        JSON.parse(localStorage.getItem('User') as string).isCustomer === 1  ?
                            <>
                                <Form.Group>
                                    <Form.Label>src:</Form.Label>
                                    <Form.Control type="text" value={srcSingle} onChange={(e) => setSRCSingle(e.target.value)}/>
                                </Form.Group>
                                <ModalMedia ProjectID={JSON.parse(localStorage.getItem('Project') as string).projectID}
                                            defaultValue={srcSingle} inputType={'hidden'}
                                            onChange={setSRCSingle} /></>
                            :<>
                                <Form.Group>
                                    <Form.Label>src:</Form.Label>
                                    <Form.Control type="text" defaultValue={srcSingle} onChange={(e) => setSRCSingle(e.target.value)}/>
                                </Form.Group>
                            </>
                        }
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Width in pixel:</Form.Label>
                                    <Form.Control type="text" defaultValue={widthSingle} onChange={(e) => setWidthSingle(e.target.value)}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Height in pixel:</Form.Label>
                                    <Form.Control type="text" defaultValue={heightSingle} onChange={(e) => setHeightSingle(e.target.value)}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Alternativ:</Form.Label>
                                    <Form.Control type="text" defaultValue={altSingle} onChange={(e) => setAltSingle(e.target.value)}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Titel:</Form.Label>
                                    <Form.Control type="text" defaultValue={TitleSingle} onChange={(e) => setTitleSingle(e.target.value)}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label>Positionierung:</Form.Label>
                            <Form.Select defaultValue={positionSingle} onChange={(e)=>setPositionSingle(e.target.value)}>
                                <option value="unset">als eigener Absatz</option>
                                <option value="left">Bild links neben Text</option>
                                <option value="right">Bild rechts neben Text</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSingle}>
                        Schließen
                    </Button>
                    <Button variant="primary" onClick={handleInsertImageSingle}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
)
    ;
};

export default ModalCreateIMG;
