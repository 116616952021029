import {WK24Button} from "../index";
import React from "react";
import {useNavigate} from "react-router-dom";


interface BackButtonProps {
    label?:string
    to?:string
    /** One or more button variant combinations **/
    variant?:'primary'| 'secondary'| 'success'| 'danger'| 'warning'| 'info'| 'dark'| 'light'| 'link'|'outline-primary'| 'outline-secondary'| 'outline-success'| 'outline-danger'| 'outline-warning'| 'outline-info'| 'outline-dark'| 'outline-light'
    /** Set custom Classes on Element */
    className?:string
}

const BackButton = (props:BackButtonProps) => {
    const navigate = useNavigate()
    const Back = () => {
        navigate(props.to !== undefined ? props.to : '/')
    }

    return(
        <>
            <WK24Button children={props.label !== undefined ? props.label : 'Zurück'} class={props.className} variant={props.variant !== undefined ? props.variant : 'outline-primary'}
                        type={'button'} onClick={Back}/>
        </>
    )
}

export default BackButton;