/**
 * @function CTA
 * @returns {string} - Returns the HTML string for a Call to Action (CTA) element.
 *
 * @description
 * This function generates the HTML for a Call to Action (CTA) element
 * with a heading, paragraph, and button.
 */
const CTA = (): string => {
    return `
    <div class="py-14 ctablock wk-full-width">
      <div class="container">
        <div class="row">
          <div class="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
            <span class="fs-5 text-warning ls-md text-uppercase fw-semibold">Dein Claim oder Slogan</span>
            <h2 class="display-8 mt-1 mb-3 fw-bold">Deine Subheadline!</h2>
            <p class="px-lg-8 mb-6">Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte wie Hamburgefonts, Rafgenduks oder Handgloves, um Schriften zu testen.</p>
            <a href="#" class="btn btn-primary mb-3 mt-3">Dein Buttontext</a>
          </div>
        </div>
      </div>
    </div><div style="background-color: rgb(255, 255, 255);">Hier kannst du deinen Text Eintragen!</div>
  `;
};

export default CTA;
