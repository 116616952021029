import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";
import {useEffect, useState} from "react";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
/** TODO: Einabu von WK  priavtekey           */

const PKstripe = () => {
    let StripePK: string = ''
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        StripePK = process.env.REACT_APP_API_STRIPE_LOCAL as string
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        StripePK = process.env.REACT_APP_API_STRIPE_DEVELOPMENT as string
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        StripePK = process.env.REACT_APP_API_STRIPE_LIVE as string
    }
    return StripePK
}

const stripePromise = loadStripe(PKstripe());

interface user {
    userID: number,
    email: string,
    firstname: string,
    lastname: string
}


export  function PaymentForm() {
    const [clientSecret, setClientSecret] = useState(null);
    const user = JSON.parse(localStorage.getItem("User") as string);
    const [price, setPrice] = useState(0)
    console.log(user.id);

    let priceWebsite = localStorage.getItem("priceWebsite");
    let priceHosting = localStorage.getItem("priceHosting");
    let voucher = localStorage.getItem("priceVoucher");
    let p;

    useEffect(() => {
        /*if (priceWebsite && priceHosting) {
            p =  JSON.parse(priceWebsite) + JSON.parse(priceHosting)
            console.log(p)
            setPrice(p)
        }*/

        if (clientSecret === null && priceWebsite && priceHosting) {
            console.log((JSON.parse(priceWebsite) + JSON.parse(priceHosting)) * 100)
            setPrice((JSON.parse(priceWebsite) + JSON.parse(priceHosting)) * 100)

            fetch(ApiRoot() + 'order/intent/' + user.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Basic " + BasicAuth()

                },
                body: JSON.stringify({
                    price: (JSON.parse(priceWebsite) + JSON.parse(priceHosting)) * 100,
                    priceFirstYear: JSON.parse(priceWebsite) * 100,
                    currency: 'eur',
                    email: user.email,
                    name: user.firstname + user.lastname
                }),
            })
            .then(((response) => response.json()))
            .then((result: any) =>
            {
                localStorage.setItem("PaymentIntent", JSON.stringify(result));
                setClientSecret(result.secret);
            }
            ) .catch((e:any) => {
                console.error('Error fetching payment intent:', e.statusText);
            })
        }
    }, [clientSecret]);

    return (
        clientSecret ? (
            <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
          <CheckoutForm />
          </Elements>
             ) : null
  );
}

