/**
 * Manages the state of the selection of the three templates
 *
 * @params {boolean} checkTemplate01 - The checked state of the first template.
 * @params {boolean} checkTemplate02 - The checked state of the second template.
 * @params {boolean} checkTemplate03 - The checked state of the third template.
 *
 */

import React, {useReducer, useState} from "react";
import '../css/step.css'
import {IntlMessage, MIcon, Store, WK24Button, Wk24Cards, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useNavigate} from "react-router-dom";
import StepReducer from "../store/reducers/step/StepReducer";
import {StepActionType} from "../store/actions/ActionType"

interface StepProps {
    value: any
}

const Step1 = (props: StepProps) => {
    const [checkTemplate01, setCheckTemplate01] = useState<boolean>(false);
    const [checkTemplate02, setCheckTemplate02] = useState<boolean>(false);
    const [checkTemplate03, setCheckTemplate03] = useState<boolean>(false);
    const [validated, setValidated] = useState(false);

    const navigate = useNavigate()
    const [step1, step1Dispatch] = useReducer(StepReducer,
        {
            template: {template: null},
            colors: {colors: null},
            logo: {logo: null},
            name: {name: null},
            pages: {pages: null}
        })

    const BackNavigate = () => {
        navigate('/step0');
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        const localTemplate = localStorage.getItem('Template');
        if (localTemplate) {
            step1Dispatch({type: StepActionType.TEMPLATE_SUCCESS, payload: {localTemplate}});
            navigate('/step2')
        }
    };

    /** Set template selection to local storage */
    const tmpSelect =(template:string) => {
        if (template === "Tmp01"){setCheckTemplate01((prev) => !prev); setCheckTemplate02(false); setCheckTemplate03(false);}
        if (template === "Tmp02"){setCheckTemplate02((prev) => !prev); setCheckTemplate01(false); setCheckTemplate03(false);}
        if (template === "Tmp03"){setCheckTemplate03((prev) => !prev); setCheckTemplate01(false); setCheckTemplate02(false);}

        localStorage.setItem('Template', template);
    }

    return (
        <div className="Step  noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                <WK24Row>
                    <WK24Col>
                        <Wk24Form.Label class="header text-center"
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step1.header.label',
                                            Store: Store
                                        })}/>
                        <p className="text-center mt-3" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Step1.title', Store: Store})
                        }}/>
                        <WK24Row class={'mb-3 mr-5 mt-4 text-center wktemplate'} style={{marginLeft: '1.33%'}}>
                            <WK24Col
                                class={checkTemplate01 ? 'p-2 border border-primary m-3 tmpchecked' : 'p-2 border border-primary m-3'}
                                style={{width: 'max-content', maxWidth: 'max-content'}}>
                                <Wk24Cards id="tmp01" bg={"dark"} text={'white'} onClick={() => tmpSelect("Tmp01")}>
                                    <Wk24Cards.Body>
                                        <img src={'/template01.jpg'} alt={''}/>
                                        {checkTemplate01 ? <MIcon children="task_alt" variant="-outlined"/> :
                                            <MIcon children="radio_button_unchecked" variant="-outlined"/>}
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            <WK24Col
                                class={checkTemplate02 ? 'p-2 border border-primary m-3 tmpchecked' : 'p-2 border border-primary m-3'}
                                style={{width: 'max-content', maxWidth: 'max-content'}}>
                                <Wk24Cards id="tmp02" bg={"dark"} text={'white'} onClick={() => tmpSelect("Tmp02")}>
                                    <Wk24Cards.Body>
                                        <img src={'/template02.jpg'} alt={''}/>
                                        {checkTemplate02 ? <MIcon children="task_alt" variant="-outlined"/> :
                                            <MIcon children="radio_button_unchecked" variant="-outlined"/>}
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            <WK24Col
                                class={checkTemplate03 ? 'p-2 border border-primary m-3 tmpchecked' : 'p-2 border border-primary m-3'}
                                style={{width: 'max-content', maxWidth: 'max-content'}}>
                                <Wk24Cards id="tmp03" bg={"dark"} text={'white'} onClick={() => tmpSelect("Tmp03")}>
                                    <Wk24Cards.Body>
                                        <img src={'/template03.jpg'} alt={''}/>
                                        {checkTemplate03 ? <MIcon children="task_alt" variant="-outlined"/> :
                                            <MIcon children="radio_button_unchecked" variant="-outlined"/>}
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                        </WK24Row>
                    </WK24Col>
                </WK24Row>
                <WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-3'}>
                            <ProgressBar now={25} style={{height: '5px'}} className={'mt-3'}/>
                        </WK24Col>
                    </WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-4'}>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnBackward.label', Store: Store})}
                                class={'float-start'} variant={'outline-primary'}
                                onClick={BackNavigate}/>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                                class={'float-end'} variant={'primary'}
                                disabled={!checkTemplate01 && !checkTemplate02 && !checkTemplate03}/>
                        </WK24Col>
                    </WK24Row>
                </WK24Row>
            </Wk24Form>
            <div className="giraffeStep1">
                <img className="giraIcon" src={'/gira1.png'} alt={''}/>
            </div>
        </div>

    )
}

export default Step1;
