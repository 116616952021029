import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import AppendHTML from "./AppendHTML";
import ModalMedia from "../../../Media/ModalMedia";

const ImageModal = () => {

    return (
        <div
            className="modal show"
            id={'ImageEdit'}
            style={{display: 'none'}}
        >
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Image-Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        {JSON.parse(localStorage.getItem('User') as string).isCustomer === '1' ||
                        JSON.parse(localStorage.getItem('User') as string).isCustomer === 1  ?
                            <>

                                <ModalMedia ProjectID={JSON.parse(localStorage.getItem('Project') as string).projectID} id={'ImageEditSrc'}/></>
                            :<>
                                <Form.Group>
                                    <Form.Control type="text" id='ImageEditSrc' defaultValue={''}/>
                                </Form.Group>
                            </>
                        }
                        <Form.Group>
                            <Form.Label>src:</Form.Label>
                            <ModalMedia ProjectID={JSON.parse(localStorage.getItem('Project') as string).projectID} id={'ImageEditSrc'}/>
                           {/* <Form.Control type="text" id='ImageEditSrc' defaultValue={''}/>*/}
                            <Form.Control type="hidden" id='ImageEditID' defaultValue={''}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Width in pixel:</Form.Label>
                            <Form.Control type="text" id='ImageEditWidth' defaultValue={''}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Height in pixel:</Form.Label>
                            <Form.Control type="text" id='ImageEditHeight' defaultValue={''}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Alternativ:</Form.Label>
                            <Form.Control type="text" id='ImageEditAlternativ' defaultValue={''}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => CloseImageEditModal()}>Schließen</Button>
                    <Button variant="primary" onClick={() => SaveImageEditModal()}>Speichern</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}

export default ImageModal;

export const ImageHero = (e: any) => {
    let ImageField = e.target as HTMLImageElement;
    ImageField.id = 'EditImage'
    let Modal = document.getElementById('ImageEdit') as HTMLDivElement;
    if (Modal !== null) {
        let src = document.getElementById('ImageEditSrc') as HTMLInputElement
        src.value = ImageField.src
        let id = document.getElementById('ImageEditID') as HTMLInputElement
        id.value = 'EditImage'
        let width = document.getElementById('ImageEditWidth') as HTMLInputElement
        width.value = '' + ImageField.width
        let height = document.getElementById('ImageEditHeight') as HTMLInputElement
        height.value = '' + ImageField.height
        let alt = document.getElementById('ImageEditAlternativ') as HTMLInputElement
        alt.value = ImageField.alt
        Modal.style.display = 'block'
    }
}

export const CloseImageEditModal = () => {
    let Modal = document.getElementById('ImageEdit') as HTMLDivElement;
    if (Modal !== null) {
        let ImageField = document.getElementById('EditImage') as HTMLImageElement;
        ImageField.id = ''
        let id = document.getElementById('ImageEditID') as HTMLInputElement
        id.value = ''
        let src = document.getElementById('ImageEditSrc') as HTMLInputElement
        src.value = ''
        let width = document.getElementById('ImageEditWidth') as HTMLInputElement
        width.value = ''
        let height = document.getElementById('ImageEditHeight') as HTMLInputElement
        height.value = ''
        let alt = document.getElementById('ImageEditAlternativ') as HTMLInputElement
        alt.value = ''
        Modal.style.display = 'none'
    }
}

export const SaveImageEditModal = () => {
    let Modal = document.getElementById('ImageEdit') as HTMLDivElement;
    if (Modal !== null) {
        let id = document.getElementById('ImageEditID') as HTMLInputElement
        let ImageField = document.getElementById(id.value) as HTMLImageElement;
        ImageField.id = ''
        let src = document.getElementById('ImageEditSrc') as HTMLInputElement
        ImageField.src = src.value
        src.value = ''
        let width = document.getElementById('ImageEditWidth') as HTMLInputElement
        ImageField.width = parseInt(width.value)
        width.value=''
        let height = document.getElementById('ImageEditHeight') as HTMLInputElement
        ImageField.height = parseInt(height.value)
        height.value=''
        let alt = document.getElementById('ImageEditAlternativ') as HTMLInputElement
        ImageField.alt = alt.value
        alt.alt = ''
        id.value = ''
        AppendHTML(Modal)
        Modal.style.display = 'none'
    }
}
