import React, { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react';

interface WebkomNotificationProps{
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}

/**
 * Init Notification Area.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <WebkomNotification>
 *     ...
 * </WebkomNotification>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const WebkomNotification = (props:WebkomNotificationProps) =>{

    return(
        <>
            <div id={'notification'}>
                {props.children}
            </div>
        </>
    )

}

export default WebkomNotification;
