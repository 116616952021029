import ImageModal from "./ImageEdit";
import ButtonEditModal from "./ButtonEdit";
import React from "react";
import LinkModal from "./ModalCreateLink";
import ModalCreateIMG from "./ModalCreateIMG";
import ElementModal from "./ElementEdit";

const Modals = (props:{showLinkModal:any,setShowLinkModal:any,EditorContent:any,showIMGModal:any,setShowIMGModal:any}) => {
    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string

   /* console.log(text)*/
    return(
        <>
            <LinkModal show={props.showLinkModal} onClose={() => props.setShowLinkModal(false)}
                       onInsertLink={props.EditorContent} onInput={text}/>
            <ModalCreateIMG show={props.showIMGModal} onClose={() => props.setShowIMGModal(false)}
                            onInsertImage={props.EditorContent}/>
            <ImageModal />
            <ButtonEditModal />
            <ElementModal />
        </>
    )
}

export default Modals;
