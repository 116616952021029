import {RegisterSitemap} from "../../../Register/RegisterSitemap";

interface SidebarConfigProps{
        value:any
}


/**
 *
 * @constructor
 * @param props
 */
const SidebarConfig = (props:SidebarConfigProps) => {

        return [...RegisterSitemap(props.value)]

}

export default SidebarConfig
