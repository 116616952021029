import React, {useState} from 'react';
import {Modal, Button, Form,InputGroup } from 'react-bootstrap';

/**
 * @typedef {Object} LinkModalProps
 * @property {boolean} show - Whether to show the modal
 * @property {() => void} onClose - Callback function to call when the modal is closed
 * @property {HTMLDivElement | null} onInsertLink - Optional element to insert the link into
 */
interface LinkModalProps {
    show: boolean;
    onClose: () => void;
    onInsertLink: HTMLDivElement | null;
    onInput?:any
}

/**
 * Link Modal component for creating and inserting links.
 *
 * @param {LinkModalProps} props - The component props
 * @returns {JSX.Element} The rendered LinkModal component
 */
const LinkModal = (props:LinkModalProps) => {
    // State variables for link details
    const [url, setUrl] = useState('');
    const [name, setName] = useState(props.onInput+'');
    const [target, setTarget] = useState('_self');
    const [protocol, setProtocol] = useState('')
    // State variables for single link modal
    const [showSingle, setShowSingle] = useState(false);
    const [urlSingle, setUrlSingle] = useState('');
    const [nameSingle, setNameSingle] = useState('');
    const [targetSingle, setTargetSingle] = useState('_self');
    const [protocolSingle, setProtocolSingle] = useState('')
    const handleCloseSingle = () => setShowSingle(false);
    const handleShowSingle = () => setShowSingle(true);


    const search = (str:string) => {
        const regex = /^(http|https):\/\//;

        if (regex.test(str)) {
            if(regex === null) {
                return
            }
            // @ts-ignore
            const protocol = regex?.exec(str)[1].toLowerCase(); // Get the protocol (http or https)
            if (protocol === 'http') {
                return('http://')
            } else if (protocol === 'https') {
                return('https://')
            }
        }

        return ''
    }

    const clearLink = (str:string) => {
        return str.replace('https://','').replace('http://','')
    }


    /**
     * Opens the single link modal and sets state variables based on clicked link.
     *
     * @param {Event} event - The click event
     */
    const Testlink = (event:Event) => {
        const element = event.currentTarget as HTMLAnchorElement;
        setUrlSingle(clearLink(element.href) as string);
        setNameSingle(element.textContent as string);
        setTargetSingle(element.target);
        setProtocolSingle(search(element.href) as string)
        element.id = 'LinkCreate';
        handleShowSingle();
    };

    /**
     * Inserts the link from the single link modal and resets its state.
     */
    const handleInsertLinkSingle = () => {
        const buildLinkElement = (element: HTMLDivElement) => {
            const link = element.querySelectorAll('#LinkCreate');
            if (link[0]) {
                let Anchor = link[0] as HTMLAnchorElement
                if(Anchor !== null) {
                    Anchor.href = urlSingle;
                    Anchor.target = targetSingle;
                    Anchor.textContent = nameSingle;
                    Anchor.id = '';
                    Anchor.removeAttribute('id');
                    Anchor.addEventListener('click', Testlink);
                }
            }
        };

        if (props.onInsertLink) {
            buildLinkElement(props.onInsertLink);
            handleCloseSingle();
        }
    };

    /**
     * Inserts the link and resets state variables.
     */
    const handleInsertLink = () => {
        const buildLinkElement = (element: HTMLDivElement) => {
            const link = element.querySelectorAll('#LinkCreate');
            if (link[0]) {
                let Anchor = link[0] as HTMLAnchorElement

                if(Anchor !== null) {
                    console.log(url)
                    Anchor.href = url;
                    Anchor.target = target;
                    if(name !== '') {
                        Anchor.textContent = name;
                    } else {
                        Anchor.textContent = props.onInput;
                    }
                    Anchor.id = '';
                    Anchor.removeAttribute('id');
                    Anchor.addEventListener('click', Testlink);
                }
            }
        };

        if (props.onInsertLink) {
            buildLinkElement(props.onInsertLink);
            setUrl('');
            setName('');
            setTarget('_self');
            setUrlSingle('');
            setNameSingle('');
            setTargetSingle('_self');
        }

        props.onClose();
    };

    /**
     * Closes the modal and optionally removes the link if onInsertLink is provided.
     */
    const handleCloseCustom = () => {
        const removeLinkElement = (element: HTMLDivElement) => {
            const link = element.querySelector('#LinkCreate'); // Use querySelector for single element
            if (link) {
                const span = document.createTextNode(link.textContent as string); // Create a span element
                link.id = '';
                link.parentNode?.replaceChild(span, link); // Replace link with span
            }
        };

        if (props.onInsertLink) {
            removeLinkElement(props.onInsertLink);
            setUrl('');
            setName('');
            setTarget('_self');
            setUrlSingle('');
            setNameSingle('');
            setTargetSingle('_self');
        }

        props.onClose();
    };



    const handleCloseCustomSingle = () => {
        const removeLinkElement = (element: HTMLDivElement) => {
            const link = element.querySelector('#LinkCreate'); // Use querySelector for single element
            if (link) {

                link.id = '';

            }
        };

        if (props.onInsertLink) {
            removeLinkElement(props.onInsertLink);
            setUrl('');
            setName('');
            setTarget('_self');
            setUrlSingle('');
            setNameSingle('');
            setTargetSingle('_self');
        }

        handleCloseSingle();
    };

    function setFullURL(url:any) {
        const fullURL = protocol + url;

        setUrl(fullURL)
        // Do something with the fullURL, such as making an HTTP request
    }

    function setFullURLSingle(urlSingle:any) {
        const fullURL = protocolSingle + urlSingle;

        setUrlSingle(fullURL)
        // Do something with the fullURL, such as making an HTTP request
    }
    return (
        <>
        <Modal show={props.show} onHide={handleCloseCustom}>
            <Modal.Header closeButton>
                <Modal.Title>Link-Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Protokoll:</InputGroup.Text>
                        <Form.Select value={protocol} onChange={(e) => setProtocol(e.target.value)}>
                            <option value="">Interner Link</option>
                            <option value="http://">http://</option>
                            <option value="https://">https://</option>
                        </Form.Select>
                        <InputGroup className="mb-3">
                        </InputGroup>
                        <InputGroup.Text id="basic-addon1">URL:</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="URL eingeben"
                            defaultValue={url}
                            onChange={(e) => setFullURL(e.target.value)}
                        />
                    </InputGroup>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control type="text" defaultValue={props.onInput}
                                      onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Zielfenster:</Form.Label>
                        <Form.Select value={target} onChange={(e) => setTarget(e.target.value)}>
                            <option value="_self">im gleichen Fenster öffnen</option>
                            <option value="_blank">in neuem Fenster öffnen</option>
                            <option value="_parent">neues Fenster mit Navigation (Elternfenster)</option>
                            <option value="_top">neues Fenster ohne Navigation (ganzes Fenster)</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCustom}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={handleInsertLink}>
                    Einfügen
                </Button>
            </Modal.Footer>
        </Modal>
            <Modal show={showSingle} onHide={handleCloseSingle}>
                <Modal.Header closeButton>
                    <Modal.Title>Link-Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Protokoll:</InputGroup.Text>
                            <Form.Select value={protocolSingle} onChange={(e) => setProtocolSingle(e.target.value)}>
                                <option value="">Interner Link</option>
                                <option value="http://">http://</option>
                                <option value="https://">https://</option>
                            </Form.Select>
                            <InputGroup className="mb-3">
                            </InputGroup>
                            <InputGroup.Text id="basic-addon1">URL:</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="URL eingeben"
                                defaultValue={urlSingle}
                                onChange={(e) => setFullURLSingle(e.target.value)}
                            />
                        </InputGroup>
                        <Form.Group>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" value={nameSingle} onChange={(e) => setNameSingle(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Zielfenster:</Form.Label>
                            <Form.Select value={targetSingle} onChange={(e) => setTargetSingle(e.target.value)}>
                                <option value="_self">gleicher Tab oder Seite</option>
                                <option value="_blank">neuer Tab oder Fenster</option>
                                <option value="_parent">Elternfenster</option>
                                <option value="_top">ganzes Fenster</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCustomSingle}>
                        Schließen
                    </Button>
                    <Button variant="primary" onClick={handleInsertLinkSingle}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
)
    ;
};

export default LinkModal;
