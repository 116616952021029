import {ToolbarConfig} from "../../../interface/ToolbarConfig";

/**
 * An array of default toolbar button configurations for basic text editing features.
 *
 * @export
 * @type {ToolbarConfig[]}
 */
export const basic: ToolbarConfig[] = [
            {icon: "format_bold", icon_type: 'material-icons-outlined', command: 'headline',title:"Fett "},
            {icon: "format_bold", icon_type: 'material-icons-outlined', command: 'fonts',title:"Fett "},
            {icon: "format_bold", icon_type: 'material-icons-outlined', command: 'bold',title:"Fett "},
            {icon: "format_italic", icon_type: 'material-icons-outlined', command: 'italic',title:"Kursiv "},
            {icon: "format_underlined", icon_type: 'material-icons-outlined', command: 'underline',title:"Unterstrichen "},
            {icon: "format_list_bulleted", icon_type: 'material-icons-outlined', command: 'insertUnorderedList',title:"Unsortierte Liste "},
            {icon: "format_list_numbered", icon_type: 'material-icons-outlined', command: 'insertOrderedList',title:"Sortierte Liste "},
            {icon: "link", icon_type: 'material-icons-outlined', command: 'createLink',title:"Link einfügen "},
            {icon: "format_align_left", icon_type: 'material-icons-outlined', command: 'justifyLeft',title:"Linksbündig "},
            {icon: "format_align_center", icon_type: 'material-icons-outlined', command: 'justifyCenter',title:"Zentriert "},
            {icon: "format_align_right", icon_type: 'material-icons-outlined', command: 'justifyRight',title:"Rechtsbündig "},
            {icon: "format_align_justify", icon_type: 'material-icons-outlined', command: 'justifyFull',title:"Blocksatz "},
            {icon: "insert_photo", icon_type: 'material-icons-outlined', command: 'insertImage',title:"Bild einfügen "},
            {icon: "ink_eraser", icon_type: 'material-symbols-sharp', command: 'removeFormat',title:"Formatierung rückgängig machen "},
            {icon: "call_to_action", icon_type: 'material-symbols-sharp', command: 'cta', extra: true,title:"Text mit Button einfügen "},
            {icon: "timeline", icon_type: 'material-symbols-sharp', command: 'progressbar', extra: true,title:"Fortschrittsbalken einfügen "},
            {icon: "edit_note", icon_type: 'material-symbols-sharp', command: 'blockquote', extra: true,title:"Zitat einfügen "},
            {icon: "domino_mask", icon_type: 'material-symbols-sharp', command: 'hero', extra: true,title:"Text mit Button und Bild einfügen "},
            {icon: "width_normal", icon_type: 'material-symbols-outlined', command: 'threeBlocks', extra: true,title:"Dreierblock einfügen"},
            {icon: "add_card", icon_type: 'material-symbols-outlined', command: 'card', extra: true,title:"Karte einfügen "},
            {icon: "code", icon_type: 'material-symbols-sharp', command: 'code',title:"Quellcodeansicht "},
            {icon: "undo", icon_type: 'material-symbols-outlined', command: 'undo',title:"Rückgängig "},
            {icon: "redo", icon_type: 'material-symbols-outlined', command: 'redo',title:"Wiederholen "}
    ]
