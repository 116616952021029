import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

export const handleSubmitDetail = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },
                                   setValidated: any, id: any, idFooter: any, idHeader: any, idMain: any, path: any) => {

    let localProject;
    let settingsID: any;
    localProject = JSON.parse(localStorage.getItem('Project') as string);
    settingsID = localProject.projectSettingsID;

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    var formdata = new FormData(form)
    let content = '';

    const entries = formdata.entries ();
    for (const elem of entries) {
        if(elem[0] === 'editorTextarea') {
            content = content + '<div class="Webbuilder">'+elem[1]+'</div>'
        }
    }

    let back =
                {
                    id: id,
                    settingsID: settingsID,
                    path: path,
                    title: formdata.get('siteName'),
                    siteTitle: formdata.get('siteTitle'),
                    metaDescription: formdata.get('metaDescription'),
                    metaProperty: [
                        {
                            propertyID: formdata.get('og:type_name_propertyID'),
                            property: 'og:type',
                            content: formdata.get('og:type'),
                            pageID: id
                        },
                        {
                            propertyID: formdata.get('og:image_name_propertyID'),
                            property: 'og:image',
                            content: formdata.get('og:image'),
                            pageID: id
                        },
                        {
                            propertyID: formdata.get('og:locale_name_propertyID'),
                            property: 'og:locale',
                            content: formdata.get('og:locale'),
                            pageID: id
                        },
                        {
                            propertyID: formdata.get('og:site_name_name_propertyID'),
                            property: 'og:site_name',
                            content: formdata.get('og:site_name'),
                            pageID: id
                        },
                        {
                            propertyID: formdata.get('og:title_name_name_propertyID'),
                            property: 'og:title',
                            content: formdata.get('og:title'),
                            pageID: id
                        },
                        {
                            propertyID: formdata.get('og:description_name_name_propertyID'),
                            property: 'og:description',
                            content: formdata.get('og:description'),
                            pageID: id
                        },
                        {
                            propertyID: formdata.get('og:url_name_name_propertyID'),
                            property: 'og:url',
                            content: formdata.get('og:url'),
                            pageID: id
                        }
                    ],
                    state: 1,
                    metaCanonical: 'https://weko24.de/',
                    pageContent: {
                        header: {
                            id: idHeader,
                            styleClass: formdata.get('HeaderClass'),
                            headline: formdata.get('HeaderTitle'),
                            text: formdata.get('sublineHeader'),
                            button: {
                                link: formdata.get('buttonLink'),
                                text: formdata.get('buttonText')
                            },
                            images: [
                                formdata.get('HeaderImage')
                            ],
                            imagePosition: formdata.get('ImagePosition')
                        },
                        main: [
                            {
                                id: idMain,
                                content: content
                            }
                        ],
                        footer: {
                            id: idFooter,
                            company: "Weko24",
                            email: formdata.get('emailFooter'),
                            phone: formdata.get('phoneFooter')
                        }
                    }

                }


    var raw = JSON.stringify(back)
    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Basic "+BasicAuth()
        },
        body: raw
    }

    fetch(ApiRoot() + 'page', requestOptions)
        .then((response) => response.text())
        .then((result) => window.location.reload())
        .catch((error) => console.log(error))


};


export const replaceUmlauts = (text: string) => {
    return text.replaceAll(/Ä/g, "Ae").replaceAll(/ä/g, "ae").replaceAll(/Ö/g, "Oe").replaceAll(/ö/g, "oe").replaceAll(/Ü/g, "Ue").replaceAll(/ü/g, "ue").replaceAll(/ß/g, "ss").replaceAll(/ /g, "-");
};
