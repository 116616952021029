import Customer from "./Pages";


export const CustomerConfig  = (props:{value: any}) => {
    return [
        {
            path: '/customer',
            elements: <Customer value={props.value} />,
            roles: [
                'superadmin',
                'admin'
            ],
            right:['isAdmin']
        },
        {
            path: '/customer/:page',
            elements: <Customer value={props.value} />,
            roles: [
                'superadmin',
                'admin'
            ],
            right:['isAdmin']
        }
    ]
}

export const CustomerMenuConfig = (value: any) => {
    return [
        {
            header: 'Ghostlogin (Admin)',
            icon: 'login',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/customer',
            right:['isAdmin']
        }
    ]
}
