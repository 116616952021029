import Design from "./Pages";
import Sites from "./Pages/sites";
import SiteDetail from "./Pages/sitesDetail";
import SocialMedia from "./Pages/socialmedia";
import Navigation from "./Pages/navigation";
import Imprint from "./Pages/imprint"

export const DesignConfig = (value: any) => {
    return [
        {
            path: '/design/',
            elements: <Design value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/sites/',
            elements: <Sites value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/sites/:id',
            elements: <SiteDetail value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/socialmedia/',
            elements: <SocialMedia value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/navigation/',
            elements: <Navigation value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/navigation/',
            elements: <Navigation value={'invisible'}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/impressum/',
            elements: <Imprint value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
    ]
}

export const DesignMenuConfig = (value: any) => {
    return [
        {
            header: 'Website',
            icon: 'public',
            roles: [
                'superadmin',
                'admin',
            ],
            menuitem: [
                /*{
                    name: 'Bestellablauf',
                    route: '/step1',
                    icon: 'list_alt',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },*/
                {
                    name: 'Design',
                    route: '/design',
                    icon: 'design_services',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },
                {
                    name: 'Seiten',
                    route: '/sites',
                    icon: 'post_add',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                }, {
                    name: 'Navigation',
                    route: '/navigation',
                    icon: 'move_up',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },
                {
                    name: 'Impressum',
                    route: '/impressum',
                    icon: 'verified',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },
                {
                    name: 'Soziale Medien',
                    route: '/socialmedia',
                    icon: 'interests',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                }
            ]
        }
    ]
}
