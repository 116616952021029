import {StepActionType} from '../../actions/ActionType'

const template = localStorage.getItem('Template');
const colors = localStorage.getItem('ColorSet');
const pages = localStorage.getItem('Navigation');
const name = localStorage.getItem('Project');
const logo = localStorage.getItem('Logo');

const initialState = {
    template: template ? {template} : {template: null},
    colors: colors ? {colors} : {colors: null},
    pages: pages ? {pages} : {colors: null},
    name: name ? {name} : {name: null},
    logo: logo ? {logo} : {logo: null}
}

type Action = {
    type: string,
    payload?: any
}

const StepReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case StepActionType.TEMPLATE_SUCCESS:
            return {
                ...state,
                template: action.payload
            }
        case StepActionType.COLOR_SUCCESS:
            return {
                ...state,
                colors: action.payload
            }
        case StepActionType.PAGE_SUCCESS:
            return {
                ...state,
                pages: action.payload
            }
        case StepActionType.NAME_SUCCESS:
            return {
                ...state,
                name: action.payload
            }
        case StepActionType.LOGO_SUCCESS:
            return {
                ...state,
                logo: action.payload
            }
        default:
            return state;
    }
}
export default StepReducer