import {WYSIWYG} from "../../WYSIWYG/WYSIWYG";
import ReactDOM from "react-dom";
import React from "react";
import {ToolbarConfig} from "../../WYSIWYG/interface/ToolbarConfig";

export const UseEffectWebbuilderMain = (SetRange: React.Dispatch<React.SetStateAction<number>>,config:ToolbarConfig[]) => {
    const elements = document.querySelectorAll('.Webbuilder');

    /*console.log(elements)*/

    if(elements.length !== 0) {
        SetRange(elements.length)
    }

    for (let x = 0; x < elements.length; x++) {
        const element = elements[x];

        if (element.innerHTML !== '') {
            const newElement = (
                <WYSIWYG id={'Webbuilder'+x} config={config} defaultValue={element.innerHTML.replace('Webbuilder', '')} />
            );
            ReactDOM.render(newElement, element);
            element.classList.remove("Webbuilder");
        }

    }
}
