/**
 * Manages the state of various color values for the theme.
 *
 * @params {string} fontColor - The default color for text elements.
 * @params {string} primaryColor - The primary accent color for key elements.
 * @params {string} secondaryColor - The secondary accent color for complementary elements.
 * @params {string} tertiarColor - A tertiary accent color for additional elements.
 * @params {string} bgColor - The background color for the theme.
 *
 * @params {string} sizeWeight - The width of the colorbox.
 * @params {string} sizeHeight - The height of the colorbox.
 *
 */

import React, {useReducer, useRef, useState} from "react";
import '../css/step.css'
import {IntlMessage, MIcon, Store, WK24Button, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useNavigate} from "react-router-dom";
import Template1 from "../../templates/Pages";
import Template2 from "../../templates/Pages/Template2";
import Template3 from "../../templates/Pages/Template3";
import StepReducer from "../store/reducers/step/StepReducer";
import {StepActionType} from "../store/actions/ActionType"

interface Step2Props {
    value: any
}

const Step2 = (props: Step2Props) => {
    const template = localStorage.getItem('Template');

    const [validated, setValidated] = useState(false);
    const [fontColor, setFontColor] = useState<string>(() => template === "Tmp03" ? '#eaeaea' : '#181818');
    const [primaryColor, setPrimaryColor] = useState<string>(() => template === "Tmp03" ? '#ffba4c' : '#10c9c3');
    const [secondaryColor, setSecondaryColor] = useState<string>(() => template === "Tmp03" ? '#e49f30' : '#08aea9');
    const [tertiarColor, setTertiarColor] = useState<string>(() => template === "Tmp03" ? '#303030' : '#f5f5f5');
    const [bgColor, setBgColor] = useState<string>(() => template === "Tmp03" ? '#070707' : '#ffffff');
    const [selectedSet, setSelectedSet] = React.useState('Set06');

    const primaryRef = useRef();

    const navigate = useNavigate();
    const [step2, step2Dispatch] = useReducer(StepReducer,
        {
            template: {template: null},
            colors: {colors: null},
            logo: {logo: null},
            name: {name: null},
            pages: {pages: null}
        })

    const colorSet = [fontColor, primaryColor, secondaryColor, tertiarColor, bgColor];
    const defaultNavi = ['Home', 'Kontakt', 'Über mich', 'Angebote', 'Galerie']

    let sizeWeight = '80px';
    let sizeHeight = '40px';

    const BackNavigate = () => {
        navigate('/step1/');
    }

    /** predefined colorsets */
    const selectColorset = (set: string) => {
        switch (set) {
            case 'Set01': {
                setFontColor('#18162e');
                setPrimaryColor('#89346d');
                setSecondaryColor('#e8c574');
                setTertiarColor('#f1e4c4');
                setBgColor('#ffffff');
                break;
            }
            case 'Set02': {
                setFontColor('#18162e');
                setPrimaryColor('#022859');
                setSecondaryColor('#67bada');
                setTertiarColor('#d7eff9');
                setBgColor('#ffffff')
                break;
            }
            case 'Set03': {
                setFontColor('#0b1316');
                setPrimaryColor('#4f4e53');
                setSecondaryColor('#e87f52');
                setTertiarColor('#eae6db');
                setBgColor('#ffffff')
                break;
            }
            case 'Set04': {
                setFontColor('#222222');
                setPrimaryColor('#13678b');
                setSecondaryColor('#45c4af');
                setTertiarColor('#e5fae7');
                setBgColor('#ffffff')
                break;
            }
            case 'Set05': {
                setFontColor('#0b1326');
                setPrimaryColor('#f25c05');
                setSecondaryColor('#949bb5');
                setTertiarColor('#efeff9');
                setBgColor('#ffffff')
                break;
            }
            default: {
                setFontColor('#181818');
                setPrimaryColor('#10c9c3');
                setSecondaryColor('#08aea9');
                setTertiarColor('#f5f5f5');
                setBgColor('#ffffff')
                break;
            }
        }
        setSelectedSet(set);
        return null;
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();

        const localColor = localStorage.getItem('ColorSet');
        if (localColor) {
            step2Dispatch({type: StepActionType.COLOR_SUCCESS, payload: {localColor}});
            navigate('/step3/')
        }
    };

    /** Preview of the selected template */
    const PreviewTemplate = () => {
        localStorage.setItem('ColorSet', JSON.stringify(colorSet));

        if (template === "Tmp01"){
            return (<Template1 value={colorSet} pages={defaultNavi} logo={'Logo'}/>)
        }
        if (template === "Tmp02") {
            return (<Template2 value={colorSet} pages={defaultNavi} logo={'Logo'}/>)
        }
        if (template === "Tmp03") {
            return (<Template3 value={colorSet} pages={defaultNavi} logo={'Logo'}/>)
        }
    }

    return (
        <div className="Step noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <WK24Row>
                    <WK24Col class="wk-left">
                        <Wk24Form.Label class="header text-center"
                                        text={IntlMessage({locale: 'de',messageId: 'Step2.header.label',Store: Store})}/>
                        <p className="text-center mt-3" 
                           dangerouslySetInnerHTML={{
                                __html: IntlMessage({locale: 'de',messageId: 'Step2.title',Store: Store})
                            }}/>
                        <WK24Row>
                            <div className="colorSets">
                                <img src={'/farbsets/farbset1.jpg'} title="Farbset 1"
                                     className={selectedSet === "Set01" ? "activeSet" : ""} alt={'Farbset 1'}
                                     onClick={() => selectColorset("Set01")}/>
                                <img src={'/farbsets/farbset2.jpg'} title="Farbset 2"
                                     className={selectedSet === "Set02" ? "activeSet" : ""} alt={'Farbset 2'}
                                     onClick={() => selectColorset("Set02")}/>
                                <img src={'/farbsets/farbset3.jpg'} title="Farbset 3"
                                     className={selectedSet === "Set03" ? "activeSet" : ""} alt={'Farbset 3'}
                                     onClick={() => selectColorset("Set03")}/>
                                <img src={'/farbsets/farbset4.jpg'} title="Farbset 4"
                                     className={selectedSet === "Set04" ? "activeSet" : ""} alt={'Farbset 4'}
                                     onClick={() => selectColorset("Set04")}/>
                                <img src={'/farbsets/farbset5.jpg'} title="Farbset 5"
                                     className={selectedSet === "Set05" ? "activeSet" : ""} alt={'Farbset 5'}
                                     onClick={() => selectColorset("Set05")}/>
                                <img src={'/farbsets/farbset6.jpg'} title="Farbset 6"
                                     className={selectedSet === "Set06" ? "activeSet" : ""} alt={'Farbset 6'}
                                     onClick={() => selectColorset("Set06")}/>
                            </div>

                            <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de',messageId: 'Step2.colorFont.title',Store: Store})}
                                    class={'mt-3 mr-3 colorlabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({locale: 'de',messageId: 'Step2.colorFont.text',Store: Store})
                                    }}/>
                                <Wk24Form.FormControl type={'color'} style={{width: sizeWeight, maxWidth: sizeWeight, height: sizeHeight}}
                                    name={'vat'}
                                                      value={colorSet[0]}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setFontColor(e.target.value)
                                                      }}/>
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de',messageId: 'Step2.hexcode.name',Store: Store})}
                                    class={'mt-3 mr-3 hexlabel'}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    class={'hexinput'}
                                    name={IntlMessage({locale:'de', messageId:'Step2.hexcode.name', Store:Store})}
                                    placeholder={colorSet[0]}
                                    value={colorSet[0]}
                                    onChange={(e:{target:{value:string}}) => {setFontColor(e.target.value);}}
                                />
                                <div className="colorReset" title={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step2.colorReset.text',
                                    Store: Store
                                })} onClick={() => {
                                    if (selectedSet === 'Set01') {
                                        setFontColor('#18162e')
                                    }
                                    if (selectedSet === 'Set02') {
                                        setFontColor('#18162e')
                                    }
                                    if (selectedSet === 'Set03') {
                                        setFontColor('#0b1316')
                                    }
                                    if (selectedSet === 'Set04') {
                                        setFontColor('#222222')
                                    }
                                    if (selectedSet === 'Set05') {
                                        setFontColor('#0b1326')
                                    }
                                    if (selectedSet === 'Set06') {
                                        setFontColor('#181818')
                                    }
                                }}>
                                    <MIcon children="restart_alt" variant="-outlined"/>
                                </div>
                            </Wk24Form.InputGroup>
                        </WK24Row>
                        <WK24Row>
                            <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                <Wk24Form.Label
                                    text={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Step2.colorPrimary.title',
                                        Store: Store
                                    })}
                                    class={'mt-3 mr-3 colorlabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step2.colorPrimary.text',
                                            Store: Store
                                        })
                                    }}/>
                                <Wk24Form.FormControl type={'color'} style={{
                                    width: sizeWeight,
                                    maxWidth: sizeWeight,
                                    height: sizeHeight
                                }}
                                                      value={colorSet[1]}
                                                      name={'vat'}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setPrimaryColor(e.target.value)
                                                      }}
                                />
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    class={'mt-3 mr-3 hexlabel'}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    class={'hexinput'}
                                    name={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    placeholder={colorSet[1]}
                                    value={colorSet[1]}
                                    onChange={(e: { target: { value: string } }) => {
                                        setPrimaryColor(e.target.value);
                                    }}
                                />
                                <div className="colorReset" title={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step2.colorReset.text',
                                    Store: Store
                                })} onClick={() => {
                                    if (selectedSet === 'Set01') {
                                        setPrimaryColor('#89346d')
                                    }
                                    if (selectedSet === 'Set02') {
                                        setPrimaryColor('#022859')
                                    }
                                    if (selectedSet === 'Set03') {
                                        setPrimaryColor('#4f4e53')
                                    }
                                    if (selectedSet === 'Set04') {
                                        setPrimaryColor('#13678b')
                                    }
                                    if (selectedSet === 'Set05') {
                                        setPrimaryColor('#f25c05')
                                    }
                                    if (selectedSet === 'Set06') {
                                        setPrimaryColor('#10c9c3')
                                    }
                                }}>
                                    <MIcon children="restart_alt" variant="-outlined"/>
                                </div>
                            </Wk24Form.InputGroup>
                        </WK24Row>
                        <WK24Row>
                            <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                <Wk24Form.Label
                                    text={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Step2.colorSecondary.title',
                                        Store: Store
                                    })}
                                    class={'mt-3 mr-3 colorlabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step2.colorSecondary.text',
                                            Store: Store
                                        })
                                    }}/>
                                <Wk24Form.FormControl type={'color'} style={{
                                    width: sizeWeight,
                                    maxWidth: sizeWeight,
                                    height: sizeHeight
                                }}
                                                      value={colorSet[2]}
                                                      name={'vat'}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setSecondaryColor(e.target.value)
                                                      }}
                                />
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    class={'mt-3 mr-3 hexlabel'}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    class={'hexinput'}
                                    name={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    placeholder={colorSet[2]}
                                    value={colorSet[2]}
                                    onChange={(e: { target: { value: string } }) => {
                                        setSecondaryColor(e.target.value);
                                    }}
                                />
                                <div className="colorReset" title={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step2.colorReset.text',
                                    Store: Store
                                })} onClick={() => {
                                    if (selectedSet === 'Set01') {
                                        setSecondaryColor('#e8c574')
                                    }
                                    if (selectedSet === 'Set02') {
                                        setSecondaryColor('#67bada')
                                    }
                                    if (selectedSet === 'Set03') {
                                        setSecondaryColor('#e87f52')
                                    }
                                    if (selectedSet === 'Set04') {
                                        setSecondaryColor('#45c4af')
                                    }
                                    if (selectedSet === 'Set05') {
                                        setSecondaryColor('#949bb5')
                                    }
                                    if (selectedSet === 'Set06') {
                                        setSecondaryColor('#08aea9')
                                    }
                                }}>
                                    <MIcon children="restart_alt" variant="-outlined"/>
                                </div>
                            </Wk24Form.InputGroup>
                        </WK24Row>
                        <WK24Row>
                            <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup mb 2">
                                <Wk24Form.Label
                                    text={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Step2.colorTertiar.title',
                                        Store: Store
                                    })}
                                    class={'mt-3 mr-3 colorlabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step2.colorTertiar.text',
                                            Store: Store
                                        })
                                    }}/>
                                <Wk24Form.FormControl type={'color'} style={{
                                    width: sizeWeight,
                                    maxWidth: sizeWeight,
                                    height: sizeHeight
                                }}
                                                      name={'vat'}
                                                      value={colorSet[3]}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setTertiarColor(e.target.value)
                                                      }}
                                />
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    class={'mt-3 mr-3 hexlabel'}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    class={'hexinput'}
                                    name={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    placeholder={colorSet[3]}
                                    value={colorSet[3]}
                                    onChange={(e: { target: { value: string } }) => {
                                        setTertiarColor(e.target.value);
                                    }}
                                />
                                <div className="colorReset" title={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step2.colorReset.text',
                                    Store: Store
                                })} onClick={() => {
                                    if (selectedSet === 'Set01') {
                                        setTertiarColor('#f1e4c4')
                                    }
                                    if (selectedSet === 'Set02') {
                                        setTertiarColor('#d7eff9')
                                    }
                                    if (selectedSet === 'Set03') {
                                        setTertiarColor('#eae6db')
                                    }
                                    if (selectedSet === 'Set04') {
                                        setTertiarColor('#e5fae7')
                                    }
                                    if (selectedSet === 'Set05') {
                                        setTertiarColor('#efeff9')
                                    }
                                    if (selectedSet === 'Set06') {
                                        setTertiarColor('#f5f5f5')
                                    }
                                }}>
                                    <MIcon children="restart_alt" variant="-outlined"/>
                                </div>
                            </Wk24Form.InputGroup>
                        </WK24Row>
                        <WK24Row>
                            <Wk24Form.InputGroup id="colorgroup" class="form-group colorgroup last mb 2">
                                <Wk24Form.Label
                                    text={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Step2.colorBackground.title',
                                        Store: Store
                                    })}
                                    class={'mt-3 mr-3 colorlabel'}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step2.colorBackground.text',
                                            Store: Store
                                        })
                                    }}/>
                                <Wk24Form.FormControl type={'color'} style={{
                                    width: sizeWeight,
                                    maxWidth: sizeWeight,
                                    height: sizeHeight
                                }}
                                                      name={'vat'}
                                                      value={colorSet[4]}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setBgColor(e.target.value)
                                                      }}
                                />
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    class={'mt-3 mr-3 hexlabel'}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    class={'hexinput'}
                                    name={IntlMessage({locale: 'de', messageId: 'Step2.hexcode.name', Store: Store})}
                                    placeholder={colorSet[4]}
                                    value={colorSet[4]}
                                    onChange={(e: { target: { value: string } }) => {
                                        setBgColor(e.target.value);
                                    }}
                                />
                                <div className="colorReset" title={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step2.colorReset.text',
                                    Store: Store
                                })} onClick={() => {
                                    setBgColor('#ffffff')
                                }}>
                                    <MIcon children="restart_alt" variant="-outlined"/>
                                </div>
                            </Wk24Form.InputGroup>
                        </WK24Row>
                        <p className="text-center mt-3 font-weight-bold" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'step2.bottom.text', Store: Store})
                        }}/>
                    </WK24Col>
                    <WK24Col class={'text-center pt-6 previewImage'} style={{backgroundColor: 'transparent'}}>
                        {PreviewTemplate()}
                    </WK24Col>
                </WK24Row>
                <WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-3'}>
                            <ProgressBar now={35} style={{height: '5px'}} className={'mt-3'}/>
                        </WK24Col>
                        <div className="chamiStep2">
                            <img className="chamiIcon" src={'/chami4.png'} alt={''}/>
                        </div>
                    </WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-4'}>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnBackward.label', Store: Store})}
                                class={'float-start'} variant={'outline-primary'}
                                onClick={BackNavigate}/>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                                class={'float-end'} variant={'primary'}/>
                        </WK24Col>
                    </WK24Row>
                </WK24Row>
            </Wk24Form>
        </div>
    )
}
export default Step2;