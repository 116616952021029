import SecureCheck from "../components/SecureCheck";
import {Route, Routes} from "react-router-dom";
import Site404 from "../404";
import React from "react";
import {RouteProps} from "../Interface/RouteProps.interface";
import RoutingConfig from "../components/RoutingConfig";

interface DefaultTemplateProps{
    /** Passed value to App.tsx */
    value:string
}

/**
 * Default template includes a sidebar and the content area
 * @param props
 * @constructor
 */
const FrontendTemplate = (props:DefaultTemplateProps) => {
    let route: RouteProps[] = RoutingConfig({value: props.value})
    return (
        <>
                            <Routes>
                                {route.map((route, index) =>
                                    (route.roles === null ?
                                            <Route
                                                key={'5'}
                                                path={route.path}
                                                element={route.elements}
                                                errorElement={<Site404/>}
                                            />
                                            :
                                    SecureCheck({role: route.roles}) ? (
                                        <Route
                                            key={'5'}
                                            path={route.path}
                                            element={route.elements}
                                            errorElement={<Site404/>}
                                        />
                                    ) : (<></>

                                    )
                                ))}
                            </Routes>
        </>
    )
}

export default FrontendTemplate;


