const AppendHTML = (element:any) => {
    const targetClass = "main-content";

    let parent = findParentByClass(element, targetClass);

    if (!parent) {
        return
    }
    let texteditorcontent = parent.querySelector('.text-editor-content')
    let editorCodearea = parent.querySelector('.code-block')

    if (texteditorcontent !== null) {
        const newContent = texteditorcontent.innerHTML;

        if (editorCodearea !== null && editorCodearea.querySelector('code') !== null) {
            // @ts-ignore
            editorCodearea.querySelector('code').textContent = newContent; // Update code area content
        }
    }
};

export default AppendHTML;



const findParentByClass = (element: any, targetClass: string): Element | null  => {
    /*
    console.log(element.parentNode)
    console.log(element.parentNode.classList.contains(targetClass))
    */

    if (element.parentNode.classList.contains(targetClass)) {
        return element.parentNode;
    } else if (element.parentNode === null) {
        return null;
    } else {
        return findParentByClass(element.parentNode, targetClass);
    }
}
