/**
 * Manages the state of the selection of the kind og website
 *
 * @params {boolean} checkWebsite - The checked state of the small Website.
 * @params {boolean} checkWebsiteBlog - The checked state of the Website with Blog.
 * @params {boolean} checkWebsiteShop - The checked state of the Website with Shop.
 *
 */

import React, {useState} from "react";
import '../css/step.css'
import {IntlMessage, MIcon, Store, WK24Button, Wk24Cards, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useNavigate} from "react-router-dom";

interface Step0Props {
    value: any
}

const Step0 = (props: Step0Props) => {
    const initPriceWebsite = 696;
    const initPriceWebsiteBlog = 876;
    const initPriceWebsiteShop = 996;
    const initPriceHosting = 294;

    const [validated, setValidated] = useState(false);
    const [checkWebsite, setCheckWebsite] = useState<boolean>(false);
    const [checkWebsiteBlog, setCheckWebsiteBlog] = useState<boolean>(false);
    const [checkWebsiteShop, setCheckWebsiteShop] = useState<boolean>(false);
    const [priceWebsite, setPriceWebsite] = useState<any>(0);

    const navigate = useNavigate()

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        const localWebsite = localStorage.getItem('Website');
        if (localWebsite) {
            //step1Dispatch({type: StepActionType.TEMPLATE_SUCCESS, payload: {localTemplate}});
            navigate('/step1')
        }
    };

    /** Set website selection to local storage */
    const webSelect = (website: string) => {
        if (website === "Website") {
            setCheckWebsite((prev) => !prev);
            setCheckWebsiteBlog(false);
            setCheckWebsiteShop(false);
            setPriceWebsite(initPriceWebsite);
        }
        if (website === "WebsiteBlog") {
            setCheckWebsiteBlog((prev) => !prev);
            setCheckWebsite(false);
            setCheckWebsiteShop(false);
            setPriceWebsite(initPriceWebsiteBlog);
        }
        if (website === "WebsiteShop") {
            setCheckWebsiteShop((prev) => !prev);
            setCheckWebsite(false);
            setCheckWebsiteBlog(false);
            setPriceWebsite(initPriceWebsiteShop);
        }

        localStorage.setItem('Website', JSON.stringify(website));
    }


    return (
        <div className="Step noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                <WK24Row>
                    <WK24Col>
                        <Wk24Form.Label class="header text-center"
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step0.header.label',
                                            Store: Store
                                        })}/>
                        <p className="text-center mt-3" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Step0.title', Store: Store})
                        }}/>
                        <WK24Row class={'mb-3 mr-5 mt-4 text-center wkweb'} style={{marginLeft: '1.33%'}}>
                            <WK24Col
                                class={checkWebsite ? 'p-2 border border-primary m-3 sitechecked' : 'p-2 border border-primary m-3'}
                                style={{width: 'max-content', maxWidth: 'max-content'}}>
                                <Wk24Cards id="web01" onClick={() => webSelect("Website")}>
                                    <Wk24Cards.Body>
                                        <h2 dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step0.Website.title',
                                                Store: Store
                                            })
                                        }}/>
                                        <ul dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step0.Website.text',
                                                Store: Store
                                            })
                                        }}/>
                                        <p className="preis"><small>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step.priceRange.text',
                                            Store: Store
                                        })}</small> {initPriceWebsite / 12},–
                                            EUR<small className="mini">{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step.firstYear.text',
                                                Store: Store
                                            })}</small><br/>
                                            <small className="preisInfo">{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step.secondYear.text',
                                                Store: Store
                                            })}{(initPriceHosting / 12).toFixed(2)} EUR</small></p>
                                        {checkWebsite ? <MIcon children="task_alt" variant="-outlined"/> :
                                            <MIcon children="radio_button_unchecked" variant="-outlined"/>}
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            <WK24Col
                                class={checkWebsite ? 'p-2 border border-primary m-3 sitechecked' : 'p-2 border border-primary m-3'}
                                style={{width: 'max-content', maxWidth: 'max-content'}}>
                                <Wk24Cards id="web02" onClick={() => webSelect("WebsiteBlog")}>
                                    <Wk24Cards.Body>
                                        <h2 dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step0.WebsiteBlog.title',
                                                Store: Store
                                            })
                                        }}/>
                                        <ul dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step0.WebsiteBlog.text',
                                                Store: Store
                                            })
                                        }}/>
                                        <p className="preis"><small>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step.priceRange.text',
                                            Store: Store
                                        })}</small> {initPriceWebsiteBlog / 12},–
                                            EUR<small className="mini">{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step.firstYear.text',
                                                Store: Store
                                            })}</small><br/>
                                            <small className="preisInfo">{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step.secondYear.text',
                                                Store: Store
                                            })} {(initPriceHosting / 12).toFixed(2)} EUR</small></p>
                                        {checkWebsiteBlog ? <MIcon children="task_alt" variant="-outlined"/> :
                                            <MIcon children="radio_button_unchecked" variant="-outlined"/>}
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            <WK24Col
                                class={checkWebsite ? 'p-2 border border-primary m-3 sitechecked' : 'p-2 border border-primary m-3'}
                                style={{width: 'max-content', maxWidth: 'max-content'}}>
                                <Wk24Cards id="web03" onClick={() => webSelect("WebsiteShop")}>
                                    <Wk24Cards.Body>
                                        <h2 dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step0.WebsiteShop.title',
                                                Store: Store
                                            })
                                        }}/>
                                        <ul dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step0.WebsiteShop.text',
                                                Store: Store
                                            })
                                        }}/>
                                        <p className="preis"><small>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step.priceRange.text',
                                            Store: Store
                                        })}</small> {initPriceWebsiteShop / 12},–
                                            EUR<small className="mini">{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step.firstYear.text',
                                                Store: Store
                                            })}</small><br/>
                                            <small className="preisInfo">{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Step.secondYear.text',
                                                Store: Store
                                            })} {(initPriceHosting / 12).toFixed(2)} EUR</small></p>
                                        {checkWebsiteShop ? <MIcon children="task_alt" variant="-outlined"/> :
                                            <MIcon children="radio_button_unchecked" variant="-outlined"/>}
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            <div className="giraffeStep0">
                                <img className="giraIcon" src={'/gira3.png'} alt={''}/>
                            </div>
                        </WK24Row>
                    </WK24Col>
                </WK24Row>
                <WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-3'}>
                            <ProgressBar now={15} style={{height: '5px'}} className={'mt-3'}/>
                        </WK24Col>
                    </WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-4'}>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                                class={'float-end'} variant={'primary'}
                                disabled={!checkWebsite && !checkWebsiteBlog && !checkWebsiteShop}
                                onClick={() => {
                                    localStorage.setItem('initPriceWebsite', JSON.stringify(priceWebsite));
                                    localStorage.setItem('initPriceHosting', JSON.stringify(initPriceHosting));
                                }}
                            />
                        </WK24Col>
                    </WK24Row>
                </WK24Row>
            </Wk24Form>
            <div className="chami">
                <img className="chamiIcon" src={'/chami1.png'} alt={''}/>
            </div>
        </div>
    )
}
export default Step0;
