/**
 * @typedef PreperatorReplaceProps
 * @prop {string} messageValue des Language Key
 * @prop {string} param Param for Preperator Function
 * @prop {string} replace Replace value for Preperator
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
interface PreperatorReplaceProps {
    messageValue: any
    param: string | undefined
    replace: string | Element | number | undefined
}

/**
 *
 * @param {PreperatorReplaceProps} props Übergabe Werte für Preperatoren
 * @constructor
 * @returns  replaceString give String with Search and Replace back.
 * @author Marcel Ugurcu
 * @version 0.0.1
 * Example
 * @example
 *  let searchAndReplace = new PreperatorReplace(messageValue,param,replace)
 messageValue = searchAndReplace.Init()
 *
 */
class PreperatorReplace implements PreperatorReplaceProps {
    /**
     * Contructor funktion die alles aus den Interface gloabl abrufbar macht
     * @param messageValue
     * @param param
     * @param replace
     * @author Marcel Ugurcu
     * @version 0.0.1
     * @constructor
     */
    constructor(
        public messageValue: any,
        public param: string | undefined,
        public replace: string | Element | number | undefined
    ) {}

    /**
     * Initatilisiert PreperatorReplace
     * @public
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
    public Init() {
        let replaceString = this.messageValue

        replaceString = replaceString.replaceAll(this.param, this.replace)

        return replaceString
    }
}
export default PreperatorReplace
