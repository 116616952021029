import Signin from "./Pages";


export const SigninConfig  = (value: any) => {
    return [
        {
            path: '/signin/',
            elements: <Signin value={''}/>,
            roles: null
        }
    ]
}

export const SigninMenuConfig = (value: any) => {
    return [
        {
            header: 'Signin',
            icon: 'login',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/signin/',
        }
    ]
}