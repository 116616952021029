import Fonts from "./Pages";

export const FontsConfig = (value: any) => {
    return [
        {
            path: '/fonts/',
            elements: <Fonts value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        }
    ]
}

export const FontsMenuConfig = (value: any) => {
    return [
        {
            header: 'Schriften',
            icon: 'spellcheck',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/fonts'
        }
    ]
}