import React, { useState, useEffect, useRef } from 'react';


const ImageDimension = (setImageDimensions:any, imageUrl:any) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
        setImageDimensions({
            height: img.height,
            width: img.width
        });
    };
    img.onerror = (err) => {
        console.log("img error");
        console.error(err);
    };
};

export default ImageDimension;
