export const ManageOverview = () => {
    return [
        {
            id: 1,
            favicon: 'https://weko24.de/wp-content/themes/wk-2024/img/favicon.png',
            app_name: 'wk24',
            backend: 'https://weko24.de/dev-be',
            frontend: 'https://weko24.de/dev',
            version: '0.1',
            lastUpdate: '23.02.2024 | 10:00',
            lastAccess: '23.02.2024 | 10:00',
            isActive: 1,
            login: 'zmdlf',
            template: '01',
            companyName: 'Weko24',
            logo: 'https://weko24.de/wp-content/themes/wk-2024/img/logo.png',
            colors: {
                font: '#181818',
                primary: '#10c9c3',
                secondary: '#08aea9',
                tertiary: '#f5f5f5',
                background: '#ffffff'
            },
            navigation: {
                mainNavigation: [
                    {
                        path: '/',
                        title: 'Home',
                        state: 1,
                        position: 1
                    },
                    {
                        path: '/ueber-mich/',
                        title: 'Über mich',
                        state: 1,
                        position: 2
                    },
                    {
                        path: '/leistungen/',
                        title: 'Leistungen',
                        state: 1,
                        position: 3
                    },
                    {
                        path: '/galerie/',
                        title: 'Galerie',
                        state: 1,
                        position: 4
                    },
                    {
                        path: '/kontakt/',
                        title: 'Kontakt',
                        state: 1,
                        position: 5
                    }
                ],
                serviceNavigation: [
                    {
                        path: '/impressum/',
                        title: 'Impressum',
                        state: 1,
                        position: 1
                    },
                    {
                        path: '/datenschutz/',
                        title: 'Datenschutz',
                        state: 1,
                        position: 2
                    },
                    {
                        path: '/sitemap/',
                        title: 'Sitemap',
                        state: 1,
                        position: 3
                    }
                ]
            },
            pages: [
                {
                    id: 4,
                    title: 'Home',
                    path: '/',
                    siteTitle: 'Das ist deine Startseite',
                    metaDescription: 'Hier steht deine Meta-Beschreibung für SEO',
                    metaProperty: [
                        {
                            propertyID: 19,
                            property: 'og:type',
                            content: 'article',
                            pageID: 4
                        },
                        {
                            propertyID: 20,
                            property: 'og:image',
                            content: 'https://weko24.de/wp-content/themes/wk-2024/img/logo.png',
                            pageID: 4
                        },
                        {
                            propertyID: 21,
                            property: 'og:locale',
                            content: 'de_DE',
                            pageID: 4
                        },
                        {
                            propertyID: 22,
                            property: 'og:site_name',
                            content: 'Weko24 Kundenportal',
                            pageID: 4
                        },
                        {
                            propertyID: 23,
                            property: 'og:title',
                            content: 'Günstige Homepage erstellen lassen | WEKO24',
                            pageID: 4
                        },
                        {
                            propertyID: 24,
                            property: 'og:description',
                            content: 'Mit uns kannst du deine eigene Homepage günstig erstellen lassen. Einfach Layout aussuchen, Inhalte hochladen und uns den Rest machen lassen! Leg jetzt los!',
                            pageID: 4
                        },
                        {
                            propertyID: 25,
                            property: 'og:url',
                            content: 'https://weko24.de/',
                            pageID: 4
                        },
                        {
                            propertyID: 26,
                            property: 'article:published_time',
                            content: '2020-03-23T09:07:32+00:00',
                            pageID: 4
                        },
                        {
                            propertyID: 27,
                            property: 'article:modified_time',
                            content: '2024-02-02T08:39:00+00:00',
                            pageID: 4
                        }
                    ],
                    state: 1,
                    metaCanonical: 'https://weko24.de/',
                    pageContent: {
                        header: {
                            id: 2,
                            styleClass: "text-right",
                            headline: "Wir machen mehr Leistung",
                            text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.",
                            button: {
                                link: "https://www.weko24.de",
                                text: "Kontakt"
                            },
                            images: [
                                "https://weko24.de/wp-content/uploads/precontent/header-website.jpg"
                            ],
                            menu: [
                                {positon: 1, title: "Startseite", link: "/", state: 1},
                                {positon: 2, title: "Leistungen", link: "/leistungen", state: 1},
                                {positon: 3, title: "Galerie", link: "/galerie", state: 1},
                                {positon: 4, title: "Über mich", link: "/ueber-mich", state: 1}
                            ]
                        },
                        main: [
                            {
                                id: 6,
                                content: "<div className=\"tmpmain \" style={{backgroundColor: colorStyles.backgroundColor}}><div className=\"Webbuilder\"><h1>Du bist neu dabei oder schon mittendrin?</h1>\n<p>Ob du dein Business gerade neu startest oder bereits die ersten Kunden hast – für uns macht das keinen Unterschied. Wir unterstützen dich dort, wo du gerade stehst und auch nur dort, <strong>wo du es möchtest.</strong></p><div className=\"py-14 \">\n<div className=\"container\">\n<div className=\"row\">\n<div className=\"offset-lg-2 col-lg-8 col-md-12 col-12 text-center\">\n<span className=\"fs-4 text-warning ls-md text-uppercase fw-semibold\">get things done</span>\n<h2 className=\"display-3 mt-4 mb-3 fw-bold\">Website</h2>\n<p className=\"lead px-lg-8 mb-6\">Wir behalten alles für dich im Blick: Gib die Betreuung und Wartung deiner Website in unsere Hände ab.</p>\n<a href=\"https://weko24.de\" className=\"btn btn-primary mb-3\">Website</a></div>\n</div>\n</div>\n</div><blockquote className=\"blockquote\">\n<p className=\"mb-2\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>\n<footer className=\"blockquote-footer\">Someone famous in <cite title=\"Source Title\">Source Title</cite></footer>\n</blockquote><h2>Günstig wie ein Baukasten – wertvoll wie eine Agentur</h2>\n<p>WEBKOMMUNIKATION24 bleibt an deiner Seite. So lange wie du willst. So intensiv wie du willst. Du entscheidest, wie wir dich unterstützen dürfen.</p></div></div>"
                            }
                        ],
                        footer: {
                            id: 5,
                            company: "Weko24",
                            email: "info@weko24.de",
                            phone: "0371 2435610",
                            socialLinks: [
                                {
                                    socialmediaID: 5,
                                    type: "facebook",
                                    link: "#",
                                    footerId: 5
                                },
                                {
                                    socialmediaID: 6,
                                    type: "instagram",
                                    link: "#",
                                    footerId: 5
                                }
                            ]
                        }
                    }

                }
            ]
        }
    ]
}
