/**
 * Manages the state of the social media
 *
 */

import React, {useState} from "react";
import '../css/design.css'
import {BackButton, IntlMessage, Store, WK24Button, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {ResponseSocialMedia} from "../Api/response/sites";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface SocialMediaProps {
    value: any
}

const SocialMedia = (props: SocialMediaProps) => {
    const [validated, setValidated] = useState(false);
    const [logoColor, setLogoColor] = useState(false);

    const localProject = JSON.parse(localStorage.getItem('Project') as string);
    const settingsID = localProject.projectID;

    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [twitterx, setTwitterx] = useState('');
    const [youtube, setYoutube] = useState('');
    const [socialChannel, setSocialChannel] = useState([{type: '', link: '', settingsID: settingsID}]);
    const response = ResponseSocialMedia(settingsID,setSocialChannel,setFacebook,setInstagram,setLinkedin,setPinterest,setTiktok,setTwitterx,setYoutube)

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
    };

    const saveData = () => {
        localStorage.setItem('SocialMedia', JSON.stringify(socialChannel));

        let back = socialChannel;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type":"application/json",
                Authorization:"Basic "+ BasicAuth()
            },
            body: JSON.stringify(back)
        };

        fetch(ApiRoot() + "social", requestOptions)
            .then((response) => response.text())
            .then((result:any) => {
                console.log(result);
                notifySave(Store);
            })
            .catch((error) => {
                console.error(error);
                notifyError(Store);
            });
    }

    /** Set Social Media Channels with selected items */
    const social = (type: string, url: string) => {
        let existingItem = false;

        socialChannel.map((item) => {
            if (item.type === type) {
                existingItem = true;
            }
            return null
        })
        if (!existingItem) {
            setSocialChannel(current => [...current, {type: type, link: url, settingsID: settingsID}]);
        } else {
            socialChannel.map((item) => {
                if (item.type === type) {
                    if (url !== '') {
                        item.link = url;
                    } else {
                        setSocialChannel(socialChannel.filter(item => item.type !== type));
                    }
                }
                return null
            })
        }
    }
    console.log(facebook)
    return (
        <div className="Socialmedia bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'SocialMedia.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'SocialMedia.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'SocialMedia.header.label', Store: Store})
            }}/>

            <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                <Wk24Form.Label class="lead"
                                text={IntlMessage({locale: 'de', messageId: 'SocialMedia.Check.label', Store: Store})}/>
                <WK24Row>
                    <Wk24Form.InputGroup id="selectgroup" class="form-group  mb 2">
                        <Wk24Form.Checks type={'checkbox'}
                                         label={IntlMessage({
                                             locale: 'de',
                                             messageId: 'SocialMedia.checkColorBlack.label',
                                             Store: Store
                                         })}
                                         value={IntlMessage({
                                             locale: 'de',
                                             messageId: 'SocialMedia.checkColorBlack.label',
                                             Store: Store
                                         })}
                                         checked={!logoColor}
                                         onChange={() => {
                                             setLogoColor(false);
                                         }}/>
                        <Wk24Form.Checks type={'checkbox'}
                                         label={IntlMessage({
                                             locale: 'de',
                                             messageId: 'SocialMedia.checkColor.label',
                                             Store: Store
                                         })}
                                         value={IntlMessage({
                                             locale: 'de',
                                             messageId: 'SocialMedia.checkColor.label',
                                             Store: Store
                                         })}
                                         checked={logoColor}
                                         onChange={() => {
                                             setLogoColor(true);
                                         }}/>
                    </Wk24Form.InputGroup>
                </WK24Row>

                <Wk24Form.Label class="lead profilhead"
                                text={IntlMessage({
                                    locale: 'de',
                                    messageId: 'SocialMedia.Profile.label',
                                    Store: Store
                                })}/>
                <WK24Row class={'mr-5  mt-3 text-center socialprofile'}>
                    <WK24Col class={''} style={{width: 'max-content', maxWidth: 'max-content'}}>
                        <Wk24Form.FormGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/facebook-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/facebook.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Facebook.name',
                                        Store: Store
                                    })}
                                    placeholder={facebook ? facebook : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Facebook.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={facebook}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('facebook', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/instagram-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/instagram.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Instagram.name',
                                        Store: Store
                                    })}
                                    placeholder={instagram ? instagram : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Instagram.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={instagram}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('instagram', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/linkedin-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/linkedin.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Linkedin.name',
                                        Store: Store
                                    })}
                                    placeholder={linkedin ? linkedin : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Linkedin.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={linkedin}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('linkedin', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                        </Wk24Form.FormGroup>
                    </WK24Col>

                    <WK24Col class={''} style={{width: 'max-content', maxWidth: 'max-content'}}>
                        <Wk24Form.FormGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/pinterest-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/pinterest.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Pinterest.name',
                                        Store: Store
                                    })}
                                    placeholder={pinterest ? pinterest : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Pinterest.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={pinterest}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('pinterest', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/tiktok-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/tiktok.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Tiktok.name',
                                        Store: Store
                                    })}
                                    placeholder={tiktok ? tiktok : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Tiktok.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={tiktok}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('tiktok', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                        </Wk24Form.FormGroup>
                    </WK24Col>

                    <WK24Col class={''} style={{width: 'max-content', maxWidth: 'max-content'}}>
                        <Wk24Form.FormGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/twitterx-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/twitterx.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Twitterx.name',
                                        Store: Store
                                    })}
                                    placeholder={twitterx ? twitterx : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Twitterx.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={twitterx}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('twitterx', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                            <Wk24Form.InputGroup>
                                {logoColor ? <img src={'/socialmedia/youtube-color.svg'} alt={''}/> :
                                    <img src={'/socialmedia/youtube.svg'} alt={''}/>}
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Youtube.name',
                                        Store: Store
                                    })}
                                    placeholder={youtube ? youtube : IntlMessage({
                                        locale: 'de',
                                        messageId: 'SocialMedia.Youtube.placeholder',
                                        Store: Store
                                    })}
                                    defaultValue={youtube}
                                    onChange={(e: { target: { value: string, } }) => {
                                        social('youtube', e.target.value);
                                    }}
                                />
                            </Wk24Form.InputGroup>
                        </Wk24Form.FormGroup>
                    </WK24Col>
                </WK24Row>

                <WK24Row>
                    <WK24Col class={'mt-3'}>
                        <BackButton className={'mr-3'}/>
                        <WK24Button children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                    variant={'primary'} onClick={() => saveData()}/>
                    </WK24Col>
                </WK24Row>
                <div className="giraffeSocial">
                    <img className="giraIcon" src={'/gira1-2.png'} alt={''}/>
                </div>
            </Wk24Form>
        </div>
    )
}

export default SocialMedia;
