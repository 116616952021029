import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {IntlMessage, Store, WK24Button, Wk24Form} from "../../../@WK24/core";
import React, {useState} from "react";

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [checkAGB, setCheckAGB] = useState<boolean>(false);
    const [checkDS, setCheckDS] = useState<boolean>(false)

    const handleSubmit = async (event:any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const baseUrl = window.location.origin;
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: baseUrl + '/order',
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };


    return (
        <form  onSubmit={handleSubmit}>
            <PaymentElement />

            <Wk24Form.InputGroup>
                <Wk24Form.Checks type={'checkbox'}
                                 label={IntlMessage({locale: 'de', messageId: 'payment.AGB.label', Store: Store})}
                                 value={"AGB"}
                                 checked={checkAGB}
                                 id={'checkAGB'}
                                 onChange={(e: { target: { value: string, } }) => {
                                     setCheckAGB((prev) => !prev);
                                 }}/>
                <label
                    htmlFor="checkAGB"
                >Ich habe die <a target="_blank" href="https://weko24.de/agb/">AGB</a> gelesen und akzeptiere diese
                </label>
            </Wk24Form.InputGroup>
            <Wk24Form.InputGroup>
                <Wk24Form.Checks type={'checkbox'}
                                 label={IntlMessage({locale: 'de', messageId: 'payment.DS.label', Store: Store})}
                                 value={"Datenschutz"}
                                 checked={checkDS}
                                 id={'checkDS'}
                                 onChange={(e: { target: { value: string, } }) => {
                                     setCheckDS((prev) => !prev);
                                 }}/>
                <label
                    htmlFor="checkDS"
                >Ich stimme zu, dass meine Daten laut der <a target="_blank"
                                                             href="https://weko24.de/datenschutzerklaerung/">Datenschutzerklärung</a> verarbeitet
                    werden
                </label>
            </Wk24Form.InputGroup>
            <WK24Button
                children={IntlMessage({locale: 'de', messageId: 'payment.btn.label', Store: Store})}
                type={'submit'} class={'float-end'} variant={'primary'} disabled={!stripe || !checkAGB || !checkDS}
            />
        </form>
    );
};

export default CheckoutForm;