import SocialLanding from "./Pages";

export const SocialLandingConfig = (value: any) => {
    return [
        {
            path: '/sociallanding/',
            elements: <SocialLanding value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        }
    ]
}

export const SocialLandingMenuConfig = (value: any) => {
    return [
        {
            header: 'Social Media Landingpage',
            icon: 'share',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/sociallanding'
        }
    ]
}