import {WYSIWYG} from "../../WYSIWYG/WYSIWYG";
import ReactDOM from "react-dom";
import React, {useEffect} from "react";
import {ToolbarConfig} from "../../WYSIWYG/interface/ToolbarConfig";

export const HandleClick = (elRef:any,range:number,SetRange:any,config:ToolbarConfig[]) => {

    let newElement = <WYSIWYG id={'Webbuilder'+(range)} config={config} defaultValue={'<div style="background-color: rgb(255, 255, 255);">Hier kannst du deinen Text Eintragen!</div>'} />
    let temp = document.createElement('div')
    ReactDOM.render(newElement, temp)
    if(elRef.current !== null) {
        if(elRef.current.firstChild !== null) {
            let ele = elRef.current.firstChild
            if (ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            let ele = elRef.current
            if (ele !== null) {
                ele.appendChild(temp)
            }
        }
        SetRange(range+1)
    }



};
