import {customerApiType} from "../request/customer..interface";
import {customer} from "../request/customer";
import { useEffect, useState } from 'react'

export const Response = (id: number,fetchData:any,setData:any) => {
    const [posts, setPosts] = useState<customerApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await customer
                .getPosts(id)
                .then((data) => {
                    setPosts(data)
                    setData(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [id,fetchData])

    return isError ? posts : posts
}
