import Container from 'react-bootstrap/Container';
import React from "react";
import {Link,To} from "react-router-dom";

interface SideBarItemProps{
    /** Set Title for Navigation. */
    title:string,
    /** Set Icon Code for Material Icons. */
    icon:string,
    /** Set Route for SidebarItem */
    route:To
}

/**
 * Items for Sidebar.<br/><br/>
 *  <strong>Code Example Single:</strong><br/>
 * ```tsx
 * <SideBar>
 *     <SideBar.Item route={sidebar.route as string} title={sidebar.header} icon={sidebar.icon} />
 * </SideBar>
 * ```
 * <br/><br/>
 *  <strong>Code Example in SideBarDropdown:</strong><br/>
 * ```tsx
 * <SideBar>
 *     <SideBar.Dropdown title={sidebar.header} icon={sidebar.icon}>
 *          <SideBar.Item route={sidebar.route as string} title={sidebar.header} icon={sidebar.icon} />
 *    </SideBar.Dropdown>
 * </SideBar>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const SideBarItem = (props:SideBarItemProps) => {
    return (
        <Container fluid className={'p-0 m-0'}>
            <Link to={props.route}><span className="material-icons pr-3" style={{verticalAlign: 'bottom'}}>
                                            {props.icon}
                                        </span> {props.title}</Link>
        </Container>
    )
}

export default SideBarItem
