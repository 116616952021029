import User from "./Pages";

export const UserConfig = (value: any) => {
    return [
        {
            path: '/user/',
            elements: <User value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        }
    ]
}

export const UserMenuConfig = (value: any) => {
    return [
        {
            header: 'User',
            icon: 'user',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/user',
        }
    ]
}
