/**
 * Manages the state of the social media landingpage.
 *
 * @params {[{title: '', path: ''}]} landingButtons - the array of the buttons for socialmedia landingpage.
 * @params {boolean} landingPage - check if landingpage create or not
 * @params {string} landingText - the text for description
 *
 */

import React, {useEffect, useState} from "react";
import '../css/socialLanding.css'
import {BackButton, IntlMessage, MIcon, Store, WK24Button, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface socialLandingProps {
    value: any
}

const SocialLanding = (props: socialLandingProps) => {
    const [data, setData] = useState<any>();
    const [socialID, setSocialID] = useState(0)
    const [validated, setValidated] = useState(false);
    const [landingButtons, setLandingButtons] = useState([{
        title: 'Impressum',
        path: '/impressum'
    }, {title: 'Datenschutz', path: '/datenschutz'}]);
    const [landingPage, setLandingPage] = useState(true);
    const [landingText, setLandingText] = useState<string>('');

    const localCustomer = JSON.parse(localStorage.getItem('Customer') as string);
    const localSettings = JSON.parse(localStorage.getItem('ProjectSettings') as string);
    const localProject = JSON.parse(localStorage.getItem('Project') as string);
    const projectID = localProject.projectID;
    const settingsID = localSettings.id;

    useEffect(() => {

        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "projetcSettings/" + projectID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                if (result) {
                    setData(result);
                    localStorage.setItem('ProjectSettings', JSON.stringify(result));
                }
            })
            .catch((error) => console.error(error));

        const requestOptionss = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "social-landing/" + settingsID, requestOptionss)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                if (result) {
                    setData(result);
                    setSocialID(result.landingID)
                    setLandingPage(result.status)
                    setLandingText(result.description)
                    setLandingButtons(result.buttons)
                    console.log(result);
                }
            })
            .catch((error) => console.error(error));

        console.log(data);
    }, [])


    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();
        setValidated(true);
    };

    /** save date: socialmedia landingpage */
    const saveData = () => {
        let back = {
            settingsID: settingsID,
            description: landingText,
            buttons: landingButtons,
            landingID:socialID,
            status: (landingPage === true ? 1 : 0)
        }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(back)
        };

        fetch(ApiRoot() + "social-landing", requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                console.log(result);
                notifySave(Store);
            })
            .catch((error) => {
                console.error(error);
                notifyError(Store);
            });
    }

    /** preview socialmedia landingpage */
    const PreviewLandingpage = () => {
            const navButton = [...landingButtons];
            return (
                <div className="landingPreview"
                     style={{background: `linear-gradient(150deg, rgba(255, 255, 255, 1) 20%, ${localSettings.colors.tertiary} 80%)`}}>
                    <img id="logo" src={localSettings.logo}
                         style={{width: '150px', height: '150px', borderColor: localSettings.colors.secondary}}
                         alt={"logo"}/>
                    <h2>{localCustomer.company}</h2>
                    <p className="landingText">{landingText}</p>
                    {navButton.map((item: any) => {
                        return (
                            <a className="wk-button" style={{backgroundColor: localSettings.colors.primary}}
                               href={item.path} target="_blank"
                               rel="nofollow noreferrer">{item.title}</a>
                        )
                    })}
                </div>
            )
    }

    /** add new button */
    const handleAddInput = () => {
        setLandingButtons(current => [...current, {title: '', path: ''}]);
    };

    /** update array of buttons */
    const handleChange = (title: string, link: string, index: number) => {
        const updatedData = [...landingButtons];
        updatedData[index] = {title: title, path: link};
        setLandingButtons(updatedData)
    };

    /** delete button from array */
    const handleDeleteInput = (index: number) => {
        const newArray = [...landingButtons];
        newArray.splice(index, 1);
        setLandingButtons(newArray);
    };

    return (
        <div className="socialLanding bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'socialLanding.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'socialLanding.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'socialLanding.header.label', Store: Store})
            }}/>

            <Wk24Form.Label class="lead labeltop"
                            text={IntlMessage({
                                locale: 'de',
                                messageId: 'SocialMedia.LandingPage.label',
                                Store: Store
                            })}/>
            <WK24Row>
                <Wk24Form.InputGroup id="selectgroup" class="form-group mb 2">
                    <Wk24Form.Checks type={'checkbox'}
                                     label={IntlMessage({
                                         locale: 'de',
                                         messageId: 'SocialMedia.LandingPageTrue.label',
                                         Store: Store
                                     })}
                                     value={IntlMessage({
                                         locale: 'de',
                                         messageId: 'SocialMedia.LandingPageTrue.label',
                                         Store: Store
                                     })}
                                     checked={landingPage}
                                     onChange={(e: { target: { value: string, } }) => {
                                         setLandingPage(true);
                                     }}/>
                    <Wk24Form.Checks type={'checkbox'}
                                     label={IntlMessage({
                                         locale: 'de',
                                         messageId: 'SocialMedia.LandingPageFalse.label',
                                         Store: Store
                                     })}
                                     value={IntlMessage({
                                         locale: 'de',
                                         messageId: 'SocialMedia.LandingPageFalse.label',
                                         Store: Store
                                     })}
                                     checked={!landingPage}
                                     onChange={(e: { target: { value: string, } }) => {
                                         setLandingPage(false);
                                     }}/>
                </Wk24Form.InputGroup>
            </WK24Row>
            {landingPage &&
                <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                    <WK24Row>
                        <WK24Col>

                            <Wk24Form.Label
                                text={IntlMessage({
                                    locale: 'de',
                                    messageId: 'socialLanding.description.name',
                                    Store: Store
                                })}
                                class={'mt-3 mr-3'}
                            />
                            <Wk24Form.FormControl
                                type={'text'}
                                class={''}
                                as={'textarea'}
                                name={IntlMessage({
                                    locale: 'de',
                                    messageId: 'socialLanding.description.name',
                                    Store: Store
                                })}
                                placeholder={IntlMessage({
                                    locale: 'de',
                                    messageId: 'socialLanding.description.placeholder',
                                    Store: Store
                                })}
                                value={landingText}
                                onChange={(e: { target: { value: string } }) => {
                                    setLandingText(e.target.value)
                                }}
                            />
                            <Wk24Form.Label
                                text={IntlMessage({
                                    locale: 'de',
                                    messageId: 'socialLanding.linkbtn.name',
                                    Store: Store
                                })}
                                class={'mt-3 mr-3 font-weight-bold mb-3'}
                            />
                            <WK24Button
                                children={<MIcon children="add" variant="-outlined"/>}
                                variant={'primary'}
                                class={'addBtn'}
                                onClick={() => {
                                    handleAddInput()
                                }}/>
                            {landingButtons.map((item, index) => {
                                let link = '';
                                let title = '';
                                return (
                                    <Wk24Form.InputGroup>
                                        <Wk24Form.FormControl type={'text'}
                                                              name={'linktext'}
                                                              placeholder={item.title}
                                                              onChange={(e: { target: { value: string } }) => {
                                                                  title = e.target.value
                                                              }}
                                        />
                                        <Wk24Form.FormControl type={'text'}
                                                              name={'linkziel'}
                                                              placeholder={item.path}
                                                              onChange={(e: { target: { value: string } }) => {
                                                                  link = e.target.value
                                                              }}
                                        />

                                        <WK24Button
                                            children={<MIcon children="check" variant="-outlined"/>}
                                            variant={'primary'}
                                            onClick={() => {
                                                handleChange(title, link, index)
                                            }}/>

                                        <WK24Button
                                            children={<MIcon children="delete" variant="-outlined"/>}
                                            variant={'primary'}
                                            class={'btnRemove'}
                                            onClick={() => {
                                                handleDeleteInput(index)
                                            }}/>
                                    </Wk24Form.InputGroup>
                                )
                            })
                            }
                        </WK24Col>

                        <WK24Col class={'text-center pt-5 previewSocial'}>
                            {PreviewLandingpage()}
                        </WK24Col>
                    </WK24Row>
                    <BackButton className={'mr-3'}  />
                    <WK24Button
                        children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                        type={'submit'} variant={'primary'} onClick={() => {
                        saveData();
                    }}
                    />
                </Wk24Form>
            }
            {!landingPage &&
            <>
            <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                <WK24Button
                    children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                    type={'submit'} variant={'primary'} onClick={() => {
                    saveData();
                }}
                />
            </Wk24Form>

            </>}


        </div>
    )
}

export default SocialLanding;
