/**
 * Manages the creation of the pdf invoice
 *
 */

import React from 'react';
import {Document, Image, Page, Text, View} from '@react-pdf/renderer';
import {styles} from './css/invoicePDF'
import FontsRegister from "./fonts/fontsPDF";
import {InvoicePDFProps} from "./interface/invoicePDFProps.interface";
import {IntlMessage, Store} from "../../@WK24/core";


const InvoicePDF = (props: InvoicePDFProps) => {
    FontsRegister(); //adds fonts

    const userItem = localStorage.getItem('User');
    const user = userItem ? JSON.parse(userItem) : null

    const localAccount = JSON.parse(localStorage.getItem('WK2024AccountData') as string);
    const priceWebsite = JSON.parse(localStorage.getItem('priceWebsite') as string);
    const priceHosting = JSON.parse(localStorage.getItem('priceHosting') as string);
    const orderDescription = JSON.parse(localStorage.getItem('orderDescription') as string);
    const orderWebsiteTyp = JSON.parse(localStorage.getItem('Website') as string);

    const currentDate = new Date();
    var numberOfMlSeconds = currentDate.getTime();
    var addMlSeconds12 = (8760 * 60) * 60000; //für ein Jahr
    var addMlSeconds = (24 * 60) * 60000; //für ein Tag
    var endDate = new Date(numberOfMlSeconds + addMlSeconds12 - addMlSeconds);

    return (
        <Document title="Rechnung">
            <Page size="A4" style={styles.page}>
                <View fixed={true}>
                    <Image style={styles.logo} src="/Logo_Webkom24.png"/>
                </View>
                <View style={styles.headSection}>
                    <View>
                        <View fixed={true}>
                            <Text style={styles.pageHead}>WEBKOMMUNIKATION24 GmbH</Text>
                            <Text style={styles.pageHead}>Zwickauer Straße 480, 09117 Chemnitz</Text>
                        </View>
                        <View style={styles.topMargin}>
                            <Text style={styles.company}>{localAccount.company}</Text>
                            <Text style={styles.company}>{localAccount.addresses[0].street}</Text>
                            <Text
                                style={styles.company}>{localAccount.addresses[0].zip} {localAccount.addresses[0].city}</Text>
                        </View>
                        {localAccount.vat &&
                            <View style={styles.topMargin}>
                                <Text style={styles.company}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.ust.text',
                                    Store: Store
                                })}: {localAccount.vat}</Text>
                            </View>
                        }
                    </View>
                    <View>
                        <View style={styles.invoiceInfo}>
                            <Text style={styles.invoiceHead}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.title',
                                Store: Store
                            })}</Text>
                            <View style={styles.sectionbox}>
                                <Text style={styles.invoiceTitle}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.invoiceNumber.title',
                                    Store: Store
                                })}</Text>
                                <Text>S-0{user.userID}</Text>
                            </View>
                            <View style={styles.sectionbox}>
                                <Text style={styles.invoiceTitle}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.invoiceDate.title',
                                    Store: Store
                                })}</Text>
                                <Text>{currentDate.toLocaleDateString()}</Text>
                            </View>
                            <View style={styles.sectionbox}>
                                <Text style={styles.invoiceTitle}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.invoiceAccount.title',
                                    Store: Store
                                })}</Text>
                                <Text>000076</Text>
                            </View>
                            <View style={styles.sectionbox}>
                                <Text style={styles.invoiceTitle}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.serviceRange.title',
                                    Store: Store
                                })}</Text>
                                <Text>{currentDate.toLocaleDateString()} - {endDate.toLocaleDateString()}</Text>
                            </View>
                            <View style={styles.sectionbox}>
                                <Text style={styles.invoiceTitle}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.pageNumber.title',
                                    Store: Store
                                })}</Text>
                                <Text>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.pageNumber.text',
                                    Store: Store
                                })}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.mainSection}>
                    <View>
                        <View style={[styles.articleList, {width: "100%"}]}>
                            <Text style={[styles.listHead, {width: "20%"}]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleNumber.title',
                                Store: Store
                            })}</Text>
                            <Text style={[styles.listHead, {width: "30%"}]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleDescription.title',
                                Store: Store
                            })}</Text>
                            <Text style={[styles.listHead, {
                                width: "10%",
                                textAlign: "center"
                            }]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleQuantity.title',
                                Store: Store
                            })}</Text>
                            <Text style={[styles.listHead, {
                                width: "10%",
                                textAlign: "center"
                            }]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleUnit.title',
                                Store: Store
                            })}</Text>
                            <Text style={[styles.listHead, {
                                width: "15%",
                                textAlign: "right"
                            }]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articlePriceUnit.title',
                                Store: Store
                            })}</Text>
                            <Text style={[styles.listHead, {
                                width: "15%",
                                textAlign: "right"
                            }]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articlePriceTotal.title',
                                Store: Store
                            })}</Text>
                        </View>
                        <View style={[styles.articleList, {width: "100%"}]}>
                            <Text style={[styles.listContent, {width: "20%"}]}>weko-2024</Text>
                            <View style={[{width: "30%"}]}>
                                <Text
                                    style={styles.listContent}>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Invoice.articleListWebsite.text',
                                    Store: Store
                                })} {orderWebsiteTyp.length >= 7 ? orderWebsiteTyp.substring(0, 7) + ' mit ' + orderWebsiteTyp.substring(7, orderWebsiteTyp.length) + ',' : orderWebsiteTyp.substring(0, 7) + ','}</Text>
                                <Text>{orderDescription !== '' ? (orderDescription.replace('//', ' + ')).replace(/\//g, '') : ''}</Text>
                            </View>
                            <Text style={[styles.listContent, {width: "10%", textAlign: "center"}]}>1</Text>
                            <Text style={[styles.listContent, {width: "10%", textAlign: "center"}]}></Text>
                            <Text style={[styles.listContent, {
                                width: "15%",
                                textAlign: "right"
                            }]}>{(priceWebsite.toFixed(2)).replace('.', ',')} €</Text>
                            <Text style={[styles.listContent, {
                                width: "15%",
                                textAlign: "right"
                            }]}>{(priceWebsite.toFixed(2)).replace('.', ',')} €</Text>
                        </View>
                        <View style={[styles.articleList, {width: "100%"}]}>
                            <Text style={[styles.listContent, {width: "20%"}]}>hosting-2024</Text>
                            <Text style={[styles.listContent, {width: "30%"}]}>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleListHosting.text',
                                Store: Store
                            })}</Text>
                            <Text style={[styles.listContent, {width: "10%", textAlign: "center"}]}>1</Text>
                            <Text style={[styles.listContent, {width: "10%", textAlign: "center"}]}></Text>
                            <Text style={[styles.listContent, {
                                width: "15%",
                                textAlign: "right"
                            }]}>{(priceHosting.toFixed(2)).replace('.', ',')} €</Text>
                            <Text style={[styles.listContent, {
                                width: "15%",
                                textAlign: "right"
                            }]}>{(priceHosting.toFixed(2)).replace('.', ',')} €</Text>
                        </View>
                    </View>
                    <View style={styles.mainFooter}>
                        <View style={[{width: "20%"}]}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleFootVat.title',
                                Store: Store
                            })}</Text>
                            <Text style={styles.footContent}>19,00 %</Text>
                        </View>
                        <View style={[{width: "25%", textAlign: "right"}]}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleFootNetto.title',
                                Store: Store
                            })}</Text>
                            <Text
                                style={styles.footContent}>{((priceWebsite + priceHosting).toFixed(2)).replace('.', ',')} €</Text>
                        </View>
                        <View style={[{width: "30%", textAlign: "right"}]}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleFootVatPrice.title',
                                Store: Store
                            })}</Text>
                            <Text
                                style={styles.footContent}>{(((priceWebsite + priceHosting) * 19 / 100).toFixed(2)).replace('.', ',')} €</Text>
                        </View>
                        <View style={[{width: "25%", textAlign: "right", fontWeight: "bold"}]}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.articleFootBrutto.title',
                                Store: Store
                            })}</Text>
                            <Text
                                style={styles.footContent}>{(((priceWebsite + priceHosting) + ((priceWebsite + priceHosting) * 19 / 100)).toFixed(2)).replace('.', ',')} €</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.footSection}>
                    <View style={[{width: "60%", marginBottom: "10px"}]}>
                        <Text>WEBKOMMUNIKATION24 GmbH</Text>
                        <Text>Zwickauer Straße 480</Text>
                        <Text>09117 Chemnitz</Text>
                    </View>
                    <View style={[{width: "40%", marginBottom: "10px"}]}>
                        <View style={styles.sectionbox}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.footBank.title',
                                Store: Store
                            })}:</Text>
                            <Text>Sparkasse Chemnitz</Text>
                        </View>
                        <View style={styles.sectionbox}>
                            <Text>IBAN:</Text>
                            <Text>DE18 8705 0000 0714 9839 93</Text>
                        </View>
                        <View style={styles.sectionbox}>
                            <Text>BIC:</Text>
                            <Text>CHEKDE81XXX</Text>
                        </View>
                    </View>
                    <View style={[{width: "23%"}]}>
                        <View style={styles.sectionbox}>
                            <Text>Web:</Text>
                            <Text>www.weko24.de</Text>
                        </View>
                        <View style={styles.sectionbox}>
                            <Text>E-Mail:</Text>
                            <Text>info@weko24.de</Text>
                        </View>
                    </View>
                    <View style={[{width: "27%"}]}>
                        <View style={styles.sectionbox}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.footRegister.title',
                                Store: Store
                            })}:</Text>
                            <Text>Chemnitz</Text>
                        </View>
                        <View style={styles.sectionbox}>
                            <Text>{IntlMessage({
                                locale: 'de',
                                messageId: 'Invoice.footCommercialRegister.title',
                                Store: Store
                            })}:</Text>
                            <Text>HRB 27243</Text>
                        </View>
                    </View>
                    <View style={[{width: "40%"}]}>
                        <View style={styles.sectionbox}>
                            <Text>{IntlMessage({locale: 'de', messageId: 'Invoice.ust.text', Store: Store})}:</Text>
                            <Text>DE 281479345</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};
export default InvoicePDF