import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

interface SitesDeactivateFetchProps {
    id: number
}

async function sitesDeactivateFetch(props: SitesDeactivateFetchProps) {
    const raw = JSON.stringify({
        "id": props
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + BasicAuth()
        },
        body: raw
    };


    await fetch(ApiRoot() + "page/deactivate", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
}

export default sitesDeactivateFetch;