/**
 * Manages the state of the imprint
 *
 * @params {number} settlement - the state of the settlement selection.
 * @params {IAccountData} accountData - the state of the customer data.
 *
 */

import React, {useState} from "react";
import '../css/design.css'
import {BackButton, IntlMessage, Store, WK24Button, Wk24Form} from "../../../@WK24/core";
import {IAccountData, initAccountData} from "../../account/interface";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface ImprintProps {
    value: any
}

const exractImprint = (value:any) => {
    let localCustomer = JSON.parse(localStorage.getItem('Customer') as string);
    const customerImprintData = {
        "director": localCustomer.gf,
        "authorized": localCustomer.representative,
        "contentResp": localCustomer.responsible_for_content,
        "settlement": localCustomer.arbitration_settlement,
        "imageRights": ""
    };
    if (JSON.parse(localStorage.getItem('ProjectSettings') as string).frontend === "" || null) {
        if (JSON.parse(localStorage.getItem('imprint') as string).director === "" && JSON.parse(localStorage.getItem('imprint') as string).authorized === "" &&
            JSON.parse(localStorage.getItem('imprint') as string).contentResp === "" && (JSON.parse(localStorage.getItem('imprint') as string).settlement === 0 || null)) {
            return customerImprintData;
        } else {
            return JSON.parse(localStorage.getItem('imprint') as string)
        }
    } else {
        return JSON.parse(localStorage.getItem('imprint') as string)
    }
}

const Imprint = (props: ImprintProps) => {
    const localProject = JSON.parse(localStorage.getItem('ProjectSettings') as string);
    const settingsID = localProject.id;
    const localUser = JSON.parse(localStorage.getItem('User') as string);

    const [validated, setValidated] = useState(false);
    const [settlement, setSettlement] = useState<number>(parseInt(exractImprint(props.value)?.settlement));
    const initData =  typeof(localStorage.getItem('imprint'))!=='undefined' ? exractImprint(props.value) : initAccountData;
    const [accountData, setAccountData] = useState<IAccountData>(initData);

    console.log(accountData);

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();

        let imprintBack = {
            "settingsID": settingsID,
            "right": accountData.imageRights,
            "gf": accountData.director,
            "representative": accountData.authorized,
            "responsible_for_content": accountData.contentResp,
            "arbitration_settlement": settlement
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(imprintBack)
        }

        fetch(ApiRoot() + "media-rights", requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                console.log(result)
                localStorage.setItem('imprint', JSON.stringify(accountData));
                notifySave(Store);

                const requestOptions = {
                    method: "Get",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + BasicAuth()
                    }
                };

                fetch(ApiRoot() + "user/" + localUser.userID, requestOptions)
                    .then((response) => response.text())
                    .then((result: any) => {
                        result = JSON.parse(result);
                        console.log(result);
                        localStorage.setItem('Customer', JSON.stringify(result.customer))
                    })
                    .catch((error) => console.error(error));

            })
            .catch((error) => {
                console.error(error);
                notifyError(Store);
            });
    };

    const save = (data: IAccountData) => {
        setAccountData(data)
        localStorage.setItem('WK2024AccountData', JSON.stringify(data));
    }

    const textInputChange = (e: any) => {
        save({...accountData, [e.target.name]: e.target.value})
    }

    return (
        <div className="Imprint bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'Imprint.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Imprint.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Imprint.header.label', Store: Store})
            }}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <Wk24Form.Label class="lead labeltop text-left"
                                text={IntlMessage({locale: 'de', messageId: 'Imprint.header.text', Store: Store})}/>
                <Wk24Form.InputGroup>
                    <Wk24Form.InputGroupText
                        text={IntlMessage({locale: 'de', messageId: 'Imprint.director.text', Store: Store})}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'director'}
                        placeholder={IntlMessage({
                            locale: 'de',
                            messageId: 'Imprint.director.placeholder',
                            Store: Store
                        })}
                        defaultValue={accountData?.director}
                        onChange={textInputChange}/>
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup>
                    <Wk24Form.InputGroupText
                        text={IntlMessage({locale: 'de', messageId: 'Imprint.authorized.text', Store: Store})}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'authorized'}
                        placeholder={IntlMessage({
                            locale: 'de',
                            messageId: 'Imprint.authorized.placeholder',
                            Store: Store
                        })}
                        defaultValue={accountData?.authorized}
                        onChange={textInputChange}/>
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup>
                    <Wk24Form.InputGroupText
                        text={IntlMessage({locale: 'de', messageId: 'Imprint.contentResp.text', Store: Store})}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'contentResp'}
                        placeholder={IntlMessage({
                            locale: 'de',
                            messageId: 'Imprint.contentResp.placeholder',
                            Store: Store
                        })}
                        defaultValue={accountData?.contentResp}
                        onChange={textInputChange}/>
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup>
                    <Wk24Form.InputGroupText
                        text={IntlMessage({locale: 'de', messageId: 'Imprint.settlement.text', Store: Store})}/>
                    <Wk24Form.Select items={[{
                        value: '0',
                        text: IntlMessage({locale: 'de', messageId: 'Imprint.settlementNotSelect.text', Store: Store})
                    }, {
                        value: '1',
                        text: IntlMessage({locale: 'de', messageId: 'Imprint.settlementSelect.text', Store: Store})
                    }]} defaultValue={accountData?.settlement}
                                     onChange={(e) => {
                                         setSettlement(e.target.value)
                                     }}/>
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup>
                    <Wk24Form.InputGroupText
                        text={IntlMessage({locale: 'de', messageId: 'Imprint.imageRights.text', Store: Store})}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        as={"textarea"}
                        rows={10}
                        name={'imageRights'}
                        placeholder={IntlMessage({
                            locale: 'de',
                            messageId: 'Imprint.imageRights.placeholder',
                            Store: Store
                        })}
                        defaultValue={accountData?.imageRights}
                        onChange={textInputChange}/>
                </Wk24Form.InputGroup>
                <BackButton className={'mr-3'}  />
                <WK24Button
                    children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                    class={''} variant={'primary'} type={'submit'}/>
            </Wk24Form>
        </div>
    )
}
export default Imprint;