import React, {useEffect, useRef} from "react";
import CodeBlock from "./CodeArea";

/**
 * Content area component that allows editable text content and handles user input.
 *
 * @param {object} props
 * @param {string} props.defaultValue - The initial HTML content to display.
 * @returns {JSX.Element} The rendered ContentArea component.
 */
export const ContentArea = (props: { defaultValue: string }) => {
    const texteditorcontent = useRef<HTMLDivElement>(null);
    const editorTextarea = useRef<HTMLTextAreaElement>(null);
    const editorCodearea = useRef<HTMLPreElement>(null);
    /**
     * Updates the text editor content with the content from the specified element.
     *
     */
    const appendHTML = () => {

        if (texteditorcontent.current !== null) {
            const newContent = texteditorcontent.current.innerHTML;

            if (editorTextarea.current !== null) {
                editorTextarea.current.value = newContent; // Update textarea value
            }

            if (editorCodearea.current !== null && editorCodearea.current.querySelector('code') !== null) {
                // @ts-ignore
                editorCodearea.current.querySelector('code').textContent = texteditorcontent.current.innerHTML; // Update code area content
            }
        }
    };

    const appendCode = () => {

        if (editorCodearea.current !== null) {
            // @ts-ignore
            const element  = editorCodearea.current.querySelector('code');

            if(element !== null) {
                if (editorTextarea.current !== null) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(element.innerHTML, 'text/html');
                    // @ts-ignore
                    editorTextarea.current.value = doc.body.textContent;
                }

                if (texteditorcontent.current !== null) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(element.innerHTML, 'text/html');
                    // @ts-ignore
                    texteditorcontent.current.innerHTML = doc.body.textContent;
                }
            }

        }
    };
    useEffect(() => {
        appendHTML(); // Update code block initially
    }, []);
    return (
        <>
            {/* Content editable div with event handlers */}
            <div
                className={"text-editor-content d-block"}
                contentEditable={true}
                ref={texteditorcontent}
                onInput={() => appendHTML()}
                dangerouslySetInnerHTML={{__html: props.defaultValue}}
            />

            {/* Hidden textarea for accessibility */}
            <textarea id="editorTextarea" ref={editorTextarea} name="editorTextarea" className="editorTextarea d-none">
                {props.defaultValue}
            </textarea>
            <CodeBlock appendCode={appendCode} contentEditable={true} content={props.defaultValue} editorCodearea={editorCodearea} />


        </>
    );
};
