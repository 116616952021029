
const gridRow = (row:number,col:number): string => {

    let Holder = '';


    for(let x = 0;x<row;x++) {

        Holder = Holder + `<div class="row border ">`
        for(let cc=0;cc<col;cc++) {
            Holder = Holder + `<div class="col border-end p-2">Spalte-${cc}</div>`
        }
        Holder = Holder + `</div>`
    }

    let Rows = `<div class="row mb-3 mt-5 wk-tablebox">${Holder}</div><div style="background-color: rgb(255, 255, 255);">Trage in die Tabelle je Spalte und Zeile deine Werte ein.</div>`


    return Rows
};

export default gridRow;
