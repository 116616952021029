import Form from 'react-bootstrap/Form';
import React, {
    CSSProperties,
    ElementType,
    KeyboardEventHandler,
    MouseEventHandler
} from "react";
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface WK24FormControlProps {
    /** See Legale Value https://www.w3schools.com/html/html_form_input_types.asp*/
    type:string,
    /** Set Name Attribute on Element */
    name:string,
    /** Set Placeholder on Element */
    placeholder?:string,
    /** Set custom Classes on Element */
    class?:string,
    /** Set DefaultValue on Element */
    defaultValue?:string,
    /** Set Value on Element */
    value?:string,
    /** Make Element Required with val true */
    required?:boolean,
    /** Combine Element with Onchange Function and Event */
    onChange?: React.ChangeEventHandler<FormControlElement>;
    /** set id on Element in DOM */
    id?:string
    /** set title on Element in DOM */
    title?:string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    /** Set Element disabled by true and remove disabled by false */
    disabled?:boolean
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Set Textarea Rows */
    rows?:number
    /** Combine Element with onKeyPress Function and Event */
    onKeyPress?:KeyboardEventHandler | undefined
    /** Combine Element with onClick Function and Event */
    onClick?:MouseEventHandler | undefined
    accept?:string
}

/**
 * Create a Input Field or Textarea with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-control#api" target="_blank">Form.Control</a>.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <Wk24Form validated={validated} Submit={handleSubmit}>
 *    <Wk24Form.FormGroup>
 *       <Wk24Form.FormControl type={'text'} name={'name'} placeholder={'placeholder'}  />
 *    </Wk24Form.FormGroup>
 * </Wk24Form>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */

const WK24FormControl = (props:WK24FormControlProps) => {

    return(
        <Form.Control type={props.type}
                      name={props.name}
                      id={props.id}
                      title={props.title}
                      style={props.style}
                      disabled = {props.disabled !== undefined ? props.disabled : false}
                      placeholder={props.placeholder}
                      className={props.class}
                      as={props.as}
                      rows={props.rows}
                      accept={props.accept}
                      defaultValue={props.defaultValue}
                     value={props.value}
                      required={props.required !== undefined ? props.required : false}
                      onKeyPress={props.onKeyPress}
                      onClick={props.onClick}
                      onChange={props.onChange}
                      multiple
        />
    )


}

export default WK24FormControl;
