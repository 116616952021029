/**
 * Manages the state the login.
 *
 * @params {string} email - The registered email.
 * @params {string} password - The password to the registered email.
 * @params {boolean} showPassword - Show the password as text or as hidden.
 * @params {boolean} loggedIn - Indicates whether the user is logged in or not.
 *
 */

import {useNavigate} from "react-router-dom";
import React, {useReducer, useState} from "react";
import {IntlMessage, MIcon, Store, WK24Button, Wk24Form} from "../../../@WK24/core";
import LoginReducer from "../store/reducers/login/LoginReducer"
import {LoginActionType} from "../store/actions/ActionType"

import '../css/login.css'
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifyLoginError} from "../../../@WK24/core/Notification/service/notification.service";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

interface LoginProps {
    value: any
}

const Login = (props: LoginProps) => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const navigate = useNavigate()

    const loggedInUser = [email, password];
    const [logIn, logInDispatch] = useReducer(LoginReducer, {isLoggedIn: loggedIn, user: null})

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
       //if (email !== null && password !== null) {
            var raw = JSON.stringify({"email" :email , "password": password })
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Basic " + BasicAuth()
                },
                body: raw
            };

            fetch(ApiRoot() + 'users/login', requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if(result.login === true ) {
                        logInDispatch({type: LoginActionType.LOGIN_SUCCESS, payload: {loggedInUser}})
                        setLoggedIn(true);
                        localStorage.setItem("userRole","superadmin")
                        localStorage.setItem("locale","DE")
                        localStorage.setItem("User", JSON.stringify(result.user))
                        localStorage.setItem("Customer", JSON.stringify(result.customer))
                        localStorage.setItem("Project", JSON.stringify(result.Project))
                        localStorage.setItem("Emails", JSON.stringify(result.Emails))
                        localStorage.removeItem('GhostUser')
                        localStorage.removeItem('GhostCustomer')
                        localStorage.removeItem('GhostProject')
                        navigate('/')
                    } else {
                        notifyLoginError(Store)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        //}
    };

    return(
        <div className="loginForm noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form.Label class="loginHeader"
                            text={IntlMessage({locale: 'de', messageId: 'loginheader.label', Store: Store})}/>

            {!loggedIn &&
                <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                    <Wk24Form.FormGroup>
                        <Wk24Form.FormControl
                            type={'email'}
                            name={IntlMessage({locale: 'de', messageId: 'loginmail.name', Store: Store})}
                            placeholder={IntlMessage({locale: 'de', messageId: 'loginmail.placeholder', Store: Store})}
                            onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
                        />
                        <Wk24Form.InputGroup>
                            <Wk24Form.FormControl
                                type={showPassword ? 'text' : 'password'}
                                name={IntlMessage({locale: 'de', messageId: 'loginpass.name', Store: Store})}
                                placeholder={IntlMessage({
                                    locale: 'de',
                                    messageId: 'loginpass.placeholder',
                                    Store: Store
                                })}
                                onChange={(e: { target: { value: string } }) => setPassword(e.target.value)}
                            />
                            <WK24Button
                                children={showPassword ? <MIcon children="visibility_off" variant="-outlined"/> :
                                    <MIcon children="visibility" variant="-outlined"/>}
                                variant={'info'}
                                onClick={() => {
                                    setShowPassword((prev) => !prev)
                                }}/>
                        </Wk24Form.InputGroup>

                        <WK24Button
                            children={IntlMessage({locale: 'de', messageId: 'loginbtn.label', Store: Store})}
                            variant="primary"
                            type="submit"
                            onClick={() => {
                                //localStorage.setItem('LoggedInUser', JSON.stringify(loggedInUser));
                            }}/>
                        {/*<WK24Button
                            children={IntlMessage({locale: 'de', messageId: 'forgotpass.label', Store: Store})}
                            variant="link" onClick={() => {
                        }}/>*/}
                    </Wk24Form.FormGroup>
                </Wk24Form>
            }

            <p className="loginInfo" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'logininfo.label', Store: Store})
            }}/>
            <div className="chamiLogin">
                <img className="chamiIcon" src={'/chami6.png'} alt={''}/>
            </div>
            <div className="giraffeLogin">
                <img className="giraIcon" src={'/gira2.jpg'} alt={''}/>
            </div>
        </div>
    )
}

export default Login;
