import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    page: {
        paddingLeft: '25mm',
        paddingRight: '15mm',
        marginTop: 20,
        fontFamily: 'Lato',
        height: 200,
        paddingBottom: 130,
        backgroundColor: "#ffffff",
        color: "#000000"
    },
    headSection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    sectionbox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    logo: {
        width: 150,
        height: 'auto',
        marginLeft: -30
    },
    pageHead: {
        fontSize: '9px',
        marginBottom: 2
    },
    company: {
        fontSize: '12px'
    },
    topMargin: {
        marginTop: 20
    },
    invoiceInfo: {
        fontSize: '10px',
        lineHeight: 1.4
    },
    invoiceHead: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: -30
    },
    invoiceTitle: {
        paddingRight: 30
    },
    mainSection: {
        marginTop: 50
    },
    articleList: {
        fontSize: '10px',
        display: "flex",
        flexDirection: "row"
    },
    listHead: {
        borderBottom: '1px solid #000000',
        paddingBottom: 5
    },
    listContent: {
        paddingTop: 5
    },
    mainFooter: {
        fontSize: '10px',
        display: "flex",
        flexDirection: "row",
        borderTop: '1px solid #000000',
        paddingTop: 5,
        marginTop: 15
    },
    footContent: {
        paddingTop: 3
    },
    footSection: {
        position: "absolute",
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        fontSize: '10px',
        flexWrap: "wrap",
        height: 150,
        justifyContent: "space-between",
        left: '25mm',
        borderTop: '1px solid #000000',
        width: '100%',
        paddingTop: 5
    },
});