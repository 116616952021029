import Row from 'react-bootstrap/Row';
import {CSSProperties, ElementType, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

export interface WK24RowProps{
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** The number of columns that will fit next to each other on extra small devices (<576px). Use auto to give columns their natural widths. */
    xs?:number | 'auto' | { cols: number | 'auto' }
    /** The number of columns that will fit next to each other on small devices (≥576px). Use auto to give columns their natural widths. */
    sm?:number | 'auto' | { cols: number | 'auto' }
    /** The number of columns that will fit next to each other on medium devices (≥768px). Use auto to give columns their natural widths. */
    md?:number | 'auto' | { cols: number | 'auto' }
    /** The number of columns that will fit next to each other on large devices (≥992px). Use auto to give columns their natural widths. */
    lg?:number | 'auto' | { cols: number | 'auto' }
    /** The number of columns that will fit next to each other on extra large devices (≥1200px). Use auto to give columns their natural widths. */
    xl?:number | 'auto' | { cols: number | 'auto' }
    /** The number of columns that will fit next to each other on extra extra large devices (≥1400px). Use auto to give columns their natural widths. */
    xxl?:number | 'auto' | { cols: number | 'auto' }
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

const Index = (props:WK24RowProps) => {

    return(
        <Row xs={props.xs} sm={props.sm} md={props.md} style={props.style} lg={props.lg} xl={props.xl} xxl={props.xxl} id={props.id} className={props.class}  as={props.as}>
            {props.children}
        </Row>
    )
}

export default Index;
