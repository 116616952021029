import React, {useEffect, useRef} from "react";

 const TablePicker = (props:{setRow:any,setCell:any}) => {

    const MakerRef = useRef<HTMLButtonElement>(null);
    const MakerinnerRef = useRef<HTMLDivElement>(null);
    const HolderRef = useRef<HTMLDivElement>(null);
    const tablePicker = (x:any, y:any) => {
        return new Promise(resolve => {
            let div = MakerinnerRef.current as HTMLDivElement
            if (div) div.remove();
            let colCount = 0;
            let rowCount = 0;
            div = document.createElement("div") ;
            div.setAttribute("id", "tblpck");
            div.innerHTML = `<style>
            #tblpck div{background:#ccc;font-family:Verdana;text-align:right}
            #tblpck table{border-spacing:3px;background:#f8f8f8}
            #tblpck td{border:1px solid #888;width:16px;height:16px;box-sizing:border-box}
            #tblpck .tblpckhighlight{border:2px solid orange;}
        <\/style><div>0x0 Row<\/div>
        <table>${`<tr>${`<td><\/td>`.repeat(6)}<\/tr>`.repeat(4)}<\/table>`;
            HolderRef.current?.appendChild(div);
            Object.assign(div.style, {left: "20px", top: "20px",width:"100px", position: "absolute", border: "1px solid #ccc"});

            div.onmouseover = (e:any) => {
                if (e.target.tagName !== "TD") return;
                let td = e.target;
                let tr = td.parentNode;
                let table = tr.parentNode;
                colCount = td.cellIndex+1;
                rowCount = tr.rowIndex+1;
                for (let row of table.rows) {
                    let inside = row.rowIndex < rowCount;
                    for (let cell of row.cells) {
                        cell.classList.toggle("tblpckhighlight", inside && cell.cellIndex < colCount);
                    }
                }
                div.children[1].textContent = `${colCount}x${rowCount} Row`;
                return false;
            };

            div.onmousedown = () => {
                if(HolderRef.current !== null) {
                    let div = HolderRef.current.querySelectorAll("#tblpck")[0] as HTMLDivElement
                    if(div !== null && div !== undefined ) {
                        div.remove();
                    }
                }

                    resolve([colCount, rowCount]);

            };
        });
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (MakerRef.current && !MakerRef.current.contains(event.target as Element)) {
                if(HolderRef.current !== null) {
                    let div = HolderRef.current.querySelectorAll("#tblpck")[0] as HTMLDivElement
                    if(div !== null && div !== undefined ) {
                        div.remove();
                    }
                }
            }
        };
        document.addEventListener('click', handleClickOutside);
        MakerRef.current?.addEventListener("click", async (e) => {

            // @ts-ignore
            let [colCount, rowCount] = await tablePicker(e.clientX, e.clientY);
            props.setRow(rowCount)
            props.setCell(colCount)
           /* console.log(JSON.stringify({colCount, rowCount}));*/
        });
    }, []);

    return (<><span ref={HolderRef} style={{position:'relative'}}>
            <button
                type="button"
                ref={MakerRef}
                className="btn"
                data-element={'row'} title={'Tabellenlayout einfügen '}
            >
                <span className="material-symbols-outlined">
                    view_module
                </span>
            </button>
            <div ref={MakerinnerRef} style={{left: "20px", top: "20px",width:"100px", position: "absolute"}}></div>
        </span>
        </>
    );
};

 export default TablePicker;
