/**
 * Manages the state of the domain.
 *
 * @params {string} domain - The state of the frontend domain input.
 * @params {string} auth - The state of the authcode input.
 * @params {boolean} statusAvailable - The state of the check whether domain is available or not.
 * @params {boolean} check - Auxiliary variable for displaying text.
 * @params {boolean} checkWait - Auxiliary variable for displaying waiting/checking text.
 * @params {[]} domainAlternative - Array for displaying alternative domains.
 *
 */

import React, {useState} from "react";
import '../css/domain.css'
import {BackButton, IntlMessage, MIcon, Store, WK24Button, Wk24Cards, Wk24Form} from "../../../@WK24/core";
import domainFetch from "./domainFetch";
import {Wk24CardsFooter} from "../../../@WK24/core/Cards/Elements/Wk24CardsFooter";
import domainAlternativeFetch from "./domainAlternativeFetch";
import {useNavigate} from "react-router-dom";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {
    notifyDomainError,
    notifyError,
    notifySave
} from "../../../@WK24/core/Notification/service/notification.service";

interface DomainProps {
    value: any
}

const Domain = (props: DomainProps) => {
    const [validated, setValidated] = useState(false);
    const [domain, setDomain] = useState<string>(props.value !== 'visibleNon' ? JSON.parse(localStorage.getItem('ProjectSettings') as string).frontend : '');
    const [auth, setAuth] = useState<string>(props.value !== 'visibleNon' ? JSON.parse(localStorage.getItem('ProjectSettings') as string).AuthCode : '');
    const [statusAvailable, setStatusAvailable] = useState(false);
    const [check, setCheck] = useState(false);
    const [checkWait, setCheckWait] = useState<boolean>();
    const [domainAlternative, setDomainAlternative] = useState([])
    const navigate = useNavigate()
    const backgroundImage = 'url(/portalbg.jpg)';

    let regDomain = {domain: ''};
    let frontend;
    let authCode;

    if (props.value !== 'visibleNon') {
        let localSettings = JSON.parse(localStorage.getItem('ProjectSettings') as string);
        frontend = localSettings.frontend;
        authCode = localSettings.AuthCode;
    }

    /** check whether domain is available or not */
    const checkDomain = (status: number) => {
        setCheckWait(false);
        if (status === 1) {
            setStatusAvailable(true);
        } else {
            if (status === 0) {
                setStatusAvailable(false);
                regDomain = {domain: domain};
                domainAlternativeFetch(regDomain).then(r => {
                    let alternative = JSON.parse(r);
                    setDomainAlternative(alternative)
                })
            }
        }
    }

    /** save data: frontend domain and authCode */
    const saveData = () => {
        const localSettings = JSON.parse(localStorage.getItem('ProjectSettings') as string);
        const localProject = JSON.parse(localStorage.getItem('Project') as string);
        const settingsID = localProject.projectSettingsID;
        const projectID = localProject.projectID;

        let settingsBack = {
            "id": settingsID,
            "favicon": localSettings.favicon,
            "backend": localSettings.backend,
            "frontend": domain,
            "version": localSettings.version,
            "lastUpdate": localSettings.lastUpdate,
            "lastAccess": localSettings.lastAccess,
            "isActive": localSettings.isActive,
            "login": localSettings.login,
            "companyName": localProject,
            "logo": localSettings.companyName,
            "colors": {
                "font": localSettings.colors.font,
                "primary": localSettings.colors.primary,
                "secondary": localSettings.colors.secondary,
                "tertiary": localSettings.colors.tertiary,
                "background": localSettings.colors.background
            },
            "template": localSettings.template,
            "AuthCode": auth
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(settingsBack)
        };
        fetch(ApiRoot() + "projetcSettings", requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                console.log(result);
                const requestGetOptions = {
                    method: "Get",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + BasicAuth()
                    }
                };

                fetch(ApiRoot() + "projetcSettings/" + projectID, requestGetOptions)
                    .then((response) => response.text())
                    .then((result: any) => {
                        result = JSON.parse(result);
                        localStorage.setItem('ProjectSettings', JSON.stringify(result));
                        notifySave(Store);
                    })
                    .catch((error) => {
                        console.error(error);
                        notifyError(Store);
                    });
            })
            .catch((error) => console.error(error));
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
    };

    return (
        <div className={props.value !== 'visibleNon' ? "Domain bpage" : "Domain backendStep noneSidebar"}
             style={props.value === 'visibleNon' ? {backgroundImage: backgroundImage} : {backgroundColor: 'transparent'}}>
            {props.value === 'visibleNon' ?
                <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/> : ''}
            <h1>{IntlMessage({locale: 'de', messageId: 'Domain.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Domain.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Domain.header.label', Store: Store})
            }}/>
            {props.value === 'visibleNon' ?
                <p className="mt-3" dangerouslySetInnerHTML={{
                    __html: IntlMessage({locale: 'de', messageId: 'Domain.headerinfo.label', Store: Store})
                }}/> : ''}
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Domain.help.label', Store: Store})
            }}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <div className="giraffeStep7">
                    <img className="giraIcon" src={'/gira2-2.png'} alt={''}/>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <Wk24Cards style={{width: '41.5rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="domain" variant="fill"/> {IntlMessage({
                            messageId: 'domain.card.title',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.InputGroup class="imagegroup">
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'Domain.label', Store: Store})}
                                    class={'mt-3 mr-3'}
                                />
                                <Wk24Form.FormControl type={'text'}
                                                      name={'vat'}
                                                      placeholder={IntlMessage({
                                                          locale: 'de',
                                                          messageId: 'Domain.placeholder',
                                                          Store: Store
                                                      })}
                                                      defaultValue={props.value !== 'visibleNon' ? frontend : ""}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setDomain(e.target.value);
                                                      }}/>
                            </Wk24Form.InputGroup>
                            {checkWait && <div>
                                <p className="checkResult"><MIcon children="hourglass_top" variant="fill"
                                                                  style={{color: '#e9e113'}}/> {IntlMessage({
                                    locale: 'de',
                                    messageId: 'checkWait.label',
                                    Store: Store
                                })}</p>
                            </div>}
                            {check && statusAvailable && !checkWait && <div>
                                <p className="checkResult"><MIcon children="done" variant="fill"
                                                                  style={{color: '#12a751'}}/> {IntlMessage({
                                    locale: 'de',
                                    messageId: 'checkAvailable.label',
                                    Store: Store
                                })}</p>
                            </div>}
                            {check && !statusAvailable && !checkWait && <div>
                                <p className="checkResult"><MIcon children="report" variant="fill"
                                                                  style={{color: '#f41909'}}/> {IntlMessage({
                                    locale: 'de',
                                    messageId: 'checkNotAvailable.label',
                                    Store: Store
                                })}</p>
                                {domainAlternative &&
                                    <div className="checkAlternative">
                                        <p><strong>Alternative Domains:</strong></p>
                                        {domainAlternative.map((domainItem: any) => {
                                            let domainStatus = '';
                                            if (domainItem.status === 1) {
                                                domainStatus = IntlMessage({
                                                    locale: 'de',
                                                    messageId: 'Domain.available.text',
                                                    Store: Store
                                                })
                                            } else {
                                                if (domainItem.status === 0) {
                                                    domainStatus = IntlMessage({
                                                        locale: 'de',
                                                        messageId: 'Domain.notAvailable.text',
                                                        Store: Store
                                                    })
                                                }
                                            }
                                            return (
                                                <p>{domainItem.domain} - {domainStatus}</p>
                                            )
                                        })
                                        }
                                    </div>}
                            </div>}
                        </Wk24Cards.Body>
                        <Wk24CardsFooter>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnCheck.label', Store: Store})}
                                class={''} variant={'link'}
                                onClick={() => {
                                    console.log(domain);
                                    if (domain.includes('.de') || domain.includes('.com') || domain.includes('.net') || domain.includes('.tech')) {
                                        setCheck(true);
                                        setCheckWait(true);
                                        regDomain = {domain: domain};
                                        domainFetch(regDomain).then(r => {
                                            let response = JSON.parse(r);
                                            checkDomain(response.status);
                                        })
                                    } else {
                                        notifyDomainError(Store);
                                    }
                                }}/>
                        </Wk24CardsFooter>

                    </Wk24Cards>
                    <Wk24Cards style={{width: '41.5rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="domain" variant="fill"/> {IntlMessage({
                            messageId: 'domainAuth.card.title',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.Label
                                text={IntlMessage({locale: 'de', messageId: 'DomainAuth.label', Store: Store})}
                                class={'mt-3 mr-3'}
                            />
                            <Wk24Form.InputGroup class="imagegroup">
                                <Wk24Form.Label
                                    text={IntlMessage({locale: 'de', messageId: 'domainAuth.card.title', Store: Store})}
                                    class={'mt-3 mr-3'}
                                />
                                <Wk24Form.FormControl type={'text'}
                                                      name={'vat'}
                                                      placeholder={IntlMessage({
                                                          locale: 'de',
                                                          messageId: 'domainAuth.placeholder',
                                                          Store: Store
                                                      })}
                                                      defaultValue={props.value !== 'visibleNon' ? authCode : ""}
                                                      onChange={(e: { target: { value: string } }) => {
                                                          setAuth(e.target.value);
                                                      }}/>
                            </Wk24Form.InputGroup>
                        </Wk24Cards.Body>
                    </Wk24Cards>
                </div>
                <div className="mt-3 col">
                    {props.value === 'visibleNon' ?
                        <WK24Button
                            children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                            class={'float-end'} variant={'primary'} onClick={() => {
                            localStorage.setItem('Domain', JSON.stringify(domain));
                            localStorage.setItem('AuthCode', JSON.stringify(auth));
                            navigate('/step7a')
                        }}
                        />
                        : <><BackButton className={'mr-3'}/>
                            <WK24Button children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                        class={''} variant={'primary'}
                                        type={'submit'}
                                //disabled={!statusAvailable}
                                        onClick={() => {
                                            regDomain = {domain: domain};
                                            localStorage.setItem('Domain', JSON.stringify(domain));
                                            localStorage.setItem('AuthCode', JSON.stringify(auth));
                                            saveData();
                                        }}/>
                        </>
                    }
                </div>
            </Wk24Form>
            <div className="chamiStep7">
                <img className="chamiIcon" src={'/chami1-2.png'} alt={''}/>
            </div>
        </div>
    )
}

export default Domain;
