import CTA from "../elements/CTA";
import AddEventlistener from "./addEventlistener";
import ProgressBar from "../elements/ProgressBar";
import ThreeBlocks from "../elements/threeBlocks";
import Card from "../elements/Card";
import blockquote from "../elements/blockquote";
import Hero from "../elements/Hero";
import {ImageHero} from "./ImageEdit";

/**
 * Applies or toggles formatting styles to the selected text or surrounding span element.
 *
 * @param {Selection} userSelection - The user's text selection.
 * @param {string} text - The selected text content.
 * @param {Range} selectedTextRange - The range of the selected text.
 * @param Element
 * @param {string} search - The formatting style to apply.
 */
export const controllCommand = (userSelection:any,text:any,selectedTextRange:any,Element:any,search:any,font?:any) => {

    if((userSelection?.anchorNode?.parentNode as HTMLElement).localName === 'span') {

        const node = userSelection?.anchorNode?.parentNode;
        node?.querySelector('#tmp');
        const Style = (node as HTMLElement)

        if (node !== null && node !== undefined && node.parentNode) {

            node.parentNode.removeChild(node);
        }
        const computedStyle = getComputedStyle(Style);
        switch (search) {
            case 'bold':
                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.fontWeight = (node as HTMLElement).style.fontWeight == 'bold' ? 'unset' : 'bold'
                break;
            case 'underline':

                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.textDecoration = (node as HTMLElement).style.textDecoration == 'underline' ? 'unset' : 'underline'

                break;
            case 'italic':

                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.fontStyle = (node as HTMLElement).style.fontStyle === 'italic' ? 'normal' : 'italic'

                break;
            case 'center':

                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.textAlign = (node as HTMLElement).style.textAlign == 'center ' ? 'unset' : 'center'

                break;
            case 'familiy':
                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }

                Style.style.fontFamily =  font
                break;
            case 'left':

                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.textAlign = (node as HTMLElement).style.textAlign == 'left ' ? 'unset' : 'left'

                break;
            case 'right':

                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.textAlign = (node as HTMLElement).style.textAlign == 'right ' ? 'unset' : 'right'

                break;
            case 'justify':

                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    Style.style.setProperty(property, value);
                }
                Style.style.textAlign = (node as HTMLElement).style.textAlign == 'justify ' ? 'unset' : 'justify'
                break;
        }
        selectedTextRange?.insertNode(Style);
    } else {


        const Element = document.createElement("span");

        const computedStyle = getComputedStyle(Element);

        Element.id = 'tmp'
        switch (search) {
            case 'bold':
                Element.style.fontWeight = Element.style.fontWeight == 'bold' ? 'unset' : 'bold'
                break;
            case 'underline':
                Element.style.textDecoration = Element.style.textDecoration == 'underline' ? 'unset' : 'underline'
                break;
            case 'italic':
                Element.style.fontStyle = Element.style.fontStyle === 'italic' ? 'normal' : 'italic';
                break;
            case 'center':
                Element.style.textAlign = Element.style.textAlign == 'center ' ? 'unset' : 'center'
                break;
            case 'left':
                Element.style.textAlign = Element.style.textAlign == 'left ' ? 'unset' : 'left'
                break;
            case 'right':
                Element.style.textAlign = Element.style.textAlign == 'right ' ? 'unset' : 'right'
                break;
            case 'justify':
                Element.style.textAlign = Element.style.textAlign == 'justify ' ? 'unset' : 'justify'
                break;
            case 'familiy':
                Element.style.fontFamily =  font
                break;
        }
        console.log(Element)
        selectedTextRange?.surroundContents(Element);
    }
    selectedTextRange.collapse(true);
}
/**
 * Applies bold formatting to the selected text.
 */
export const boldCommand = () => {
    const strongElement = document.createElement("span");
    strongElement.id = 'bold'

    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string
    strongElement.innerText = text;
    const selectedTextRange = userSelection?.getRangeAt(0);


    if (!selectedTextRange) {
        return; // No selection
    } else {
        controllCommand(userSelection,text,selectedTextRange,strongElement,'bold')
    }

}

export const FontCommand = (e:any) => {
    const strongElement = document.createElement("span");
    strongElement.id = 'familiy'

    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string
    strongElement.innerText = text;
    const selectedTextRange = userSelection?.getRangeAt(0);


    if (!selectedTextRange) {
        return; // No selection
    } else {
        controllCommand(userSelection,text,selectedTextRange,strongElement,'familiy',e.target.value)
        let select = e.target as HTMLSelectElement;
        select.value = '';
    }

}

/**
 * Applies italic formatting to the selected text.
 */
export const italicCommand = () => {
    const strongElement = document.createElement("span");
    strongElement.id = 'italic'
    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string
    strongElement.innerText = text;
    const selectedTextRange = userSelection?.getRangeAt(0);
    if (!selectedTextRange) {
        return; // No selection
    } else {
        controllCommand(userSelection,text,selectedTextRange,strongElement,'italic')
    }
}
/**
 * Applies underline formatting to the selected text.
 */
export const underlineCommand = () => {
    const strongElement = document.createElement("span");
    strongElement.id = 'underline'
    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string
    strongElement.innerText = text;
    const selectedTextRange = userSelection?.getRangeAt(0);
    if (!selectedTextRange) {
        return; // No selection
    } else {
        controllCommand(userSelection,text,selectedTextRange,strongElement,'underline')
    }
}
/**
 * Applies text alignment to the selected text or surrounding span element.
 *
 * @param {string} value - The alignment value ('center', 'left', 'right', or 'justify').
 */
export const TextAlignCommand = (value:any) => {

    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string

    const selectedTextRange = userSelection?.getRangeAt(0);

    const node = userSelection?.anchorNode?.parentNode;
    node?.querySelector('#tmp');
    const Style = (node as HTMLElement)
    const computedStyle = getComputedStyle(Style);
    if (node !== null && node !== undefined && node.parentNode) {

        node.parentNode.removeChild(node);
    }
    switch (value) {
        case 'center':

            for (let i = 0; i < computedStyle.length; i++) {
                const property = computedStyle[i];
                const value = computedStyle.getPropertyValue(property);
                Style.style.setProperty(property, value);
            }
            Style.style.textAlign = (node as HTMLElement).style.textAlign == 'center ' ? 'unset' : 'center'
            break;
        case 'left':
            for (let i = 0; i < computedStyle.length; i++) {
                const property = computedStyle[i];
                const value = computedStyle.getPropertyValue(property);
                Style.style.setProperty(property, value);
            }
            Style.style.textAlign = (node as HTMLElement).style.textAlign == 'left ' ? 'unset' : 'left'
            break;
        case 'right':

            for (let i = 0; i < computedStyle.length; i++) {
                const property = computedStyle[i];
                const value = computedStyle.getPropertyValue(property);
                Style.style.setProperty(property, value);
            }
            Style.style.textAlign = (node as HTMLElement).style.textAlign == 'right ' ? 'unset' : 'right'
            break;
        case 'justify':

            for (let i = 0; i < computedStyle.length; i++) {
                const property = computedStyle[i];
                const value = computedStyle.getPropertyValue(property);
                Style.style.setProperty(property, value);
            }
            Style.style.textAlign = (node as HTMLElement).style.textAlign == 'justify ' ? 'unset' : 'justify'
            break;
    }
    selectedTextRange?.insertNode(Style);
    selectedTextRange?.collapse(true);
}
/**
 * Removes all styles and tags from the selected text, leaving only plain text.
 */
export const removeStylesAndTags = () => {

    const userSelection = window.getSelection();
    let text = userSelection?.toString() as string

    const selectedTextRange = userSelection?.getRangeAt(0);

    const node = userSelection?.anchorNode?.parentNode;
    node?.querySelector('#tmp');
    const Style = (node as HTMLElement)
    const computedStyle = getComputedStyle(Style);
    if (node !== null && node !== undefined && node.parentNode) {

        node.parentNode.removeChild(node);
    }
    Style.style.cssText = "";

    selectedTextRange?.insertNode(Style);
    selectedTextRange?.collapse(true);
}
/**
 * Executes the "undo" command in the document.
 */
export const undo = () => {
    document.execCommand("undo")
}
/**
 * Executes the "redo" command in the document.
 */
export const redo = () => {
    document.execCommand("redo")
}
/**
 * Creates a list (ordered or unordered) from the selected text.
 *
 * @param {string} mode - The type of list to create: "li" for unordered, "ol" for ordered.
 */
export const List = (mode:string) => {
    const selection = window.getSelection();
    if (!selection) return;

    const range = selection.getRangeAt(0);
    const fragment = range.extractContents();
    let ul = document.createElement("ul");
    if(mode === 'li') {
        ul = document.createElement("ul");
    } else if(mode === 'ol') {
        ul = document.createElement("ol");
    }

    for (const node of fragment.childNodes) {
        const li = document.createElement("li");
        li.textContent = node.textContent;
        ul.appendChild(li);
    }

    range.insertNode(ul);
}

const handleInsert = (textToInsert: string) => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(textToInsert));
    }
};
/**
 * Handles pasting text into the editor, ensuring consistent formatting.
 *
 * @param {ClipboardEvent} event - The paste event.
 */
export const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault(); // Prevent default paste behavior
    const pastedText = event.clipboardData?.getData('text');
    if (pastedText) {
        handleInsert(pastedText);
    }
};
/**
 * Replaces a specific element with another element within a designated HTMLDivElement.
 *
 * @param {string} identifier - The identifier of the element to replace.
 * @param {string} element - The replacement element's HTML content.
 * @param {HTMLDivElement} Holder - The parent HTMLDivElement containing the element to replace.
 */
export  const ReplaceElement = (identifier:string,element:string,Holder:HTMLDivElement) => {

    const Editor = Holder.querySelectorAll('.text-editor-content')[0] as HTMLDivElement;
    Editor.innerHTML = Editor.innerHTML.replace(identifier,element);
    let Code = Holder.querySelectorAll('.code-block')[0] as HTMLPreElement;
    // @ts-ignore
    Code.querySelector('code').textContent = Code.innerText.replace(identifier,element)
    let Textarea = Holder.querySelectorAll('.editorTextarea')[0] as HTMLTextAreaElement;
    Textarea.value = Textarea.value.replace(identifier,element)
}
/**
 * Handles clicks on various editor commands.
 *
 * @param {any} event - The click event.
 * @param {any} setShowLinkModal - A function to set the visibility of a link modal.
 * @param {any} setShowIMGModal - A function to set the visibility of an image modal.
 * @param {any} ReplaceElement - The ReplaceElement function for element replacement.
 * @param {any} EditorHolder - An element related to managing editor state.
 * @param {any} Holder - The parent HTMLDivElement containing the editor content.
 */
export const handleClick = (event: any,setShowLinkModal:any,setShowIMGModal:any,ReplaceElement:any,EditorHolder:any,Holder:any) => {
    const command = event.currentTarget.dataset['element'] as string;
    /*ElementsInclude(command,setShowLinkModal,setShowIMGModal,inputEditor,EditorHolder,Holder)*/
    if (command === 'createLink') {
        setShowLinkModal(true)
        const selection = window.getSelection();
        if (!selection) return;

        const range = selection.getRangeAt(0);
        const fragment = range.toString();
        document.execCommand("insertHTML", false, `<a id="LinkCreate" href="#" target="_self" >${fragment}</a>`);
    } else if (command === 'insertImage') {
        setShowIMGModal(true)
        document.execCommand("insertHTML", false, '<img id="IMGCreate" src=""  alt=""/>');
    }else if (command === 'cta') {
        document.execCommand("insertHTML", false, '[MakeCTA]');
        ReplaceElement('[MakeCTA]',CTA(),Holder)
        AddEventlistener(EditorHolder)
    }else if (command === 'progressbar') {
        document.execCommand("insertHTML", false, '[MakeCardSlide]');
        ReplaceElement('[MakeCardSlide]',ProgressBar(),Holder)
        AddEventlistener(EditorHolder)
    }else if (command === 'threeBlocks') {
        document.execCommand("insertHTML", false, '[MakethreeBlocks]');
        ReplaceElement('[MakethreeBlocks]',ThreeBlocks(),Holder)
        AddEventlistener(EditorHolder)
    }else if (command === 'card') {
        document.execCommand("insertHTML", false, '[MakeCards]');
        ReplaceElement('[MakeCards]',Card(),Holder)
        AddEventlistener(EditorHolder)
    }else if (command === 'blockquote') {
        document.execCommand("insertHTML", false, '[blockquote]');
        ReplaceElement('[blockquote]',blockquote(),Holder)
        AddEventlistener(EditorHolder)
    }else if (command === 'row') {
        document.execCommand("insertHTML", false, '[row]');

    }else if (command === 'hero') {
        document.execCommand("insertHTML", false, '[hero]');
        ReplaceElement('[hero]',Hero(),Holder)
        AddEventlistener(EditorHolder)
        const buttons = document.querySelectorAll('.imageHero');
        for (const button of buttons) {
            button.addEventListener('click', ImageHero);
        }
    } else if (command === 'code') {
        const editorContent  = Holder.querySelectorAll('.text-editor-content')[0] as HTMLDivElement;
        const codeBlock = Holder.querySelectorAll('.code-block')[0] as HTMLDivElement;

        if(editorContent !== null && codeBlock !== null) {
            const hasClass = (element: any, className: string) => element.classList.contains(className);
            if (hasClass(editorContent, 'd-block')) {
                editorContent.classList.add("d-none");
                editorContent.classList.remove("d-block");
                codeBlock.classList.remove("d-none");
                codeBlock.classList.add("d-block");
            } else {
                editorContent.classList.remove("d-none");
                editorContent.classList.add("d-block");
                codeBlock.classList.add("d-none");
                codeBlock.classList.remove("d-block");
            }
        }
    } else if (command === 'bold') {
        boldCommand()
    } else if (command === 'italic') {
        italicCommand()
    } else if (command === 'underline') {
        underlineCommand()
    } else if (command === 'insertUnorderedList') {
        List('li')
    }else if (command === 'insertOrderedList') {
        List('ol')
    }else if (command === 'removeFormat') {
        removeStylesAndTags()
    }else if (command === 'justifyLeft') {
        TextAlignCommand('left')
    }else if (command === 'justifyCenter') {
        TextAlignCommand('center')
    }else if (command === 'justifyRight') {
        TextAlignCommand('right')
    }else if (command === 'justifyFull') {
        TextAlignCommand('justify')
    }else if (command === 'undo') {
        undo()
    }else if (command === 'redo') {
        redo()
    }else if (command === 'headline') {

    }else {
        document.execCommand(command, true, 'null');
    }

};
