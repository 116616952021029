/**
 * Manages the state of the registration.
 *
 * @params {string} email - The new registered email.
 * @params {string} password - The password to the new registered email.
 * @params {boolean} showPassword - Show the password as text or as hidden.
 * @params {string} name - The name of the new user.
 * @params {string} firstname - The firstname of the new user.
 *
 */

import {useNavigate} from "react-router-dom";
import React, {useReducer, useState} from "react";
import {IntlMessage, MIcon, WK24Button, Wk24Form} from "../../../@WK24/core";
import SignInReducer from "../store/reducers/signin/SigninReducer";
import {SignInActionType} from "../store/actions/ActionType";
import signinFetch from "./signinFetch";

import '../css/signin.css'
import {
    notifyRegistrationEmptyFields,
    notifyRegistrationError,
    notifyRegistrationPassCheck,
    notifyRegistrationPassSecure,
    notifyRegistrationSuccess
} from "../../../@WK24/core/Notification/service/notification.service";
import {useSelector} from "react-redux";
import {Response} from "../API/response/signin"

interface SigninProps {
    value: any
}

const SignIn = (props: SigninProps) => {
    const Store = useSelector((state: any) => state)
    const respData = Response(100, '');

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [password2, setPassword2] = useState<string>('');
    const [showPassword2, setShowPassword2] = useState(false);
    const [name, setName] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    const navigate = useNavigate()

    const user = [firstname, name, email, password];
    let regUser = {email: '', password: '', firstname: '', lastname: ''};
    const [signIn, signInDispatch] = useReducer(SignInReducer, {newUser: null})

    //check if E-Mail exists
    const checkEmail = () => {
        const index = (respData).findIndex((item) => item.email === email)
        if (index !== -1) {
            notifyRegistrationError(Store);
            navigate('/login')
        } else {
            notifyRegistrationSuccess(Store);
            signinFetch(regUser).then(() => {
                setLoggedIn(true);
                localStorage.setItem("userRole", "superadmin")
                localStorage.setItem("locale", "DE")
                navigate('/step4')
            })
        }
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();

        if (email !== '' && password !== '') {
            if (password.match("^(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[0-9].*)(?=.*\\W.*).{8,}$") != null) {
                if (password === password2) {
                    signInDispatch({type: SignInActionType.REGISTER_SUCCESS, payload: {user}})
                    checkEmail();
                } else {
                    notifyRegistrationPassCheck(Store);
                }
            } else {
                notifyRegistrationPassSecure(Store);
            }
        } else {
            notifyRegistrationEmptyFields(Store);
        }
    };

    return(
        <div className="signInForm noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form.Label class="signInHeader"
                            text={IntlMessage({locale: 'de', messageId: 'signinHeader.label', Store: Store})}/>

            <Wk24Form validated={validated} Submit={handleSubmit}>
                <Wk24Form.FormGroup>
                    <Wk24Form.InputGroup>
                        <Wk24Form.FormControl
                            type={'text'}
                            name={IntlMessage({locale: 'de', messageId: 'signinFirstname.name', Store: Store})}
                            placeholder={IntlMessage({
                                locale: 'de',
                                messageId: 'signinFirstname.placeholder',
                                Store: Store
                            })}
                            onChange={(e: { target: { value: string } }) => setFirstname(e.target.value)}
                        />
                        <Wk24Form.FormControl
                            type={'text'}
                            name={IntlMessage({locale: 'de', messageId: 'signinLastname.name', Store: Store})}
                            placeholder={IntlMessage({
                                locale: 'de',
                                messageId: 'signinLastname.placeholder',
                                Store: Store
                            })}
                            onChange={(e: { target: { value: string } }) => setName(e.target.value)}
                        />
                    </Wk24Form.InputGroup>
                    <Wk24Form.FormControl
                        type={'email'}
                        name={IntlMessage({locale: 'de', messageId: 'signinMail.name', Store: Store})}
                        placeholder={IntlMessage({locale: 'de', messageId: 'signinMail.placeholder', Store: Store})}
                        onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
                    />
                    <Wk24Form.InputGroup>
                        <Wk24Form.FormControl
                            type={showPassword ? 'text' : 'password'}
                            name={IntlMessage({locale: 'de', messageId: 'signinPass.name', Store: Store})}
                            placeholder={IntlMessage({locale: 'de', messageId: 'signinPass.placeholder', Store: Store})}
                            onChange={(e: { target: { value: string } }) => {
                                setPassword(e.target.value)
                            }}
                        />
                        <WK24Button type={'button'}
                            children={showPassword ? <MIcon children="visibility_off" variant="-outlined"/> :
                                <MIcon children="visibility" variant="-outlined"/>}
                            variant={'info'}
                            onClick={() => {
                                setShowPassword((prev) => !prev)
                            }}/>
                    </Wk24Form.InputGroup>
                    <Wk24Form.InputGroup>
                        <Wk24Form.FormControl
                            type={showPassword2 ? 'text' : 'password'}
                            name={IntlMessage({locale: 'de', messageId: 'signinPass2.name', Store: Store})}
                            placeholder={IntlMessage({
                                locale: 'de',
                                messageId: 'signinPass2.placeholder',
                                Store: Store
                            })}
                            onChange={(e: { target: { value: string } }) => setPassword2(e.target.value)}
                        />
                        <WK24Button type={'button'}
                            children={showPassword2 ? <MIcon children="visibility_off" variant="-outlined"/> :
                                <MIcon children="visibility" variant="-outlined"/>}
                            variant={'info'}
                            onClick={() => {
                                setShowPassword2((prev) => !prev)
                            }}/>
                    </Wk24Form.InputGroup>
                    <WK24Button
                        children={IntlMessage({locale: 'de', messageId: 'signinButton.text', Store: Store})}
                        variant={'primary'}
                        type={'submit'}
                        onClick={() => {
                            regUser = {email: email, password: password, firstname: firstname, lastname: name};
                        }}/>
                </Wk24Form.FormGroup>
            </Wk24Form>

            <Wk24Form.FormGroup class="loginBlock">
                <Wk24Form.Label text={IntlMessage({locale: 'de', messageId: 'loginBlock.label', Store: Store})}/>
                <WK24Button
                    children={IntlMessage({locale: 'de', messageId: 'loginLink.label', Store: Store})}
                    variant={'link'}
                    onClick={() => {
                        navigate('/login')
                    }}/>
            </Wk24Form.FormGroup>

            <p className="signInInfo" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'signinInfo.label', Store: Store})
            }}/>
            <div className="chamiLogin">
                <img className="chamiIcon" src={'/chami6.png'} alt={''}/>
            </div>
            <div className="giraffeLogin">
                <img className="giraIcon" src={'/gira2.jpg'} alt={''}/>
            </div>
        </div>
    )
}
export default SignIn;
