import Card from 'react-bootstrap/Card';
import {
    CSSProperties,
    ElementType,
    JSXElementConstructor,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    ReactPortal
} from "react";
import {Wk24CardsBody} from "./Elements/Wk24CardsBody";
import {Wk24CardsFooter} from "./Elements/Wk24CardsFooter";
import {Wk24CardsHeader} from "./Elements/Wk24CardsHeader";
import {Wk24CardsImg} from "./Elements/Wk24CardsImg";
import {Wk24CardsImgOverlay} from "./Elements/Wk24CardsImgOverlay";
import {Wk24CardsLink} from "./Elements/Wk24CardsLink";
import {Wk24CardsSubtitle} from "./Elements/Wk24CardsSubtitle";
import {Wk24CardsText} from "./Elements/Wk24CardsText";
import {Wk24CardsTitle} from "./Elements/Wk24CardsTitle";
import {Wk24CardsGroup} from "./Elements/Wk24CardsGroup";

export interface Wk24CardsProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    bg?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    text?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'white' | 'muted'
    border?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    body?:boolean
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string,
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    /** Combine Element with onClick Function and Event */
    onClick?:MouseEventHandler | undefined
}

const Wk24Cards = (props:Wk24CardsProps) => {

    return(
        <>
            <Card id={props.id}  className={props.class} onClick={props.onClick} body={props.body} border={props.border} as={props.as} text={props.text} bg={props.bg} style={props.style}>
                {props.children}
            </Card>
        </>
    )
}

Wk24Cards.Body = Wk24CardsBody
Wk24Cards.Footer = Wk24CardsFooter
Wk24Cards.Header = Wk24CardsHeader
Wk24Cards.Img = Wk24CardsImg
Wk24Cards.ImgOverlay = Wk24CardsImgOverlay
Wk24Cards.Link = Wk24CardsLink
Wk24Cards.Subtitle = Wk24CardsSubtitle
Wk24Cards.Text = Wk24CardsText
Wk24Cards.Title = Wk24CardsTitle
Wk24Cards.Group = Wk24CardsGroup

export default Wk24Cards;
