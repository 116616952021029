import { AxiosResponse } from 'axios'
import axios from 'axios'
import {ApiRoot, ApiRootCDN} from "./Component/ApiRoot";

/**
 * Establishes the connection using Axios and already maps the header token and the API URL and so forth
 * @constructor
 * @version 0.0.1
 * @visibleName API
 * @author [Marcel Ugurcu](https://wum-solution.de)
 */
export const DocumentationHolder = () => {

    return(
        <>
        </>
    )
}

export const responseBody = (response: AxiosResponse) => response.data

export const configAxios = {
    headers: {
        Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('token') as string
        )}`
    }
}

export const httpClient = axios.create({
    baseURL: ApiRoot()
})

export const httpClientWithAuth = axios.create({
    baseURL: ApiRoot(),
    auth: {
        username: process.env.REACT_APP_AUTH_USERNAME as string,
        password: process.env.REACT_APP_AUTH_PASSWORD as string
    }
})

export const BasicAuth = () => {
    return btoa(`${process.env.REACT_APP_AUTH_USERNAME as string}:${process.env.REACT_APP_AUTH_PASSWORD as string}`);
}

export const httpClientToken = axios.create({
    baseURL: ApiRoot()
})

export const httpClientCDN = axios.create({
    baseURL: ApiRootCDN()
})


export const requests = {
    get: (url: string) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .get(url, configAxios)
            .then(responseBody),
    post: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .post(url, body, configAxios)
            .then(responseBody),
    put: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .put(url, body, configAxios)
            .then(responseBody),
    patch: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .patch(url, body, configAxios)
            .then(responseBody),
    delete: (url: string) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .delete(url, configAxios)
            .then(responseBody)
}

export const requestCDN = {
    get: (url: string) => httpClientCDN.get(url).then(responseBody),
    post: (url: string, body: {}) =>
        httpClientCDN.post(url, body).then(responseBody),
    put: (url: string, body: {}) =>
        httpClientCDN.put(url, body).then(responseBody),
    patch: (url: string, body: {}) =>
        httpClientCDN.patch(url, body).then(responseBody),
    delete: (url: string) => httpClientCDN.delete(url).then(responseBody)
}


