/**
 * Manages the state of the media success page.
 *
 */

import React, {useEffect, useRef, useState} from "react";
import '../css/media.css'
import {BackButton, IntlMessage, WK24Button, Wk24Form} from "../../../@WK24/core";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import axios from "axios";
import Galerie from "./Galerie";
import Wk24Modal from "../../../@WK24/core/Modal";
import {ResponseMedia} from "../../../@WK24/core/Media/API/response/media";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";
import {useSelector} from "react-redux";

interface MediaProps {
    value: any
}

const Media = (props: MediaProps) => {
    const [files, setFiles] = useState<FileList | null>();
    const filesRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'uploading', 'success', 'error'
    let MediaItems = ResponseMedia(JSON.parse(localStorage.getItem('Project') as string).projectID)
    const [Media, setMedia] = useState<any>(MediaItems);
    const Store = useSelector((state: any) => state)

    let projectID = JSON.parse(localStorage.getItem('Project') as string).projectID;

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "media/" + projectID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                console.log(result);
                setMedia((result));
            })
            .catch((error) => console.error(error));
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleUpload = async () => {
        if (!files) {
            return alert(IntlMessage({locale: 'de', messageId: 'Media.uploadError.text', Store: Store}));
        }

        setUploadStatus('uploading'); // Indicate upload progress

        try {
            const formData = new FormData();
            for(let x =0;x<files.length;x++) {
                formData.append(`file${x}`, files[x]);
            }

            const response = await axios.post(ApiRoot() + `media/${JSON.parse(localStorage.getItem('Project') as string).projectID}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Basic " + BasicAuth()
                },
                onUploadProgress: (progressEvent: any) => {
                    const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadStatus(`Uploading: ${uploadPercentage}%`);
                },
            });

            console.log(response.data); // Handle successful upload response
            setUploadStatus('success');
        } catch (error) {
            console.error(error);
            setUploadStatus('error');
            notifyError(Store)
        } finally {
            setFiles(null); // Clear selected file after upload
            notifySave(Store);
        }

        /** get new media data from database*/
        getData();

    }
    const saveImages = () => {

        handleUpload()

        /*setUploadStatus('uploading');

        const formData = new FormData();
        for (const file of files) {
            formData.append('file', files);
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: "Basic " + BasicAuth()
            },
            body: formData,
            onUploadProgress: (progressEvent:any) => {
                const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadStatus(`Uploading: ${uploadPercentage}%`);
            }
        }
        fetch(ApiRoot() + "mediaBlaBla", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                setUploadStatus('success');
            })
            .catch((error) => {
                console.error(error);
                setUploadStatus('error');
            });*/
    }

    /** Convert an image to base64 string */
    const toDataURL = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    }

    /** Set the image list in state and to localstorage (base64) */
    const handleFileList = async (e: any) => {
        const fileList = e.target.files;
        const imageList = [];

        if (fileList !== null) {
            for (let i = 0; i < fileList.length; i++) {
                let base64img = await toDataURL(fileList[i]);
                imageList.push(base64img);
            }
        }

        setFiles(fileList);
        localStorage.setItem('ImageList', JSON.stringify(imageList));
        localStorage.setItem('FileList', JSON.stringify(files));
    };

    /** Clear the selected files inputs */
    const ClearFiles = () => {
        if (filesRef.current !== null) {
            (filesRef.current as HTMLInputElement).value = '';
            setFiles(null);
            localStorage.setItem('ImageList', JSON.stringify([]));
        }
    }

    /** Modal Image Carousel */
    const ImageModal = (props: any) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={'wkmodal'}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Carousel fade slide={false}>
                        {files &&
                            [...files].map((file) => (
                                <Carousel.Item>
                                    <img src={URL.createObjectURL(file)} alt=""/>
                                </Carousel.Item>
                            ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        );
    }

    /** Preview of the thumbnails from the selected images */
    const ThumbPreview = () => {
        let thumbSizeWeight = '200px';
        let thumbSizeHeight = '100px';

        return (
            <div className="thumbArea">
                {files && <Wk24Form.Label
                    text={IntlMessage({locale: 'de', messageId: 'Step4.thumbImage.title', Store: Store})}
                    class={'mt-3 mr-3'}/>}
                {files &&
                    [...files].map((file) => (
                        <img src={URL.createObjectURL(file)} alt=""
                             style={{maxWidth: thumbSizeWeight, height: thumbSizeHeight}}
                             onClick={() => {
                                 setModalShow(true)
                             }}
                        />
                    ))}
                <ImageModal show={modalShow} onHide={() => setModalShow(false)}/>
            </div>
        )
    }

    return (
        <div className="Media bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'Media.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Media.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Media.header.label', Store: Store})
            }}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <Wk24Form.InputGroup class="form-group mt-3 mb 2 imagegroup">
                    <Wk24Form.Label
                        text={IntlMessage({locale: 'de', messageId: 'Media.images.title', Store: Store})}
                        class={'mt-3 mr-3 imagelabel'}
                    />
                    <Form.Control type={'file'}
                                  name={'vat'}
                                  ref={filesRef}
                                  placeholder={''}
                                  accept="image/*"
                                  multiple={true}
                                  onChange={handleFileList}/>
                    <WK24Button type={'button'}
                                variant={'danger'}
                                onClick={ClearFiles}>{'X'} </WK24Button>
                </Wk24Form.InputGroup>
            </Wk24Form>
            {ThumbPreview()}
            <BackButton className={'mr-3'}/>
            <Wk24Modal children={Galerie(Media)} Button={{
                Text: IntlMessage({locale: 'de', messageId: 'Media.openBtn.text', Store: Store}),
                Class: ' mr-3'
            }} fullscreen={true}
                       title={'Galerie'}/>
            <WK24Button
                children={IntlMessage({locale: 'de', messageId: 'Media.uploadBtn.text', Store: Store})}
                class={''} variant={'primary'} type={'button'} onClick={() => saveImages()}/>
            {uploadStatus === 'success' &&
                <p>{IntlMessage({locale: 'de', messageId: 'Media.uploadSuccess.text', Store: Store})}</p>}
            {uploadStatus === 'error' &&
                <p>{IntlMessage({locale: 'de', messageId: 'Media.uploadNotSuccess.text', Store: Store})}</p>}

            <div className="giraffeMedien">
                <img className="giraIcon" src={'/gira6.png'} alt={''}/>
            </div>
        </div>
    )
}

export default Media;


