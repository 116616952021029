import Dashboard from "./Pages";


export const DashbaordConfig  = (props:{value: any}) => {
    return [
        {
            path: '/',
            elements: <Dashboard value={props.value} />,
            roles: [
                'superadmin',
                'admin'
            ]
        }
    ]
}

export const DashbaordMenuConfig = (value: any) => {
    return [
        {
            header: 'Dashboard',
            icon: 'home',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/',
        }
    ]
}
