import React, {useRef,useState,useEffect} from "react";
import {MIcon, WK24Button} from "../../index";
import {HandleClick} from "../const/handleClick";
import {ToolbarConfig} from "../../WYSIWYG/interface/ToolbarConfig";

interface DraggableItem {
    content: string;
    index: number;
}

const Navigator = (props:{ranges:any,elRef:any,SetRange:any,config:ToolbarConfig[]}) => {
    const [navigatorList, setNavigatorList] = useState<DraggableItem[]>([]);
    const [open, setOpen] = useState(false);
    const deleteElement = (id:any,index:any) => {

        let element = (document.getElementById(id) as HTMLDivElement).parentElement
        if(element !== null) {
            element.remove();
        }
        const newList = [...navigatorList];
        newList.splice(index,1);
        setNavigatorList(newList);
        props.SetRange(props.ranges - 1)

    }
    const NavigatorRef = useRef<HTMLDivElement>(null);
    const expand = (mode:string) => {
        if(NavigatorRef.current !== null) {
            if(mode === 'more'){
                NavigatorRef.current.style.display = 'block'
                setOpen(true)
            } else if(mode === 'less'){
                NavigatorRef.current.style.display = 'none'
                setOpen(false)
            }
        }
    }


    useEffect(() => {
        const newList: DraggableItem[] = [];
        for (let r = 0; r < props.ranges; r++) {
            newList.push({ content: `Webbuilder${r}`, index: r }); // Use r + 1 for consistent indexing
        }
        setNavigatorList(newList);
    }, [props.ranges]); // Re-run useEffect when range changes

    const dragItemRef = useRef<number | null>(null);
    const dragOverItemRef = useRef<number | null>(null);

    const handleDragStart = (e:any, index: number) => {
        dragItemRef.current = index;
    };

    const handleDragEnter = (e: any, index: number) => {
        dragOverItemRef.current = index;
    };

    const handleDrop = () => {
        if (dragItemRef.current !== null && dragOverItemRef.current !== null) {
            const newList = [...navigatorList];
            const draggedItem = newList.splice(dragItemRef.current, 1)[0];
            newList.splice(dragOverItemRef.current, 0, draggedItem);
            setNavigatorList(newList);
            dragItemRef.current = null;
            dragOverItemRef.current = null;
            for(let n=0;n<newList.length;n++)
            {
                if(n === newList.length-1) {
                    console.log('Last')
                } else {
                    // @ts-ignore
                    document.getElementById(newList[n].content).parentNode.parentNode.appendChild(document.getElementById(newList[n+1].content).parentNode)
                }

            }
        }
    };



    return (
        <>
            <h3>Navigator
                {open === false
                ?
                    <MIcon newMaterial={true} variant={'-outlined'} onClick={() => expand('more')}>
                        expand_more
                    </MIcon>
                    :
                    <MIcon newMaterial={true} variant={'-outlined'} onClick={() => expand('less')}>
                        expand_less
                    </MIcon>
                }
            </h3>
            <div ref={NavigatorRef} style={{display: 'none',height: "80vh",overflowY: "scroll"}}>
            {navigatorList.map((item, index) => (
                <div
                    key={index}
                    draggable
                    style={{backgroundColor: '#cccaca', textAlign: 'center'}}
                    className={'p-3 border rounded m-2'}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragEnter={(e) => handleDragEnter(e, index)}
                    onDragEnd={handleDrop}
                >
                    {item.content}
                    <span className="material-icons-outlined float-end" onClick={() => deleteElement(item.content, index)}>
                        delete_forever
                    </span>

                </div>
            ))}
                <WK24Button children={<><span className="material-icons-outlined">
                    add
                </span></>} class={'p-3 rounded-circle'} variant={'primary'} onClick={() => HandleClick(props.elRef,props.ranges,props.SetRange,props.config)}
                            type={'button'}/>
            </div>
        </>
    );

}

export default Navigator;
