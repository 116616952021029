import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";

interface SigninFetchProps {
    email: string,
    password: string,
    firstname: string,
    lastname: string
}

async function signinFetch(props: SigninFetchProps) {
    const raw = JSON.stringify({
        "email": props.email,
        "password": props.password,
        "firstname": props.firstname,
        "lastname": props.lastname
    });

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + BasicAuth()
        },
        body: raw
    };

    await fetch(ApiRoot() + "users", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            console.log(result);
            result = JSON.parse(result);
            localStorage.setItem("User", JSON.stringify(result))
        })
        .catch((error) => console.error(error));

}

export default signinFetch;