/**
 * Manages the state of the customer data.
 *
 * @params {IAccountData} accountData - The state of the customer data.
 * @params {boolean} checkB2B - The state of check B2B.
 * @params {boolean} checkCustomerData - The state of check the necessary customer data.
 *
 */

import {
    BackButton,
    IntlMessage,
    MIcon,
    Store,
    WK24Button,
    Wk24Cards,
    WK24Col,
    Wk24Form,
    WK24Row
} from "../../../@WK24/core";
import React, {useState} from "react";
import {IAccountData, IAddress, initAccountData} from "../interface";
import {AccountProps} from "../interface/AccountProps.interface";
import '../css/account.css'
import Address from "../Elements/Address";
import WK24InputGroupText from "../../../@WK24/core/Form/Elements/InputGroupText";
import {useNavigate} from "react-router-dom";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

const exractCustomer = (value:any) => {
    if (value !== 'visibleNon') {
    let localCustomer = JSON.parse(localStorage.getItem('Customer') as string)
    localCustomer.addresses = [JSON.parse(localCustomer.address)]
    localCustomer.addresses[0].zip = [JSON.parse(localCustomer.address).postCode]
    localCustomer.addresses[0].city = [JSON.parse(localCustomer.address).location]
    localCustomer.lastname = localCustomer.name
    localCustomer.birthday = localCustomer.dob
    localCustomer.director = localCustomer.gf
    localCustomer.authorized = localCustomer.representative
    localCustomer.contentResp = localCustomer.responsible_for_content
    localCustomer.settlement = localCustomer.arbitration_settlement
    localCustomer.coworker = localCustomer.employeesNumber
    localCustomer.requiredVat = localCustomer.subjectToVat
    localCustomer.vat = localCustomer.taxID
    localCustomer.commercial_register = JSON.parse(localCustomer.commercialRegister)
    localCustomer.commercial_register.court = localCustomer.commercial_register.registryCourt

    return localCustomer
    } else{
        return typeof(localStorage.WK2024AccountData)!=='undefined' ? JSON.parse(localStorage.WK2024AccountData) : initAccountData;
    }

}

const Account = (props: AccountProps) => {
    const [validated, setValidated] = useState(false);
    const initData =  typeof(localStorage.getItem('Customer'))!=='undefined' ? exractCustomer(props.value) : initAccountData;
    const [accountData, setAccountData] = useState<IAccountData>(initData);
    const [checkB2B, setCheckB2B] = useState<boolean>(false);
    const [checkCustomerData, setCheckCustomerData] = useState<boolean>(false);
    const navigate = useNavigate()
    const backgroundImage = 'url(/portalbg.jpg)';

    const user = JSON.parse(localStorage.getItem('User') as string);
    let localCustomer;
    let customerID: any;
    if (props.value !== 'visibleNon') {
        localCustomer = JSON.parse(localStorage.getItem('Customer') as string);
        customerID = localCustomer.customerID;
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        event.preventDefault();
    };

    /** save customer data */
    const saveData = () => {
        let back = [{
            "customerID": customerID,
            "company": accountData.company,
            "name": accountData.lastname,
            "firstname": accountData.firstname,
            "dob": accountData.birthday,
            "phone": accountData.phone,
            "userID": user.userID,
            "gf": accountData.director,
            "representative": accountData.authorized,
            "responsible_for_content": accountData.contentResp,
            "column_name": null,
            "arbitration_settlement": accountData.settlement,
            "smallBusiness": accountData.smallBusiness,
            "freelancer": accountData.freelancer,
            "employeesNumber": accountData.coworker,
            "subjectToVat": accountData.requiredVat,
            "taxID": accountData.vat,
            "commercialRegister": {
                "registryCourt": accountData.commercial_register.court,
                "department": accountData.commercial_register.department,
                "number": accountData.commercial_register.number
            },
            "address": {
                "street": accountData.addresses[0].street,
                "postCode": accountData.addresses[0].zip,
                "location": accountData.addresses[0].city
            }
        }]
        //console.log(back);
         const requestOptions = {
             method: "POST",
             headers: {
                 "Content-Type":"application/json",
                 Authorization:"Basic "+ BasicAuth()
             },
             body: JSON.stringify(back)
         };



         fetch(ApiRoot() + "customer", requestOptions)
             .then((response) => response.text())
             .then((result:any) => {
                 let backJSON:any = back[0]
                 backJSON.address = JSON.stringify( backJSON.address)
                 backJSON.commercialRegister = JSON.stringify( backJSON.commercialRegister)
                 localStorage.setItem('Customer', JSON.stringify(backJSON))
                 notifySave(Store);
                 //console.log(result)
             })
             .catch((error) => {
                 console.error(error);
                 notifyError(Store);
             });
    }

    const save = (data:IAccountData) => {
        setAccountData(data)
        localStorage.setItem('WK2024AccountData', JSON.stringify(data));
    }
    const textInputChange = (e: any) => {
        save({...accountData, [e.target.name]: e.target.value})
    }
    const NumberInputChange = (e: any) => {
        save({...accountData, [e.target.name]: Number(e.target.value)})
    }

    /** check necessary customer data */
    const checkData = () => {
        if ((accountData.lastname !== '') && (accountData.firstname !== '') && (accountData.company !== '') && (accountData.birthday !== '')
            && (accountData.addresses[0].street !== '') && (accountData.addresses[0].zip !== '') && (accountData.addresses[0].city !== '')) {
            setCheckCustomerData(true);
            navigate('/step7/');
        } else {
            setCheckCustomerData(false);
        }
    }

    const SmallBusiness = (accountData.freelancer === 1) || (accountData.smallBusiness === 1 )

    return (
        <div
            className={props.value !== 'visibleNon' ? "AccountForm pb-5 bpage" : "AccountForm backendStep noneSidebar"}>
            {props.value === 'visibleNon' ?
                <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/> : ''}
            <h1>{IntlMessage({locale: 'de', messageId: 'Account.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Account.subTitle',
                Store: Store
            })}</small></h1>
            {props.value === 'visibleNon' ?
                <p className="mb-4" dangerouslySetInnerHTML={{
                    __html: IntlMessage({locale: 'de', messageId: 'Account.header.label', Store: Store})
                }}/> : <p></p>}

            <Wk24Form validated={validated} Submit={handleSubmit}>
                {props.value === 'visibleNon' ?
                    <div className="giraffeStep6">
                        <img className="giraIcon" src={'/gira5.jpg'} alt={''}/>
                    </div> :
                    <div className="giraffeStep6">
                        <img className="giraIcon" src={'/gira4.png'} alt={''}/>
                    </div>
                }
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <Wk24Cards style={{width: '41.5rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="contacts" variant="fill"/> {IntlMessage({
                            messageId: 'header.personal_data',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.InputGroup>
                                <Wk24Form.InputGroupText
                                    text={IntlMessage({
                                        messageId: 'firstname.label',
                                        Store: Store
                                    }) + ' ' + IntlMessage({messageId: 'lastname.label', Store: Store})}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    required
                                    name={'firstname'}
                                    defaultValue={accountData?.firstname}
                                    placeholder={IntlMessage({messageId: 'firstname.placeholder', Store: Store})}
                                    onChange={textInputChange}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={'lastname'}
                                    required
                                    defaultValue={accountData?.lastname}
                                    placeholder={IntlMessage({messageId: 'lastname.placeholder', Store: Store})}
                                    onChange={textInputChange}
                                />
                            </Wk24Form.InputGroup>

                            <Wk24Form.InputGroup>
                                <WK24InputGroupText text={IntlMessage({messageId: 'birthday.text', Store: Store})}/>
                                <Wk24Form.FormControl
                                    type={'date'}
                                    required
                                    defaultValue={accountData?.birthday}
                                    name={'birthday'}
                                    onChange={textInputChange}
                                />
                            </Wk24Form.InputGroup>

                            <Wk24Form.InputGroup>
                                <WK24InputGroupText text={IntlMessage({messageId: 'mail.text', Store: Store})}/>
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={'email'}
                                    required
                                    defaultValue={user?.email}
                                    placeholder={IntlMessage({messageId: 'Account.mail.placeholder', Store: Store})}
                                    onChange={textInputChange}
                                />
                            </Wk24Form.InputGroup>
                            <Wk24Form.InputGroup>
                                <WK24InputGroupText text={IntlMessage({messageId: 'phone.text', Store: Store})}/>
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={'phone'}
                                    defaultValue={accountData?.phone}
                                    placeholder={IntlMessage({messageId: 'phone.placeholder', Store: Store})}
                                    onChange={textInputChange}
                                />
                            </Wk24Form.InputGroup>
                            <Address value={accountData?.addresses[0]}
                                     onChange={(data: IAddress) => {
                                let AC = {...accountData}
                                AC.addresses[0] = data;
                                setAccountData(AC)
                            }} onChangeData={accountData}/>
                        </Wk24Cards.Body>
                    </Wk24Cards>
                    <Wk24Cards style={{width: '41.5rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="apartment" variant="fill"/> {IntlMessage({
                            messageId: 'header.company_data',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <Wk24Form.InputGroup>
                                <Wk24Form.InputGroupText
                                    text={IntlMessage({messageId: 'company.label', Store: Store})}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={'company'}
                                    required
                                    placeholder={IntlMessage({messageId: 'company.placeholder', Store: Store})}
                                    defaultValue={accountData?.company}
                                    onChange={textInputChange}/>
                            </Wk24Form.InputGroup>
                            <WK24Row>
                                <WK24Col sm={12} xl={6}>
                                    <Wk24Form.InputGroup class="labelBeforeChekbox mb-2">
                                        <Wk24Form.InputGroupText
                                            class={' '}
                                            text={IntlMessage({
                                                messageId: 'smallBusiness.label',
                                                Store: Store
                                            })}
                                        />
                                        <Wk24Form.Select
                                            items={[
                                                {value: 0, text: 'Nein'},
                                                {value: 1, text: 'Ja'}
                                            ]}
                                            defaultValue={accountData.smallBusiness}
                                            name="smallBusiness"
                                            onChange={NumberInputChange}
                                            class="checkboxAfterInputGroupText"
                                        />
                                    </Wk24Form.InputGroup>
                                </WK24Col>
                                <WK24Col sm={12} xl={6}>
                                    <Wk24Form.InputGroup class="labelBeforeChekbox mb-2">
                                        <Wk24Form.InputGroupText
                                            class={' '}
                                            text={IntlMessage({
                                                messageId: 'freelancer.label',
                                                Store: Store
                                            })}
                                        />
                                        <Wk24Form.Select
                                            items={[
                                                {value: 0, text: 'Nein'},
                                                {value: 1, text: 'Ja'}
                                            ]}
                                            defaultValue={accountData.freelancer}
                                            name="freelancer"
                                            onChange={NumberInputChange}
                                            class="checkboxAfterInputGroupText"
                                        />
                                    </Wk24Form.InputGroup>
                                </WK24Col>
                                <WK24Col sm={12} xl={6}>
                                    <Wk24Form.InputGroup class="form-gropup ">
                                        <Wk24Form.InputGroupText
                                            text={IntlMessage({messageId: 'count_coworker.label', Store: Store})}
                                            InfoButton={{
                                                display: true,
                                                text: IntlMessage({
                                                    messageId: 'count_coworker.infoLabel',
                                                    Store: Store
                                                })
                                            }}
                                        />
                                        <Wk24Form.Select
                                            items={[
                                                {value: 0, text: '0'},
                                                {value: 5, text: '< 10'},
                                                {value: 10, text: '>= 10'}
                                            ]}
                                            defaultValue={accountData.coworker}
                                            name="coworker"
                                            onChange={NumberInputChange}
                                        />
                                    </Wk24Form.InputGroup>
                                </WK24Col>
                                <WK24Col sm={12} xl={6}>
                                    <Wk24Form.InputGroup>
                                        <Wk24Form.InputGroupText
                                            class={' '}
                                            text={IntlMessage({
                                                messageId: 'VATrequired.label',
                                                Store: Store
                                            })}
                                        />
                                        <Wk24Form.Select
                                            items={[
                                                {value: 1, text: 'Ja'},
                                                {value: 0, text: 'Nein'},
                                            ]}
                                            defaultValue={accountData.requiredVat}
                                            name="requiredVat"
                                            onChange={NumberInputChange}
                                            class="checkboxAfterInputGroupText"
                                        />
                                    </Wk24Form.InputGroup>
                                </WK24Col>
                            </WK24Row>
                            <Wk24Form.InputGroup class="form-gropup mb 2">
                                <Wk24Form.InputGroupText
                                    text={IntlMessage({messageId: 'VAT.label', Store: Store})}
                                />
                                <Wk24Form.FormControl
                                    type={'text'}
                                    name={'vat'} placeholder={IntlMessage({messageId: 'VAT.placeholder', Store: Store})}
                                    defaultValue={accountData?.vat}
                                    onChange={textInputChange}
                                />
                            </Wk24Form.InputGroup>
                            <Wk24Form.InputGroup class="form-gropup mt-2 mb 2">
                                <Wk24Form.InputGroupText
                                    text={IntlMessage({messageId: 'commercial_register.label', Store: Store})}
                                />
                                <Wk24Form.FloatingLabel
                                    text={IntlMessage({
                                        messageId: 'commercial_register.court.label',
                                        Store: Store
                                    })} class="mb-3">
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        name={'commercial_register'}
                                        id="court"
                                        defaultValue={accountData.commercial_register?.court}
                                        onChange={(e: React.ChangeEvent<Element>) => {
                                            save({
                                                ...accountData,
                                                commercial_register: {
                                                    ...accountData.commercial_register,
                                                    court: (e.target as HTMLInputElement).value
                                                }
                                            })
                                        }}
                                    />
                                </Wk24Form.FloatingLabel>
                                <Wk24Form.FloatingLabel
                                    text={IntlMessage({
                                        messageId: 'commercial_register.department.label',
                                        Store: Store
                                    })}>
                                    <Wk24Form.Select
                                        items={[
                                            {value: '', text: ''},
                                            {value: 'HRA', text: 'HRA'},
                                            {value: 'HRB', text: 'HRB'}
                                        ]}
                                        defaultValue={accountData.commercial_register?.department}
                                        onChange={(e: React.ChangeEvent<Element>) => {
                                            save({
                                                ...accountData,
                                                commercial_register: {
                                                    ...accountData.commercial_register,
                                                    department: (e.target as HTMLInputElement).value
                                                }
                                            })
                                        }}
                                        class=""
                                    />
                                </Wk24Form.FloatingLabel>
                                <Wk24Form.FloatingLabel
                                    text={IntlMessage({
                                        messageId: 'commercial_register.number.label',
                                        Store: Store
                                    })}>
                                    <Wk24Form.FormControl
                                        type={'text'}
                                        name={'commercial_register'}
                                        defaultValue={accountData.commercial_register?.number}
                                        onChange={(e: React.ChangeEvent<Element>) => {
                                            save({
                                                ...accountData,
                                                commercial_register: {
                                                    ...accountData.commercial_register,
                                                    number: (e.target as HTMLInputElement).value
                                                }
                                            })
                                        }}
                                    />
                                </Wk24Form.FloatingLabel>
                            </Wk24Form.InputGroup>

                            {props.value === 'visibleNon' ?
                                <Wk24Form.Checks type={'checkbox'}
                                                 label={IntlMessage({
                                                     locale: 'de',
                                                     messageId: 'Account.b2bTrue.label',
                                                     Store: Store
                                                 })}
                                                 value={IntlMessage({
                                                     locale: 'de',
                                                     messageId: 'Account.b2bTrue.label',
                                                     Store: Store
                                                 })}
                                                 checked={checkB2B}
                                                 onChange={(e: { target: { value: string, } }) => {
                                                     setCheckB2B((prev) => !prev);
                                                 }}/>
                                : ''}
                        </Wk24Cards.Body>
                    </Wk24Cards>
                </div>
                <div className="mt-3 col">
                    {props.value === 'visibleNon' ?
                        <WK24Button
                            children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                            class={'float-end'} disabled={!checkB2B} variant={'primary'}
                            onClick={() => checkData()}
                        />
                        : <>
                            <BackButton className={'mr-3'}/>
                            <WK24Button children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                        variant={'primary'}
                                        type={'submit'} onClick={() => saveData()}/>
                        </>
                    }
                </div>
            </Wk24Form>
        </div>
    )
}
export default Account;
