import {signinApiType} from "../request/signin.interface";
import {signin} from "../request/signin";
import { useEffect, useState } from 'react'

export const Response = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<signinApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await signin
                .getPosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}
