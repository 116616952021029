import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Galerie = (media:any) => {

    let ImageHolder = [];

    for (let m = 0; m < media.length; m++) {
        ImageHolder.push(
            <Col key={m} xs={12} md={6} lg={2} className={'mb-3'}>
                <Card>
                    <img src={media[m].url} alt={media[m].file}/>

                </Card>
            </Col>
        )
    }

    return(
        <>
            <Row xs={1} md={2} lg={3}>
                {ImageHolder}
            </Row>
        </>
    )
}

export default Galerie;