import Template1 from "../../templates/Pages";
import Webbuilder from "../../../@WK24/core/Webbuilder";
import {handleSubmit} from "../components/handleSubmit";
import React, {useEffect, useState} from "react";
import {IntlMessage, Store, WK24Button, Wk24Form} from "../../../@WK24/core";
import SettingsEditor from "../../../components/editor/Settings/SettingsEditorNew";
import {useNavigate} from "react-router-dom";


const EditorTmp1 = () => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        let ElementHolder = document.getElementById('ElementHolder') as HTMLDivElement
        if (ElementHolder !== null) {
            const elements = ElementHolder.querySelectorAll(".tmpheader")
            const HeaderHolder = document.getElementById('HeaderHolder') as HTMLDivElement
            if (HeaderHolder !== null && elements !== null && elements !== undefined && elements.length > 0) {
                let tmpHeader = elements[0] as HTMLDivElement
                HeaderHolder.appendChild(tmpHeader)
            }
            const elementsfooter = ElementHolder.querySelectorAll(".tmpfooter")
            const FooterHolder = document.getElementById('FooterHolder') as HTMLDivElement
            if (FooterHolder !== null && elementsfooter !== null && elementsfooter !== undefined && elementsfooter.length > 0) {
                let tmpFooter = elementsfooter[0] as HTMLDivElement
                FooterHolder.appendChild(tmpFooter)
            }

            let headerTitle = document.getElementsByClassName('headlineHeader')[0] as HTMLParagraphElement
            if (headerTitle) {
                let headerTitleInput = document.getElementById('headerTitle') as HTMLInputElement
                if (headerTitleInput) {
                    headerTitleInput.value = headerTitle.innerText
                }
            }
            let sublineHeader = document.getElementsByClassName('sublineHeader')[0] as HTMLParagraphElement
            if (sublineHeader) {
                let sublineHeaderInput = document.getElementById('sublineHeader') as HTMLTextAreaElement
                if (sublineHeaderInput) {
                    sublineHeaderInput.value = sublineHeader.innerText
                }
            }

            let ButtonHeader = document.getElementsByClassName('ButtonHeader')[0] as HTMLAnchorElement
            if (ButtonHeader) {
                let buttonText = document.getElementById('buttonText') as HTMLInputElement
                let buttonLink = document.getElementById('buttonLink') as HTMLInputElement
                if (ButtonHeader) {
                    buttonText.value = ButtonHeader.innerText
                    buttonLink.value = ButtonHeader.href
                }
            }

            let emailFooter = document.getElementsByClassName('emailFooter')[0] as HTMLAnchorElement
            if (emailFooter) {
                let buttonText = document.getElementById('emailFooter') as HTMLInputElement
                if (emailFooter) {
                    buttonText.value = emailFooter.innerText
                }
            }

            let phoneFooter = document.getElementsByClassName('phoneFooter')[0] as HTMLAnchorElement
            if (emailFooter) {
                let buttonText = document.getElementById('phoneFooter') as HTMLInputElement
                if (phoneFooter) {
                    buttonText.value = phoneFooter.innerText
                }
            }
        }

    }, []); // Re-run useEffect when range changes

    return (
        <div className="bigStep noneSidebar">
            <Wk24Form validated={validated} Submit={(e) => handleSubmit(e, setValidated, navigate)}>
                <Wk24Form.Label class="header text-left"
                                text={IntlMessage({locale: 'de', messageId: 'webuilder.title', Store: Store})}/>
                <p className="text-left mt-3 stepAbstand" dangerouslySetInnerHTML={{
                    __html: IntlMessage({locale: 'de', messageId: 'webuilder.description', Store: Store})
                }}/>
                <Webbuilder

                    config={[]}
                    SettingsEditor={SettingsEditor}
                    childs={<Template1 value={JSON.parse(localStorage.getItem('ColorSet') as string)}
                                       pages={JSON.parse(localStorage.getItem('Pages') as string)}
                                       logo={JSON.parse(localStorage.getItem('Project') as string)}/>}/>
                <WK24Button children={"weiter"} class={'p-3 editorPrev float-end'} variant={'primary'}
                            type={'submit'}/>
            </Wk24Form>
            <div className="chamiEditor">
                <img className="chamiIcon" src={'/chami3-2.png'} alt={''}/>
            </div>
        </div>
    )
}

export default EditorTmp1;
