import Nav from 'react-bootstrap/Nav';
import SideBarItem from "./SideBarItem";
import SideBarDropdown from "./SideBarDropdown";
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react';

/**
 * @description test
 * @param children test
 */
export interface SideBarProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}

/**
 * Initialize Sidebar<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <SideBar>
 *     ...
 * </SideBar>
 * ```
 * @constructor
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @param props
 */
const SideBar = (props: SideBarProps) => {
    return <Nav className={'flex-column'}>{props.children}</Nav>
}

SideBar.Dropdown = SideBarDropdown
SideBar.Item = SideBarItem

export default SideBar
