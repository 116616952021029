import { RegisterRoute } from '../../../Register/RegisterRoute'

interface RoutingConfigProps{
        value:any
}


/**
 *
 * @constructor
 * @param props
 */
const RoutingConfig = (props:RoutingConfigProps) => {

        return [...RegisterRoute(props.value)]

}

export default RoutingConfig
