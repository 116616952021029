import Login from "./Pages";


export const LoginConfig  = (value: any) => {
    return [
        {
            path: '/login/',
            elements: <Login value={''} />,
            roles: null
        }
    ]
}

export const LoginMenuConfig = (value: any) => {
    return [
        {
            header: 'Login',
            icon: 'login',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/login/',
        }
    ]
}
