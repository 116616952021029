import {configureStore} from '@reduxjs/toolkit'
import LocaleReducer from '../@WK24/core/IntlMessage/localization/store/Reducers/Locale/LocaleReducer'
import LoginReducer from "../modules/login/store/reducers/login/LoginReducer";
import SignInReducer from "../modules/signin/store/reducers/signin/SigninReducer";
import StepReducer from "../modules/Step/store/reducers/step/StepReducer";

const store = configureStore({
    reducer: {
        locale: LocaleReducer,
        userLogin: LoginReducer,
        userSignIn: SignInReducer,
        steps: StepReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
