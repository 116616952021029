
import {InfoButton, IntlMessage, MIcon, Store, Wk24Form} from "../../../@WK24/core";
import React from "react";
import {HeaderTitle} from "./const/HeaderTitle";
import {SublineTitle} from "./const/SublineTitle";
import {ButtonTitle} from "./const/ButtonTitle";
import {ButtonLink} from "./const/ButtonLink";
import {emailFooter} from "./const/emailFooter";
import {phoneFooter} from "./const/phoneFooter";



const SettingsEditorNew = ({ expand,open,SeoRef }: { expand:any,open:any,SeoRef:any}):JSX.Element => {

    return(
        <>
            <h3>Einstellungen
                {open === false
                    ?
                    <MIcon newMaterial={true} variant={'-outlined'} onClick={() => expand('more')}>
                        expand_more
                    </MIcon>
                    :
                    <MIcon newMaterial={true} variant={'-outlined'} onClick={() => expand('less')}>
                        expand_less
                    </MIcon>
                }
            </h3>
            <hr/>
            <div ref={SeoRef} style={{display: 'none'}}>
                <h4>Header</h4>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Header Title'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.title', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'headerTitle'}
                        name={'HeaderTitle'}
                        placeholder={''}
                        onChange={(e)=>HeaderTitle(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Header Description'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.subtitle', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'sublineHeader'}
                        name={'sublineHeader'}
                        placeholder={''}
                        as={'textarea'}
                        rows={6}
                        onChange={(e)=>SublineTitle(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Button Text'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.buttonText', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'buttonText'}
                        name={'buttonText'}
                        placeholder={''}
                        onChange={(e)=>ButtonTitle(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Button link'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.buttonLink', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'buttonLink'}
                        name={'buttonLink'}
                        placeholder={''}
                        onChange={(e)=>ButtonLink(e)}
                    />
                </Wk24Form.FormGroup>
                <hr/>
                <h4>Footer</h4>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Email'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.email', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'emailFooter'}
                        name={'emailFooter'}
                        placeholder={''}
                        onChange={(e)=>emailFooter(e)}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Telefon'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.phone', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        id={'phoneFooter'}
                        name={'phoneFooter'}
                        placeholder={''}
                        onChange={(e)=>phoneFooter(e)}
                    />
                </Wk24Form.FormGroup>
                <hr/>
                <h4>Seo <InfoButton
                    text={'Suchmaschinenoptimierung'}
                    icon={'help'}
                /></h4>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Title'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.seoTitle', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteTitle'}
                        placeholder={''}

                    />
                </Wk24Form.FormGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'Description'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.seoDescription', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'metaDescription'}
                        placeholder={''}
                        as={'textarea'}
                    />
                </Wk24Form.FormGroup>
                <hr/>
                <h4>Open Graph Angaben<InfoButton
                    text={'Hier kannst du angeben, wie die Seitenvorschau aussehen soll, wenn die Seite auf Social Media geteilt wird. Wenn du nichts einträgst, werden Standardeinträge übermittelt. '}
                    icon={'help'}
                /></h4>
                <Wk24Form.InputGroup class="form-gropup mt-2">
                    <Wk24Form.InputGroupText
                        text={'og:type'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:type', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGtype'}
                        placeholder={''}

                    />
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup class="form-gropup mt-2">
                    <Wk24Form.InputGroupText
                        text={'og:image'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:image', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGimage'}
                        placeholder={''}

                    />
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup class="form-gropup mt-2">
                    <Wk24Form.InputGroupText
                        text={'og:locale'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:locale', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGlocale'}
                        placeholder={''}

                    />
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup class="form-gropup mt-2">
                    <Wk24Form.InputGroupText
                        text={'og:site_name'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:site_name', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGsite_name'}
                        placeholder={''}

                    />
                </Wk24Form.InputGroup>
                <Wk24Form.InputGroup class="form-gropup mt-2">
                    <Wk24Form.InputGroupText
                        text={'og:title'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:title', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGtitle'}
                        placeholder={''}

                    />
                </Wk24Form.InputGroup>
                <Wk24Form.FormGroup class="form-gropup mt-2">
                    <Wk24Form.Label
                        text={'og:description'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:description', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGdescription'}
                        placeholder={''}
                        as={'textarea'}
                    />
                </Wk24Form.FormGroup>
                <Wk24Form.InputGroup class="form-gropup mt-2">
                    <Wk24Form.InputGroupText
                        text={'og:url'}
                        InfoButton={{
                            display: true,
                            text: IntlMessage({messageId: 'settings.webuilder.header.og:url', Store: Store})
                        }}
                    />
                    <Wk24Form.FormControl
                        type={'text'}
                        name={'siteOGurl'}
                        placeholder={''}

                    />
                </Wk24Form.InputGroup>
            </div>
        </>
    )
}

export default SettingsEditorNew;
