import {CSSProperties, JSXElementConstructor, MouseEventHandler, ReactElement, ReactNode, ReactPortal} from "react";

interface Index {
    className?:string,
    style?: CSSProperties | undefined,
    id?:string
    newMaterial?:boolean
    variant?: 'fill' | '-outlined' | '-rounded' | '-sharp' | undefined
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Combine Element with onClick Function and Event */
    onClick?:MouseEventHandler | undefined
}

const MIcon = (props:Index) => {

    if (props.newMaterial === true) {
        return (
            <>
        <span onClick={props.onClick} className={"material-symbols"+(props.variant !== undefined ? props.variant === 'fill' ? '-outlined' : props.variant : '-outlined')+ ' ' + (props.className !== undefined ? props.className : '')} style={props.style} id={props.id}>
                {props.children}
        </span>
            </>
        )
    } else {
        return (
            <>
        <span className={"material-icons"+(props.variant !== undefined ? props.variant === 'fill' ? '' : props.variant : '-outlined')+ ' ' + (props.className !== undefined ? props.className : '')} style={props.style} id={props.id}>
                {props.children}
        </span>
            </>
        )
    }


}

export default MIcon;
