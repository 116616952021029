/**
 * Manages the state of the emails.
 *
 * @params {[{name:string,id:number}]} mailInput - The state of the email input.
 *
 */

import React, {useState} from "react";
import '../css/domain.css'
import {BackButton, IntlMessage, MIcon, Store, WK24Button, Wk24Cards, Wk24Form} from "../../../@WK24/core";
import WK24InputGroupText from "../../../@WK24/core/Form/Elements/InputGroupText";
import {Wk24CardsFooter} from "../../../@WK24/core/Cards/Elements/Wk24CardsFooter";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifyError, notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface EMailProps {
    value: any
}

const EMail = (props: EMailProps) => {
    const [validated, setValidated] = useState(false);
    const [mail1, setMail1] = useState('');
    const [mail2, setMail2] = useState('');
    const [mail3, setMail3] = useState('');
    const [mailInput, setMailInput] = useState([{name:'',id:0}, {name:'',id:0}, {name:'',id:0}])

    const user = JSON.parse(localStorage.getItem('User') as string);
    let localSettings = JSON.parse(localStorage.getItem('ProjectSettings') as string);
    // Fehlerhafter Weg
    /*const localProject = JSON.parse(localStorage.getItem('Project') as string);*/
    const settingsID = localSettings.id;
    const domain = localSettings.frontend;

    if (localStorage.getItem('Emails')) {
        const mails = JSON.parse(localStorage.getItem('Emails') as string);
        console.log(mails);
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();

        /** save data: emails */
        let back: any = []

        mailInput.map((item: any, index) => {
            if(item.name !== '') {
                let backItem = {
                    "userID": user.userID,
                    "settingsID": settingsID,
                    "email": item.name,
                    "emailID":item.id
                }
                back.push(backItem)
            }

        })

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type":"application/json",
                Authorization:"Basic "+ BasicAuth()
            },
            body: JSON.stringify(back)
        };


       fetch(ApiRoot() + "email", requestOptions)
            .then((response) => response.text())
            .then((result:any) => {
                console.log(result)
                const requestGetOptions = {
                    method: "Get",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + BasicAuth()
                    }
                };

                fetch(ApiRoot() + "email/" + settingsID, requestGetOptions)
                    .then((response) => response.text())
                    .then((result: any) => {
                        result = JSON.parse(result);
                        console.log(result);
                        localStorage.setItem('Emails', JSON.stringify(result));
                        notifySave(Store);
                    })
                    .catch((error) => {
                        console.error(error);
                        notifyError(Store);
                    });

            })
            .catch((error) => console.error(error));
    }

    /** add email input to the mailInput array */
    const emailInput = (email: string, index: number,id:any) => {
        let emailData = [...mailInput];
        emailData[index] = {name:email,id:id};
        setMailInput(emailData);
    }

    /** add new email input field */
    const addInput = () => {
        let newField = {name:'',id:0};
        setMailInput([...mailInput, newField]);
    }

    return (
        <div className="Mails bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'Mails.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Mails.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Mails.header.label', Store: Store})
            }}/>

            <Wk24Form validated={validated} Submit={(e) => handleSubmit(e)}>
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <Wk24Cards style={{width: '41.5rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="mark_email_read" variant="fill"/> {IntlMessage({
                            messageId: 'mails.card.title',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            {mailInput.map((input: any, index) => {
                                return (
                                    <Wk24Form.InputGroup>
                                        <Wk24Form.FormControl
                                            type={'text'}
                                            name={'E-Mail' + index}
                                            placeholder={IntlMessage({
                                                locale: 'de',
                                                messageId: 'Mails.placeholder',
                                                Store: Store
                                            })}
                                            defaultValue={(localStorage.getItem('Emails') !== undefined && localStorage.getItem('Emails') !== null && JSON.parse(localStorage.getItem('Emails') as string)[index] !== undefined) ? JSON.parse(localStorage.getItem('Emails') as string)[index].email.split('@')[0] : ""}
                                            onChange={(e: { target: { value: string } }) => {
                                                emailInput(e.target.value, index, (localStorage.getItem('Emails') !== undefined && localStorage.getItem('Emails') !== null && JSON.parse(localStorage.getItem('Emails') as string)[index] !== undefined) ? JSON.parse(localStorage.getItem('Emails') as string)[index].emailID : 0);
                                            }}
                                        />
                                        <WK24InputGroupText
                                            text={(domain === '' || domain === undefined) ? IntlMessage({
                                                locale: 'de',
                                                messageId: 'Mails.domain.placeholder',
                                                Store: Store
                                            }) : ('@' + domain)}/>
                                    </Wk24Form.InputGroup>
                                )
                            })}
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'Mails.input.add', Store: Store})}
                                        class={''} variant={'link'}
                                        disabled={mailInput.length >= 9}
                                        type={'button'} onClick={addInput}/>
                        </Wk24Cards.Body>
                        <Wk24CardsFooter>
                            <WK24Button children={IntlMessage({locale: 'de', messageId: 'btnSave.label', Store: Store})}
                                        class={''} variant={'link'}
                                        type={'submit'}/>
                        </Wk24CardsFooter>
                    </Wk24Cards>
                    <Wk24Cards class="cardInfo" style={{width: '41.5rem'}}>
                        <Wk24Cards.Header as="h5"><MIcon children="report" variant="fill"/> {IntlMessage({
                            locale: 'de',
                            messageId: 'Mails.attention.title',
                            Store: Store
                        })}</Wk24Cards.Header>
                        <Wk24Cards.Body>
                            <p className="mt-3" dangerouslySetInnerHTML={{
                                __html: IntlMessage({locale: 'de', messageId: 'Mails.attention.text', Store: Store})
                            }}/>
                            <p className="mt-3" dangerouslySetInnerHTML={{
                                __html: IntlMessage({locale: 'de', messageId: 'Mails.attention.label', Store: Store})
                            }}/>
                            <ul>
                                <li>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Mails.attention.listitem1',
                                    Store: Store
                                })}</li>
                                <li>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Mails.attention.listitem2',
                                    Store: Store
                                })}</li>
                                <li>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Mails.attention.listitem3',
                                    Store: Store
                                })}</li>
                            </ul>
                        </Wk24Cards.Body>
                    </Wk24Cards>
                    <div className="giraffeEmails">
                        <img className="giraIcon" src={'/gira1.png'} alt={''}/>
                    </div>
                </div>
            </Wk24Form>
            <BackButton className={'mr-3'}/>
        </div>
    )
}

export default EMail;
