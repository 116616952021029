import FrontendTemplate1 from "./Pages";
import FrontendTemplate2 from "./Pages/FrontendTemplate2";
import FrontendTemplate3 from "./Pages/FrontendTemplate3";

export const FrontendTemplateConfig = (value: any) => {
    return [
        {
            path: '/frontendtemplate1/',
            elements: <FrontendTemplate1 value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/frontendtemplate2/',
            elements: <FrontendTemplate2 value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/frontendtemplate3/',
            elements: <FrontendTemplate3 value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        }
    ]
}

export const FrontendTemplateMenuConfig = (value: any) => {
    return [
        {
            header: 'FrontendTemplates',
            icon: 'login',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/frontendtemplates/',
        }
    ]
}