/**
 * Manages the state of the selected sites
 *
 * @params {any} pages - the array of the pages.
 *
 */

import React, {useEffect, useState} from "react";
import '../css/design.css'
import {BackButton, IntlMessage, MIcon, Store, WK24Button, Wk24Cards} from "../../../@WK24/core";
import sitesDeactivateFetch from "./sitesDeactivateFetch"
import sitesActivateFetch from "./sitesActivateFetch";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {notifySave} from "../../../@WK24/core/Notification/service/notification.service";

interface SitesProps {
    value: any
}

const Sites = (props: SitesProps) => {
    const [pages, setPages] = useState<any>([]);

    const localProject = JSON.parse(localStorage.getItem('Project') as string);
    const settingsID = localProject.projectSettingsID;

    useEffect(() => {
        let raw = {
            settingsID: settingsID
        }

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            },
            body: JSON.stringify(raw)
        };

        fetch(ApiRoot() + "page/all", requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                setPages(result);
            })
            .catch((error) => console.error(error));

    }, [])

    /** Set Icon color */
    const pageIconState = (state: number) => {
        let stateColor = '';

        switch (state) {
            case 1:
                stateColor = '#12a751';
                break;
            case 2:
                stateColor = '#e9e113';
                break;
            case 0:
                stateColor = '#f41909';
                break;
            default:
                break;
        }
        return (
            <MIcon children="radio_button_checked" variant="fill" style={{color: stateColor}}/>
        )
    }

    /** Set text activate or deactivate */
    const setTitle = (pageState: number) => {
        let title = ''

        if (pageState === 1) {
            title = IntlMessage({locale: 'de', messageId: 'Sites.btn.deactivate', Store: Store})
        }
        if (pageState === 2) {
            title = IntlMessage({locale: 'de', messageId: 'Sites.btn.activate', Store: Store})
        }
        return (title)
    }

    /** handle state change */
    const handleStateChange = (item: any) => {
        let state = item.state;
        const currentDataIndex = (pages).findIndex((todo: any) => todo.state === state);
        const updatedData = Object.assign({}, pages[currentDataIndex]);

        if (state === 1) {
            sitesDeactivateFetch(item.id);
            updatedData.state = 2;
        } else {
            if (state === 2) {
                sitesActivateFetch(item.id)
                updatedData.state = 1;
            }
        }
        const newData = pages.slice();
        newData[currentDataIndex] = updatedData;
        setPages(newData);
        console.log(pages);
        notifySave(Store);
    }

    /** handle state delete */
    const handleStateDelete = (item: any) => {
        let state = item.state;

        if (state === 1 || state === 2) {
            state = 0;
        }
        return state;
    }

    return (
        <div className="Sites bpage">
            <h1>{IntlMessage({locale: 'de', messageId: 'Sites.title', Store: Store})}<br/><small>{IntlMessage({
                locale: 'de',
                messageId: 'Sites.subTitle',
                Store: Store
            })}</small></h1>
            <p className="mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Sites.header.label', Store: Store})
            }}/>
            <div className="d-flex flex-row flex-wrap">
                {pages.map((item: any) => {
                    return (
                            <Wk24Cards style={{width: '20rem'}}>
                                <Wk24Cards.Header as="h5">
                                    {pageIconState(item.state)}
                                    {item.title}
                                </Wk24Cards.Header>
                                <Wk24Cards.Body>
                                    <Wk24Cards.Text>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Sites.body.title',
                                            Store: Store
                                        })}</strong> {item.siteTitle}</p>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Sites.body.description',
                                            Store: Store
                                        })}</strong> {item.metaDescription}</p>
                                    </Wk24Cards.Text>
                                </Wk24Cards.Body>
                                <Wk24Cards.Footer>
                                    <WK24Button children={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Sites.btn.edit',
                                        Store: Store
                                    })} class={''} href={"/sites/"+item.id} variant={'link'}
                                                onClick={() => {
                                                }}/>
                                    <WK24Button children={setTitle(item.state)} class={''} variant={'link'}
                                                onClick={() => {
                                                    handleStateChange(item);
                                                }}
                                    />
                                    <WK24Button children={IntlMessage({
                                        locale: 'de',
                                        messageId: 'Sites.btn.delete',
                                        Store: Store
                                    })} class={''} variant={'link'}
                                                onClick={() => () => {
                                                    item.state = handleStateDelete(item);
                                                }}/>
                                </Wk24Cards.Footer>
                            </Wk24Cards>
                    )
                    }
                )}
            </div>
            <BackButton  />
        </div>
    )
}
export default Sites;