import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";

const Responsibility = (props:{range:any,HeaderRef:any,FooterRef:any,tmp:any}) => {
    const [show, setShow] = useState(true);
    const [ModalClass, setModalClass] = useState('');
    const [Content, setContent] = useState('');
    const HolderRef = useRef<HTMLDivElement>(null);
    const Modalref = useRef<HTMLDivElement>(null);
    const handleClose = () => setShow(false);
    useEffect(() => {
        const elements = document.querySelectorAll('.code-block');
        let HTMLHolder:string = '';
        for (let x = 0; x < elements.length; x++) {
            let tmp = elements[x] as HTMLDivElement;
            if(tmp.querySelector('code') !== null) {

                HTMLHolder = HTMLHolder + tmp.innerHTML
            }
        }
        let parser = new DOMParser();
        let doc = parser.parseFromString(HTMLHolder, 'text/html');
        setContent(doc.body.textContent as string)
        if(HolderRef.current !== null) {
            HolderRef.current.innerHTML = doc.body.textContent as string
        }

    }, [props.range]);


    const OpenModal = (MClass:string,width:any,height:any) => {
        const elements = document.querySelectorAll('.code-block');
        let HTMLHolder:string =  '';
        for (let x = 0; x < elements.length; x++) {
            let tmp = elements[x] as HTMLDivElement;
            if(tmp.querySelector('code') !== null) {

                HTMLHolder = HTMLHolder + tmp.innerHTML
            }
        }
        setModalClass(MClass)
        let parser = new DOMParser();
        let doc = parser.parseFromString(HTMLHolder, 'text/html');
        setContent(doc.body.textContent as string)
        if(HolderRef.current !== null) {
            HolderRef.current.innerHTML = doc.body.textContent as string
        }
        openModalInNewTab('RespoModal', width, height,Modalref.current)
    }


    let Colorset = (localStorage.getItem('SiteColor') !== null && localStorage.getItem('SiteColor') !== undefined ? JSON.parse(localStorage.getItem('SiteColor') as string).backgroundColor :'#fff')

    return(
        <>
            <ButtonGroup aria-label="Basic example">
                <Button variant="primary" type={'button'} className={'p-2'} title={'Smartphone Portrait'} onClick={() => OpenModal('handy_Hoch',360,568)}>
                <span className="material-icons-outlined">
                    stay_primary_portrait
                </span>
                </Button>
                <Button variant="primary" type={'button'} className={'p-2'} title={'Smartphone Landscape'} onClick={() => OpenModal('handy_Quer',480,320)}>
                <span className="material-icons-outlined" >
                    stay_primary_landscape
                </span>
                </Button>
                <Button variant="primary" type={'button'} className={'p-2'} title={'Tablet Portrait'} onClick={() => OpenModal('tablet_Hoch', 768, 1024)}>
                <span className="material-symbols-outlined">
                tablet_mac
                </span>
                </Button>
                <Button variant="primary" type={'button'} className={'p-2'} title={'Tablet Landscape'} onClick={() => OpenModal('tablet_Quer', 1024, 768)}>
               <span className="material-symbols-outlined">
                    tablet
                </span>
                </Button>
                <Button variant="primary" type={'button'} className={'p-2'} title={'Laptop'} onClick={() => OpenModal('laptop',1920, 1280)}>
                <span className="material-icons-outlined">
                    laptop_mac
                </span>
                </Button>
            </ButtonGroup>
            <div id={'RespoModal'}>
                <Modal.Dialog style={{backgroundColor: Colorset, display: 'none'}}>
                    <Modal.Body id={'ModalContent'} ref={Modalref} className={props.tmp !== undefined ? props.tmp : "template1 "}>
                        <div
                            dangerouslySetInnerHTML={{__html: (props.HeaderRef.current !== null ? (props.HeaderRef.current as HTMLDivElement).innerHTML : '')}}/>
                        <div className="tmpmain " style={{backgroundColor: Colorset}}>
                                <div id={'holder'} ref={HolderRef}
                                     dangerouslySetInnerHTML={{__html: Content}}/>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{__html: (props.FooterRef.current !== null ? (props.FooterRef.current as HTMLDivElement).innerHTML : '')}}/>
                            <br style={{clear: 'both'}}/>
                    </Modal.Body>
                    <Modal.Footer style={{backgroundColor: 'white'}}>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>

                </Modal.Dialog></div>
        </>
    )
}

export default Responsibility;

const openModalInNewTab = (modalId:any,width:any,height:any,modalContent:any) => {
    let Colorset = (localStorage.getItem('SiteColor') !== null && localStorage.getItem('SiteColor') !== undefined ? JSON.parse(localStorage.getItem('SiteColor') as string).backgroundColor :'#fff')
    let fontColor = (localStorage.getItem('SiteColor') !== null && localStorage.getItem('SiteColor') !== undefined ? JSON.parse(localStorage.getItem('SiteColor') as string).fontColor :'#000')
    let primaryColor = (localStorage.getItem('SiteColor') !== null && localStorage.getItem('SiteColor') !== undefined ? JSON.parse(localStorage.getItem('SiteColor') as string).primaryColor :'#ec4040')
    let tertiarColor = (localStorage.getItem('SiteColor') !== null && localStorage.getItem('SiteColor') !== undefined ? JSON.parse(localStorage.getItem('SiteColor') as string).secondaryColor :'#60aeb8')
    let secondaryColor = (localStorage.getItem('SiteColor') !== null && localStorage.getItem('SiteColor') !== undefined ? JSON.parse(localStorage.getItem('SiteColor') as string).secondaryColor :'#313c52')


    if(modalContent !== null) {
        modalContent.classList.add("laptop");
        // Erstellen Sie ein neues Fenster
        const newWindow = window.open(undefined, '_blank', 'width='+width+',height='+height);
        let Header = document.head;
        let Styles = Header.querySelectorAll('style');
        for(let h=0;h<Styles?.length;h++) {
            const styleElement = document.createElement('style');
            styleElement.innerHTML = Styles[h].innerHTML
            newWindow?.document.head.appendChild(styleElement)
        }
        let Links = Header.querySelectorAll('link');
        for(let h=0;h<Links?.length;h++) {
            if(Links[h].rel !== undefined && Links[h].rel === 'stylesheet') {
                const styledElement = document.createElement('link');
                styledElement.rel = "stylesheet"
                styledElement.href=Links[h].href
                newWindow?.document.head.appendChild(styledElement)
            }
        }

        const styleElement = document.createElement('style');
        styleElement.innerHTML = 'body{background-color:lightgray !important; --customBtnColor:'+ primaryColor +'; --customTitleColor:'+ secondaryColor + '; --customFontColor:'+ fontColor +'; --customTertiaerColor:'+ tertiarColor +'; --customBGColor:'+ Colorset +'}'
        newWindow?.document.head.appendChild(styleElement)
        // Kopieren Sie den Modal-Inhalt in das neue Fenster
        newWindow?.document.body.appendChild(modalContent.cloneNode(true));
        // Zentrieren Sie das Modal im neuen Fenster
        newWindow?.focus();/*
        modalContent.style.position = 'absolute';
        modalContent.style.top = '50%';
        modalContent.style.left = '50%';
        modalContent.style.transform = 'translate(-50%, -50%)';*/

        // Zeigen Sie das Modal an
        modalContent.style.display = 'block';
    }

}
