import {ResponseFonts, ResponseSiteGeneral, ResponseSiteSingle} from "../Api/response/sites";
import {NavLink, useParams} from "react-router-dom";
import Webbuilder from "../../../@WK24/core/Webbuilder";
import {BackButton, IntlMessage, Store, WK24Button, Wk24Form} from "../../../@WK24/core";
import React, {useEffect, useState} from "react";
import SettingsEditorDetail from "../../../components/editor/Settings/SettingsEditorDetail";
import {handleSubmitDetail} from "../components/handleSubmit";

interface SiteDetailProps {
    value: any
}

const SiteDetail  = (props: SiteDetailProps) => {
    let localProject;
    let settingsID: any;
    localProject = JSON.parse(localStorage.getItem('Project') as string);
    settingsID = localProject.projectID;

    const { id } = useParams<{ id: string }>()
    const Response = ResponseSiteSingle(id)
    const General = ResponseSiteGeneral(settingsID)
    const Fonts = ResponseFonts(JSON.parse(localStorage.getItem('User') as string).userID)

    const [validated, setValidated] = useState(false);
    if(Response !== undefined && General !== undefined) {
        let colorStyles = {
            primaryColor: General.colors.primary,
            secondaryColor: General.colors.secondary,
            tertiarColor: General.colors.tertiary,
            fontColor: General.colors.font,
            backgroundColor: General.colors.background
        }

        localStorage.setItem('SiteColor',JSON.stringify(colorStyles))

        /** set custom styles */
        document.body.style.setProperty("--customBtnColor", colorStyles.primaryColor);
        document.body.style.setProperty("--customTitleColor", colorStyles.secondaryColor);
        document.body.style.setProperty("--customFontColor", colorStyles.fontColor);
        document.body.style.setProperty("--customTertiaerColor", colorStyles.tertiarColor);
        document.body.style.setProperty("--customBGColor", colorStyles.backgroundColor);

        let idFooter = Response.pageContent.footer.id;
        let idHeader = Response.pageContent.header.id;
        let idMain = Response.pageContent.main[0].id;

        for(let x=0;x<Fonts.length;x++) {
          createFonts(Fonts[x].name,Fonts[x].projectID)
        }


        return(
            <>
                <Wk24Form validated={validated}
                          Submit={(e) => handleSubmitDetail(e, setValidated, Response.id, idFooter, idHeader, idMain, Response.path)}>
                     <Wk24Form.Label class="headerTitle text-left"
                                     text={IntlMessage({locale: 'de', messageId: 'webuilder.title', Store: Store})}/>
                     <p className={'text-left mt-3 stepAbstandpage'} dangerouslySetInnerHTML={{
                         __html: IntlMessage({messageId: 'webuilder.detail.description', Store: Store})
                     }}/>
                <Webbuilder tmp={'template'+General.template.replace('0','')}
                    header={<>
                        <div className={"tmpheader HeaderImage " + Response.pageContent.header.imagePosition}
                             style={{backgroundImage: "url(" + Response.pageContent.header.images[0] + ")"}}>
                            <div className="topheader">
                                <a id="logo" href={"/"}></a>
                                <nav className="wk-horizontal">
                                    <ul className="navi">
                                        {General.navigation.mainNavigation.map((page, index) => (
                                            <li>
                                                <NavLink
                                                    key={index}
                                                    to={page.path}
                                                    className={({isActive, isPending}) =>
                                                        isPending ? "pending" : isActive ? "active" : ""}
                                                >{page.title}</NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                            <div className={"headercontent "+Response.pageContent.header.styleClass}>
                                <p className="title headlineHeader" style={{color: colorStyles.secondaryColor}}>
                                    {Response.pageContent.header.headline}
                                </p>
                                <p className={"sublineHeader"}>{Response.pageContent.header.text}</p>
                                <p>
                                    <a className="wk-button ButtonHeader" style={{backgroundColor: colorStyles.primaryColor}}
                                      href={Response.pageContent.header.button.link}>
                                        {Response.pageContent.header.button.text}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </>}
                    footer={<>
                        <div className="tmpfooter" style={{backgroundColor: colorStyles.tertiarColor}}>
                            <div className="footercontent">
                                <p className="footer-company">Hier stehen deine Adressangaben</p>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>Kontakt:</td>
                                        <td><a className={'emailFooter'} style={{color: colorStyles.primaryColor}}
                                               href={"mailto:"+Response.pageContent.footer.email}> {Response.pageContent.footer.email}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Telefon:</td>
                                        <td><a className={'phoneFooter'} style={{color: colorStyles.primaryColor}}
                                               href={"tel:+493712435610"}>{Response.pageContent.footer.phone}</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="socialmedia">
                                    {Response.pageContent.footer.socialLinks.map((page: { type: string; link: string }, index: any) => (
                                        <a href={page.link}><img src={'/socialmedia/'+page.type+'.svg'} alt={''} /></a>
                                    ))}
                                </div>
                            </div>
                            <div className="down">
                                <nav className="footernav wk-horizontal">
                                    <ul>
                                        {General.navigation.serviceNavigation.map((page, index) => (
                                            <li>
                                                <NavLink
                                                    key={index}
                                                    to={page.path}
                                                    className={({isActive, isPending}) =>
                                                        isPending ? "pending" : isActive ? "active" : ""}
                                                >{page.title}</NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                <div id="copyright">
                                    <span id="company">&copy; {new Date().getFullYear()} {Response.pageContent.footer.company}</span> |
                                    <span id="weko">
                            <a href={"https://www.weko24.de"}
                               style={{color: colorStyles.fontColor}}
                               target="_blank"
                               rel={"nofollow noreferrer"}> weko24.de</a>
                        </span>
                                </div>
                            </div>
                        </div>
                    </>}
                    data={Response}
                    config={[]}
                    SettingsEditor={SettingsEditorDetail}
                    childs={<>
                        <div className="tmpmain " style={{backgroundColor: colorStyles.backgroundColor}}>
                            <div
                                dangerouslySetInnerHTML={{__html: Response.pageContent.main[0].content}}/>
                        </div>
                    </>
                    }/>
                    <BackButton to={'/sites'} />
                     <WK24Button children={<>Speichern <span className="material-icons-outlined" style={{verticalAlign: 'bottom'}}>
                    send
                </span></>} class={'p-3 editorPrev float-end'} variant={'primary'}
                                 type={'submit'}/>
                 </Wk24Form>
            </>
        )
    } else {
        return (
            <>

            </>
        )
    }

}

export default SiteDetail;


export const createFonts = (name:string,projectID:number) => {
    let FontsRoot: string | undefined
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        FontsRoot = process.env.REACT_APP_FONT_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        FontsRoot = process.env.REACT_APP_FONT_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        FontsRoot = process.env.REACT_APP_FONT_URL_LIVE
    } else {
        FontsRoot = process.env.REACT_APP_FONT_URL_LIVE
    }
    const fontFile = new FontFace(
        name,
        "url("+FontsRoot+"/fonts/"+projectID+"/"+name+".woff2)",
    );
    document.fonts.add(fontFile);
}