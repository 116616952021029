export const phoneFooter = (e:any) => {
    let phoneFooter = document.getElementsByClassName('phoneFooter')
    for(let sh =0;sh< phoneFooter.length;sh++) {
        let tmp = phoneFooter[sh] as HTMLAnchorElement
        if(tmp) {
            tmp.innerText = e.target.value
            tmp.href = 'tel:'+e.target.value
        }
    }
}
