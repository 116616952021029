import React from "react";
import DefaultTemplate from "./Default";
import FrontendTemplate from "./frontend";

interface TemplateSwitcherProps {
    /** Name of the selected template  */
    layout: string
}

/**
 * With the template switcher, different templates can be selected and played out. So that the right template can be chosen for every project. And you can revise new versions of existing templates and then connect them
 * @param props
 * @constructor
 */
const TemplateSwitcher = (props:TemplateSwitcherProps) => {


    switch (props.layout) {
        case 'default':
            return <DefaultTemplate value={''} />
        case 'frontend':
            return <FrontendTemplate value={''} />
        default:
            return <DefaultTemplate value={''} />
    }

}

export default TemplateSwitcher;
