import Col from 'react-bootstrap/Col'
import {CSSProperties, ElementType, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

export interface WK24ColProps{
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** The number of columns to span on extra small devices (<576px) */
    xs?:boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
    /** The number of columns to span on small devices (≥576px) */
    sm?:boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
    /** The number of columns to span on medium devices (≥768px) */
    md?:boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
    /** The number of columns to span on large devices (≥992px) */
    lg?:boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
    /** The number of columns to span on extra large devices (≥1200px) */
    xl?:boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
    /** The number of columns to span on extra extra large devices (≥1400px) */
    xxl?:boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

const WK24Col = (props:WK24ColProps) => {

    return(
        <>
            <Col xs={props.xs} sm={props.sm} md={props.md} style={props.style} lg={props.lg} xl={props.xl} xxl={props.xxl} id={props.id} className={props.class}  as={props.as}>
                {props.children}
            </Col>
        </>
    )
}

export default WK24Col;
