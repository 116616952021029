import Account from "./Pages";
import Domain from "./Pages/domain";
import EMail from "./Pages/email";
import Voucher from "./Pages/voucher";

export const AccountConfig  = (value: any) => {
    return [
        {
            path: '/account/',
            elements: <Account value={value} />,
            roles: [
                'superadmin',
                'admin',
                'registered'
            ]
        },
        {
            path: '/step6/',
            elements: <Account value={'visibleNon'}/>,
            roles: [
                'superadmin',
                'admin',
                'registered'
            ],
            isNested:false
        },
        {
            path: '/domain/',
            elements: <Domain value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'registered'
            ]
        },
        {
            path: '/step7/',
            elements: <Domain value={'visibleNon'}/>,
            roles: [
                'superadmin',
                'admin',
                'registered'
            ],
            isNested:false
        },
        {
            path: '/step7a/',
            elements: <Voucher value={'visibleNon'}/>,
            roles: [
                'superadmin',
                'admin',
                'registered'
            ],
            isNested:false
        },
        {
            path: '/emails/',
            elements: <EMail value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'registered'
            ]
        }
    ]
}

export const AccountMenuConfig = (value: any) => {
    return [
        {
            header: 'Einstellungen',
            icon: 'settings',
            roles: [
                'superadmin',
                'admin',
            ],
            menuitem: [
                {
                    name: 'Daten',
                    route: '/account',
                    icon: 'contact_page',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },
                /*{
                    name: 'Zahlungen',
                    route: '/payments',
                    icon: 'payments',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },*/
                {
                    name: 'Domains',
                    route: '/domain',
                    icon: 'domain',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                },
                {
                    name: 'E-Mails',
                    route: '/emails',
                    icon: 'mark_email_read',
                    roles: [
                        'superadmin',
                        'admin',
                    ]
                }
            ]
        }
    ]
}
