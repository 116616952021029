import {SignInActionType} from '../../actions/ActionType'

const newUser = localStorage.getItem('User');

const initialState = newUser
    ? {newUser}
    : {newUser: null};

type Action = {
    type: string,
    payload?: any
}

const SignInReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case SignInActionType.REGISTER_SUCCESS:
            return {
                ...state,
                newUser: action.payload
            }
        case SignInActionType.REGISTER_FAIL:
            return {
                ...state,
                newUser: null
            }
        default:
            return state;
    }
}
export default SignInReducer