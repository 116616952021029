export const HeaderTitle = (e:any) => {
    let headerTitle = document.getElementsByClassName('headlineHeader')
    for(let ht =0;ht< headerTitle.length;ht++) {
        let tmp = headerTitle[ht]  as HTMLParagraphElement
        if(tmp) {
            tmp.innerHTML = e.target.value
        }
    }

}
