import Payment from "./Pages";

export const PaymentConfig = (value: any) => {
    return [
        {
            path: '/payment/',
            elements: <Payment value={''}/>,
            roles: [
                'superadmin',
                'admin'
            ]
        },
        {
            path: '/step8/',
            elements: <Payment value={'visibleNon'}/>,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        }
    ]
}

export const PaymentMenuConfig = (value: any) => {
    return [
        {
            header: 'Payment',
            icon: 'public',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/payment'
        }
    ]
}
