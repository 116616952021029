import { useSelector } from "react-redux";
import '../css/dashbaord.css';
import { IntlMessage } from "../../../@WK24/core";
import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import { Response } from "../Api/response/customer";
import { Pagination } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {useParams} from "react-router-dom";
import {
    notifyGhostLogin
} from "../../../@WK24/core/Notification/service/notification.service";
interface DashboardProps {
    value: string;
}

interface CustomerState {
    // State variables for pagination
    page: number;
}

const Customer: React.FC<DashboardProps> = (props) => {
    const { page } = useParams<{ page: string }>()
    const [data, setData] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [row, setRow] = useState(10)
    const [state, setState] = useState<CustomerState>({
        page: page !== undefined ? parseInt(page) : 1
    });
    const [fetchData, setFetchData] =  useState(0);
    const Store = useSelector((state: any) => state);
    const response = Response(0,fetchData,setData); // Replace with actual response data from store
    const handleSearchChange = (event: any,filter:string) => {
        setSearchTerm(event.target.value);
        setSearchFilter(filter)
    };

    const filteredData:any = () => {
        switch(searchFilter) {
            case 'customerID':
                if(searchTerm === '') {
                    return data.filter((item) =>
                        item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                } else {
                    return data.filter((item) =>
                        item.customerID == searchTerm
                    );
                }
            case 'userID':
                if(searchTerm === '') {
                    return data.filter((item) =>
                        item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                } else {
                    return data.filter((item) =>
                        item.userID == searchTerm);
                }
            case 'name':
                return data.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            case 'company':
                return data.filter((item) =>
                    item.company.toLowerCase().includes(searchTerm.toLowerCase())
                    );
            default:
                return data.filter((item) =>
                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
        }
    }



    const userItem = localStorage.getItem('User');
    const user = userItem ? JSON.parse(userItem) : null;

    // Handles page change
    const handlePageChange = (newPage: number) => {
        setState({ ...state, page: newPage });
    };

    useEffect(() => {
        // Handle potential data fetching logic here (if not done elsewhere)
    }, []);

    // Calculate total pages and current page data based on pagination settings
    const totalPages = Math.ceil(filteredData().length / row);
    const currentPageData = filteredData().slice((state.page - 1) * row, state.page * row);

    // Generate pagination items based on total pages and active page
    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
        paginationItems.push(
            <Pagination.Item key={number} active={number === state.page} onClick={(e)=>handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }

    const ChangeRow = (e:any) => {
        setRow(e.target.value)
    }

    const setGhostUSer = (customer:any) => {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + BasicAuth()
            }
        };

        fetch(ApiRoot() + "user/" + customer.userID, requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);

                /**
                 * @desc This code snippet checks whether certain items exist in the local storage
                 * and creates them if necessary.
                 *
                 * @param {string} localStorage.getItem('User') - The value for the user from the local storage.
                 * @param {string} localStorage.getItem('Customer') - The value for the customer from the local storage.
                 * @param {string} localStorage.getItem('Project') - The value for the project from the local storage.
                 */

                // Checks if a "GhostUser" item exists in the local storage and creates it if necessary.
                if (localStorage.getItem('GhostUser') === null) {
                    localStorage.setItem('GhostUser', localStorage.getItem('User') as string);
                }

                // Checks if a "GhostCustomer" item exists in the local storage and creates it if necessary.
                if (localStorage.getItem('GhostCustomer') === null) {
                    localStorage.setItem("GhostCustomer", localStorage.getItem('Customer') as string);
                }

                // Checks if a "GhostProject" item exists in the local storage and creates it if necessary.
                if (localStorage.getItem('GhostProject') === null) {
                    localStorage.setItem("GhostProject", localStorage.getItem('Project') as string);
                }

                // Checks if a "GhostCustomer" item exists in the local storage and creates it if necessary.
                if (localStorage.getItem('GhostEmails') === null) {
                    localStorage.setItem("GhostEmails", localStorage.getItem('Emails') as string);
                }

                // Checks if a "GhostProject" item exists in the local storage and creates it if necessary.
                if (localStorage.getItem('Ghostimprint') === null) {
                    localStorage.setItem("Ghostimprint", localStorage.getItem('imprint') as string);
                }

                // Checks if a "GhostProject" item exists in the local storage and creates it if necessary.
                if (localStorage.getItem('GhostProjectSettings') === null) {
                    localStorage.setItem("GhostProjectSettings", localStorage.getItem('ProjectSettings') as string);
                }

                localStorage.setItem("User", JSON.stringify(result.user))
                localStorage.setItem("Customer", JSON.stringify(result.customer))
                localStorage.setItem("Project", JSON.stringify(result.Project))
                let settingsID = result.Project.projectID;



                const requestOptions = {
                    method: "Get",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + BasicAuth()
                    }
                };

                fetch(ApiRoot() + "projetcSettings/" + settingsID, requestOptions)
                    .then((response) => response.text())
                    .then((result: any) => {
                        result = JSON.parse(result);
                        setData(result);
                        localStorage.setItem('ProjectSettings', JSON.stringify(result))
                        let imprintBack = {
                            "root":  result.frontend
                        }
                        const requestOptionss = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: "Basic " + BasicAuth()
                            },
                            body: JSON.stringify(imprintBack)
                        }

                        fetch(ApiRoot() + "impressum", requestOptionss)
                            .then((response) => response.json())
                            .then((result: any) => {

                                let newResult = {
                                    director:(result.customer !== null ? result.customer.gf : ''),
                                    imageRights:(result.mediaRights !== null ? result.mediaRights.right : ''),
                                    authorized: (result.customer !== null ? result.customer.representative: ''),
                                    settlement: (result.customer !== null ? result.customer.arbitration_settlement: ''),
                                    contentResp: (result.customer !== null ? result.customer.responsible_for_content: '')}
                                localStorage.setItem('imprint',JSON.stringify(newResult))
                                fetch(ApiRoot() + "email", requestOptions)
                                    .then((response) => response.text())
                                    .then((result:any) => {
                                        const requestGetOptions = {
                                            method: "Get",
                                            headers: {
                                                "Content-Type": "application/json",
                                                Authorization: "Basic " + BasicAuth()
                                            }
                                        };

                                        fetch(ApiRoot() + "email/" + settingsID, requestGetOptions)
                                            .then((response) => response.text())
                                            .then((result: any) => {
                                                result = JSON.parse(result);
                                                console.log(result);
                                                localStorage.setItem('Emails', JSON.stringify(result))
                                                notifyGhostLogin(Store)
                                                setTimeout(() => {
                                                    window.location.href = '/customer/'+state.page;
                                                }, 2000);
                                            })
                                            .catch((error) => {
                                                localStorage.setItem('Emails', '[]')
                                                notifyGhostLogin(Store)
                                                setTimeout(() => {
                                                    window.location.href = '/customer/'+state.page;
                                                }, 2000);
                                                console.error(error)});
                                    })
                                    .catch((error) => console.error(error));

                            })
                            .catch((error) => console.error(error));
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
    }


    return (
        <div className="Dashboard bpage">
            <h1>
                {IntlMessage({ locale: 'de', messageId: 'admin.dashboard.title', Store: Store })}<br/>
                <small>
                    {IntlMessage({ locale: 'de', messageId: 'dashBoard.subTitle', Store: Store })} {user.firstname}!
                </small>
                <Form.Select aria-label="Default select example" onChange={(e)=>ChangeRow(e)}
                             style={{width:'100px'}} className={'float-end'}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </Form.Select>
            </h1>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Suche nach Name" onChange={(e)=>handleSearchChange(e,'name')}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Suche nach Company" onChange={(e)=>handleSearchChange(e,'company')}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="number" placeholder="Suche nach CustomerID" onChange={(e)=>handleSearchChange(e,'customerID')}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="number" placeholder="Suche nach UserID" onChange={(e)=>handleSearchChange(e,'userID')}/>
                    </Form.Group>
                </Col>
            </Row>

            <div className="d-flex flex-row flex-wrap justify-content-between">
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Adresse</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentPageData.map((route: { customerID: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; company: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; address: { street: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; postCode: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; location: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }; }, index: number) => (
                        <tr key={route.customerID} onDoubleClick={()=>setGhostUSer(route)}>
                            <td>{index + 1 + (state.page - 1) * row}</td>
                            <td>{route.name}</td>
                            <td>{route.company}</td>
                            <td>{route.address.street}, {route.address.postCode} {route.address.location}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    {paginationItems}
                </Pagination>

            </div>
        </div>
    );
};

// JSDoc comments for Customer component
/**
 * Customer component that renders a table of customer data with pagination.
 *
 * @author [Your Name]
 * @version 1.0.0
 * @param {DashboardProps} props - The component's props.
 * @returns {React.ReactElement} - The rendered component.
 */

export default Customer;


