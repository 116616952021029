import {requests} from '../../../../@WK24/core/RestFullApi/api'
import {sitesApiType} from "./sites.interface";
import {SiteGeneralAPIInterface} from "./SiteGeneral.interface";

export const sites = {
    getPosts: (limit: any, searchValue: any): Promise<sitesApiType[]> =>
        requests.get(`page`),
    getPostsFonts: (uid: any): Promise<sitesApiType[]> =>
        requests.get(`/fonts/${uid}`),
    getPostsDetail: (id: any): Promise<sitesApiType> =>
        requests.get(`page/${id}`),
    getPostsDetailGeneral: (id: any): Promise<SiteGeneralAPIInterface> =>
        requests.get(`projetcSettings/${id}`),
    getActivePosts: (limit: any, searchValue: any): Promise<sitesApiType[]> =>
        requests.get(`page/active`),
    getDeactivePosts: (limit: any, searchValue: any): Promise<sitesApiType[]> =>
        requests.get(`page/deactive`),
    createPost: (post: sitesApiType, id: number): Promise<sitesApiType> =>
        requests.put(`page/${id}`, post),
    updatePost: (post: any, id: number): Promise<sitesApiType> =>
        requests.post(`page/${id}`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`page/${id}`),
}
