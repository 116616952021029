import {NotificationMessage} from "./notification.message";
import {IntlMessageValue} from "../../IntlMessage/IntlMessage";

/**
 * The file contains all registration notifications. These can be used directly in the respective locations.<br/><br/>
 *  <strong>Example embeded Function:</strong><br/>
 * ```js
 * notifySave(Store);
 * ```
 *
 *  <strong>Example Function in notification.service.tsx:</strong><br/>
 * ```js
 * export const notifySave = (Stores: any) =>
 *     NotificationMessage({
 *         title: IntlMessageValue(
 *             'de',
 *             'notification.service.provision.title',
 *             Stores
 *         ),
 *         message: IntlMessageValue(
 *             'de',
 *             'notification.service.provision.message',
 *             Stores
 *         ),
 *         type: 'success',
 *         insert: 'top',
 *         container: 'top-center',
 *         animationIn: ['animate__animated', 'animate__fadeIn'],
 *         animationOut: ['animate__animated', 'animate__fadeOut'],
 *         dismiss: {
 *             duration: 3000,
 *
 *         }
 *     })
 * ```
 * @constructor
 * @version 0.0.1
 * @visibleName NotificationService
 * @author [Marcel Ugurcu](https://wum-solution.de)
 */
export const DocumentationHolder = () => {

        return(
            <>
            </>
        )
}

export const notifySave = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.save.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.save.message',
            Stores
        ),
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })

export const notifyError = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.error.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.error.message',
            Stores
        ),
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })

export const notifyRegistrationSuccess = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.success.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.successRegistration.message',
            Stores
        ),
        type: 'success',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })

export const notifyRegistrationError = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.error.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.errorRegistration.message',
            Stores
        ),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })

export const notifyRegistrationEmptyFields = (Stores: any) =>
    NotificationMessage({
            title: IntlMessageValue(
                'de',
                'notification.service.error.title',
                Stores
            ),
            message: IntlMessageValue(
                'de',
                'notification.service.emptyRegistrationFields.message',
                Stores
            ),
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                    duration: 5000,

            }
    })
export const notifyRegistrationPassCheck = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.error.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.emptyRegistrationPassword.message',
            Stores
        ),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })
export const notifyRegistrationPassSecure = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.error.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.RegistrationPasswordSecure.message',
            Stores
        ),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })
export const notifyLoginSuccess = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.success.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.successLogin.message',
            Stores
        ),
        type: 'success',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,
        }
    })

export const notifyLoginError = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.error.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.errorLogin.message',
            Stores
        ),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })
export const notifyDomainError = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.error.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.errorDomain.message',
            Stores
        ),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,

        }
    })
export const notifyAccountDataWarning = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'notification.service.dataWarning.title',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'notification.service.dataWarning.message',
            Stores
        ),
        type: 'warning',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 100000,

        }
    })

export const notifyGhostLogin = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'Sie werden eingeloggt.',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'Die Seite wird einmal neu geladen',
            Stores
        ),
        type: 'success',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 100000,

        }
    })

export const notifyGhostLogout = (Stores: any) =>
    NotificationMessage({
        title: IntlMessageValue(
            'de',
            'Sie werden ausgeloggt.',
            Stores
        ),
        message: IntlMessageValue(
            'de',
            'Sie werden auf das Dashbaord weitergeleitet',
            Stores
        ),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 100000,

        }
    })