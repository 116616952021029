import {useState} from "react";
import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {ModalHeader} from "./Elements/ModalHeader";
import {ModalBody} from "./Elements/ModalBody";
import {ModalFooter} from "./Elements/ModalFooter";
import React from 'react'
interface UniModalProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
    Button:{
        oldButton?:boolean
        Text: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
        OnClick?: any;
        Variant?:string
        Class?:string
        ID?:string
        Type?:"button" | "submit" | "reset" | undefined
    }
    fullscreen?:string | true | undefined
    title?:string

}

const Wk24Modal = (props:UniModalProps) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const ButtonFunc = () => {
        handleShow();
        if(props.Button.OnClick !== undefined){
            props.Button.OnClick()
        }

    }



    return(
        <>
            {props.Button.oldButton === true ?
            <button
                type="button"
                className="btn"
                onClick={ButtonFunc}
            >
                {props.Button.Text}
            </button>
                :
            <Button type={props.Button.Type}
                    variant={props.Button.Variant !== undefined ? props.Button.Variant : "primary"} id={props.Button.ID}
                    className={props.Button.Class} onClick={ButtonFunc}>
                {props.Button.Text}
            </Button>
            }
            <Modal show={show} fullscreen={props.fullscreen} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {props.children}
                </Modal.Body>
            </Modal>
        </>
    )

}

Wk24Modal.Header = ModalHeader
Wk24Modal.Body = ModalBody
Wk24Modal.Footer = ModalFooter

export default Wk24Modal;
