import DraggableDiv from "../../DragAbleDiv";
import React, {useRef, useState} from "react";

const Settings = ({ SettingsEditor,data }: { SettingsEditor({expand, open, SeoRef}: {expand: any, open: any, SeoRef: any,data?:any}): JSX.Element,data:any }) => {
    const SeoRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const expand = (mode:string) => {
        if(SeoRef.current !== null) {
            if(mode === 'more'){
                SeoRef.current.style.display = 'block'
                setOpen(true)
            } else if(mode === 'less'){
                SeoRef.current.style.display = 'none'
                setOpen(false)
            }
        }
    }


    return(
        <>
            {data !== undefined ?

                    <DraggableDiv style={{top:'-290px',left:'-350px'}} content={<><SettingsEditor SeoRef={SeoRef} expand={expand} open={open} data={data}   /></>} className={''}/>
                    :
            <DraggableDiv style={{top:'-290px',left:'-350px'}} content={<><SettingsEditor SeoRef={SeoRef} expand={expand} open={open}   /></>} className={''}/>
            }
                </>
    )
}

export default Settings;
