export const ApiRoot = () => {
    let apiRoot: string | undefined
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        apiRoot = process.env.REACT_APP_API_URL_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        apiRoot = process.env.REACT_APP_API_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        apiRoot = process.env.REACT_APP_API_URL_LIVE
    }
    return apiRoot
}

export const ApiRootCDN = () => {
    let apiRootCDN: string | undefined
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        apiRootCDN = process.env.REACT_APP_CDN_API_URL_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        apiRootCDN = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        apiRootCDN = process.env.REACT_APP_CDN_API_URL_LIVE
    }

    return apiRootCDN
}
