export interface IAddress {
	street:string,
	zip:string,
	city:string
}

export const defaultAddress:IAddress={
	street:'',
	zip:'',
	city:''
}