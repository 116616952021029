
import EditorTmp1 from "./Pages/EditorTmp1";
import EditorTmp2 from "./Pages/EditorTmp2";
import EditorTmp3 from "./Pages/EditorTmp3";

export const EditorTemplateConfig  = (value: any) => {
    return [
        {
            path: '/template1/editor',
            elements: <EditorTmp1 />,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        },
        {
            path: '/template2/editor',
            elements: <EditorTmp2 />,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        },
        {
            path: '/template3/editor',
            elements: <EditorTmp3 />,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        }
    ]
}

