import Template1 from "./Pages";
import Template2 from "./Pages/Template2";
import Template3 from "./Pages/Template3";

export const TemplateConfig  = (value: any) => {
    return [
        {
            path: '/template1/',
            elements: <Template1 value={JSON.parse(localStorage.getItem('Colorsets') as string)} pages={['']} logo={''}/>,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        },
        {
            path: '/template2/',
            elements: <Template2 value={['']} pages={['']} logo={''}/>,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        },
        {
            path: '/template3/',
            elements: <Template3 value={['']} pages={['']} logo={''}/>,
            roles: [
                'superadmin',
                'admin'
            ],
            isNested:false
        },

    ]
}

export const TemplateMenuConfig = (value: any) => {
    return [
        {
            header: 'Templates',
            icon: 'login',
            roles: [
                'superadmin',
                'admin',
            ],
            route: '/templates/',
        }
    ]
}
