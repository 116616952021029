/**
 * Manages navigation data and visibility of individual pages.
 *
 * @params {string[]} nav - An array of navigation link names.
 * @params {boolean} checkPageAboutMe - Visibility state of the "About Me" page.
 * @params {boolean} checkPageOffers - Visibility state of the "Offers" page.
 * @params {boolean} checkPageGallery - Visibility state of the "Gallery" page.
 * @params {boolean} checkPageTeam - Visibility state of the "Team" page.
 * @params {boolean} checkPagePrice - Visibility state of the "Price" page.
 * @params {boolean} checkPageNews - Visibility state of the "News" page.
 * @params {boolean} checkPageOwn - Visibility state of the "Own" page.
 * @params {string} ownPage - The own page title.
 * @params {boolean} checkOwnPageTitle - Check if the own page title is set
 * @params {number} pageNumber - The number of selected pages.
 *
 */

import React, {useReducer, useState} from "react";
import '../css/step.css'
import {IntlMessage, MIcon, Store, WK24Button, Wk24Cards, WK24Col, Wk24Form, WK24Row} from "../../../@WK24/core";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useNavigate} from "react-router-dom";
import Template1 from "../../templates/Pages";
import Template2 from "../../templates/Pages/Template2";
import Template3 from "../../templates/Pages/Template3";
import StepReducer from "../store/reducers/step/StepReducer";
import {StepActionType} from "../store/actions/ActionType"

interface Step3Props {
    value: any
}

const Step3 = (props: Step3Props) => {
    const websiteType = JSON.parse(localStorage.getItem('Website') as string);
    let navItemType: string = 'Galerie';
    if (websiteType === "Website") {
        navItemType = 'Galerie';
    }
    if (websiteType === "WebsiteBlog") {
        navItemType = 'Blog';
    }
    if (websiteType === "WebsiteShop") {
        navItemType = 'Shop';
    }

    const [nav, setNav] = useState(['Home', 'Kontakt', 'Über mich', 'Angebote', navItemType]);
    const [checkPageAboutMe, setCheckPageAboutMe] = useState<boolean>(true);
    const [checkPageOffers, setCheckPageOffers] = useState<boolean>(true);
    const [checkPageGallery, setCheckPageGallery] = useState<boolean>(true);
    const [checkPageTeam, setCheckPageTeam] = useState<boolean>(false);
    const [checkPagePrice, setCheckPagePrice] = useState<boolean>(false);
    const [checkPageNews, setCheckPageNews] = useState<boolean>(false);
    const [checkPageOwn, setCheckPageOwn] = useState<boolean>(false);
    const [ownPage, setOwnPage] = useState<string>('');
    const [checkOwnPageTitle, setCheckOwnPageTitle] = useState<boolean>(false);
    const [checkPageOwn2, setCheckPageOwn2] = useState<boolean>(false);
    const [ownPage2, setOwnPage2] = useState<string>('');
    const [checkOwnPageTitle2, setCheckOwnPageTitle2] = useState<boolean>(false);
    const [checkPageOwn3, setCheckPageOwn3] = useState<boolean>(false);
    const [ownPage3, setOwnPage3] = useState<string>('');
    const [checkOwnPageTitle3, setCheckOwnPageTitle3] = useState<boolean>(false);
    const [validated, setValidated] = useState(false);
    const [pageNumber, setPageNumber] = useState<number>(3);
    const [webTypeBlog, setWebTypeBlog] = useState<boolean>(() => websiteType === "WebsiteBlog");
    const [webTypeShop, setWebTypeShop] = useState<boolean>(() => websiteType === "WebsiteShop");
    const [webTypeSite, setWebTypeSite] = useState<boolean>(() => websiteType === "Website");
    const [checkPageShop, setCheckPageShop] = useState<boolean>(true);
    const [checkPageBlog, setCheckPageBlog] = useState<boolean>(true);

    const navigate = useNavigate()
    const [step3, step3Dispatch] = useReducer(StepReducer,
        {
            template: {template: null},
            colors: {colors: null},
            logo: {logo: null},
            name: {name: null},
            pages: {pages: null}
        })

    const BackNavigate = () => {
        navigate('/step2/')
    }

    const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        const localPages = localStorage.getItem('Navigation');
        if (localPages) {
            step3Dispatch({type: StepActionType.PAGE_SUCCESS, payload: {localPages}});
            navigate('/step3a/')
        }
    };

    /** Preview of the selected template */
    const PreviewTemplate = () => {
        const template = localStorage.getItem('Template');
        const colorSet = localStorage.getItem('ColorSet');

        localStorage.setItem('Navigation', JSON.stringify(nav));

        if (template === "Tmp01" && colorSet) {
            localStorage.setItem('Pages', JSON.stringify(nav));
            return (<Template1 value={JSON.parse(colorSet)} pages={nav} logo={'Logo'}/>)
        }
        if (template === "Tmp02" && colorSet) {
            localStorage.setItem('Pages', JSON.stringify(nav));
            return (<Template2 value={JSON.parse(colorSet)} pages={nav} logo={'Logo'}/>)
        }
        if (template === "Tmp03" && colorSet) {
            localStorage.setItem('Pages', JSON.stringify(nav));
            return (<Template3 value={JSON.parse(colorSet)} pages={nav} logo={'Logo'}/>)
        }
    }

    /** Set navi with selected items */
    const navPages = (pageCheck:boolean, pageItem:string) => {
        let existingItem = false;
        let pages = pageNumber;

        for (let i = 0; i < nav.length; i++) {
            if(nav[i] === pageItem){
                existingItem = true;
            }
        }

        if (pageCheck && !existingItem) {
            if (pages <= 3) {
                setNav(current => [...current, pageItem]);
                pages = pages + 1;
            }
        } else {
            if (!pageCheck && existingItem) {
                setNav(nav.filter(item => item !== pageItem));
                pages = pages - 1;
            }
        }
        setPageNumber(pages);
        console.log(nav);
    }

    return (
        <div className="Step noneSidebar">
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <Wk24Form validated={validated} Submit={handleSubmit}>
                <WK24Row>
                    <WK24Col>
                        <Wk24Form.Label class="header text-center"
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Step3.header.label',
                                            Store: Store
                                        })}/>
                        <div className="giraffeStep3">
                            <img className="giraIcon" src={'/gira5.jpg'} alt={''}/>
                        </div>
                        <p className="text-center mt-3 stepAbstand" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Step3.title', Store: Store})
                        }}/>
                        <Wk24Form.Label
                            text={IntlMessage({locale: 'de', messageId: 'Step3.preselection.label', Store: Store})}
                            class={'m-3 pageSelection'}
                            InfoButton={{
                                display: true,
                                text: IntlMessage({locale: 'de', messageId: 'Step3.preselection.info', Store: Store})
                            }}/>
                        <WK24Row class={' m-3 pagegroup'}>
                            <WK24Col class={'p-2 border border-primary m-1'}>
                                <Wk24Cards bg={"dark"} text={'white'}>
                                    <Wk24Cards.Body>
                                        <Wk24Form.Checks type={'checkbox'}
                                                         label={IntlMessage({
                                                             locale: 'de',
                                                             messageId: 'Step3.about.label',
                                                             Store: Store
                                                         })}
                                                         value={IntlMessage({
                                                             locale: 'de',
                                                             messageId: 'Step3.about.label',
                                                             Store: Store
                                                         })}
                                                         checked={checkPageAboutMe}
                                                         disabled={!checkPageAboutMe && (pageNumber >= 3)}
                                                         onChange={(e: { target: { value: string, } }) => {
                                                             navPages(!checkPageAboutMe, e.target.value);
                                                             setCheckPageAboutMe((prev) => !prev)
                                                         }}/>
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            <WK24Col class={'p-2 border border-primary m-1'}>
                                <Wk24Cards bg={"dark"} text={'white'}>
                                    <Wk24Cards.Body>
                                        <Wk24Form.Checks type={'checkbox'}
                                                         value={IntlMessage({
                                                             locale: 'de',
                                                             messageId: 'Step3.offers.label',
                                                             Store: Store
                                                         })}
                                                         label={IntlMessage({
                                                             locale: 'de',
                                                             messageId: 'Step3.offers.label',
                                                             Store: Store
                                                         })}
                                                         checked={checkPageOffers}
                                                         disabled={!checkPageOffers && (pageNumber >= 3)}
                                                         onChange={(e: { target: { value: string } }) => {
                                                             navPages(!checkPageOffers, e.target.value);
                                                             setCheckPageOffers((prev) => !prev)
                                                         }}/>
                                    </Wk24Cards.Body>
                                </Wk24Cards>
                            </WK24Col>
                            {webTypeBlog &&
                                <WK24Col class={'p-2 border border-primary m-1'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.blog.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.blog.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageBlog}
                                                             disabled={!checkPageBlog && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageBlog, e.target.value);
                                                                 setCheckPageBlog((prev) => !prev)
                                                             }}/>
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                            }
                            {webTypeShop &&
                                <WK24Col class={'p-2 border border-primary m-1'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.shop.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.shop.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageShop}
                                                             disabled={!checkPageShop && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageShop, e.target.value);
                                                                 setCheckPageShop((prev) => !prev)
                                                             }}/>
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                            }
                            {webTypeSite &&
                                <WK24Col class={'p-2 border border-primary m-1'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.gallery.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.gallery.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageGallery}
                                                             disabled={!checkPageGallery && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageGallery, e.target.value);
                                                                 setCheckPageGallery((prev) => !prev)
                                                             }}/>
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                            }
                        </WK24Row>
                        {(webTypeBlog || webTypeShop) && <>
                            <Wk24Form.Label
                                text={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step3.additionalPages.label',
                                    Store: Store
                                })}
                                class={'m-3 pageSelection'}
                            />
                            <p className="ml-3 mb-4" dangerouslySetInnerHTML={{
                                __html: IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step3.additionalPageBlog.text',
                                    Store: Store
                                })
                            }}/>
                        </>}

                        {webTypeSite && <>
                            <Wk24Form.Label
                                text={IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step3.additionalPages.label',
                                    Store: Store
                                })}
                                class={'m-3 pageSelection'}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessage({
                                        locale: 'de',
                                        messageId: 'Step3.additionalPages.info',
                                        Store: Store
                                    })
                                }}/>
                            <p className="ml-3 mb-4" dangerouslySetInnerHTML={{
                                __html: IntlMessage({
                                    locale: 'de',
                                    messageId: 'Step3.additionalPageWebsite.text',
                                    Store: Store
                                })
                            }}/>
                            <WK24Row class={' m-3 pagegroup'}>
                                <WK24Col class={'p-2 border border-primary m-1'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.team.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.team.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageTeam}
                                                             disabled={!checkPageTeam && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageTeam, e.target.value);
                                                                 setCheckPageTeam((prev) => !prev)
                                                             }}/>
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                                <WK24Col class={'p-2 border border-primary m-1'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.price.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.price.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPagePrice}
                                                             disabled={!checkPagePrice && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPagePrice, e.target.value);
                                                                 setCheckPagePrice((prev) => !prev)
                                                             }}/>
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                                <WK24Col class={'p-2 border border-primary m-1'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.news.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.news.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageNews}
                                                             disabled={!checkPageNews && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageNews, e.target.value);
                                                                 setCheckPageNews((prev) => !prev)
                                                             }}/>
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                                <WK24Col class={'p-2 border border-primary m-1 ownpage'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.own.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.own.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageOwn}
                                                             disabled={!checkPageOwn && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageOwn, e.target.value);
                                                                 setCheckPageOwn((prev) => !prev);
                                                                 setCheckOwnPageTitle(false)
                                                             }}/>
                                            {checkPageOwn &&
                                                <Wk24Form.InputGroup>
                                                    <Wk24Form.FormControl type={'text'}
                                                                          name={IntlMessage({
                                                                              locale: 'de',
                                                                              messageId: 'Step3.own.title',
                                                                              Store: Store
                                                                          })}
                                                                          placeholder={IntlMessage({
                                                                              locale: 'de',
                                                                              messageId: 'Step3.own.placeholder',
                                                                              Store: Store
                                                                          })}
                                                                          value={ownPage}
                                                                          disabled={checkOwnPageTitle}
                                                                          onChange={(e: {
                                                                              target: { value: string }
                                                                          }) => {
                                                                              setOwnPage(e.target.value)

                                                                          }}
                                                    />
                                                    <WK24Button children={<MIcon children="check" variant="-outlined"/>}
                                                                type={'button'} variant={'primary'}
                                                                onClick={() => {
                                                                    for (let i = 0; i < nav.length; i++) {
                                                                        if (nav[i] === 'Eigene Seite 1') {
                                                                            nav[i] = ownPage;
                                                                        }
                                                                    }
                                                                    setCheckOwnPageTitle(true)
                                                                }}/>
                                                    <WK24Button
                                                        children={<MIcon children="delete" variant="-outlined"/>}
                                                        type={'button'} class={'changeBtn'} variant={'primary'}
                                                        onClick={() => {
                                                            let last = ownPage;
                                                            setOwnPage('');
                                                            for (let i = 0; i < nav.length; i++) {
                                                                if (nav[i] === last) {
                                                                    nav[i] = 'Eigene Seite';
                                                                }
                                                            }
                                                            setCheckOwnPageTitle(false);
                                                        }}/>
                                                </Wk24Form.InputGroup>
                                            }
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                                <WK24Col class={'p-2 border border-primary m-1 ownpage'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.own2.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.own2.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageOwn2}
                                                             disabled={!checkPageOwn2 && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageOwn2, e.target.value);
                                                                 setCheckPageOwn2((prev) => !prev);
                                                                 setCheckOwnPageTitle2(false)
                                                             }}/>
                                            {checkPageOwn2 &&
                                                <Wk24Form.InputGroup>
                                                    <Wk24Form.FormControl type={'text'}
                                                                          name={IntlMessage({
                                                                              locale: 'de',
                                                                              messageId: 'Step3.own.title',
                                                                              Store: Store
                                                                          })}
                                                                          placeholder={IntlMessage({
                                                                              locale: 'de',
                                                                              messageId: 'Step3.own.placeholder',
                                                                              Store: Store
                                                                          })}
                                                                          value={ownPage2}
                                                                          disabled={checkOwnPageTitle2}
                                                                          onChange={(e: {
                                                                              target: { value: string }
                                                                          }) => {
                                                                              setOwnPage2(e.target.value)
                                                                          }}
                                                    />
                                                    <WK24Button children={<MIcon children="check" variant="-outlined"/>}
                                                                type={'button'} variant={'primary'}
                                                                onClick={() => {
                                                                    for (let i = 0; i < nav.length; i++) {
                                                                        if (nav[i] === 'Eigene Seite 2') {
                                                                            nav[i] = ownPage2;
                                                                        }
                                                                    }
                                                                    setCheckOwnPageTitle2(true)
                                                                }}/>
                                                    <WK24Button
                                                        children={<MIcon children="delete" variant="-outlined"/>}
                                                        type={'button'} class={'changeBtn'} variant={'primary'}
                                                        onClick={() => {
                                                            let last = ownPage2;
                                                            setOwnPage2('');
                                                            for (let i = 0; i < nav.length; i++) {
                                                                if (nav[i] === last) {
                                                                    nav[i] = 'Eigene Seite 2';
                                                                }
                                                            }
                                                            setCheckOwnPageTitle2(false);
                                                        }}/>
                                                </Wk24Form.InputGroup>
                                            }
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                                <WK24Col class={'p-2 border border-primary m-1 ownpage'}>
                                    <Wk24Cards bg={"dark"} text={'white'}>
                                        <Wk24Cards.Body>
                                            <Wk24Form.Checks type={'checkbox'}
                                                             value={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.own3.label',
                                                                 Store: Store
                                                             })}
                                                             label={IntlMessage({
                                                                 locale: 'de',
                                                                 messageId: 'Step3.own3.label',
                                                                 Store: Store
                                                             })}
                                                             checked={checkPageOwn3}
                                                             disabled={!checkPageOwn3 && (pageNumber >= 3)}
                                                             onChange={(e: { target: { value: string } }) => {
                                                                 navPages(!checkPageOwn3, e.target.value);
                                                                 setCheckPageOwn3((prev) => !prev);
                                                                 setCheckOwnPageTitle3(false)
                                                             }}/>
                                            {checkPageOwn3 &&
                                                <Wk24Form.InputGroup>
                                                    <Wk24Form.FormControl type={'text'}
                                                                          name={IntlMessage({
                                                                              locale: 'de',
                                                                              messageId: 'Step3.own.title',
                                                                              Store: Store
                                                                          })}
                                                                          placeholder={IntlMessage({
                                                                              locale: 'de',
                                                                              messageId: 'Step3.own.placeholder',
                                                                              Store: Store
                                                                          })}
                                                                          value={ownPage3}
                                                                          disabled={checkOwnPageTitle3}
                                                                          onChange={(e: {
                                                                              target: { value: string }
                                                                          }) => {
                                                                              setOwnPage3(e.target.value)
                                                                          }}
                                                    />
                                                    <WK24Button children={<MIcon children="check" variant="-outlined"/>}
                                                                type={'button'} variant={'primary'}
                                                                onClick={() => {
                                                                    for (let i = 0; i < nav.length; i++) {
                                                                        if (nav[i] === 'Eigene Seite 3') {
                                                                            nav[i] = ownPage3;
                                                                        }
                                                                    }
                                                                    setCheckOwnPageTitle3(true)
                                                                }}/>
                                                    <WK24Button
                                                        children={<MIcon children="delete" variant="-outlined"/>}
                                                        type={'button'} class={'changeBtn'} variant={'primary'}
                                                        onClick={() => {
                                                            let last = ownPage3;
                                                            setOwnPage3('');
                                                            for (let i = 0; i < nav.length; i++) {
                                                                if (nav[i] === last) {
                                                                    nav[i] = 'Eigene Seite 3';
                                                                }
                                                            }
                                                            setCheckOwnPageTitle3(false);
                                                        }}/>
                                                </Wk24Form.InputGroup>
                                            }
                                        </Wk24Cards.Body>
                                    </Wk24Cards>
                                </WK24Col>
                            </WK24Row>
                        </>}
                    </WK24Col>
                    <WK24Col class={'text-center pt-6 previewImage'} style={{backgroundColor: 'transparent'}}>
                        {PreviewTemplate()}
                    </WK24Col>
                </WK24Row>
                <WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-3'}>
                            <ProgressBar now={66.668} style={{height: '5px'}} className={'mt-3'}/>
                        </WK24Col>
                    </WK24Row>
                    <WK24Row class={'pr-5 pl-5'}>
                        <WK24Col class={'mt-4'}>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnBackward.label', Store: Store})}
                                class={'float-start'} variant={'outline-primary'}
                                        onClick={BackNavigate}/>
                            <WK24Button
                                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                                type={'submit'} class={'float-end'} variant={'primary'}/>
                        </WK24Col>
                    </WK24Row>
                </WK24Row>
            </Wk24Form>
        </div>
    )
}

export default Step3;
