/**
 * Manages the state of the media success page.
 *
 */

import React, {useEffect} from "react";
import '../css/order.css'
import {IntlMessage, Store, WK24Button} from "../../../@WK24/core";
import {useNavigate} from "react-router-dom";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";

import {PDFDownloadLink} from '@react-pdf/renderer';
import InovicePDF from '../../invoicePDF'

interface OrderProps {
    value: any
}

const Order = (props: OrderProps) => {

    const userItem = localStorage.getItem('User');
    const user = userItem ? JSON.parse(userItem) : null
    console.log(user);

    useEffect(() => {
        let date = new Date();
        const localDomain = JSON.parse(localStorage.getItem('Domain') as string);
        const localAuthCode = JSON.parse(localStorage.getItem('AuthCode') as string);
        const localTemplate = (localStorage.getItem('Template') as string).replace("Tmp", "");
        const localColor = JSON.parse(localStorage.getItem('ColorSet') as string);
        const localNavigation = JSON.parse(localStorage.getItem('Navigation') as string);
        const localProject = JSON.parse(localStorage.getItem('Project') as string);
        const localLogo = JSON.parse(localStorage.getItem('Logo') as string);
        const localFavicon = JSON.parse(localStorage.getItem('Favicon') as string);
        const localPage = JSON.parse(localStorage.getItem('setPage') as string);
        const localAccount = JSON.parse(localStorage.getItem('WK2024AccountData') as string);
        const localPayment = JSON.parse(localStorage.getItem('PaymentIntent') as string);
        const priceWebsite = JSON.parse(localStorage.getItem('priceWebsite') as string);
        const priceHosting = JSON.parse(localStorage.getItem('priceHosting') as string);
        const orderDescription = JSON.parse(localStorage.getItem('orderDescription') as string);
        const orderWebsiteTyp = JSON.parse(localStorage.getItem('Website') as string);

        let settingsBack = {
            "favicon": localFavicon,
            "backend": null,
            "frontend": localDomain,
            "version": "0.0",
            "lastUpdate": date,
            "lastAccess": date,
            "isActive": "1",
            "login": null,
            "companyName": localProject,
            "logo": localLogo,
            "colors": {
                "font": localColor[0],
                "primary": localColor[1],
                "secondary": localColor[2],
                "tertiary": localColor[3],
                "background": localColor[4]
            },
            "template": localTemplate,
            "AuthCode": localAuthCode
        }

        /*  Proejct Settings */
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization:"Basic "+ BasicAuth()
            },
            body: JSON.stringify(settingsBack)
        };
        fetch(ApiRoot() + "projetcSettings", requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result)
                localStorage.setItem('ProjectSettingsID', JSON.stringify(result.projectSettingsID));
                let menuBack = {
                    "navigation": {
                        "mainNavigation": [
                            {
                                "position": "1",
                                "state": "1",
                                "path": "/" + (localNavigation[0].toLowerCase().replace(/ /g, "-")),
                                "title": localNavigation[0],
                                "projectSettingsID": result.projectSettingsID
                            },
                            {
                                "position": "2",
                                "state": "1",
                                "path": "/" + (localNavigation[1].toLowerCase().replace(/ /g, "-")),
                                "title": localNavigation[1],
                                "projectSettingsID": result.projectSettingsID
                            },
                            {
                                "position": "3",
                                "state": "1",
                                "path": "/" + (localNavigation[2].toLowerCase().replace(/ /g, "-")),
                                "title": localNavigation[2],
                                "projectSettingsID": result.projectSettingsID
                            },
                            {
                                "position": "4",
                                "state": "1",
                                "path": "/" + (localNavigation[3].toLowerCase().replace(/ /g, "-")),
                                "title": localNavigation[3],
                                "projectSettingsID": result.projectSettingsID
                            },
                            {
                                "position": "5",
                                "state": "1",
                                "path": "/" + (localNavigation[4].toLowerCase().replace(/ /g, "-")),
                                "title": localNavigation[4],
                                "projectSettingsID": result.projectSettingsID
                            }
                        ],
                        "serviceNavigation": [
                            {
                                "position": "1",
                                "state": "1",
                                "path": "/impressum",
                                "title": "Impressum",
                                "projectSettingsID": result.projectSettingsID
                            },
                            {
                                "position": "2",
                                "state": "1",
                                "path": "/datenschutz",
                                "title": "Datenschutz",
                                "projectSettingsID": result.projectSettingsID
                            },
                            {
                                "position": "3",
                                "state": "1",
                                "path": "/sitemap",
                                "title": "Sitemap",
                                "projectSettingsID": result.projectSettingsID
                            }
                        ]
                    }
                }
                const requestOptionsMenu = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Basic " + BasicAuth()
                    },
                    body: JSON.stringify(menuBack)
                };
                fetch(ApiRoot() + "menu/" + result.projectSettingsID, requestOptionsMenu)
                    .then((response) => response.text())
                    .then((resultMenu: any) => {

                        /* Page */
                        let pageBack = {
                            "settingsID": result.projectSettingsID,
                            "title": localPage[0].title,
                            "path": localPage[0].path,
                            "siteTitle": localPage[0].siteTitle,
                            "metaDescription": localPage[0].metaDescription,
                            "metaProperty": [
                                {
                                    "property": "og:type",
                                    "content": localPage[0].metaProperty[0].content,
                                },
                                {
                                    "property": "og:image",
                                    "content": localPage[0].metaProperty[1].content,
                                },
                                {
                                    "property": "og:locale",
                                    "content": localPage[0].metaProperty[2].content,
                                },
                                {
                                    "property": "og:site_name",
                                    "content": localPage[0].metaProperty[3].content,
                                },
                                {
                                    "property": "og:title",
                                    "content": localPage[0].metaProperty[4].content,
                                },
                                {
                                    "property": "og:description",
                                    "content": localPage[0].metaProperty[5].content,
                                },
                                {
                                    "property": "og:url",
                                    "content": localPage[0].metaProperty[6].content,
                                }
                            ],
                            "state": localPage[0].state,
                            "metaCanonical": localPage[0].metaCanonical,
                            "pageContent": {
                                "header": {
                                    "styleClass": localPage[0].pageContent.header.styleClass,
                                    "headline": localPage[0].pageContent.header.headline,
                                    "text": localPage[0].pageContent.header.text,
                                    "button": {
                                        "link": localPage[0].pageContent.header.button.link,
                                        "text": localPage[0].pageContent.header.button.text
                                    },
                                    "images": [
                                        localPage[0].pageContent.header.images[0]
                                    ]
                                },
                                "main": [
                                    {
                                        "content": localPage[0].pageContent.main[0].content
                                    }
                                ],
                                "footer": {
                                    "company": localProject,
                                    "email": localPage[0].pageContent.footer.email,
                                    "phone": localPage[0].pageContent.footer.phone
                                }
                            }
                        }
                        const requestOptionsPage = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: "Basic " + BasicAuth()
                            },
                            body: JSON.stringify(pageBack)
                        };
                        fetch(ApiRoot() + "page", requestOptionsPage)
                            .then((response) => response.text())
                            .then((resultPage: any) => {

                                /* Order */
                                let orderBack = {
                                    name: localProject,
                                    projectSeetingsId: result.projectSettingsID,
                                    employee: [
                                        {
                                            "id": "47"
                                        },
                                        {
                                            "id": "32"
                                        }
                                    ],
                                    customer: {
                                        userID: user.id,
                                        firstname: localAccount.firstname,
                                        name: localAccount.lastname,
                                        email: localAccount.email,
                                        company: localAccount.company,
                                        smallBusiness: localAccount.smallBusiness,
                                        freelancer: localAccount.freelancer,
                                        employeesNumber: localAccount.employeesNumber,
                                        subjectToVat: localAccount.requiredVat,
                                        taxID: localAccount.vat,
                                        commercialRegister: {
                                            registryCourt: localAccount.commercial_register.court,
                                            department: localAccount.commercial_register.department,
                                            number: localAccount.commercial_register.number
                                        },
                                        address: {
                                            street: localAccount.addresses[0].street,
                                            postCode: localAccount.addresses[0].zip,
                                            location: localAccount.addresses[0].city
                                        }
                                    },
                                    order: {
                                        price: (priceWebsite + priceHosting),
                                        description: orderWebsiteTyp + orderDescription,
                                        payment: {
                                            paymentID: localPayment.paymentID,
                                            createDate: localPayment.createDate,
                                            status: localPayment.status,
                                            intent: localPayment.intent,
                                            secret: localPayment.secret,
                                            userID: user.id,
                                            stripeCustomerId: localPayment.stripeCustomerId,
                                            subcriptionStipeID: localPayment.subcriptionStipeID
                                        }
                                    }
                                }
                                const requestOptionsOrder = {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: "Basic " + BasicAuth()
                                    },
                                    body: JSON.stringify(orderBack)
                                };
                                fetch(ApiRoot() + "order/" + result.projectSettingsID, requestOptionsOrder)
                                    .then((response) => response.text())
                                    .then((resultOrder: any) => console.log(resultOrder))
                                    .catch((error) => console.error(error));
                            })
                            .catch((error) => console.error(error));
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
    }, []);


    const backgroundImage = 'url(/orderbg.jpg)';

    const navigate = useNavigate()

    return (
        <div className="Order successStep backendStep noneSidebar" style={{backgroundImage: backgroundImage}}>
            <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/>
            <h1 dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Order.title', Store: Store})
            }}/>
            <p className="lead mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Order.header.label', Store: Store})
            }}/>
            <p className="lead mt-3" dangerouslySetInnerHTML={{
                __html: IntlMessage({locale: 'de', messageId: 'Order.description', Store: Store})
            }}/>
            <WK24Button
                children={IntlMessage({locale: 'de', messageId: 'Order.btn.label', Store: Store})}
                type={'button'} variant={'primary'} onClick={() => navigate('/login')}
            />

            {/* Invoice download */}
            <PDFDownloadLink document={<InovicePDF/>} fileName="RechnungWeko24.pdf">
                {({blob, url, loading, error}) => (loading ? <WK24Button
                    children={IntlMessage({locale: 'de', messageId: 'Order.invoiceCreate.text', Store: Store})}
                    type={'button'} variant={'primary'} style={{marginLeft: '1em'}}/> : <WK24Button
                    children={IntlMessage({locale: 'de', messageId: 'Order.invoiceDownload.text', Store: Store})}
                    type={'button'} variant={'primary'} style={{marginLeft: '1em'}}/>)}
            </PDFDownloadLink>

        </div>
    )
}

export default Order;
