export const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },setValidated:any,navigate:any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    var formdata = new FormData(form)
    let content = '';

    const entries = formdata.entries ();
    for (const elem of entries) {
        if(elem[0] === 'editorTextarea') {
            content = content + '<div class="Webbuilder">'+elem[1]+'</div>'
        }
    }


    let Pages = JSON.parse(localStorage.getItem('Pages') as string)
    let PagesHolder = [];
    for(let p=0;p<Pages.length;p++){
        if(Pages[p] === 'Home') {
            PagesHolder.push(
                {
                    path: '/',
                    title: Pages[p],
                }
            )
        } else {
            PagesHolder.push(
                {
                    path: '/'+replaceUmlauts(Pages[p])+'/',
                    title: Pages[p],
                }
            )
        }

    }


    let back = [
                {
                    id: 0,
                    title: 'Home',
                    path: '/',
                    siteTitle: formdata.get('siteTitle'),
                    metaDescription: formdata.get('metaDescription'),
                    metaProperty: [
                        {
                            propertyID: 0,
                            property: 'og:type',
                            content: formdata.get('siteOGtype'),
                            pageID: 0
                        },
                        {
                            propertyID: 0,
                            property: 'og:image',
                            content: formdata.get('siteOGimage'),
                            pageID: 0
                        },
                        {
                            propertyID: 0,
                            property: 'og:locale',
                            content: formdata.get('siteOGlocale'),
                            pageID: 0
                        },
                        {
                            propertyID: 0,
                            property: 'og:site_name',
                            content: formdata.get('siteOGsite_name'),
                            pageID: 0
                        },
                        {
                            propertyID: 0,
                            property: 'og:title',
                            content: formdata.get('siteOGtitle'),
                            pageID: 0
                        },
                        {
                            propertyID: 0,
                            property: 'og:description',
                            content: formdata.get('siteOGdescription'),
                            pageID: 0
                        },
                        {
                            propertyID: 0,
                            property: 'og:url',
                            content: formdata.get('siteOGurl'),
                            pageID: 0
                        }
                    ],
                    state: 1,
                    metaCanonical: 'https://weko24.de/',
                    pageContent: {
                        header: {
                            id: 0,
                            styleClass: "text-right",
                            headline: formdata.get('HeaderTitle'),
                            text: formdata.get('sublineHeader'),
                            button: {
                                link: formdata.get('buttonLink'),
                                text: formdata.get('buttonText')
                            },
                            images: [
                                "https://weko24.de/wp-content/uploads/precontent/header-website.jpg"
                            ],
                            imagePosition: "center"
                        },
                        main: [
                            {
                                id: 0,
                                content: content
                            }
                        ],
                        footer: {
                            id: 0,
                            company: "Weko24",
                            email: formdata.get('emailFooter'),
                            phone: formdata.get('phoneFooter')
                        }
                    }

                }
            ]

    localStorage.setItem('setPage',JSON.stringify(back))
    navigate('/step6')
};


export const replaceUmlauts = (text: string) => {
    return text.
    replaceAll(/Ä/g, "Ae").
    replaceAll(/ä/g, "ae").
    replaceAll(/Ö/g, "Oe").
    replaceAll(/ö/g, "oe").
    replaceAll(/Ü/g, "Ue").
    replaceAll(/ü/g, "ue").
    replaceAll(/ß/g, "ss").
    replaceAll(/ /g, "-");
};
