import {Font} from "@react-pdf/renderer";

// @ts-ignore
import latoRegular from "./invoiceFonts/lato-v24-latin-regular.ttf";
// @ts-ignore
import latoBold from "./invoiceFonts/lato-v24-latin-700.ttf";

const FontsRegister = () => {
    Font.register({
        family: 'Lato',
        fonts: [
            {
                src: latoRegular,
                fontWeight: "normal"
            },
            {
                src: latoBold,
                fontWeight: "bold"
            }
        ]
    })
}
export default FontsRegister;