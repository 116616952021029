
const Card = (): string => {
    return `
    <!-- 3 box text -->
<div class="card">
  <img id="IMGCreate" class="card-img-top" src="https://cdn.wum-solution.de//WUM/2023/01/23/md/Exkurs-UX-Design.png" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div><div>Hier kannst du deinen Text Eintragen!</div>
  `;
};

export default Card;
