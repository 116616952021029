import React, {RefObject} from 'react';

interface CodeBlockProps {
    content:string
    appendCode: any;
    contentEditable: boolean;
    editorCodearea: RefObject<HTMLPreElement>;
}
/**
 * CodeBlock-Komponente zum Anzeigen und Bearbeiten von Codeinhalten.
 *
 * @param {CodeBlockProps} props - Komponenteneigenschaften
 * @param {string} props.content - Der anfängliche Codeinhalt, der angezeigt werden soll
 * @param {function} props.appendCode - Eine Callback-Funktion, die beim Aktualisieren des Codes ausgelöst wird
 * @param {boolean} [props.contentEditable=false] - Ob der Code bearbeitbar ist
 * @param {RefObject<HTMLPreElement>} props.editorCodearea - Ref-Objekt für den Zugriff auf das Codebereichselement
 * @returns {JSX.Element} Die gerenderte Codeblock-Komponente
 */
const CodeBlock = (props:CodeBlockProps) => {

    return (
        <div className={"code-block d-none"}>
                <pre ref={props.editorCodearea} contentEditable={props.contentEditable} onInput={() => props.appendCode()}>
                    <code>{props.content}</code>
                </pre>
        </div>
    );
};

export default CodeBlock;
