/**
 * Manages the state of the voucher page.
 *
 * @params {any} priceWebsite - The state of the website price.
 * @params {any} priceHosting - The state of the hosting price.
 * @params {string} voucherWebsite - The state of the voucher for website.
 * @params {string} voucherHosting - The state of the voucher for hosting.
 * @params {string} voucherWebsiteText - The state of the voucher text for website.
 * @params {string} voucherHinweis - The state of the voucher info for 50cent verifizierung.
 * @params {string} voucherHostingText - The state of the voucher text for hosting.
 * @params {boolean} checkCreateProducts - The state of the check for create products.
 * @params {boolean} checkTraining - The state of the check for training.
 * @params {boolean} checkText - The state of the check for text writing.
 *
 */

import React, {useState} from "react";
import '../../payment/css/payment.css';
import '../css/voucher.css';

import {IntlMessage, MIcon, Store, WK24Button, Wk24Cards, Wk24Form} from "../../../@WK24/core";
import {BasicAuth} from "../../../@WK24/core/RestFullApi/api";
import {ApiRoot} from "../../../@WK24/core/RestFullApi/Component/ApiRoot";
import {useNavigate} from "react-router-dom";

interface VoucherProps {
    value: any
}

const Voucher = (props: VoucherProps) => {
    const initPriceWebsite = JSON.parse(localStorage.getItem('initPriceWebsite') as string);
    const initPriceHosting = JSON.parse(localStorage.getItem('initPriceHosting') as string);
    const websiteType = JSON.parse(localStorage.getItem('Website') as string);

    const [priceWebsite, setPriceWebsite] = useState<any>(initPriceWebsite);
    const [priceHosting, setPriceHosting] = useState<any>(initPriceHosting);
    const [voucherWebsite, setVoucherWebsite] = useState('');
    const [voucherHosting, setVoucherHosting] = useState('');
    const [voucherWebsiteText, setVoucherWebsiteText] = useState('')
    const [voucherHinweis, setVoucherHinweis] = useState('')
    const [voucherHostingText, setVoucherHostingText] = useState('')
    const [checkCreateProducts, setCheckCreateProducts] = useState<boolean>(false);
    const [checkTraining, setCheckTraining] = useState<boolean>(false);
    const [checkText, setCheckText] = useState<boolean>(false);
    const [websiteShop, setWebsiteShop] = useState<boolean>(() => websiteType === "WebsiteShop");
    const [productCount, setProductCount] = useState(0);
    const navigate = useNavigate()

    /** check voucher code */
    const checkCode = (priceItem: string) => {
        let price: any;

        const raw = JSON.stringify({
            "code": voucherWebsite
        });
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Basic " + BasicAuth()
            },
            body: raw
        };
        fetch(ApiRoot() + "voucher", requestOptions)
            .then((response) => response.text())
            .then((result: any) => {
                result = JSON.parse(result);
                console.log(result);

                if (priceItem === "Website" && result.voucher.type === "1") {
                    if (result.state === 2) {
                        price = priceWebsite - ((priceWebsite / 100) * result.voucher.amount);
                        console.log(price)
                        price = parseFloat(price)
                        if (price <= 0.5) {
                            price = 0.5;
                            setPriceWebsite(0.5);
                            console.log('true');
                            setVoucherHinweis(IntlMessage({
                                locale: 'de',
                                messageId: 'Voucher.verify.label',
                                Store: Store
                            }))
                        } else {
                            setPriceWebsite(price);
                            console.log('false');
                        }
                        setVoucherWebsiteText(IntlMessage({
                            locale: 'de',
                            messageId: 'Voucher.success.label',
                            Store: Store
                        }))
                        console.log(priceWebsite)
                    } else {
                        price = initPriceWebsite;

                        if (result.state === 1) {
                            setVoucherWebsiteText(IntlMessage({
                                locale: 'de',
                                messageId: 'Voucher.used.label',
                                Store: Store
                            }))
                        }
                        if (result.state === 0) {
                            setVoucherWebsiteText(IntlMessage({
                                locale: 'de',
                                messageId: 'Voucher.error.label',
                                Store: Store
                            }))
                        }
                    }
                } else {
                    if (priceItem === "Hosting" && result.voucher.type === "2") {
                        if (result.state === 2) {
                            let price = priceHosting - ((priceHosting / 100) * result.voucher.amount);
                            setPriceHosting(price);
                            setVoucherHostingText(IntlMessage({
                                locale: 'de',
                                messageId: 'Voucher.success.label',
                                Store: Store
                            }))
                        } else {
                            let price = initPriceHosting;
                            setPriceHosting(price)
                            if (result.state === 1) {
                                setVoucherHostingText(IntlMessage({
                                    locale: 'de',
                                    messageId: 'Voucher.used.label',
                                    Store: Store
                                }))
                            }
                            if (result.state === 0) {
                                setVoucherHostingText(IntlMessage({
                                    locale: 'de',
                                    messageId: 'Voucher.error.label',
                                    Store: Store
                                }))
                            }
                        }
                    }
                }
            })
            .catch((error) => console.error(error));

    }

    /** check extra options and set price */
    const checkExtra = () => {
        let currentPrice = 0;
        let createProductPrice = 0;
        let trainingPrice = 0;
        let textPrice = 0;
        let orderDescription = '';

        if (checkCreateProducts) {
            createProductPrice = productCount * 10;
            orderDescription = orderDescription + IntlMessage({
                locale: 'de',
                messageId: 'Voucher.orderProduct.description',
                Store: Store
            }) + productCount + 'Produkt(en)/';
        } else {
            createProductPrice = 0;
        }

        if (checkTraining) {
            trainingPrice = trainingPrice + 90;
            orderDescription = orderDescription + IntlMessage({
                locale: 'de',
                messageId: 'Voucher.orderTraining.description',
                Store: Store
            });
        } else {
            trainingPrice = 0;
        }

        if (checkText) {
            textPrice = textPrice + 240;
            orderDescription = orderDescription + IntlMessage({
                locale: 'de',
                messageId: 'Voucher.orderText.description',
                Store: Store
            });
        } else {
            textPrice = 0;
        }

        currentPrice = currentPrice + createProductPrice + trainingPrice + textPrice;
        localStorage.setItem('orderDescription', JSON.stringify(orderDescription));

        return currentPrice;
    }

    /** set text for website variants */
    const webSelect = () => {
        if (websiteType === "Website") {
            return (IntlMessage({locale: 'de', messageId: 'Voucher.website.text', Store: Store}));
        }
        if (websiteType === "WebsiteBlog") {
            return (IntlMessage({locale: 'de', messageId: 'Voucher.websiteBlog.text', Store: Store}));
        }
        if (websiteType === "WebsiteShop") {
            return (IntlMessage({locale: 'de', messageId: 'Voucher.websiteShop.text', Store: Store}));
        }
    }

    /** set text for quit date */
    const checkDate = () => {
        const current = new Date();
        var numberOfMlSeconds = current.getTime();
        var addMlSeconds12 = (8760 * 60) * 60000; //für ein Jahr
        var addMlSeconds3 = (2190 * 60) * 60000; //für 3 Monate
        var endDate = new Date(numberOfMlSeconds + addMlSeconds12);
        var endDateR = new Date((numberOfMlSeconds + addMlSeconds12) - addMlSeconds3);

        let text = endDate.toLocaleDateString() + IntlMessage({
            locale: 'de',
            messageId: 'Voucher.quitDate.text',
            Store: Store
        }) + endDateR.toLocaleDateString() + IntlMessage({
            locale: 'de',
            messageId: 'Voucher.quitDate2.text',
            Store: Store
        })
        return (text)
    }

    return (
        <div className={props.value !== 'visibleNon' ? "Order pb-5 bpage" : "Order backendStep noneSidebar"}>
            {props.value === 'visibleNon' ?
                <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/> : ''}
            {props.value === 'visibleNon' ?
                <h1>{IntlMessage({
                    locale: 'de',
                    messageId: 'Voucher.title',
                    Store: Store
                })}<br/><small>{IntlMessage({locale: 'de', messageId: 'Voucher.subtitle', Store: Store})}</small></h1> :
                <h1>{IntlMessage({locale: 'de', messageId: 'Voucher.subtitle', Store: Store})}</h1>}

            <div className="d-flex flex-row flex-wrap justify-content-between">
                <div className="voucherItem">
                    <p className="mt-3" dangerouslySetInnerHTML={{
                        __html: IntlMessage({locale: 'de', messageId: 'Voucher.header.text', Store: Store})
                    }}/>
                    <h3 dangerouslySetInnerHTML={{
                        __html: IntlMessage({locale: 'de', messageId: 'Voucher.header.title', Store: Store})
                    }}/>
                    <div className="payView">
                        <div className="payBox d-flex flex-row flex-wrap">
                            <Wk24Cards style={{width: '20rem'}}>
                                <Wk24Cards.Header as="h5">
                                    <MIcon children="public" variant="fill"/> {webSelect()}
                                </Wk24Cards.Header>
                                <Wk24Cards.Body>
                                    <Wk24Cards.Text>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.price.label',
                                            Store: Store
                                        })}</strong>{(initPriceWebsite / 12).toFixed(2)} EUR
                                            {IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.priceRange.label',
                                                Store: Store
                                            })}<br/>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.firstYear.title',
                                                Store: Store
                                            })}
                                        </p>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.voucherCode.title',
                                            Store: Store
                                        })}</strong></p>
                                        <Wk24Form.InputGroup>
                                            <Wk24Form.FormControl
                                                type={'text'}
                                                class={'text-center'}
                                                name={'Gutscheincode'}
                                                placeholder={IntlMessage({
                                                    locale: 'de',
                                                    messageId: 'Voucher.voucherCode.placeholder',
                                                    Store: Store
                                                })}
                                                onChange={(e: { target: { value: string } }) => {
                                                    setVoucherWebsite(e.target.value);
                                                }}
                                            />
                                            <WK24Button
                                                children={<MIcon children="check" variant="-outlined"/>}
                                                variant={'primary'}
                                                onClick={() => {
                                                    checkCode('Website');
                                                }}/>
                                        </Wk24Form.InputGroup>
                                        <p className="voucherText">{voucherWebsiteText}<br/>{voucherHinweis}</p>
                                        <p className="mt-3" dangerouslySetInnerHTML={{
                                            __html: IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.billing.text',
                                                Store: Store
                                            })
                                        }}/>
                                    </Wk24Cards.Text>
                                </Wk24Cards.Body>
                                <Wk24Cards.Footer>
                                    <p><strong>{IntlMessage({
                                        locale: 'de',
                                        messageId: 'Voucher.billingTotal.text',
                                        Store: Store
                                    })} {(priceWebsite).toFixed(2)} EUR <small>{IntlMessage({
                                        locale: 'de',
                                        messageId: 'Voucher.firstYear.title',
                                        Store: Store
                                    })}</small></strong></p>
                                </Wk24Cards.Footer>
                            </Wk24Cards>
                            <Wk24Cards style={{width: '20rem'}}>
                                <Wk24Cards.Header as="h5"><MIcon children="dns"
                                                                 variant="fill"/> {IntlMessage({
                                    locale: 'de',
                                    messageId: 'Voucher.hosting.text',
                                    Store: Store
                                })}</Wk24Cards.Header>
                                <Wk24Cards.Body>
                                    <Wk24Cards.Text>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.price.label',
                                            Store: Store
                                        })}</strong>{(initPriceHosting / 12).toFixed(2)} EUR
                                            {IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.priceRange.label',
                                                Store: Store
                                            })}<br/>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.secondYear.title',
                                                Store: Store
                                            })}</p>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.voucherCode.title',
                                            Store: Store
                                        })}</strong></p>
                                        <Wk24Form.InputGroup>
                                            <Wk24Form.FormControl
                                                type={'text'}
                                                class={'text-center'}
                                                name={'Gutscheincode'}
                                                placeholder={IntlMessage({
                                                    locale: 'de',
                                                    messageId: 'Voucher.voucherCode.placeholder',
                                                    Store: Store
                                                })}
                                                onChange={(e: { target: { value: string } }) => {
                                                    setVoucherHosting(e.target.value);
                                                }}
                                            />
                                            <WK24Button
                                                children={<MIcon children="check" variant="-outlined"/>}
                                                variant={'primary'}
                                                onClick={() => {
                                                    checkCode('Hosting');
                                                }}/>
                                        </Wk24Form.InputGroup>
                                        <p className="voucherText">{voucherHostingText}</p>
                                        <p className="mt-3"><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.billingHosting.text',
                                            Store: Store
                                        })}</strong>{checkDate()}</p>
                                    </Wk24Cards.Text>
                                </Wk24Cards.Body>
                                <Wk24Cards.Footer>
                                    <p><strong>{IntlMessage({
                                        locale: 'de',
                                        messageId: 'Voucher.billingTotal.text',
                                        Store: Store
                                    })} {(priceHosting).toFixed(2)} EUR <small>{IntlMessage({
                                        locale: 'de',
                                        messageId: 'Voucher.secondYear.title',
                                        Store: Store
                                    })}</small></strong></p>
                                </Wk24Cards.Footer>
                            </Wk24Cards>
                            {(checkTraining || checkCreateProducts || checkText) &&
                                <Wk24Cards style={{width: '20rem'}}>
                                    <Wk24Cards.Header as="h5"><MIcon children="extension"
                                                                     variant="fill"/> {IntlMessage({
                                        locale: 'de',
                                        messageId: 'Voucher.extraCosts.text',
                                        Store: Store
                                    })}</Wk24Cards.Header>
                                    <Wk24Cards.Body>
                                        <Wk24Cards.Text>
                                            {checkCreateProducts &&
                                                <p>{productCount} {IntlMessage({
                                                    locale: 'de',
                                                    messageId: 'Voucher.createProducts.text',
                                                    Store: Store
                                                })} ({productCount} x 10,- €)</p>}
                                            {checkTraining && <p>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.training.text',
                                                Store: Store
                                            })} (1 Std. x 90,- €)</p>}
                                            {checkText && <p>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.createTexts.text',
                                                Store: Store
                                            })} (240,- €)</p>}
                                            <p><strong>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.price.label',
                                                Store: Store
                                            })}</strong>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.billingPlan.text',
                                                Store: Store
                                            })} {checkExtra()},– EUR<br/>{IntlMessage({
                                                locale: 'de',
                                                messageId: 'Voucher.firstYear.title',
                                                Store: Store
                                            })}</p>
                                            <p className="mt-3" dangerouslySetInnerHTML={{
                                                __html: IntlMessage({
                                                    locale: 'de',
                                                    messageId: 'Voucher.billing.text',
                                                    Store: Store
                                                })
                                            }}/>
                                        </Wk24Cards.Text>
                                    </Wk24Cards.Body>
                                    <Wk24Cards.Footer>
                                        <p><strong>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.billingTotal.text',
                                            Store: Store
                                        })} {(checkExtra()).toFixed(2)} EUR <small>{IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.firstYear.title',
                                            Store: Store
                                        })}</small></strong></p>
                                    </Wk24Cards.Footer>
                                </Wk24Cards>
                            }
                        </div>
                    </div>
                    {websiteShop &&
                        <p dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Voucher.shopInfo.text', Store: Store})
                        }}/>
                    }
                </div>
                <div className="voucherItem voucherBox">
                    <div className="chamiStep7a">
                        <img className="chamiIcon" src={'/chami2.png'} alt={''}/>
                    </div>
                    <p className="lead">{IntlMessage({locale: 'de', messageId: 'Voucher.help.title', Store: Store})}</p>
                    <div className="voucherInnerBox">
                        {websiteShop && <>
                            <Wk24Form.Checks type={'checkbox'}
                                             label={IntlMessage({
                                                 locale: 'de',
                                                 messageId: 'Voucher.helpProducts.label',
                                                 Store: Store
                                             })}
                                             value={'Produkte anlegen'}
                                             checked={checkCreateProducts}
                                             onChange={(e: { target: { value: string, } }) => {
                                                 setCheckCreateProducts((prev) => !prev);
                                             }}/>
                            <Wk24Form.Label class="checkLabel" text={IntlMessage({
                                locale: 'de',
                                messageId: 'Voucher.helpProductsCost.text',
                                Store: Store
                            })}/>
                            {checkCreateProducts &&
                                <Wk24Form.InputGroup>
                                    <Wk24Form.InputGroupText
                                        text={IntlMessage({
                                            locale: 'de',
                                            messageId: 'Voucher.helpProductsCount.text',
                                            Store: Store
                                        })}
                                    />
                                    <Wk24Form.Select
                                        items={[...new Array(101)]
                                            .map((each, index: any) => ({value: index, text: index}))
                                        }
                                        defaultValue={productCount}
                                        name="productCount"
                                        onChange={(e: { target: { value: string, } }) => {
                                            setProductCount(Number(e.target.value));
                                        }}
                                        class="checkboxAfterInputGroupText mb-3"
                                    />
                                </Wk24Form.InputGroup>
                            }
                        </>}
                    </div>
                    <div className="voucherInnerBox">
                        <Wk24Form.Checks type={'checkbox'}
                                         label={IntlMessage({
                                             locale: 'de',
                                             messageId: 'Voucher.helpTraining.text',
                                             Store: Store
                                         })}
                                         value={'Einarbeitung'}
                                         checked={checkTraining}
                                         onChange={(e: { target: { value: string, } }) => {
                                             setCheckTraining((prev) => !prev);
                                         }}/>
                        <Wk24Form.Label class="checkLabel" text={IntlMessage({
                            locale: 'de',
                            messageId: 'Voucher.helpTrainingCosts.text',
                            Store: Store
                        })}/>
                    </div>
                    <div className="voucherInnerBox last">
                        <Wk24Form.Checks type={'checkbox'}
                                         label={IntlMessage({
                                             locale: 'de',
                                             messageId: 'Voucher.helpTexts.text',
                                             Store: Store
                                         })}
                                         value={'Texte'}
                                         checked={checkText}
                                         onChange={(e: { target: { value: string, } }) => {
                                             setCheckText((prev) => !prev);
                                         }}/>
                        <Wk24Form.Label class="checkLabel" text={IntlMessage({
                            locale: 'de',
                            messageId: 'Voucher.helpTextsCosts.text',
                            Store: Store
                        })}/>
                    </div>
                    <div className="payBoxTotal">
                        <p className="lead" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Voucher.totalCosts.title', Store: Store})
                        }}/>
                        <table>
                            <tbody>
                            <tr>
                                <td>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Voucher.firstYear.title',
                                    Store: Store
                                })}:
                                </td>
                                <td className="text-right"><strong>{(priceWebsite + checkExtra()).toFixed(2)} EUR
                                    mtl.</strong></td>
                            </tr>
                            <tr>
                                <td>{IntlMessage({
                                    locale: 'de',
                                    messageId: 'Voucher.secondYear.title',
                                    Store: Store
                                })}:
                                </td>
                                <td className="text-right"><strong>{(priceHosting).toFixed(2)} EUR mtl.</strong>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p className="mt-3 mb-0" dangerouslySetInnerHTML={{
                            __html: IntlMessage({locale: 'de', messageId: 'Voucher.billingCosts.info', Store: Store})
                        }}/>
                    </div>

                </div>
            </div>
            <WK24Button
                children={IntlMessage({locale: 'de', messageId: 'btnForward.label', Store: Store})}
                class={'float-end'} variant={'primary'} onClick={() => {
                localStorage.setItem('priceWebsite', JSON.stringify(priceWebsite + checkExtra()));
                localStorage.setItem('priceHosting', JSON.stringify(priceHosting));
                navigate('/step8')
            }}></WK24Button>
        </div>
    )
}

export default Voucher;