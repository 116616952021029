import { requests } from '../../../../@WK24/core/RestFullApi/api'
import {customerApiType} from "./customer..interface";

export const customer = {
    getPosts: (id: number): Promise<customerApiType[]> =>
        requests.get(`customers`),
    createPost: (post: customerApiType, id: number): Promise<customerApiType> =>
        requests.put(`projetcSettings/${id}/`, post),
    updatePost: (post: any, id: number): Promise<customerApiType> =>
        requests.post(`projetcSettings/${id}/`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`projetcSettings/${id}/`),
}
