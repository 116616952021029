import {sitesApiType} from "../request/sites.interface";
import {sites} from "../request/sites";
import {useEffect, useState} from 'react'
import {SiteGeneralAPIInterface} from "../request/SiteGeneral.interface";
import {socialMedia} from "../request/socialMedia";
import {designApiType} from "../request/design.interface";

export const Response = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<sitesApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await sites
                .getPosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponseFonts = (id: any) => {
    const [posts, setPosts] = useState<sitesApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await sites
                .getPostsFonts(id)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseSocialMedia = (id: any,setSocialChannel:any,setFacebook:any,setInstagram:any,setLinkedin:any,setPinterest:any,setTiktok:any,setTwitterx:any,setYoutube:any) => {
    const [posts, setPosts] = useState<designApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await socialMedia
                .getPostsSocialMedia(id)
                .then((data) => {
                    setPosts(data)
                    setSocialChannel(data)
                    data.map((item) => {
                        if (item.type === 'facebook') {
                            setFacebook(item.link)
                        }
                        if (item.type === 'instagram') {
                            setInstagram(item.link)
                        }
                        if (item.type === 'linkedin') {
                            setLinkedin(item.link)
                        }
                        if (item.type === 'pinterest') {
                            setPinterest(item.link)
                        }
                        if (item.type === 'tiktok') {
                            setTiktok(item.link)
                        }
                        if (item.type === 'twitterx') {
                            setTwitterx(item.link)
                        }
                        if (item.type === 'youtube') {
                            setYoutube(item.link)
                        }
                    })
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}




export const ResponseSiteSingle = (id: any) => {
    const [posts, setPosts] = useState<sitesApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await sites
                .getPostsDetail(id)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseSiteGeneral = (id: any) => {
    const [posts, setPosts] = useState<SiteGeneralAPIInterface>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await sites
                .getPostsDetailGeneral(id)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}


export const ResponseActive = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<sitesApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await sites
                .getActivePosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponseDeactive = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<sitesApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await sites
                .getDeactivePosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

